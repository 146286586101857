import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import RoutingMachine from './Routing';
import '../../../node_modules/leaflet/dist/leaflet.css';

class MapComponent extends Component {
  state = {
    lat: 52.5219039,
    lng: 13.4122106,
    zoom: 13,
  }
  
  constructor() {
    super();

    this.map = React.createRef();
  }

  render() {
    const position = [this.state.lat, this.state.lng];
    const { waypoints } = this.props;

    return (
      <Map center={position} zoom={this.state.zoom} ref={this.map} scrollWheelZoom={false}>
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {!!waypoints && 
          <RoutingMachine
            color="#004594"
            map={this.map}
            routefound={(e, way, shouldUpdateInput) => this.props.routefound(e, way, shouldUpdateInput)}
            waypoints={waypoints}
          />
        }
      </Map>
    )
  }
}

export default MapComponent;