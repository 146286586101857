import _ from "lodash";
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { Table } from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import Buttons from './Buttons';
import RemoveMaterialMessage from './RemoveMaterialMessage';

class Materials extends React.Component {

  constructor(props) {
    super(props);

    const tableData = props.tableData;

    this.state = {
      column: null,
      direction: null,
      tableData
    }
  }

  handleSort = (clickedColumn) => {
    const { column, direction, tableData } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tableData: _.sortBy(tableData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      tableData: tableData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const { t, readOnly, history, materials } = this.props;
    const { column, tableData, direction } = this.state;

    return(
      <div>
        <Buttons />
        <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
        <Table celled sortable selectable className="materialsList">
          <Table.Header>
            <Table.Row style={{fontSize: '12px'}}>
              <Table.HeaderCell
                sorted={column === 'name' ? direction : null}
                onClick={() => this.handleSort('name')}
                className="textSort">
                  {t("material.name")}
              </Table.HeaderCell>

              <Table.HeaderCell 
                sorted={column === 'shortname' ? direction : null}
                onClick={() => this.handleSort('shortname')}
                className="textSort">
                  {t("material.shortname")}
              </Table.HeaderCell>

              {!readOnly && <Table.HeaderCell></Table.HeaderCell>}

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {tableData.map(m => (
              <Table.Row key={m.id} style={{fontSize: '12px'}}>
                <Table.Cell>
                  {m.name}
                </Table.Cell>

                <Table.Cell>
                  {m.shortname}
                </Table.Cell>

                {!readOnly && (<Table.Cell>
                  <div className="buttonLine" style={{justifyContent: 'center'}}>
                    <LabelledButton
                      label={t("material.actions.edit")}
                      icon="edit outline"
                      onClick={() => history.push(`/auftragsdispo/materials/${m.id}`)}
                    />

                    <RemoveMaterialMessage id={m.id} />
                  </div>
                </Table.Cell>)}

              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const Materials_routerWrapper = withRouter(Materials);
const Materials_i18nWrapper = withNamespaces('translation')(Materials_routerWrapper);

function mapStateToProps(state, props) {
  const permissions = state.permissions;
  const materials = state.materials;
  const history = props.history;
  const tableData = materials.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return { 
    readOnly: permissions.filter(p => p.perm_type === 'MENU_MATERIAL' && p.perm_desc === 'W').length < 1,
    materials,
    tableData,
    history,
  };
}

export default connect(mapStateToProps, {})(Materials_i18nWrapper);