import { ADD_MATERIAL, EDIT_MATERIAL, REMOVE_MATERIAL, LOAD_MATERIALS } from '../actions/materials';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_MATERIAL:
      return [...state, action.data];
    case EDIT_MATERIAL:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
    case REMOVE_MATERIAL:
      return state.filter(({ id }) => id !== action.id);
    case LOAD_MATERIALS:
      return action.data;
  }
}