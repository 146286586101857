import React, { Component } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Search } from 'semantic-ui-react';

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

class MapSearch extends Component {

  state = {
    results: []
  }
  
  provider = new OpenStreetMapProvider();

  handleSeach = debounce(async query => {
    const results = await this.provider.search({ query });
    await this.setState({results});
  }, 250);

  handleResultSelect = (e, { result }) => {
    let marker = {
      lat: result.lat,
      lng: result.lng
    };

    this.props.updatePosition(marker);
  }

  render() {
    const {results, value} = this.state;

    const searchOption = results.map(r => ({
      title: r.label,
      lat: r.y,
      lng: r.x,
    }));

    return (
      <Search
        fluid
        onResultSelect={this.handleResultSelect}
        onSearchChange={(e) => this.handleSeach(e.target.value)}
        results={searchOption}
        value={value}
        style={{
          marginBottom: '5px',
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: '99'
        }}
      />
    )
  }
}

export default MapSearch;