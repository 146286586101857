import { store } from "../store";
import moment from 'moment';
import { editMultipleTaktsWithoutDispatch } from "../actions/productionTakt";
import { asyncForEach } from "./async-foreach";

export async function getSilo(takt) {
  const state = store.getState();
  const orders = state.orders;
  const silos = state.silos;
  const productionTakt = state.productionTakt;

  const taktOrder = orders.filter(o => o.id == takt.orders_id)[0];
  const taktOrderAmount = taktOrder.materials.amount;

  const taktAmountPercentage = takt.amount;
  const taktAmount = taktOrderAmount / 100 * taktAmountPercentage;

  const mixingPlants = state.mixingPlants.filter(mp => mp.id == taktOrder.mixingPlant);
  const ordersId = orders.map(o => o.id);

  for (let a = 0; a < mixingPlants.length; a++) {
    const mixingPlant = mixingPlants[a];
    const mpStandardMix = mixingPlant.standard;
    const mpSilos = silos.filter(s => s.mixing_plants_id == mixingPlant.id);
    const availableSilos = [];

    for (let b = 0; b < mpSilos.length; b++) {
      const silo = mpSilos[b];
      const siloTakts = productionTakt
        .filter(pt => ordersId.indexOf(pt.orders_id) !== -1)
        .filter(pt => pt.silo == silo.id)
        .sort((a, b) => {
          const orderA = orders.filter(o => o.id == a.orders_id)[0];
          const orderB = orders.filter(o => o.id == b.orders_id)[0];
    
          if (parseInt(orderA.materials.start) < parseInt(orderB.materials.start)) return -1;
          if (parseInt(orderA.materials.start) > parseInt(orderB.materials.start)) return 1;

          return 0;
        }); 

      if (siloTakts.length === 0)
        availableSilos.push(silo);
      else {
        let siloIsFree = false;

        for (let c = 0; c < siloTakts.length; c++) {
          const taktForSilo = siloTakts[c];
          const order = orders.filter(o => o.id == taktForSilo.orders_id)[0];

          const orderForSiloStart = moment(order.materials.start);
          const orderForSiloForSiloEnd = moment(order.materials.end);
          const orderStart = moment(taktOrder.materials.start);
          const orderEnd = moment(taktOrder.materials.end);

          if(orderStart.isBetween(orderForSiloStart, orderForSiloForSiloEnd, null, '[]') ||
             orderEnd.isBetween(orderForSiloStart, orderForSiloForSiloEnd, null, '[]')) {
            siloIsFree = false;
            break;
          }
          else
            siloIsFree = true;
        }

        if(siloIsFree)
          availableSilos.push(silo);
      }
    }

    // If no silo is really "available", just take the first one
    if (availableSilos.length < 1) {
      const mixingplantSilos = silos.filter(s => s.mixing_plants_id == mixingPlant.id);
      availableSilos.push(mixingplantSilos[0]);
    }

    const idealSilo = availableSilos.reduce((previousSilo, currentSilo) => {
      const previousSiloMax = previousSilo.max;
      const currentSiloMax = currentSilo.max;

      const previousDiff = Math.abs(previousSiloMax - taktAmount);
      const currentDiff = Math.abs(currentSiloMax - taktAmount);

      if (currentDiff < previousDiff)
        return currentSilo;
      else
        return previousSilo;
    });

    return idealSilo;
  }

}

export async function assignSilo() {
  let state = store.getState();
  let productionTakt = state.productionTakt;
  let orders = state.orders;

  let ordersId = orders.map(o => o.id);

  productionTakt = productionTakt
    .filter(pt => ordersId.indexOf(pt.orders_id) !== -1)
    .filter(pt => !(!!pt.silo))
    .sort((a, b) => {
      let orderA = orders.filter(o => o.id == a.orders_id)[0];
      let orderB = orders.filter(o => o.id == b.orders_id)[0];

      if(!orderA) return -1;
      if(!orderB) return 1;

      if (parseInt(orderA.materials.start) < parseInt(orderB.materials.start)) return -1;
      if (parseInt(orderA.materials.start) > parseInt(orderB.materials.start)) return 1;
      return 0;
    }); 

  if(productionTakt.length === 0) return;

  let taktsToUpdate = [];
  await asyncForEach(productionTakt, async (takt) => {
    let silo = await getSilo(takt);

    if(silo) {
      takt.silo = silo.id;
      taktsToUpdate.push(takt);
    }
  });

  if(taktsToUpdate.length === 0) return;

  await editMultipleTaktsWithoutDispatch(taktsToUpdate);
}

// offset in minutes
// start in Epoch
export function siloCapacityByTime(siloId, startTime, offset, endTime = undefined) {
  let state = store.getState();
  let productionTakt = state.productionTakt;
  let orders = state.orders;

  let start = moment(startTime);
  let end = moment(start).add(offset, 'minutes');
  
  let takts = productionTakt.filter(pt => {
    let order = orders.filter(o => o.id == pt.orders_id)[0];

    if(!order) return false;

    let taktStart = moment(order.materials.start).add(pt.offset, 'minutes');
    let taktEnd = moment(taktStart).add(pt.duration, 'minutes');

    let orderStart = moment(order.materials.start);
    let orderEnd = moment(order.materials.end);
    
    return pt.silo == siloId && ((
      start.isBetween(taktStart, taktEnd, null, '[]') || 
      end.isBetween(taktStart, taktEnd, null, '[]')
    ) ||
    (
      start.isBetween(orderStart, orderEnd, null, '[]') || 
      end.isBetween(orderStart, orderEnd, null, '[]')
    ))
  });

  let totalProduced = takts.reduce((acc, takt) => {
    let order = orders.filter(o => o.id == takt.orders_id)[0];

    if(!order) return acc;

    let taktStart = moment(order.materials.start).add(takt.offset, 'minutes');
    let timePassed = start.diff(taktStart, 'minutes');
    let duration = parseFloat(takt.duration)/60;
    let materialAmount = parseFloat((parseFloat(takt.amount)/100) * parseFloat(order.materials.amount));

    let tonsPerHour = parseFloat(parseFloat(materialAmount)/duration);

    let produced = (tonsPerHour * timePassed)/60;

    if(produced > materialAmount) produced = materialAmount;

    acc += produced;

    return acc;
  }, 0);

  let ordersFromSilo = productionTakt.filter(pt => {
    let order = orders.filter(o => o.id == pt.orders_id)[0];

    if(!order) return false;

    let orderStart = moment(order.materials.start);
    let orderEnd = moment(order.materials.end);
    
    return pt.silo == siloId && (
      start.isBetween(orderStart, orderEnd, null, '[]') || 
      end.isBetween(orderStart, orderEnd, null, '[]')
    )
  });

  let totalDelivered = ordersFromSilo.reduce((acc, takt) => {
    let order = orders.filter(o => o.id == takt.orders_id)[0];

    if(!order) return acc;

    let orderStart = moment(order.materials.start);
    let orderEnd = moment(order.materials.end);
    let timePassed = start.diff(orderStart, 'minutes');

    if(timePassed < 0) return acc;

    let duration = orderEnd.diff(orderStart, 'minutes');
    let materialAmount = parseFloat(order.materials.amount);

    let tonsPerMinutes = parseFloat(parseFloat(materialAmount)/duration);

    acc += (tonsPerMinutes * timePassed);

    return acc;
  }, 0);

  let onSilo = totalProduced - totalDelivered;

  return onSilo;
}




export async function isSiloAvailable(taktId, siloId, newStart = null) {
  const state = store.getState();
  const orders = state.orders;
  const silos = state.silos;
  const productionTakts = state.productionTakt;
  const mixingPlants = state.mixingPlants;
  const selectedTakt = productionTakts.filter(pt => 
    pt.id == taktId
  )[0];
  const selectedSilo = silos.filter(s =>
    s.id == siloId
  )[0];
  if (!selectedSilo) return true;
  const selectedTaktOffset = parseInt(selectedTakt.offset);


  const order = orders.filter(o => 
    o.id == selectedTakt.orders_id
  )[0];
  const orderStart = moment(order.materials.start);
  const orderEnd = moment(order.materials.end);
  const orderMaterial = order.materials.material_id;

  const productionStart = newStart || (moment(orderStart.valueOf()).add(selectedTaktOffset, "minutes"));
  const productionEnd = orderEnd;

  const siloTakts = productionTakts.filter(pt =>
    pt.silo == selectedSilo.id
  );


  let isAvailable = true;  
  for (let i = 0; i < siloTakts.length; i++) {
    const siloTakt = siloTakts[i];
    const siloTaktOffset = parseInt(siloTakt.offset);

    const siloOrder = orders.filter(o => 
      o.id == siloTakt.orders_id
    )[0];

    if (!siloOrder)
      continue;

    const siloOrderStart = moment(siloOrder.materials.start);
    const siloOrderEnd = moment(siloOrder.materials.end);
    const siloOrderMaterial = siloOrder.materials.material_id;

    const siloProductionStart = moment(siloOrderStart.valueOf()).add(siloTaktOffset, "minutes");
    const siloProductionEnd = siloOrderEnd;

    if (orderMaterial !== siloOrderMaterial) {
      const isStartOverlapping = productionStart.isBetween(siloProductionStart, siloProductionEnd);
      const isEndOverlapping = productionEnd.isBetween(siloProductionStart, siloProductionEnd);

      if (isStartOverlapping || isEndOverlapping) {
        isAvailable = false;
        break;
      }
    }
  }

  return isAvailable;
}

export function isSiloAvailableSynchronous(taktId, siloId, newStart = null) {
  const state = store.getState();
  const orders = state.orders;
  const silos = state.silos;
  const productionTakts = state.productionTakt;
  const mixingPlants = state.mixingPlants;
  const selectedTakt = productionTakts.filter(pt => 
    pt.id == taktId
  )[0];
  const selectedSilo = silos.filter(s =>
    s.id == siloId
  )[0];
  if (!selectedSilo) return true;
  const selectedTaktOffset = parseInt(selectedTakt.offset);


  const order = orders.filter(o => 
    o.id == selectedTakt.orders_id
  )[0];
  const orderStart = moment(order.materials.start);
  const orderEnd = moment(order.materials.end);
  const orderMaterial = order.materials.material_id;

  const productionStart = newStart || (moment(orderStart.valueOf()).add(selectedTaktOffset, "minutes"));
  const productionEnd = orderEnd;

  const siloTakts = productionTakts.filter(pt =>
    pt.silo == selectedSilo.id
  );


  let isAvailable = true;  
  for (let i = 0; i < siloTakts.length; i++) {
    const siloTakt = siloTakts[i];
    const siloTaktOffset = parseInt(siloTakt.offset);

    const siloOrder = orders.filter(o => 
      o.id == siloTakt.orders_id
    )[0];

    if (!siloOrder)
      continue;

    const siloOrderStart = moment(siloOrder.materials.start);
    const siloOrderEnd = moment(siloOrder.materials.end);
    const siloOrderMaterial = siloOrder.materials.material_id;

    const siloProductionStart = moment(siloOrderStart.valueOf()).add(siloTaktOffset, "minutes");
    const siloProductionEnd = siloOrderEnd;

    if (orderMaterial !== siloOrderMaterial) {
      const isStartOverlapping = productionStart.isBetween(siloProductionStart, siloProductionEnd);
      const isEndOverlapping = productionEnd.isBetween(siloProductionStart, siloProductionEnd);

      if (isStartOverlapping || isEndOverlapping) {
        isAvailable = false;
        break;
      }
    }
  }

  return isAvailable;
}