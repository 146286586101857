import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TimelineElementsCapacity from './TimelineElementsCapacity'
import TimelineElementsCapacityLineChart from './TimelineElementsCapacityLineChart'
import TimelineSiloCapacityLineChart from './TimelineSiloCapacityLineChart'
import SiloDiagram from "../../../Silos/SiloDiagram/SiloDiagram";

class Capacity extends Component {
  static propTypes = {
    hasRightSidebar: PropTypes.bool.isRequired,
    showPeriod: PropTypes.func.isRequired,
    canvasTimeStart: PropTypes.number.isRequired,
    canvasTimeEnd: PropTypes.number.isRequired,
    canvasWidth: PropTypes.number.isRequired,
    minUnit: PropTypes.string.isRequired,
    timeSteps: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    headerLabelFormats: PropTypes.object.isRequired,
    subHeaderLabelFormats: PropTypes.object.isRequired,
    stickyOffset: PropTypes.number,
    stickyHeader: PropTypes.bool.isRequired,
    headerLabelGroupHeight: PropTypes.number.isRequired,
    headerLabelHeight: PropTypes.number.isRequired,
    leftSidebarHeader: PropTypes.node,
    rightSidebarHeader: PropTypes.node,
    leftSidebarWidth: PropTypes.number,
    rightSidebarWidth: PropTypes.number,
    headerRef: PropTypes.func.isRequired,
    scrollHeaderRef: PropTypes.func.isRequired
  }

  render() {
    const {
      width,
      stickyOffset,
      stickyHeader,
      headerRef,
      scrollHeaderRef,
      hasRightSidebar,
      showPeriod,
      canvasTimeStart,
      canvasTimeEnd,
      canvasWidth,
      minUnit,
      timeSteps,
      headerLabelFormats,
      subHeaderLabelFormats,
      headerLabelGroupHeight,
      headerLabelHeight,
      leftSidebarHeader,
      rightSidebarHeader,
      leftSidebarWidth,
      rightSidebarWidth,
      visibleTimeStart,
      visibleTimeEnd,
      intendedFocus,
      mixingPlants,
      isMixtureMaster,
      showSiloCapacityGraph
    } = this.props

    const headerStyle = {
      top: stickyHeader ? stickyOffset || 0 : 0
    }

    // const headerClass = stickyHeader ? 'header-sticky' : ''
    const headerClass = ''

    // const leftSidebar = leftSidebarHeader && leftSidebarWidth > 0 && (
    //   <div
    //     className="rct-sidebar-header"
    //     style={{ width: leftSidebarWidth }}
    //   >
    //     {leftSidebarHeader}
    //   </div>
    // )

    const leftSidebar = (
      <div
        className="rct-sidebar-header"
        style={{ width: leftSidebarWidth }}
      >
        {leftSidebarHeader}
      </div>
    )

    const rightSidebar = rightSidebarHeader && rightSidebarWidth > 0 && (
      <div
        className="rct-sidebar-header rct-sidebar-right"
        style={{ width: rightSidebarWidth }}
      >
        {rightSidebarHeader}
      </div>
    )

    const mixingPlantsIds = mixingPlants.map(mp => mp.id);

    let graph = null;
    if(intendedFocus === 'day' && !isMixtureMaster) {
      graph = (
        <TimelineElementsCapacity
          data-testid="timeline-elements-header"
          hasRightSidebar={hasRightSidebar}
          showPeriod={showPeriod}
          canvasTimeStart={canvasTimeStart}
          canvasTimeEnd={canvasTimeEnd}
          canvasWidth={canvasWidth}
          minUnit={minUnit}
          timeSteps={timeSteps}
          step={this.props.step}
          width={width}
          headerLabelFormats={headerLabelFormats}
          subHeaderLabelFormats={subHeaderLabelFormats}
          headerLabelGroupHeight={headerLabelGroupHeight}
          headerLabelHeight={headerLabelHeight}
          scrollHeaderRef={scrollHeaderRef}
          mixingPlants={this.props.mixingPlants}
          orders={this.props.orders}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onScroll={this.props.onScroll}
          traditionalZoom={this.props.traditionalZoom}
        />
      );
    }

    if(intendedFocus !== 'day' && !isMixtureMaster) { 
      graph = (
        <TimelineElementsCapacityLineChart
          data-testid="timeline-elements-header-capacity-line-chart"
          hasRightSidebar={hasRightSidebar}
          showPeriod={showPeriod}
          canvasTimeStart={canvasTimeStart}
          canvasTimeEnd={canvasTimeEnd}
          canvasWidth={canvasWidth}
          minUnit={minUnit}
          timeSteps={timeSteps}
          width={width}
          headerLabelFormats={headerLabelFormats}
          subHeaderLabelFormats={subHeaderLabelFormats}
          headerLabelGroupHeight={headerLabelGroupHeight}
          headerLabelHeight={headerLabelHeight}
          scrollHeaderRef={scrollHeaderRef}
          mixingPlants={this.props.mixingPlants}
          orders={this.props.orders}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          onScroll={this.props.onScroll}
          traditionalZoom={this.props.traditionalZoom}
        />
      );
    }

    if(isMixtureMaster) {
      graph = (
        <TimelineSiloCapacityLineChart
          data-testid="timeline-elements-header-silo-capacity-line-chart"
          hasRightSidebar={hasRightSidebar}
          showPeriod={showPeriod}
          canvasTimeStart={canvasTimeStart}
          canvasTimeEnd={canvasTimeEnd}
          canvasWidth={canvasWidth}
          minUnit={minUnit}
          timeSteps={timeSteps}
          width={width}
          headerLabelFormats={headerLabelFormats}
          subHeaderLabelFormats={subHeaderLabelFormats}
          headerLabelGroupHeight={headerLabelGroupHeight}
          headerLabelHeight={headerLabelHeight}
          scrollHeaderRef={scrollHeaderRef}
          mixingPlants={this.props.mixingPlants}
          orders={this.props.orders}
          productionTakt={this.props.productionTakt}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          cursorMarkerDate={this.props.cursorMarkerDate}
          silos={this.props.silos}
          currentTime={this.props.currentTime}
          onScroll={this.props.onScroll}
          traditionalZoom={this.props.traditionalZoom}
        />
      );
    }

    return (
      <div
        className={`rct-header-container ${headerClass}`}
        data-testid="timeline-elements-container"
        ref={headerRef}
        style={headerStyle}
      >
        {leftSidebar}
        <div style={{ width, height: '190px' }} data-testid="timeline-elements-header-container">
          {graph}
        </div>
        {isMixtureMaster && showSiloCapacityGraph &&
          <div style={{
              width: "calc(100% - 200px)",
              height: "200px",
              position: "absolute",
              right: "0",
              background: "white",
              border: "1px solid #00000057",
              // marginRight: "14px",
              // marginTop: "30px",
              paddingLeft: "10px",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.34)"
            }}>
            <div style={{
              position: 'absolute',
              bottom: '0'
            }}>
              <SiloDiagram time={this.props.currentTime} mixingPlants={mixingPlantsIds} />
            </div>
          </div>
        } 
      </div>
    )
  }
}

export default Capacity
