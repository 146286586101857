import { store } from '../store';

export const SET_LOADING_STATE = 'app/SET_LOADING_STATE';
export const SET_APPSTATE = 'app/SET_APPSTATE';
export const SET_ADMIN_VIEW_PROD_TAKT = 'app/SET_ADMIN_VIEW_PROD_TAKT';
export const SET_PRODUCTION_VIEW = 'app/SET_PRODUCTION_VIEW';
export const SET_SILO_CAPACITY_DATA = 'app/SET_SILO_CAPACITY_DATA';
export const SET_MULTI_SELECT = 'app/SET_MULTI_SELECT';
export const SET_FULL_SCREEN = 'app/SET_FULL_SCREEN';

export async function setLoadingState(state) {
  await store.dispatch({
    type: SET_LOADING_STATE,
    state
  });
}

export function updateAppState(state) {
  return (dispatch) => {
    const stateToUpdate = {
      calendar_visibletimestart: state.visibleTimeStart,
      calendar_visibletimeend: state.visibleTimeEnd,
      calendar_zoomstep: state.step,
      calendar_mp_filter: state.mixingPlantFilter,
      calendar_show_capacity: state.showHorizontalCapacity,
      calendar_show_silo_graph: state.showSiloCapacityGraph
    };

    dispatch({
      type: SET_APPSTATE,
      stateToUpdate
    })
  }
}

export function viewProductionTakt(mixingPlantId) {
  return (dispatch) => {
    dispatch({
      type: SET_ADMIN_VIEW_PROD_TAKT,
      mixingPlantId
    });
  }
}

export function changeProductionView(view) {
  return (dispatch) => {
    dispatch({
      type: SET_PRODUCTION_VIEW,
      view
    });
  } 
}

export function setSiloCapacityData(data) {
  store.dispatch({
    type: SET_SILO_CAPACITY_DATA,
    data
  });
}

export function setMultiSelect() {
  return (dispatch) => {
    dispatch({
      type: SET_MULTI_SELECT
    });
  } 
}

export function setFullScreen() {
  return (dispatch) => {
    dispatch({
      type: SET_FULL_SCREEN
    });
  } 
}