export const SAVE_TO_STORE = 'basedata/save_to_store';
export const LOAD_BASEDATA = 'basedata/load_basedata';

export function saveToStore(data) {
  return dispatch => {
    dispatch({
      type: SAVE_TO_STORE,
      data
    })
  }
}