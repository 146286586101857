import { 
  ADD_PRODUCTION_TAKT, 
  EDIT_PRODUCTION_TAKT, 
  REMOVE_PRODUCTION_TAKT, 
  LOAD_PRODUCTION_TAKT, 
  ADD_MULTIPLE_PRODUCTION_TAKT,
  REMOVE_MULTIPLE_PRODUCTION_TAKT,
  EDIT_MULTIPLE_TAKTS
} from '../actions/productionTakt';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_PRODUCTION_TAKT:
      return [...state, action.data];
    case EDIT_PRODUCTION_TAKT:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
    case EDIT_MULTIPLE_TAKTS:
      let taktsIds = action.data.map(d => d.id);
      return state.map(m => {
        if(taktsIds.indexOf(m.id) !== -1) {
          let updatedTakt = action.data.filter(t => t.id == m.id)[0];

          if(!updatedTakt) return m;

          return {
            ...m,
            ...updatedTakt
          }

        } else {
          return m;
        }
      });
    case ADD_MULTIPLE_PRODUCTION_TAKT: 
      return [...state, ...action.data];
    case REMOVE_PRODUCTION_TAKT:
      return state.filter(({ id }) => id !== action.id);
    case REMOVE_MULTIPLE_PRODUCTION_TAKT:
      return state.filter(({ id }) => action.data.indexOf(id) === -1);
    case LOAD_PRODUCTION_TAKT:
      return action.data;
  }
}