import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Icon, Button, Popup } from "semantic-ui-react";
import LabelledButton from '../../LabelledButton/LabelledButton';

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class DeleteOrderModal extends React.Component {

  state = {
    open: false
  };

  handleModal = open => {

    if(open && this.props.selected.length === 0) return;

    let state = {
      open: open
    };

    this.setState(state);
  };

  handlePositive = async (state) => {
    this.setState({open: false});
    await this.props.deleteOrder();
  }

  render() {

    const { t } = this.props;
    const { open } = this.state;
    
    return(
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
        trigger={
          <Popup 
            trigger={
              <Button
                className="buttonPadRight"
                // label={t("order.actions.delete")}
                icon="trash"
                onClick={() => this.handleModal(true)}
              />
            }
            position="top center"
            content={t("order.actions.delete")}
          />
        }
      >
        <Modal.Header>{t("order.remove.header")}</Modal.Header>
        <Modal.Content>
          {t("order.remove.message")}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const DeleteOrderModal_i18nWrapper = withNamespaces('translation')(DeleteOrderModal);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(DeleteOrderModal_i18nWrapper);