import moment from 'moment';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { updateSchedule } from '../../actions/scheduler';
import { Segment, Header, Button, Icon, Input, Label } from 'semantic-ui-react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Leaflet from 'leaflet'
import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet-routing-machine/dist/leaflet-routing-machine'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'leaflet-routing-machine/dist/leaflet.routing.icons.png'
import MapComponent from '../MapLocal/Map'


class SubComponent extends React.Component {
    state = {
        id: "",
        waypoints: {
            to: [],
            back: []
        },
    }

    componentDidMount() {
        const { row, waypoints, subComponentOpenToggle, mixingplant, constructionSite } = this.props;
        const orderid = row.original.orderid;
        let newWaypoints;

        const waypointsAvailable = !!waypoints && 
                                    typeof waypoints === "object" &&
                                    (!!waypoints.to && waypoints.to.length >= 2) &&
                                    (!!waypoints.back && waypoints.back.length >= 2);

        if (!waypointsAvailable) {
            const mpPos = mixingplant.position;
            const csPos = constructionSite.position;

            newWaypoints = {
                to: [
                    mpPos,
                    csPos
                ],
                back: [
                    csPos,
                    mpPos
                ]
            }
        }

        subComponentOpenToggle(orderid);

        this.setState({
            id: orderid,
            waypoints: waypointsAvailable ? waypoints : newWaypoints
        });
    }

    componentWillUnmount() {
        const { subComponentOpenToggle } = this.props;
        const { id } = this.state;

        subComponentOpenToggle(id);
    }

    handleInput = (field, e) => {
        const { id } = this.state;
        e.persist();

        this.props.handleInput(id, field, e);
    }

    routefound = (e, way, shouldUpdateInput) => {
        const { id } = this.state;
        let tmpWaypoints = [];
        let waypoints = Object.assign(this.state.waypoints);

        for (let i = 0; i < e.waypoints.length; i++) {
            let waypoint = e.waypoints[i];
            tmpWaypoints.push(waypoint.latLng);
        }
        waypoints[way] = tmpWaypoints;

        const routeSummary = e.routes[0].summary;

        const routeTime = routeSummary.totalTime;
        const time = Math.round(routeTime / 60);

        if (way === "to" && !this.props.data.wayto)
            this.props.data.wayto = time;
        else if (!this.props.data.wayback)
            this.props.data.wayback = time;
            


        this.props.routeFound(id, time, way, waypoints, shouldUpdateInput);
        
        this.setState({
            waypoints
        });
    }

    render() {
        const { t, data } = this.props;
        const { waypoints } = this.state;
        const { wayto, load, paver, installation, leave, wayback } = data;
        let total = Math.round(parseInt(wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(wayback)) || "";

        return (
            <div className="subComponent">
                <MapComponent 
                    className="map"
                    zoom={10}
                    routefound={(e, way, shouldUpdateInput) => this.routefound(e, way, shouldUpdateInput)}
                    waypoints={waypoints}>
        
                    <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">Here Maps</a> contributors'
                    url="https://bpo-asphalt.de/common/interfaces/tile.php?z={z}&x={x}&y={y}&key=auftrafsdispo&type=truck"
                    />
                </MapComponent>
                <div className="cycleTimes">
    
                    <div className="title">
                        <h2>
                            {t("scheduler.sidebar_labels.title")}
                        </h2>
                    </div>
    
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.load")}</label>
                        <Input type="number" min="0" onInput={(e) => this.handleInput("load", e)} placeholder='0 min' value={data.load}/>
                    </div>
    
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.wayto")}</label>
                        <Input type="number" min="0" onInput={(e) => this.handleInput("wayto", e)} placeholder='0 min' value={data.wayto}/>
                    </div>
    
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.paver")}</label>
                        <Input type="number" min="0" onInput={(e) => this.handleInput("paver", e)} placeholder='0 min' value={data.paver}/>
                    </div>
                
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.installation")}</label>
                        <Input type="number" min="0" onInput={(e) =>this.handleInput("installation", e)} placeholder='0 min' value={data.installation}/>
                    </div>
                
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.leave")}</label>
                        <Input type="number" min="0" onInput={(e) => this.handleInput("leave", e)} placeholder='0 min' value={data.leave}/>
                    </div>
                    
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.wayback")}</label>
                        <Input type="number" min="0" onInput={(e) => this.handleInput("wayback", e)} placeholder='0 min' value={data.wayback}/>
                    </div>
            
                    <div className="cycleTime">
                        <label>{t("scheduler.sidebar_labels.total")}</label>
                        <Input readOnly type="number" min="0" placeholder='0 min' value={total}/>
                    </div>
                </div>
            </div>
        );    
    }
}

export default withNamespaces('translation')(
   (SubComponent)
)