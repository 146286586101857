import React from 'react';
import { connect } from "react-redux";
import { Popup } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';
import { save } from '../../actions/authentication';
import SaveImg from "../../images/save.png";


const Save = ({ t, save, authtenticated }) => {

  const handleClick = e => {
    if(authtenticated) save()
  }

  return(
    <Popup trigger={
      <a className="ad" title="" onClick={handleClick}>
				<img alt={t("topbuttons.save")} src={SaveImg} />
			</a>
    } content={t('topbuttons.save')} />
  );
}

const Save_i18nWrapper = withNamespaces('translation')(Save);

const mapStateToProps = (state, props) => {
  return { authtenticated: state.auth.authenticated };
};

export default connect(mapStateToProps, {save})(Save_i18nWrapper);