import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Tab, Segment, Header } from "semantic-ui-react";
import { saveToStore } from '../../actions/basedata';
import BaseData from '../BaseData/BaseData';
import MixingPlants from '../MixingPlants/MixingPlants';
import Materials from '../Materials/Materials';
import Customers from '../Customers/Customers';
import Settings from '../Settings/Settings';

const tabsKeys = {
  "basedata": 0,
  "mixingplants": 1,
  "materials": 2,
  "customers": 3,
  "silos": 4,
  "settings": 5
};

class Home extends React.Component {

  state = {
    activeIndex: this.props.tab
  }

  componentWillMount() {
    const {permissions, history} = this.props;

    let canSeeMasterdata = permissions.filter(p => p.perm_type === 'MASTERDATA');

    if(canSeeMasterdata.length === 0) history.push("/auftragsdispo/login");
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {

    const { t, permissions } = this.props;

    const panes = [];
    let permMenu = permissions.filter(p => p.perm_type.indexOf('MENU_') !== -1);

    let material = false;
    let customers = false;
    let mixingPlant = false;
    let stammdaten = false;

    permMenu.forEach(menu => {
      switch (menu.perm_type) {
        case "MENU_MATERIAL":
          if(!material){
            panes.push({ index: 2, menuItem: t('menu.item3'), render: () => <Tab.Pane><Materials /></Tab.Pane> });
            material = true;
          }
          break;
        case "MENU_CUSTOMERS":
          if(!customers){
            panes.push({ index: 3, menuItem: t('menu.item6'), render: () => <Tab.Pane><Customers /></Tab.Pane> });
            customers = true;
          }
          break;
        case "MENU_MIXING_PLANT":
        if(!mixingPlant) {
          panes.push({ index: 1, menuItem: t('menu.item2'), render: () => <Tab.Pane><MixingPlants /></Tab.Pane> });
          mixingPlant = true;
        }
          break;
        case "MENU_STAMMDATEN":
        if(!stammdaten){
          panes.push({ index: 0, menuItem: t('basedata.header'), render: () => <Tab.Pane><BaseData /></Tab.Pane> });
          stammdaten = true;
        }
        break;
        // case "MENU_SILO":
        //   panes.push({ index: 4, menuItem: t('menu.item7'), render: () => <Tab.Pane><Silos /></Tab.Pane> });
        //   break;
        default:
          break;
      }
    });

    panes.push({ index: 4, menuItem: t('settings.header'), render: () => <Tab.Pane><Settings /></Tab.Pane> });

    panes.sort((a, b) => parseInt(a.index, 10) - parseInt(b.index, 10));

    return(
      <div className='page'>
        <Segment raised>
            <Header as="h2">
              <Header.Content>{t("menu.item1")}</Header.Content>
            </Header>
          <Tab activeIndex={this.state.activeIndex} panes={panes} onTabChange={this.handleTabChange} />
        </Segment>
      </div>
    );
  }
}

const Home_i18nWrapper = withNamespaces('translation')(Home);

function mapStateToProps(state, props) {
  let tab = 0;

  if(props.location.search !== "" && props.location.search.includes('tab')) {
    const tabKey = props.location.search.split('=')[1];
    tab = tabsKeys[tabKey];

    if(!tab) tab = 0;
  }

  return {
    tab,
    permissions: state.permissions,
    history: props.history
  };
}

export default connect(mapStateToProps, {saveToStore})(Home_i18nWrapper);
