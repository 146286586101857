export const UPDATE_SCHEDULE = 'scheduler/UPDATE_SCHEDULE';

export function updateSchedule(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_SCHEDULE,
      data
    });
  }
}
