import uuid from 'uuid/v4';
import moment from 'moment';
import { addDeletedEntry } from './deleted';

export const ADD_MIXING_PLANT = 'mixingplants/ADD_MIXING_PLANT';
export const EDIT_MIXING_PLANT = 'mixingplants/EDIT_MIXING_PLANT';
export const REMOVE_MIXING_PLANT = 'mixingplants/REMOVE_MIXING_PLANT';
export const LOAD_MIXING_PLANT = 'mixingplants/LOAD_MIXING_PLANT';

export function addMixingPlant(data) {
  return dispatch => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;
    dispatch({
      type: ADD_MIXING_PLANT,
      data
    });
  }
}

export function editMixingPlant(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_MIXING_PLANT,
      data
    });
  }
}

export function removeMixingPlant(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'mixing_plants'
    });

    dispatch({
      type: REMOVE_MIXING_PLANT,
      id
    });
  }
}