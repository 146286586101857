import React from 'react';
import { withNamespaces } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popup, Icon } from 'semantic-ui-react';
import moment from 'moment';

class DayViewItem extends React.Component {

  editOrderGroup = (orderGroup) => {
    this.props.history.push(`/auftragsdispo/orders/edit/${orderGroup}`);
  }

  openRealtime = (orderId) => {
    // window.open(`/schedule/${day}/${mpid}`)
    this.props.history.push(`/auftragsdispo/realtime/${orderId}`);
  }

  render() {
    const {
      customer, 
      order, 
      openModal, 
      item,
      selected,
      constructionSite,
      showCustomerAsTitle,
      mixingPlants
    } = this.props;
    
    const titleArray = item.title.split('*-*');

    let selectedStyle = {};
    if(selected) {
      selectedStyle.border = 'none';
      // selectedStyle.boxShadow = 'inset 0 0 8px #000000bf';
    }

    console.log(order.materials.obs,'obsss');

    let nohover = order.materials.obs.replace(/\s/g, "") === ""? "nohover" : "";
    let orderDay = moment(order.materials.start).startOf("day").valueOf();
    let mpid = order.mixingPlant;
    let orderId = order.id;
    const mixingplant = mixingPlants.filter(
      mp => mp.id === order.mixingPlant
    )[0];
    let readonly = false;

    if (!!mixingplant)
      readonly = mixingplant.readonly;

    return (
      <div className="itemBox" style={{backgroundColor: item.color, color: item.textColor, ...selectedStyle}}>

        <Popup 
          trigger={(
            <div className="itemBoxTop" onDoubleClick={() => {if (!readonly) openModal(true, item.id)}}>{showCustomerAsTitle ? customer.name : constructionSite.name}</div>
          )}
          content={(
            <div>{constructionSite.name}</div>
          )}
          position='top center'
          hideOnScroll
        />
        
        <div className="itemBoxCenter" onDoubleClick={() => {if (!readonly) openModal(true, item.id)}}>{`${titleArray[1]} - ${titleArray[2]}`}</div>
        <div className="itemBoxBottomGrouper">
          <div className="itemBoxBottom"
            onClick={() => readonly ? {} : openModal(true, item.id)}
            style={{cursor: 'pointer'}}
          >
            {!readonly && 
              <Icon name="edit" />
            }
          </div>
          <div className="itemBoxBottom"
            onClick={() => readonly ? {} : this.editOrderGroup(order.orderGroup)}
            style={{cursor: 'pointer'}}
          >
            {!readonly &&
              <Icon name="object group" />
            }
          </div>
          <div className={`itemBoxBottom infobutton ${nohover}`}>
            {order.materials.obs.replace(/\s/g, "") !== "" &&  !readonly &&
              (
                <Popup trigger={
                  <Icon name="info" className="filldiv"/>
                } content={order.materials.obs} position='top center' hideOnScroll on={['hover', 'click']}/>
              )
            }
          </div>
          <div className="itemBoxBottom"
            // onClick={() => window.open('../?chosen=1c67ad5ce1aa5970ef9d9b918adc6bd9.bfd&chosenproj=Ad-Hoc-Planungen%20%282016%29&optfilename=', '_blank')}
            onClick={() => { if (!readonly) this.openRealtime(orderId) }}
            style={{cursor: 'pointer'}}
          >
            { !readonly &&
            <Icon name="stopwatch"/>
            }
          </div>
        </div>
      </div>
    );
  }
}

const DayViewItem_i18nWrapper = withNamespaces('translation')(DayViewItem);

function mapStateToProps(state, props) { 
  const orders = state.orders;
  const customers = state.customers;
  const constructionSites = state.constructionSites;
  const item = props.item;
  const settings = state.settings;
  const mixingPlants = state.mixingPlants;

  const showCustomerAsTitle = settings.filter(s => s.name === 'show_customer_in_box' && s.value === '0').length > 0;

  let order = orders.filter(o => o.id === item.id)[0];
  let constructionSite = constructionSites.filter(cs => cs.id === order.constructionSite)[0];
  let customer = customers.filter(c => c.id === constructionSite.customer_id)[0];

  return {
    customer,
    order,
    history: props.history,
    constructionSite,
    showCustomerAsTitle,
    mixingPlants
  }
}

export default withRouter(connect(mapStateToProps, {})(DayViewItem_i18nWrapper));
