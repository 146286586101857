import { ADD_DELETED_ENTRY, LOAD_DELETED, ADD_MULTIPLE_DELETED_ENTRY } from '../actions/deleted';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_DELETED_ENTRY:
      return [...state, action.data];
    case ADD_MULTIPLE_DELETED_ENTRY:
      return [...state, ...action.data];
    case LOAD_DELETED:
      return action.data;
  }
}