import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { updateSchedule } from '../../actions/scheduler';
import { Segment, Header, Button, Icon, Input } from 'semantic-ui-react';
import moment from 'moment';


class Diagram extends React.Component {
    state = {
        constructionSites: {},
        constructionSiteNames: {}, 
    }
    
    componentDidMount = () => {
        const data = this.props.location.state.data;
        this.drawTaktdiagramm(data);
    } 

    populateList = (diagramm_t_h, diagramStartEnd) => {
        let output = {};
        for (let i = 0; i < Object.keys(diagramm_t_h).length; i++) {
            let csName = Object.keys(diagramm_t_h)[i];

            if (!(csName in output))
                output[csName] = [];

            let keys = Object.keys(diagramm_t_h[csName]);
            var len = keys.length;
            keys.sort();
            
            var diagramm_t_h_first = diagramStartEnd.start;
            var diagramm_t_h_last = diagramStartEnd.end;
    
            var tmpOutput = new Array();
            var tempo = 0;
            for (var di = diagramm_t_h_first; di <= diagramm_t_h_last; di++) {
                if (typeof diagramm_t_h[csName][di] != "undefined") {
                    tempo = diagramm_t_h[csName][di];
                }
                var temp = new Array(parseInt(di), parseInt(tempo));
                tmpOutput.push(temp);
            }

            output[csName] = tmpOutput;
        } 
        
        return output;
    } 

    makeSmall(t) {
        return parseInt(moment(t).unix() / 60);
    }

    format_time(t) {
        return moment(t * 60 * 1000).format("HH:mm");
    }
    
    drawTaktdiagramm = (data) => {
        const { t } = this.props;
        const globalConstructionSites = this.props.location.state.constructionSites;
        const dayOrders = this.props.location.state.dayOrders;

        var cont_id = "taktplan";
        var self = this;
        var tagesmenge = 0;
        var site_amounts = {};
        var site_trucks = {};
        var lkw_diagramm = [];
        var lkw_diagramm_labels = [];
        var lkw_color = [];
        var diagramm_t_h = {};
        var maxwert = 0;
        var truckmap = {};
        var truckcount = 1;
        var minwert = 999999999999;
        var colors = ["#004594", "#940500", "#009405", "#8f9400", "#050094", "#944f00", "#00944f", "#4f0094", "#940045", "#459400", "#008f94", "#94008f"];
        var constructionSites = {};
        var constructionSiteNames = {};
        let diagramStartEnd = {
            start: Infinity,
            end: -Infinity
        };

        for (var i = 0; i < data.lfs.length; i++) {

            var start = this.makeSmall(data.lfs[i].loadStart);
            var end = this.makeSmall(data.lfs[i].returnPlant);
            var ustart = this.makeSmall(data.lfs[i].unloadStart);
            var uend = this.makeSmall(data.lfs[i].unloadEnd);
            
            if (start < diagramStartEnd.start)
                diagramStartEnd.start = start;

            if (end > diagramStartEnd.end) 
                diagramStartEnd.end = end;

            let csName = data.lfs[i].site;
            if (!(csName in constructionSites)) {
                let colorIndex = Object.keys(constructionSites).length % colors.length;

                var color = colors[colorIndex];
                constructionSites[csName] = color;
            }

            if (!(csName in diagramm_t_h)){
                diagramm_t_h[csName] = {};
                site_amounts[csName] = 0;
                site_trucks[csName] = 0;
            }

            
            site_amounts[csName] += parseFloat(data.lfs[i].amount);
            diagramm_t_h[csName][start] = site_amounts[csName];
            tagesmenge = Math.max(site_amounts[csName],tagesmenge);
            if (!(data.lfs[i].lkw in truckmap))
                truckmap[data.lfs[i].lkw] = truckcount++;
        }
        
        for (let key in site_amounts) {
            let order = dayOrders.filter((dayOrder) => {
                return dayOrder.id == key
            })[0];

            let constructionSite = globalConstructionSites.filter((gcs) => {
                return gcs.id == order.constructionSite
            })[0];

            constructionSiteNames[key] = constructionSite.name;
        }

        tagesmenge += Math.round(tagesmenge * 0.02);
        
        var faktor = Math.floor(tagesmenge / data.used);
     

        for (var i = 0; i < data.lfs.length; i++) { 
            var sfaktor = tagesmenge / (2 * data.used) * (2 * truckmap[data.lfs[i].lkw] - 1); 
            var start = this.makeSmall(data.lfs[i].loadStart);
            var end = this.makeSmall(data.lfs[i].returnPlant);
            var ustart = this.makeSmall(data.lfs[i].unloadStart);
            var uend = this.makeSmall(data.lfs[i].unloadEnd);
            let csName = data.lfs[i].site;
            let rawTruckLabel = data.lfs[i].lkw;
            let trucklabel = (rawTruckLabel).split("-");
            trucklabel = trucklabel[trucklabel.length - 1].substring(2);
            minwert = Math.min(start, minwert);
            maxwert = Math.max(end, maxwert);

            site_trucks[csName] += 1;

            if (data.lfs[i].pauseData.length) {
                var pstart = parseInt(data.lfs[i].pauseData[1] / 60);
                var pend = parseInt(data.lfs[i].pauseData[2] / 60);
                lkw_diagramm.push([[start, sfaktor, pstart - start], [pend, sfaktor, end - pend], [ustart, sfaktor, uend - ustart]]);
                lkw_color.push(constructionSites[csName]);
            } else {
                lkw_diagramm.push([[start, sfaktor, end - start], [ustart, sfaktor, uend - ustart]]);
                lkw_color.push(constructionSites[csName]);

            }
            lkw_diagramm_labels.push(trucklabel);
        }

        // $("#takt_legende").empty();
        // for (var mixture in mixes) {

        //     $("<div/>").addClass("colorgroup").append([$("<div/>").addClass("colorcolor").css("background-color", mixes[mixture]), $("<div/>").addClass("colortext").text(mixture)]).appendTo($("#takt_legende"));
        // }

        this.setState({
            constructionSites,
            constructionSiteNames
        });

        var diagramm_t_h_x = this.populateList(diagramm_t_h, diagramStartEnd);

        var mouse_track = false;
        var cross = {};
        var select_x = {};

        if (window.innerWidth > 1139) {
            select_x = {
                mode: 'x',
                fps: 30
            };
            mouse_track = true;
            cross = {
                mode: 'xy'
            };
        }

        (function basic_timeline(container) {
            var data = [],
                    timeline = {
                        show: true,
                        barWidth: .8 * faktor
                    },
                    markers = [],
                    labels = lkw_diagramm_labels,
                    colors = lkw_color,
                    i, graph, point;
            // Timeline

            window.Flotr._.each(lkw_diagramm, function (d) {
                data.push({
                    data: d,
                    timeline: window.Flotr._.clone(timeline)
                });
            });
            
            // Markers
            window.Flotr._.each(lkw_diagramm, function (d) {
                point = d[0];
                markers.push([point[0], point[1]]);
            });


            let diagramKeys = Object.keys(diagramm_t_h_x);

            for (let diagramKeyIndex = 0; diagramKeyIndex < diagramKeys.length; diagramKeyIndex++) {
                let diagramKey = diagramKeys[diagramKeyIndex];

                data.push({
                    data: diagramm_t_h_x[diagramKey],
                    color: constructionSites[diagramKey],
                    mouse: {
                        track: mouse_track,
                        lineColor: 'purple',
                        relative: true,
                        position: 'sw',
                        sensibility: 30,
                        // => The smaller this value, the more precise you've to point
                        trackDecimals: 0,
                        trackFormatter: function (obj) {
                            return self.format_time(obj.x) + ` ${t("scheduler.graph.time")} • (` + constructionSiteNames[diagramKey] + ") - " + obj.y + 't';
                        }
                    }
                });
            }

            data.push({
                data: markers,
                markers: {
                    show: true,
                    position: 'w',
                    fontSize: 10,
                    labelFormatter: function (o) {
                        return labels[o.index];
                    }
                }
            });
            // Draw Graph
            var options = {
                crosshair: cross,
                selection: select_x,
                resolution: 2,
                colors: colors,
                xaxis: {
                    noTicks: 10,
                    title: t("scheduler.graph.x_label"),
                    max: maxwert + 2,
                    min: minwert - 1,
                    tickFormatter: function (x) {
                        return self.format_time(x);
                    },
                    autoscale: true,
                },
                yaxis: {
                    noTicks: 10,
                    title: "t",
                    titleAngle: 0,
                    min: 0,
                    max: tagesmenge,
                    autoscale: true,

                },
                grid: {
                    horizontalLines: false
                }
            },      
            graph = window.Flotr.draw(container, data, options);
            console.log(graph);

            function drawGraph(opts) {
                // Clone the options, so the 'options' variable always keeps intact.
                var o = window.Flotr._.extend(window.Flotr._.clone(options), opts || {});
                // Return a new graph.
                return window.Flotr.draw(container, data, o);
            }
            // Actually draw the graph.
            graph = drawGraph();
            // Hook into the 'window.Flotr:select' event.
            if (mouse_track) {
                // window.Flotr.EventAdapter.observe(container, 'window.Flotr:select', function (area) {
                //     // Draw graph with new area
                //     graph = drawGraph({
                //         xaxis: {
                //             min: area.x1,
                //             max: area.x2,
                //             noTicks: 10,
                //             title: "Uhrzeit",
                //             tickFormatter: function (x) {
                //                 return self.format_time(x);
                //             }
                //         },
                //         yaxis: {
                //             min: area.y1,
                //             max: area.y2,
                //             noTicks: 10,
                //             title: "t",
                //             titleAngle: 0
                //         }
                //     });
                // });
                // When graph is clicked, draw the graph with default area.
                window.Flotr.EventAdapter.observe(container, 'window.Flotr:click', function () {
                    drawGraph();
                });
            }
        })(document.getElementsByClassName(cont_id)[0]);
    }

    handleReturn = _ => {
        const { day, mpid } = this.props.match.params;
        this.props.history.push(`/auftragsdispo/schedule/${day}/${mpid}`);
    }

    generateLegend = () => {
        const { constructionSites, constructionSiteNames } = this.state;

        if (Object.keys(constructionSites).length < 1)
            return;

        const csKeys = Object.keys(constructionSites);


        if (constructionSites) {
            let legend = csKeys.map((csn, i) => 

                <div key={`${csn}${i}`} className="legendItem">
                    <div className="colorBox" style={{backgroundColor: constructionSites[csn]}}></div>
                    <span className="csName">
                        {
                            constructionSiteNames[csn]
                        }
                    </span>
                </div>
            );
            
            return legend;
        }
    }

    render() {
        const { t } = this.props;
        const { day } = this.props.match.params;
        const dayString = moment(parseFloat(day)).format("L");
        if (isNaN(day) || !day)
            dayString = "";

        return(
            <div className='page cycleDiagramPage'>
                <Segment raised>
                    <Header as="h2" className="left floated">
                        <Header.Content>{`${t("scheduler.header")} - ${dayString}`}</Header.Content>
                    </Header>

                    <div style={{textAlign: 'right'}}>
                        <Button.Group>
                        <Button
                            type="button"
                            onClick={() => this.handleReturn()}
                        >
                            <Icon name="arrow left" />
                            {t("buttons.return")}
                        </Button>
                        </Button.Group>
                    </div>


                    <div>
                        <div className="taktplan" style={{width: "100%", height: "600px"}}></div>
                        <div id="legend">{this.generateLegend()}</div>
                    </div>
                </Segment>
            </div> 
        );
    } 
} 

export default withNamespaces('translation')(
    (Diagram)
);