export const defaultKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time'
}

export const defaultTimeSteps = {
  second: 1,
  minute: 1,
  hour: 1,
  day: 1,
  month: 1,
  year: 1
}

export const defaultHeaderLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM/YY',
  monthMedium: 'MM/YYYY',
  monthMediumLong: 'MMM YYYY',
  monthLong: 'MMMM YYYY',
  dayShort: 'L',
  // dayShort: 'ddd, DD.MM.YY',
  dayLong: 'dddd, LL',
  hourShort: 'HH',
  hourMedium: 'HH:00',
  hourMediumLong: 'L, HH:00',
  hourLong: 'dddd, LL, HH:00',
  time: 'LLL',
  week: 'W, MMM YYYY',
}

// export const defaultSubHeaderLabelFormats = {
//   yearShort: 'YY',
//   yearLong: 'YYYY',
//   monthShort: 'MM',
//   monthMedium: 'MMM',
//   monthLong: 'MMMM',
//   dayShort: 'D',
//   dayMedium: 'dd D',
//   dayMediumLong: 'ddd, Do',
//   dayLong: 'dddd, Do',
//   hourShort: 'HH',
//   hourLong: 'HH:00',
//   minuteShort: 'mm',
//   minuteLong: 'HH:mm',
//   week: 'W',
// }

export const defaultSubHeaderLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM',
  monthMedium: 'MMM',
  monthLong: 'MMMM',
  dayShort: 'D',
  // dayMedium: 'dd D',
  dayMedium: 'ddd, DD',
  dayMediumLong: 'ddd DD.MM',
  dayLong: 'ddd DD.MM.YY',
  hourShort: 'HH',
  hourLong: 'HH:00',
  minuteShort: 'mm',
  minuteLong: 'HH:mm',
  week: 'W',
}

export const defaultSubHeaderLabelFormatsEN = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM',
  monthMedium: 'MMM',
  monthLong: 'MMMM',
  dayShort: 'D',
  // dayMedium: 'dd D',
  dayMedium: 'ddd, DD',
  dayMediumLong: 'ddd MM/DD',
  dayLong: 'ddd MM/DD/YY',
  hourShort: 'HH',
  hourLong: 'HH:00',
  minuteShort: 'mm',
  minuteLong: 'HH:mm',
  week: 'W',
}

