import React from 'react';
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Input, Segment, Dropdown, Checkbox, Button, Icon, Header, Message, Table } from "semantic-ui-react";
import { getUserToken, USER_LOCALSTORAGE_TOKEN } from '../../actions/authentication';
import roleTemplates from "./roleTemplates";
import Validate from "react-validate-form";
import { validationRules } from "../../validationRules";
import { CUSTOMER_PERMISSION, AVAILABLE_PERMISSIONS_ARRAY, AVAILABLE_PERMISSIONS_DEEP_ARRAY } from "../../constants/constants";
import axios from "axios";

const URL = process.env.REACT_APP_SERVER_URL;

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        const { userToEdit, permissions, mixingplants } = props;
        const roleTemplateKeys = Object.keys(roleTemplates);
        let selectedRole = !!userToEdit ? userToEdit.role : undefined;
        let activeMixingplants = [];
        let activePermissions = [];
        if (!!userToEdit)
            activePermissions = userToEdit.permissions.map(permission => permission.id);

        if (!selectedRole && !!userToEdit) {
            // find out if the specific combination of permissions selected matches with a role-template
            for (let i = 0; i < roleTemplateKeys.length; i++) {
                const roleKey = roleTemplateKeys[i];
                let roleTemplatePermissions;

                if (typeof roleTemplates[roleKey].permissions === "object") {
                    roleTemplatePermissions = roleTemplates[roleKey].permissions.sort();
                } else if (roleTemplates[roleKey].permissions === "*") {
                    roleTemplatePermissions = AVAILABLE_PERMISSIONS_ARRAY
                        .filter(permission => permission.id !== CUSTOMER_PERMISSION.id)
                        .map(permission => permission.id)
                        .sort();
                }

                activePermissions.sort();

                if (JSON.stringify(roleTemplatePermissions) === JSON.stringify(activePermissions)) {
                    selectedRole = roleTemplateKeys[i];
                    break;
                } else {
                    selectedRole = "";
                }
            }
        }

        // console.log(userToEdit,'userToEdit');

        if (!!userToEdit && !!userToEdit.mixingplants)
            activeMixingplants = userToEdit.mixingplants.map(mixingplant => mixingplant.id);

        this.state = {
            userToEdit,
            permissions,
            selectedRole,
            activePermissions,
            mixingplants,
            activeMixingplants,
            userName: !!userToEdit ? userToEdit.name : "",
            userEmail: !!userToEdit ? userToEdit.email : "",
            userPassword:  "",
            changePassword: false,
        };
    }

    handleTemplateChange = (target, data) => {
        const selectedRole = data.value;
        const { userToEdit, mixingplants } = this.state;
        let { activeMixingplants } = this.state;

        if (!selectedRole || selectedRole === "") {
            this.setState({
                selectedRole,
            });
            return;
        }

        let templatePermissions = roleTemplates[selectedRole].permissions;
        if (typeof templatePermissions === "object")
            templatePermissions = templatePermissions.map(permission => permission.id);

        const permissions = AVAILABLE_PERMISSIONS_ARRAY.map(permission => permission.id);

        const activePermissions = permissions.filter(permission =>
            // if the templatePermissions is "*" select all permissions except for the customer permission 77c12ed3-5e13-4dbe-b38f-48066d557803
            templatePermissions.includes(permission) || (templatePermissions === "*" && permission !== CUSTOMER_PERMISSION.id)
        );

        if (selectedRole === "ADMIN") {
            activeMixingplants = mixingplants.map(mixingplant => mixingplant.id);
        }

        this.setState({
            selectedRole,
            activePermissions,
            activeMixingplants,
        });
    }

    handlePermissionToggle = (permissionId, isActive) => {
        let { permissions, activePermissions, selectedRole } = this.state;
        const roleTemplateKeys = Object.keys(roleTemplates);

        // toggle the permission being active
        if (isActive) {
            activePermissions = activePermissions.filter(permission => {
                return permission !== permissionId;
            });
        }
        else
            activePermissions.push(permissionId);

        // find out if the specific combination of permissions selected matches with a role-template
        for (let i = 0; i < roleTemplateKeys.length; i++) {
            const roleKey = roleTemplateKeys[i];
            let roleTemplatePermissions;

            if (typeof roleTemplates[roleKey].permissions === "object") {
                roleTemplatePermissions = roleTemplates[roleKey].permissions
                    .map(permission => permission.id)
                    .sort();
            }
            else if (roleTemplates[roleKey].permissions === "*") {
                roleTemplatePermissions = AVAILABLE_PERMISSIONS_ARRAY
                    .filter(permission => permission.id !== CUSTOMER_PERMISSION.id)
                    .map(permission => permission.id)
                    .sort();
            }

            activePermissions.sort();

            if (JSON.stringify(roleTemplatePermissions) === JSON.stringify(activePermissions)) {
                selectedRole = roleTemplateKeys[i];
                break;
            } else {
                selectedRole = "";
            }
        }

        this.setState({
            activePermissions,
            selectedRole,
        });
    }

    handleMixingplantToggle = (mixingplantId, isActive) => {
        const { mixingplants } = this.state;
        let { activeMixingplants } = this.state;

        // toggle the mixingplant being active
        if (isActive) {
            activeMixingplants = activeMixingplants.filter(mixingplant => {
                return mixingplant !== mixingplantId;
            });
        }
        else
            activeMixingplants.push(mixingplantId);

        this.setState({
            activeMixingplants,
        });
    }

    handleChangePasswordCheckbox = (checkboxValue) => {
        this.setState({ 'changePassword': !checkboxValue });
    }

    handleInputChange = (e, data) => {
        const { takenUserNames } = this.props;
        const { userToEdit } = this.state;
        const fieldName = data.name;
        let value = data.value;
        console.log(value,'valueeee')
        let userNameTakenError = false;

        value = value.replace(/\s/g, "_").toLowerCase();

        if (fieldName === "userName" &&
            takenUserNames.includes(value.toLowerCase())) {
            if (userToEdit) {
                userNameTakenError = false;

            } else {

                userNameTakenError = true;
            }

        }

        this.setState({
            [fieldName]: value,
            userNameTakenError,
        });
    }

    handleSave = () => {


        const { userName, userEmail, userPassword, activeMixingplants, activePermissions, selectedRole, userToEdit } = this.state;
        const { takenUserNames, selectedCompanyId } = this.props;
        const token = getUserToken();



        let userData = !!userToEdit ? Object.assign(userToEdit) : {};
        userData.name = userName;
        userData.email = userEmail;
        // userData.password = userEmail;
        userData.mixingplants = activeMixingplants;
        userData.permissions = activePermissions;
        userData.role = selectedRole;
        userData.company = selectedCompanyId;
        if(userPassword){
            userData.password = userPassword;
        }
        userData = {
            user: userData
        };

        const formData = new FormData();
        formData.append('token', token);
        formData.append("data", JSON.stringify(userData))

       

        axios.post(`${URL}/roles/save`, formData)
            .then((result) => { 
                if(result){
                    if(result.statusText=='OK'){
                        alert('Updated Successfully');
                    }
                }
            })
            .catch((result) => {
                if (!result.response) return;
                else if (result.response.status === 401) {
                    localStorage.removeItem(USER_LOCALSTORAGE_TOKEN);
                }
            });

    }

    render() {
        const { t, handleReturn } = this.props;
        const {
            userToEdit,
            permissions,
            activePermissions,
            selectedRole,
            mixingplants,
            activeMixingplants,
            userName,
            userEmail,
            userNameTakenError,
            changePassword,
            userPassword
        } = this.state;

        const validations = {
            userName: ["nameRequired"],
            userEmail: ["emailRequired"],
        };

        const rolesDropdownOptions = Object.keys(roleTemplates).map(role => (
            { key: role, text: role, value: role }
        ));
        rolesDropdownOptions.unshift({ key: "", text: "BENUTZERDEFINIERT", value: "" });

        const canSave = !!userName && userName !== "" &&
            !!userEmail && userEmail !== "" &&
            !userNameTakenError;

        // sort permissions alphabetically by translation
        const permissionCheckboxes = AVAILABLE_PERMISSIONS_DEEP_ARRAY.sort((a, b) => {
            const aTranslation = t(a[0].translation);
            const bTranslation = t(b[0].translation);

            if (aTranslation > bTranslation) return 1;
            if (bTranslation > aTranslation) return -1;
            return 0;
        })
            .map(permissionArray => {
                const checkboxes = [];
                for (let i = 0; i < permissionArray.length; i++) {
                    const permission = permissionArray[i];
                    const isActive = activePermissions.includes(permission.id);
                    const rw = permission.rw;

                    let checkboxLabel = "ENABLED";

                    if (rw === "R")
                        checkboxLabel = t("READ");
                    else if (rw === "W")
                        checkboxLabel = t("WRITE");

                    checkboxes.push(
                        <Checkbox
                            className="permissionCheckbox"
                            key={permission.id}
                            label={checkboxLabel}
                            checked={isActive}
                            onChange={() => this.handlePermissionToggle(permission.id, isActive)} />
                    );
                }

                const labeledCheckboxes = (
                    <tr key={permissionArray[0].id + "_tr"}>
                        <td className="permissionLabel">
                            <label>
                                {t(permissionArray[0].translation)}
                            </label>
                        </td>
                        <td className="permissionCheckboxCell">
                            {checkboxes}
                        </td>
                    </tr>
                );

                return labeledCheckboxes;
            })

        return (
            <div>
                <Validate validations={validations} rules={validationRules}>
                    {({ validate, errorMessages, allValid, errorCount }) => (
                        <div>
                            <div style={{ textAlign: 'right' }}>
                                <Button.Group style={{ marginBottom: "12px" }}>
                                    <Button
                                        type="button"
                                        onClick={handleReturn}
                                    >
                                        <Icon name="arrow left" />
                                        {t("buttons.return")}
                                    </Button>

                                    <Button
                                        disabled={!(errorCount < 1 && canSave)}
                                        type="button"
                                        onClick={() => this.handleSave()}
                                        color="green">
                                        <Icon name="checkmark" />
                                        {t("buttons.ok")}
                                    </Button>

                                </Button.Group>
                            </div>

                            <label>BENUTZERNAME</label>
                            <Input
                                error={(!!errorMessages.userName && errorMessages.userName.length > 0) || userNameTakenError}
                                value={userName}
                                name="userName"
                                onChange={(e, data) => {
                                    this.handleInputChange(e, data);
                                    validate(e);
                                }}
                            />
                            {userNameTakenError &&
                                <Message negative>
                                    {t("USERNAMETAKENEERROR")}
                                </Message>
                            }

                            <label>EMAIL</label>
                            <Input
                                error={!!errorMessages.userEmail && errorMessages.userEmail.length > 0}
                                value={userEmail}
                                name="userEmail"
                                onChange={(e, data) => {
                                    this.handleInputChange(e, data);
                                    validate(e);
                                }}
                            />
                            {userToEdit ? (
                                <>
                                    <Checkbox
                                        style={{ display: "block", marginTop: "8px" }}
                                        key={1}
                                        label={'changePassword'}
                                        checked={changePassword}
                                        onChange={() => this.handleChangePasswordCheckbox(changePassword)} />



                                    <label>Password</label>
                                    <Input
                                        error={!!errorMessages.userPassword && errorMessages.userPassword.length > 0}
                                        value={userPassword}
                                        name="userPassword"
                                        type='password'
                                        disabled={!changePassword}
                                        onChange={(e, data) => {
                                            this.handleInputChange(e, data);
                                            validate(e);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <label>Password</label>
                                    <Input
                                        error={!!errorMessages.userPassword && errorMessages.userPassword.length > 0}
                                        value={userPassword}
                                        name="userPassword"
                                        type='password'
                                        onChange={(e, data) => {
                                            this.handleInputChange(e, data);
                                            validate(e);
                                        }}
                                    />
                                    
                                </>

                            )}
                        </div>
                    )}
                </Validate>

                <label>GRUPPE</label>
                <Dropdown
                    name="group_dropdown"
                    id="group_dropdown"
                    selection
                    placeholder="BENUTZERDEFINIERT"
                    value={selectedRole}
                    options={rolesDropdownOptions}
                    onChange={this.handleTemplateChange}
                    style={{ marginBottom: "12px" }}
                />
                <div style={{ width: "fit-content" }}>
                    <Header as="h4">RECHTE: </Header>
                    {typeof permissions === "object" &&
                        <Table>
                            <tbody>
                                {permissionCheckboxes}
                            </tbody>
                        </Table>
                    }

                    <Header as="h4">EINSEHBARE MISCHANLAGEN: </Header>
                    {typeof mixingplants === "object" && mixingplants.map(mixingplant => {
                        const isActive = activeMixingplants.includes(mixingplant.id);

                        return (
                            <Checkbox
                                style={{ display: "block", marginTop: "8px" }}
                                key={mixingplant.id}
                                label={mixingplant.name}
                                checked={isActive}
                                onChange={() => this.handleMixingplantToggle(mixingplant.id, isActive)} />
                        );
                    })}
                </div>
            </div>
        );
    }
}

const wrapper = withNamespaces('translation')(EditUser);

function mapStateToProps(state, props) {
    return {};
}

export default withRouter(connect(mapStateToProps, {})(wrapper));