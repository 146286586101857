import { 
  SET_LOADING_STATE, 
  SET_APPSTATE, 
  SET_ADMIN_VIEW_PROD_TAKT, 
  SET_PRODUCTION_VIEW, 
  SET_SILO_CAPACITY_DATA,
  SET_MULTI_SELECT,
  SET_FULL_SCREEN
} from '../actions/app';

const defaultState = {
  loading: false,
  calendar_visibletimestart: 0,
  calendar_visibletimeend: 0,
  calendar_zoomstep: 3,
  calendar_mp_filter: [],
  calendar_show_capacity: false,
  calendar_show_silo_graph: false,
  admin_view_prod_takt: false,
  view: 'order',
  siloCapacityData: {},
  multi_select: false,
  full_screen: false
}

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case SET_LOADING_STATE:
      return { ...state, loading: action.state };
    case SET_ADMIN_VIEW_PROD_TAKT:
      return { ...state, admin_view_prod_takt: action.mixingPlantId };
    case SET_APPSTATE: 
      return {...state, ...action.stateToUpdate}
    case SET_PRODUCTION_VIEW: 
      return {...state, view: action.view}
    case SET_SILO_CAPACITY_DATA:
      return {...state, siloCapacityData: action.data}
    case SET_MULTI_SELECT:
      return {...state, multi_select: !state.multi_select}
    case SET_FULL_SCREEN:
      return {...state, full_screen: !state.full_screen}
  }
}