import { store } from '../store';

export const ADD_DELETED_ENTRY = 'deleted/ADD_DELETED_ENTRY';
export const LOAD_DELETED = 'deleted/LOAD_DELETED';
export const ADD_MULTIPLE_DELETED_ENTRY = 'deleted/ADD_MULTIPLE_DELETED_ENTRY';

export function addDeletedEntry(data) {
  store.dispatch({
    type: ADD_DELETED_ENTRY,
    data
  });
}

export function addMultipleDeletedEntry(data) {
  store.dispatch({
    type: ADD_MULTIPLE_DELETED_ENTRY,
    data
  });
}

export function updateDeleted(deleted) {
  return dispatch => {
    dispatch({
      type: LOAD_DELETED,
      data: deleted
    });
  }
}