export const validationRules = {
    nameRequired: {
        test: function test(val) {
            return val.length > 0;
        },
        message: function message(name) {
            return "general.errormessages.name_missing_error";
        }
    },
    emailRequired: {
        test: function test(val) {
            return val.length > 0;
        },
        message: function message(name) {
            return "general.errormessages.email_missing_error";
        }
    },
    gt0: {
        test: function test(val) {
            return val.length > 0 &&
                !isNaN(parseInt(val)) &&
                parseInt(val) >= 1;
        },
        message: function message(name) {
            return "general.errormessages.capacity_error";
        }
    }
};