import _ from 'lodash'
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Table } from "semantic-ui-react";
import Buttons from './Buttons';
import { withRouter } from "react-router";
import LabelledButton from '../LabelledButton/LabelledButton';
import RemoveMixingPlantMessage from './RemoveMixingPlantMessage';
import { formatNumber } from "../../helpers/format-number";

class MixingPlants extends React.Component {

  constructor(props) {
    super(props);

    const tableData = props.tableData;

    this.state = {
      column: null,
      direction: null,
      tableData
    }
  }

  handleSort = (clickedColumn) => {
    const { column, direction, tableData } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tableData: _.sortBy(tableData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      tableData: tableData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const { t, readOnly, mixingPlants, lng, history } = this.props;
    const { column, tableData, direction } = this.state;

    return(
      <div>
        <Buttons />
        <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
        <Table celled sortable selectable className="mixingplantsList">
          <Table.Header>
            <Table.Row style={{fontSize: '12px'}}>
              <Table.HeaderCell
                sorted={column === 'name' ? direction : null}
                onClick={() => this.handleSort('name')}
                className="textSort">
                  {t("mixingplant.name")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'tel' ? direction : null}
                onClick={() => this.handleSort('tel')}
                className="numberSort">
                  {t("mixingplant.tel")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'fax' ? direction : null}
                onClick={() => this.handleSort('fax')}
                className="numberSort">
                  {t("mixingplant.fax")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'email' ? direction : null}
                onClick={() => this.handleSort('email')}
                className="textSort">
                  {t("mixingplant.email")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'tonday' ? direction : null}
                onClick={() => this.handleSort('tonday')}
                className="numberSort">
                  {t("mixingplant.tonday")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'standard' ? direction : null}
                onClick={() => this.handleSort('standard')}
                className="numberSort">
                  {t("mixingplant.standard")}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={column === 'maxmix' ? direction : null}
                onClick={() => this.handleSort('maxmix')}
                className="numberSort">
                  {t("mixingplant.maxmix")}
              </Table.HeaderCell>

              {!readOnly && <Table.HeaderCell></Table.HeaderCell>}

            </Table.Row>
          </Table.Header>

          <Table.Body>
            {tableData.map(mp => (
              <Table.Row key={mp.id} style={{fontSize: '12px'}}>
                <Table.Cell>
                  {mp.name}
                </Table.Cell>

                <Table.Cell>
                  {mp.tel}
                </Table.Cell>

                <Table.Cell>
                  {mp.fax}
                </Table.Cell>

                <Table.Cell>
                  {mp.email}
                </Table.Cell>

                <Table.Cell>
                  {formatNumber(parseFloat(mp.tonday), lng)}
                </Table.Cell>

                <Table.Cell>
                  {formatNumber(parseFloat(mp.standard), lng)}
                </Table.Cell>

                <Table.Cell>
                  {formatNumber(parseFloat(mp.maxmix), lng)}
                </Table.Cell>

                {!readOnly && 
                  <Table.Cell>
                    <div className="buttonLine" style={{justifyContent: 'center'}}>
                      <LabelledButton
                        label={t("mixingplant.silos.new.buttontitle")}
                        icon="edit outline"
                        onClick={() => history.push(`/auftragsdispo/mixing-plants/${mp.id}/silos`)}
                      />

                      <LabelledButton
                        label={t("mixingplant.actions.edit")}
                        icon="edit outline"
                        onClick={() => history.push(`/auftragsdispo/mixing-plants/${mp.id}`)}
                      />

                      <RemoveMixingPlantMessage id={mp.id} />
                    </div>
                  </Table.Cell>
                }
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

const MixingPlants_routerWrapper = withRouter(MixingPlants);
const MixingPlants_i18nWrapper = withNamespaces('translation')(MixingPlants_routerWrapper);

function mapStateToProps(state, props) {
  const permissions = state.permissions;
  const mixingPlants = state.mixingPlants;
  const history = props.history;
  const lng = props.lng;
  const tableData = mixingPlants;

  return { 
    readOnly: permissions.filter(p => p.perm_type === 'MENU_MIXING_PLANT' && p.perm_desc === 'W').length < 1,
    mixingPlants,
    tableData,
    history,
    lng
  };
}

export default connect(mapStateToProps, {})(MixingPlants_i18nWrapper);