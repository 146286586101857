import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Icon, Button, Form, Segment, Header, Message } from "semantic-ui-react";
import { save } from '../../actions/authentication';
import { editSilo } from '../../actions/silos';
import Validate from "react-validate-form";
import { validationRules } from "../../validationRules";

const inlineStyle = {
  field: {
    marginTop: '10px'
  }
};

class Silo extends React.Component {

  state = {
    id: '',
    name: '',
    max: '',
    actual: ''
  };

  componentDidMount() {
    this.setState({
      id: this.props.silo.id,
      name: this.props.silo.name,
      max: this.props.silo.max,
      actual: this.props.silo.actual
    });
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;
    state['edited'] = true;

    this.setState(state);
  };

  handlePositive = async (state) => {
    const { authenticated, save } = this.props;
    let data = JSON.parse(JSON.stringify(state));

    await this.props.editSilo(data);

    if (authenticated)
      await save();

    this.props.history.push(`/auftragsdispo/mixing-plants/${this.props.mixingPlantId}/silos`);
  }

  handleNegative = _ => {
    this.props.history.push(`/auftragsdispo/mixing-plants/${this.props.mixingPlantId}/silos`);
  }

  render() {

    const { t } = this.props;

    const validations = {
      name: ["nameRequired"],
      max: ["gt0"]
    };

    return(
      <div className='page'>
        <Validate validations={validations} rules={validationRules}>
          {({ validate, errorMessages, allValid, errorCount }) => (
            <Segment raised>
              <Header as="h2" floated="left">
                <Header.Content>{t("silo.edit.header")}</Header.Content>
              </Header>

              <div style={{textAlign: 'right'}}>
                <Button.Group>
                  <Button
                    type="button"
                    onClick={() => this.handleNegative()}
                    color="grey"
                  >
                    <Icon name="close" />
                    {t("buttons.cancel")}
                  </Button>
                  <Button disabled={errorCount >= 1} type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                    <Icon name="checkmark" />
                    {t("buttons.ok")}
                  </Button>
                </Button.Group>
              </div>

              <Form.Group style={{clear: 'both'}}>
                <Form.Input 
                  label={t("silo.name")} 
                  name="name"
                  placeholder={t("silo.name")} 
                  value={this.state.name}
                  onChange={(e) => {
                    this.handleInputChange('name', e);
                    validate(e);
                  }}
                  fluid
                />
                {!!errorMessages.name && errorMessages.name.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.name[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>  

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  type="number"
                  min="0"
                  name="max"
                  label={t("silo.max")} 
                  placeholder={t("silo.max")} 
                  value={this.state.max}
                  onChange={(e) => {
                    this.handleInputChange('max', e);
                    validate(e);
                  }}
                  fluid
                />
                {!!errorMessages.max && errorMessages.max.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.max[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  type="number"
                  min="0"
                  label={t("silo.actual")} 
                  placeholder={t("silo.actual")} 
                  value={this.state.actual}
                  onChange={(e) => this.handleInputChange('actual', e)}
                  fluid
                />
              </Form.Group>

            </Segment>  
          )}
        </Validate>
      </div>
    );
  }
}

const Silo_i18nWrapper = withNamespaces('translation')(Silo);

function mapStateToProps(state, props) {
  const authenticated = state.auth.authenticated;
  const silos = state.silos;
  const id = 'id' in props.match.params ? props.match.params.id : undefined;
  const mixingPlantId = 'mixing_plant_id' in props.match.params ? props.match.params.mixing_plant_id : undefined;
  const permissions = state.permissions;

  const readOnly = permissions.filter(p => p.perm_type == 'MENU_SILO' && p.perm_desc === 'W').length < 1;

  if(readOnly) props.history.push('/auftragsdispo/login');

  if(!id) props.history.push('/auftragsdispo/base-data?tab=mixingplants');
  if(!mixingPlantId) props.history.push('/auftragsdispo/base-data?tab=mixingplants');

  const silo = silos.filter(m => m.id == id)[0];

  if(!silo) props.history.push('/auftragsdispo/base-data?tab=mixingplants');

  return {
    history: props.history,
    silo,
    siloId: id,
    mixingPlantId,
    authenticated
  };
}

export default withRouter(connect(mapStateToProps, {editSilo, save})(Silo_i18nWrapper));