import { MapLayer } from 'react-leaflet';
import L from 'leaflet';
import { withLeaflet } from 'react-leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/src';
import "../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css"
import "../../plugins/lrm-graphhopper/src/L.Routing.GraphHopper"
import marker_rout from "../../images/marker_rout.png";

class RoutingMachine extends MapLayer {
  createLeafletElement() {
    const { color, map, routefound, waypointsChanged, waypoints } = this.props;
    const self = this;

    const wayto = [];
    const wayback = [];

    if (!waypoints) return;

    try {
      for (let i = 0; i < waypoints.to.length; i++) {
        let waypoint = waypoints.to[i];
  
        if (!waypoint.lat && !waypoint.lng) {
          let [lat, lng] = waypoint.split(",");
          lat = parseFloat(lat) || 48.93394645;
          lng = parseFloat(lng) || 8.74796254;
          waypoint = L.latLng(lat, lng);
        }
  
        wayto.push(waypoint);
      }
  
      for (let i = 0; i < waypoints.back.length; i++) {
        let waypoint = waypoints.back[i];
  
        if (!waypoint.lat && !waypoint.lng) {
          let [lat, lng] = waypoint.split(",");
          lat = parseFloat(lat) || 48.93394645;
          lng = parseFloat(lng) || 8.74796254;
          waypoint = L.latLng(lat, lng);
        }
        
        wayback.push(waypoint);
      }
      
      function createroutemarker(i, wp, n) { 
        let isBigMarker = (i === 0) || (i === n-1);
        
        if (isBigMarker) {
          return L.marker(wp.latLng, {
            draggable: false
          });
        } else {
          var greenIcon = L.icon({
            iconUrl: marker_rout,
            iconSize: [14, 13], // size of the icon
            iconAnchor: [7, 6.5], // point of the icon which will correspond to marker's location
          });
  
          return L.marker(wp.latLng, {
            draggable: true,
            icon: greenIcon
          });
        }
      }
  
      let hin = L.Routing.control({
        router: new L.Routing.GraphHopper(),
        plan: L.Routing.plan(wayto, {
          createMarker: function (i, wp, n) {
            return createroutemarker(i, wp, n);
          }
        }),
        lineOptions: {
            styles: [{color: 'black', opacity: 0.15, weight: 19}, {color: 'black', opacity: 0.5, weight: 6}, {color: 'black', opacity: 1, weight: 2}],
        },
        routeWhileDragging: false,
        show: false
      }).addTo(map.current.leafletElement);
  
      let back = L.Routing.control({
        router: new L.Routing.GraphHopper(),
        plan: L.Routing.plan(wayback, {
          createMarker: function (i, wp, n) {
            return createroutemarker(i, wp, n);
          }
  
        }),
        lineOptions: {
            styles: [{color: '#999', opacity: 0.15, weight: 19}, {color: '#999', opacity: 0.5, weight: 6}, {color: '#999', opacity: 1, weight: 2}],
        },
        routeWhileDragging: false,
        show: false
      }).addTo(map.current.leafletElement);
  
      let shouldUpdateInput = false;
  
      hin.on("waypointschanged", () => {
        shouldUpdateInput = true;
      });
  
      back.on("waypointschanged", (e) => {
        shouldUpdateInput = true;
      })
  
      hin.on("routesfound", function (e) {
        routefound(e, "to", shouldUpdateInput);
      });
  
      back.on("routesfound", function (e) {
        routefound(e, "back", shouldUpdateInput);
      });
      
      // let leafletElement = L.Routing.control({
      //   router: new L.Routing.GraphHopper(),
      //   waypoints: road,
      //   lineOptions: {
      //     styles: [{ 
      //       color, 
      //       opacity: .8,
      //       weight: 6 
      //     }]
      //   },
      //   addWaypoints: true,
      //   draggableWaypoints: true,
      //   fitSelectedRoutes: false,
      //   showAlternatives: false,
      //   altLineOptions: { styles: [{opacity: 0.4}] },
      //   // createMarker: () => { return null; }
      // })
      // .addTo(map.current.leafletElement);
  
      // leafletElement.hide(); // hide road describtion
  
      return hin.getPlan();
    }
    catch (e) {
      console.log("error @ map: ", e);
      return null;
    }
  }
}

export default withLeaflet(RoutingMachine);