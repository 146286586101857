import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import AddConstructionSite from './AddConstructionSite';

class Buttons extends React.Component {

  state = {};

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state);
  };

  render() {

    return(
      <div className='buttonLine'>
        <AddConstructionSite />
      </div>
    );
  }
}

const Buttons_i18nWrapper = withNamespaces('translation')(Buttons);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(Buttons_i18nWrapper);