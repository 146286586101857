import uuid from 'uuid/v4';
import moment from 'moment';
import { addDeletedEntry } from './deleted';

export const ADD_MATERIAL = 'materials/ADD_MATERIAL';
export const EDIT_MATERIAL = 'materials/EDIT_MATERIAL';
export const REMOVE_MATERIAL = 'materials/REMOVE_MATERIAL';
export const LOAD_MATERIALS = 'materials/LOAD_MATERIALS';

export function addMaterial(data) {
  return dispatch => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;
    dispatch({
      type: ADD_MATERIAL,
      data
    });
  }
}

export function editMaterial(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_MATERIAL,
      data
    });
  }
}

export function removeMaterial(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'materials'
    });

    dispatch({
      type: REMOVE_MATERIAL,
      id
    });
  }
}