import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {Table} from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import RemoveConstructionSitesMessage from './RemoveConstructionSitesMessage';
import {CUSTOMER_PERMISSION} from '../../constants/constants';


const ConstructionSitesList = ({t, constructionSites, history, isCustomer, customerId}) => {

    const setButtons = (constructionSite) => {

        let buttons = (
            <React.Fragment>
                <LabelledButton
                    label={t("construction_sites.actions.edit")}
                    icon="edit outline"
                    onClick={() => history.push(`/auftragsdispo/customers/${customerId}/construction-sites/${constructionSite.id}`)}
                />
                <RemoveConstructionSitesMessage id={constructionSite.id}/>
            </React.Fragment>
        );

        if (isCustomer) {
            buttons = (
                <React.Fragment>
                    <LabelledButton
                        label={t("basedata.header")}
                        icon="book"
                        onClick={() => history.push(`/auftragsdispo/construction-sites/${constructionSite.id}`)}
                    />
                    <LabelledButton
                        label={t("construction_sites.actions.edit")}
                        icon="edit outline"
                        onClick={() => history.push(`/auftragsdispo/orders/${constructionSite.id}`)}
                    />
                </React.Fragment>
            );
        }

        return buttons;
    }


    return (
        <Table.Body>
            {constructionSites.map(constructionSite => (
                <Table.Row key={constructionSite.id} style={{fontSize: '12px'}}>
                    <Table.Cell width={2}>{constructionSite.name}</Table.Cell>
                    <Table.Cell width={2}>{constructionSite.contract_number}</Table.Cell>
                    <Table.Cell width={3}>
                        <div className="buttonLine" style={{justifyContent: 'center', marginBottom: '0px'}}>
                            {setButtons(constructionSite)}
                        </div>
                    </Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    );
}

const ConstructionSitesList_routerWrapper = withRouter(ConstructionSitesList);
const ConstructionSitesList_i18nWrapper = withNamespaces('translation')(ConstructionSitesList_routerWrapper);

function mapStateToProps(state, props) {
    let constructionSites = state.constructionSites;
    const customers = state.customers;
    const user = state.user;

    let isCustomer = false;
    !!user.permissions && user.permissions.forEach(permission => {
          if(permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
          }
        });

    let customerId = 'id' in props.match.params ? props.match.params.id : undefined;

    if (isCustomer) {
        customerId = customers.filter(c => c.user_id == user.id)[0].id;
    }

    if (!customerId && !isCustomer) props.history.push('/auftragsdispo/base-data?tab=customers');

    const customer = customers.filter(c => c.id == customerId);

    if (customer.length === 0 && !isCustomer) props.history.push('/auftragsdispo/base-data?tab=customers');

    constructionSites = constructionSites.filter(cs => cs.customer_id == customerId);

    return {
        constructionSites,
        isCustomer,
        customerId
    };
}

export default withRouter(connect(mapStateToProps, {})(ConstructionSitesList_i18nWrapper));