import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Form } from 'semantic-ui-react';

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class CutOrderModal extends React.Component {
  state = {
    open: true,
    amount: this.props.amount
  }

  handleModal = open => {
    this.props.showCutOrderModal();
  }

  handleInputChange = (field, e) => {
    let state = JSON.parse(JSON.stringify(this.state));
    state[field] = e.target.value;

    this.setState(state);
  };

  handlePositive = state => {
    const { amount } = state;
    
    if(amount <= 0) return;
    if(amount >= this.props.amount) return;
    
    this.props.cutOrder(amount);
  }

  render() {

    const { t } = this.props;
    const { open, amount } = this.state;

    return (
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
      >
        <Modal.Header>{t("order.cut.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <label>{t("order.cut.text")}</label>
            <div className="field">
              <div className="field">
                <div className="ui fluid input">
                  <input 
                    min="0"
                    placeholder={t("order.neworder.materials.amount")} 
                    type="number" 
                    value={amount}
                    onChange={(e) => this.handleInputChange('amount', e)}
                    autoFocus
                  />
                </div>
              </div>
            </div>
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const CutOrderModal_i18nWrapper = withNamespaces('translation')(CutOrderModal);

function mapStateToProps(state, props) { 
  const orders = state.orders;
  let order = orders.filter(o => o.id == props.orderToCut)[0];

  return {
    amount: order.materials.amount
  }
}

export default connect(mapStateToProps, {})(CutOrderModal_i18nWrapper);