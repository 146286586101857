import React from 'react';
import Logout from './Logout';
import Save from './Save';
import Refresh from './Refresh';
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import { isTokenValid, getUserToken } from '../../actions/authentication';
import { Modal, Segment, Header, Button, Icon } from "semantic-ui-react";
import ReLogin from "./ReLogin";

class TopButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      portalOpen: false,
    }
  }
  
  openPortal = () => {
    this.setState({
      portalOpen: true,
    });
  }

  closePortal = (shouldRefresh) => {
    const { authenticated } = this.props;
    const token = getUserToken();

    if(authenticated && shouldRefresh) 
      isTokenValid(token);

    this.setState({
      portalOpen: false,
    });
  }

  render() {
    const { t } = this.props;
    const { portalOpen } = this.state;
    
    return (
      <div id="buttons">
        <Refresh openPortal={this.openPortal}/>
			  <Save />
        <Logout />
        <div />

        <Modal open={portalOpen}>
          <Header>{t("topbuttons.refresh_warning_title")}</Header>
          <Modal.Content>
            <p>
              {t("topbuttons.refresh_warning_content_1")}
            </p>
            <p>
              {t("topbuttons.refresh_warning_content_2")}
            </p>
          </Modal.Content>
          
          <Modal.Actions>
            <Button color="red" inverted onClick={() => this.closePortal(false)}>
              <Icon name="cancel"/>
              {t("buttons.cancel")}
            </Button>

            <Button color="green" inverted onClick={() => this.closePortal(true)}>
              <Icon name="checkmark" />
              {t("buttons.yes")}
            </Button>
          </Modal.Actions>
        </Modal>
        <ReLogin />
		  </div>
    )
  }
}

const TopButtons_i18nWrapper = withNamespaces('translation')(TopButtons);

const mapStateToProps = (state, props) => {
  return { authenticated: state.auth.authenticated };
};

export default connect(mapStateToProps, {})(TopButtons_i18nWrapper);
// Daten aktualisieren? Achtung: Ungespeicherte daten gehen verloren.