import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import i18n from 'i18next';
import { Dimmer, Loader } from "semantic-ui-react";
import requireAuth from '../requireAuth/requireAuth';
import noRequireAuth from '../noRequireAuth/noRequireAuth';
import Home from '../Home/Home';
import Login from '../Login/Login';
import Scheduler from '../Scheduler/Scheduler'
import Diagram from '../Scheduler/Diagram'
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import FootNavigation from '../Navigation/FootNavigation';
import MixingPlants from '../MixingPlants/MixingPlants';
import MixingPlant from '../MixingPlants/MixingPlant';
import Materials from '../Materials/Materials';
import Material from '../Materials/Material';
import Orders from '../Orders/Orders';
import NewOrder from '../Orders/NewOrder/NewOrder';
import ConstructionSites from '../ConstructionSites/ConstructionSites';
import ConstructionSite from '../ConstructionSites/ConstructionSite';
import Customers from '../Customers/Customers';
import Customer from '../Customers/Customer';
import Silos from '../Silos/Silos';
import Silo from '../Silos/Silo';
import Realtime from '../Realtime/Realtime';
import rolesmanagement from '../rolesmanagement/rolesmanagement';
import {CUSTOMER_PERMISSION} from '../../constants/constants';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB, de } from 'date-fns';
import './App.css';
import CustomerView from '../CustomerView/CustomerView';
import CustomerOrder from '../CustomerView/CustomerOrder';
import CustomerInformation from '../CustomerView/CustomerInformation';
import moment from 'moment';

class App extends Component {

  componentWillMount() {
    registerLocale('en', enGB);
    registerLocale('de', de);

    let localLang = localStorage.getItem("language");
    if (localLang) {
      i18n.changeLanguage(localLang.toLowerCase());
      moment.locale(localLang.toLowerCase());
      setDefaultLocale(localLang.toLowerCase());
    }
    
    // Add message when the user tries to leave the website
    window.addEventListener("beforeunload", function(e) {
      let confirmationMessage = "";
      // Gecko, Trident, Chrome 34+
      e.returnValue = confirmationMessage;
      // Gecko, WebKit, Chrome <34
      return confirmationMessage;
    });
  }

  render() {

    let { t, loading, fullscreen, isCustomer } = this.props;

    // if(isCustomer) fullscreen = true;

    let mainClass = [];
    if(fullscreen) mainClass.push('fullscreen');

    return (
        <div className='main'>
          <Dimmer active={!!loading} inverted>
            <Loader inverted>{t("app.loading")}</Loader>
          </Dimmer>
          <Header fullscreen={fullscreen} />

          {fullscreen
            ? <FootNavigation />
            : <Navigation />
          }
          
          <main className={mainClass.join(' ')}>
            {!!isCustomer 
              ? (
                <Switch>
                  <Route exact path="/auftragsdispo/" component={requireAuth(CustomerView)} />
                  <Route exact path="/auftragsdispo/customers/:id" component={requireAuth(Customer)} />
                  <Route exact path="/auftragsdispo/construction-sites/:id" component={requireAuth(ConstructionSite)} />
                  <Route exact path="/auftragsdispo/orders/:id" component={requireAuth(CustomerOrder)} />
                  <Route exact path="/auftragsdispo/base-data" component={requireAuth(CustomerInformation)} />
                  <Route exact path="/auftragsdispo/login" component={noRequireAuth(Login)} />
                  <Redirect to="/auftragsdispo/login" />
                </Switch>
              )
              : (
                <Switch>
                  <Route exact path="/auftragsdispo/base-data" component={requireAuth(Home)} />
                  <Route exact path="/auftragsdispo/mixing-plants" component={requireAuth(MixingPlants)} />
                  <Route exact path="/auftragsdispo/materials" component={requireAuth(Materials)} />
                  <Route exact path="/auftragsdispo/" component={requireAuth(Orders)} />
                  <Route exact path="/auftragsdispo/orders/edit/:id" component={requireAuth(NewOrder)} />
                  <Route exact path="/auftragsdispo/orders/new" component={requireAuth(NewOrder)} />
                  <Route exact path="/auftragsdispo/mixing-plants/:id" component={requireAuth(MixingPlant)} />
                  <Route exact path="/auftragsdispo/materials/:id" component={requireAuth(Material)} />
                  {/* <Route exact path="/construction-sites" component={requireAuth(ConstructionSites)} /> */}
                  <Route exact path="/auftragsdispo/customers/:customer_id/construction-sites/:id" component={requireAuth(ConstructionSite)} />
                  <Route exact path="/auftragsdispo/customers" component={requireAuth(Customers)} />
                  <Route exact path="/auftragsdispo/schedule/:day/:mpid" component={requireAuth(Scheduler)} />
                  <Route exact path="/auftragsdispo/schedule/:day/:mpid/diagram" component={requireAuth(Diagram)} />
                  <Route exact path="/auftragsdispo/customers/:id" component={requireAuth(Customer)} />
                  <Route exact path="/auftragsdispo/customers/:id/construction-sites" component={requireAuth(ConstructionSites)} />
                  <Route exact path="/auftragsdispo/mixing-plants/:id/silos" component={requireAuth(Silos)} />
                  <Route exact path="/auftragsdispo/mixing-plants/:mixing_plant_id/silos/:id" component={requireAuth(Silo)} />
                  <Route exact path="/auftragsdispo/realtime/:orderId" component={requireAuth(Realtime)} />
                  <Route exact path="/auftragsdispo/login" component={noRequireAuth(Login)} />
                  <Route exact path="/auftragsdispo/rolesmanagement" component={requireAuth(rolesmanagement)} />
                  <Redirect to="/auftragsdispo/login" />
                </Switch>
              )
            }
          </main>
        </div>
    );
  }
}

const App_i18nWrapper = withNamespaces('translation')(App);

function mapStateToProps(state, props) {
  const user = state.user;

  let isCustomer = false;
  !!user.permissions && user.permissions.forEach(permission => {
    if(permission.id === CUSTOMER_PERMISSION.id) {
      isCustomer = true;
    }
  });

  return { 
    loading: state.app.loading,
    fullscreen: state.app.full_screen,
    isCustomer
  };
}

export default withRouter(connect(mapStateToProps, {})(App_i18nWrapper));