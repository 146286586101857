
import React from 'react';
import LabelledButton from '../LabelledButton/LabelledButton';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { setMultiSelect } from '../../actions/app';
import { Button, Popup } from "semantic-ui-react";


class SetMultiSelect extends React.Component {
  state = {}

  render() {

    const {t, multiSelect} = this.props;

    let icon = multiSelect ? "clone" : "square";
    let label = multiSelect ? t("order.buttons.multiselect_true") : t("order.buttons.multiselect_false")

    return (
      <Popup
        trigger={
          <Button
            onClick={() => this.props.setMultiSelect()}
            icon={icon}
            // label={label}
          />
        }
        content={label}
        position="top center"
      />                
    )
  }
}

function mapStateToProps(state) { 
  const multiSelect = state.app.multi_select;

  return {
    multiSelect
  }
}

const reduxWrapper = connect(mapStateToProps, {setMultiSelect})(SetMultiSelect);
export default withNamespaces('translation')(reduxWrapper);
