import React, { Component } from 'react'
import moment from 'moment'
import { Button, Icon, Modal, Form, Dropdown } from 'semantic-ui-react'
import alphanumerical from "../../helpers/utils"

export default class DailyButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      mixingPlant: null,
    }
  }

  handleChooseMixingPlant = (event, data) => {
    this.setState({mixingPlant: data.value});
  }  

  handleDailyButtonClick = (day, availableMixingplants, modalResult = null) => {
    const { mixingPlantFilter } = this.props;
    let mpid;

    availableMixingplants = availableMixingplants.filter(mp => mp.readonly === false);

    if (mixingPlantFilter.length === 1) {
      mpid = mixingPlantFilter[0];
    }
    else if (availableMixingplants.length === 1) {
      mpid = availableMixingplants[0].id;
    }
    else if (modalResult) {
      mpid = modalResult;
    }
    else {
      this.setState({
        isModalOpen: true
      });

      return;
    }
      
    this.openSchedule(day, mpid);
  }

  openSchedule = (day, mpid) => {
    this.props.history.push(`/auftragsdispo/schedule/${day}/${mpid}`);
  }

  render() {
    const { t, items, orders, timeVal, mixingPlants } = this.props;

    const dayItems = items.filter(i =>
      i.start_time.valueOf() === timeVal
    );
    const dayItemsKeys = dayItems.map(dI => 
      dI.id
    );

    const dayOrders = orders.filter(o =>
      dayItemsKeys.includes(o.id)
    );
    const dayOrdersKeys = dayOrders.map(dO =>
      dO.mixingPlant
    );

    const availableMixingplants = mixingPlants.filter(
      mp => dayOrdersKeys.includes(mp.id) && mp.readonly === false
    ); 
    const mixingPlantsDropdown = availableMixingplants.map(mp => ({
      key: mp.id,
      value: mp.id,
      text: mp.name
    }));

    return (
      availableMixingplants.length > 0 &&
        <Modal
          closeIcon={false}
          closeOnDimmerClick={false}
          open={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          trigger={
            <Button icon className="dailyButton" onClick={() => this.handleDailyButtonClick(timeVal, availableMixingplants)} >
              <Icon name="truck"/>
            </Button>
          }
        >
          <Modal.Header>{t("scheduler.daily_button.modal.header")}</Modal.Header>
          <Modal.Content>
            <Form.Group>
              <label>{t("scheduler.daily_button.modal.text")}</label>
              <Dropdown 
                placeholder={t("scheduler.daily_button.modal.header")}
                fluid 
                search 
                selection 
                options={mixingPlantsDropdown} 
                onChange={this.handleChooseMixingPlant}
                // value={mixing_plant}
              />
            </Form.Group>
          </Modal.Content>

          <Modal.Actions>
            <Button.Group>
              <Button
                type="button"
                onClick={() => this.setState({ isModalOpen: false})}
                color="grey"
              >
                <Icon name="close" /> {t("buttons.cancel")}
              </Button>

              <Button type="button" onClick={_ => this.handleDailyButtonClick(timeVal, availableMixingplants, this.state.mixingPlant)} color="green">
                <Icon name="checkmark" />
                {t("buttons.ok")}
              </Button>
            </Button.Group>
          </Modal.Actions>


        </Modal>
    );
  }
}
