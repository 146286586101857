import _ from "lodash";
import React from 'react';
import {withNamespaces} from "react-i18next";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Header, Segment, Dropdown, Button, Icon, Table} from "semantic-ui-react";
import {getUserToken, USER_LOCALSTORAGE_TOKEN, caller} from '../../actions/authentication';
import axios from "axios";
import EditUser from "./EditUser";

const URL = process.env.REACT_APP_SERVER_URL;

class rolesmanagement extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            column: null,
            direction: null,
            isEditingUser: false
        }
    }

    componentDidMount() {
        this.loadCompanies();
    }

    handleSort = (clickedColumn) => {
        const {column, direction, tableData} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                tableData: _.sortBy(tableData, [clickedColumn]),
                direction: 'ascending',
            });

            return;
        }

        this.setState({
            tableData: tableData.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });
    }

    async loadCompanies() {
        try {
            const token = getUserToken();
            if (typeof token === "undefined") throw "user is not authenticated";
            const formData = new FormData();
            formData.append('token', token);

            axios.post(`${URL}/roles/companies`, formData)
                .then((result) => {
                    const companies = result.data.companies;
                    const permissions = result.data.permissions;
                    const users = companies[0].users; 

                    const tableData = users.sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    });

                    this.setState({
                        companies,
                        permissions,
                        selectedCompanyId: companies[0].id,
                        tableData
                    });
                })
                .catch((result) => {
                    if (result.response.status === 401) {
                        localStorage.removeItem(USER_LOCALSTORAGE_TOKEN);
                    }
                });
        } 
        catch (error) {
            console.error(error);
            localStorage.removeItem(USER_LOCALSTORAGE_TOKEN);
        }
    }

    handleCompanyChange = (event, data) => {
        const { companies } = this.state;
        const comId = parseInt(data.value);
        let company = companies.filter(company => company.id === comId)[0];

        const users = company.users; 

        const tableData = users.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        this.setState({
            selectedCompanyId: comId,
            tableData
        });
    };

    handleDeleteUser = (userId) => {
        console.log(userId);
    }

    render() {
        const {t} = this.props;
        const { 
            companies,
            permissions,
            selectedCompanyId,
            column,
            direction,
            tableData,
            isEditingUser,
            userToEdit,
            isAddingUser,
        } = this.state;

        let company;
        let dropDownValues = [];
        let users = [];
        let mixingplants = []; 
        let takenUserNames = [];

        if (!!companies) {
            dropDownValues = companies.map(company => {
                return {key: company.id, text: company.name, value: company.id};
            });

            company = companies.filter(company => company.id == selectedCompanyId)[0];
        }

        if (!!company) {
            users = company.users;
            mixingplants = company.mixingplants;
            let companyName = company.name;
            takenUserNames = users.map(user => user.name);
        }

        return (
            <div className='page'>
                <Segment raised>
                    <Header as="h2" floated={isEditingUser || isAddingUser ? "left" : null}>
                        <Header.Content>{t('menu.userManaging')}</Header.Content>
                    </Header>
                    { !isEditingUser && !isAddingUser && 
                        <div>
                            {!!companies && companies.length > 1 &&
                                <div>
                                    <label>{t('admin.company')}</label>
                                    <Dropdown
                                        fluid 
                                        search 
                                        selection
                                        value={selectedCompanyId}
                                        options={dropDownValues}
                                        placeholder={t('admin.select')}
                                        onChange={this.handleCompanyChange}
                                        style={{marginBottom: "12px"}}
                                    />
                                </div>
                            }

                            <Button
                                onClick={() => this.setState({
                                    isAddingUser: true,
                                })}>
                                    <Icon name="plus"/>
                                    BENUTZER HINZUFÜGEN
                            </Button>

                            {!!tableData &&
                                <div>
                                    <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
                                    <Table celled sortable selectable className="usersList" style={{tableStyle: "fixed"}}>
                                        <Table.Header>
                                            <Table.Row style={{fontSize: '12px'}}>
                                                <Table.HeaderCell
                                                    sorted={column === 'name' ? direction : null}
                                                    onClick={() => this.handleSort('name')}
                                                    className="textSort">
                                                    {t("XXXXXXX")}
                                                </Table.HeaderCell>

                                                <Table.HeaderCell
                                                    sorted={column === 'email' ? direction : null}
                                                    onClick={() => this.handleSort('email')}
                                                    className="textSort">
                                                    {t("XXXXXXX")}
                                                </Table.HeaderCell>

                                                <Table.HeaderCell>
                                                    {t("XXXXXXX")}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {tableData.map(user => (
                                                <Table.Row key={user.id} style={{fontSize: '12px'}}>
                                                    <Table.Cell>
                                                        {user.name}
                                                    </Table.Cell>

                                                    <Table.Cell>
                                                        {user.email}
                                                    </Table.Cell>

                                                    <Table.Cell style={{display: "flex", justifyContent: "center"}}>
                                                      <Button.Group>
                                                            <Button
                                                                onClick={() => this.setState({
                                                                    isEditingUser: true,
                                                                    userToEdit: user,
                                                                })}
                                                            >
                                                                <Icon name="edit outline" />
                                                                BEARBEITEN
                                                            </Button>
    
                                                            <Button
                                                                onClick={() => {this.handleDeleteUser(user.id)}}
                                                                className="delete-red"
                                                            >
                                                                <Icon name="trash" />
                                                                LÖSCHEN
                                                            </Button>
                                                      </Button.Group>
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            }
                        </div>
                    }
                    { isEditingUser && 
                        <EditUser 
                            userToEdit={userToEdit}
                            permissions={permissions}
                            mixingplants={mixingplants}
                            takenUserNames={takenUserNames}
                            handleReturn={() => this.setState({
                                isEditingUser: false,
                            })}/>
                    }
                    { isAddingUser &&
                        <EditUser 
                            permissions={permissions}
                            mixingplants={mixingplants}
                            takenUserNames={takenUserNames}
                            handleReturn={() => this.setState({
                                isAddingUser: false,
                            })}/>
                    }
                </Segment>
            </div>
        )
    }
}

const wrapper = withNamespaces('translation')(rolesmanagement);

function mapStateToProps(state, props) {
    const { user } = state;

    return {
        user
    };
}

export default withRouter(connect(mapStateToProps, {})(wrapper));