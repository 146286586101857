import React from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router";
import { Icon } from "semantic-ui-react";
import MakebigImg from "../../images/icon_pu.png";
import { setFullScreen } from '../../actions/app';

class FootNavigation extends React.Component {

  render() {

    const { t, permissions, authenticated, fullscreen } = this.props;

    let links = [
      { to: "/auftragsdispo/", name: t('menu.item4'), icon: "book" }    
    ];

    let canSeeMasterdata = permissions.filter(p => p.perm_type === 'MASTERDATA');

    if(canSeeMasterdata.length > 0) {
      links.push({ to: "/auftragsdispo/base-data", name: t('menu.item1'), icon: "edit outline" });
    }

    if(!authenticated) links = [];

    let navigationClasses = ['footnavigation'];
    if(fullscreen) navigationClasses.push('fullscreen')

    let els = [(
      <a 
        className="footlinker" 
        aria-current="page" 
        href="#" 
        style={{position: "relative"}}
        onClick={this.props.setFullScreen}
      >
        <img alt="link" className="img" src={MakebigImg} style={{width: "35px", paddingTop: "12px"}} />
      </a>
    )];
		for (let i = 0; i < links.length; i++) {
			els.push(
				<NavLink
					style={{position: 'relative'}}
					key={i}
          // activeClassName={window.location.pathname !== links[i].to ? null : "highlighted"}
          activeClassName="highlighted"
					className="footlinker"
					to={links[i].to}
          exact={true}
				>
          <Icon name={links[i].icon} size='large' />
				</NavLink>
			);
    }

    return (
      <div className={navigationClasses.join(' ')}>
        {els}
      </div>
    )
  }
}

const FootNavigation_i18nWrapper = withNamespaces('translation')(FootNavigation);

function mapStateToProps(state, props) {
  return { 
    permissions: state.permissions,
    authenticated: state.auth.authenticated,
    fullscreen: state.app.full_screen
  };
}

export default withRouter(connect(mapStateToProps, {setFullScreen})(FootNavigation_i18nWrapper));