import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import Timeline from '../Timeline';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import uuid from 'uuid/v4';
import UpdateGroup from '../Orders/NewOrder/UpdateGroup';
import AddMaterialToMixingPlant from '../Orders/NewOrder/AddMaterialToMixingPlant';
import { defaultItemRenderer } from '../Timeline/lib/items/defaultItemRenderer';
import { Message, Popup, Button, Input } from "semantic-ui-react";
import containerResizeDetector from '../Timeline/resize-detector/container';
import MixingPlantFilter from '../Filters/MixingPlantFilter';
import DeleteOrderModal from '../Orders/NewOrder/DeleteOrderModal';
import { NEW_ORDER_CONTEXT } from '../Timeline/lib/timeline/timelineContexts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const moment = extendMoment(Moment);

class MixingPlantsCapacityTimeline extends React.Component {
  state = {
    visibleTimeStart: this.props.visibleTimeStart,
    visibleTimeEnd: this.props.visibleTimeEnd,
    groups: this.props.groups,
    mixingPlantFilter: [],
    selected: []
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.visibleTimeStart !== this.props.visibleTimeStart) {
      this.setState({ 
        visibleTimeStart: this.props.visibleTimeStart, 
        visibleTimeEnd: this.props.visibleTimeEnd
      });
    }
  }

  handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.setState({ visibleTimeStart, visibleTimeEnd });
    this.props.updateTime(visibleTimeStart, visibleTimeEnd);
  };

  addMaterial = newGroup => {
    const {groups} = JSON.parse(JSON.stringify(this.state));
    let groupIds = groups.map(g => g.id);

    let indexFromParent = groupIds.indexOf(newGroup.parent);
    if(indexFromParent === -1) return;

    let indexToInsert = ++indexFromParent;  
    groups.splice(indexToInsert, 0, newGroup)

    this.setState({groups});
  }

  onCanvasClick = (groupId, time, e) => {
    const {groups} = this.state;

    let group = groups.filter(g => g.id == groupId)[0];

    if('root' in group && group.root) return;

    this.props.addOrderMaterial({
      id: uuid(),
      material_id: groupId.split('_')[0],
      group_id: groupId,
      amount: 0,
      start: moment(time).hour(8).minute(0).valueOf(),
      end: moment(time).hour(17).minute(0).valueOf(),
      tonPerHour: 0,
      obs: '',
      status: 'STANDARD',
      mixingPlant: group.parent,
      order: {}
    }, time);
  }

  onItemSelect = (itemId, e, time) => {
    this.setState({selected: [itemId]});
    this.props.onItemSelect(itemId, e, time);
  }

  onItemResize = (itemId, time, edge) => { 
    this.props.onItemResize(itemId, time, edge);
  }

  onItemMove = (itemId, dragTime, newGroupOrder) => { 
    this.props.onItemMove(itemId, dragTime, newGroupOrder);
  }

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    showMaxCapacityMessage
  }) => { 

    const {groups} = this.state;
    let group = groups.filter(g => g.id == item.group)[0];

    if(!group.root) return defaultItemRenderer({item, itemContext, getItemProps, getResizeProps});

    const itemProps = {...getItemProps(item.itemProps)};

    itemProps.style.background = 'white';

    let borderColor = 'black';
    let backgroundColor = "#badbff";
    let percentage = item.percentage;
    let classes = "";

    if(item.percentage > 100) {
      borderColor = 'black';
      backgroundColor = "red";
      percentage = 100;
      classes = "overcapacity";
    }

    if(isNaN(percentage)) {
      percentage = 0;
    }

    let marginTop = '1px';
    if(showMaxCapacityMessage) marginTop = '0px';

    return (
      <Popup
        wide={"very"}
        trigger={
          <div {...itemProps}>
            <div
              style={{
                padding: '0px !important'
              }}
              className="rct-capacity-item rct-item-content"
            >
              <div style={{
                background: '#badbff',
                width: itemContext.dimensions.width - 4,
                height: itemContext.dimensions.height - 5,
                border: `1.5px solid ${borderColor}`,
                position: 'relative'
              }}>
                <div style={{
                  width: `${percentage}%`, 
                  height: '30.7px',
                  marginTop,
                  backgroundColor: backgroundColor,
                  overflow: 'hidden'
                }}
              className={classes}
                >
                  <div
                   style={{
                      fontWeight: 'bolder',
                      paddingLeft: '3px',
                      lineHeight: '30px'
                    }}
                  >
                    {item.total > 0 && percentage >= 25 ? item.total : ''}
                  </div>
                </div>
              </div>
            </div>  
          </div>
        }
        content={(
          <div>
            <p>
              {`${item.total} ${this.props.t("text.from")} ${item.tonday} t (${item.percentage}%)`}
            </p>
            {/* <p>{this.props.t("mixingplant.tonday")}: {item.tonday}</p>
            <p>{this.props.t("mixingplant.usedtonday")}: {item.total}</p>
            <p>{this.props.t("mixingplant.percentage")}: {item.percentage}%</p> */}
          </div>
        )}
        position="bottom center"
        hideOnScroll
      />
    );
  }

  groupRenderer = ({ group }) => { 
    let { groups } = this.state;

    return (
      <div 
        className="rct-sidebar-row" 
        style={{
          height: '38px', 
          lineHeight: '38px',
          display: 'flex',
          marginLeft: group.root ? '0px' : '15px',
          border: '0px'
        }}
        key={group.id}
      >
        {group.root 
          ? (<AddMaterialToMixingPlant key={group.id} mixingPlant={group} groups={groups} addMaterial={this.addMaterial} />)
          : (<UpdateGroup  
              updateMaterial={this.updateMaterial} 
              group={group} 
              removeMaterial={this.removeMaterial} 
              closeMaterialsByDay={this.closeMaterialsByDay}
              groups={groups}
            />
          )
        }
        
      </div>
    );
  }

  closeMaterialsByDay = _ => {
    this.setState({selected: []});
    this.props.closeMaterialsByDay();
  }

  updateMaterial = (newGroup, oldGroup) => {
    let {groups} = this.state;
    let {order_materials, materials} = this.props;

    let material = materials.filter(m => m.id == newGroup.split('_')[0])[0];

    groups = groups.map(g => {
      if(g.id == oldGroup) {
        g.id =`${newGroup}`;
        g.title = material.name

        return g;
      }

      return g;
    });

    
    order_materials = order_materials.map(om => {
      if(om.material_id == oldGroup.split('_')[0]) {
        om.material_id = newGroup.split('_')[0];
        om.group_id = `${newGroup}`
        return om;
      }

      return om;
    });

    this.props.updateOrderMaterials(order_materials);

    this.setState({
      groups
    });
  }

  removeMaterial = groupId =>{
    let {groups} = this.state;
    let {order_materials} = this.props;

    groups = groups.filter(g => g.id !== groupId);
    order_materials = order_materials.filter(om => om.group_id !== groupId);

    this.props.updateOrderMaterials(order_materials);

    this.setState({
      groups,
      shouldShowMaterialsByDay: false
    });
  }

  jumpToDate = (date, event) => {
    const dateMoment = moment(new Date(date)).startOf("day").valueOf();
    const visibleTimeEnd = moment(dateMoment).add(7, "days").valueOf();

    this.setState({
      visibleTimeStart: dateMoment,
      visibleTimeEnd: visibleTimeEnd
    });
  }

  onRowClickFilter = mpId => {
    const { mixingPlants } = this.props;
    const { mixingPlantFilter:mpFilter } = this.state;
    const isMixingPlant = !!mixingPlants.filter(mp => {
      return mp.id == mpId
    })[0];

    if (isMixingPlant) {
      if (mpFilter.length === 1 && mpFilter[0] === mpId)
        this.setMixingPlantFilter([]);
      else
        this.setMixingPlantFilter([mpId]);
    }
  }


  setMixingPlantFilter = filterValue => {
    this.setState({mixingPlantFilter: filterValue});
  }

  deleteOrder = _ => {
    const {selected} = this.state;

    if(selected.length === 0) return;
    
    this.props.removeOrder(selected[0]);
  }

  render() {
    let { t, orders, order_materials } = this.props;
    let { visibleTimeStart, visibleTimeEnd, groups, mixingPlantFilter, selected } = this.state;

    let range = moment.range(visibleTimeStart, visibleTimeEnd);

    let items = [];

    let showMaxCapacityMessage = false;

    if(mixingPlantFilter.length > 0) {
      groups = groups.filter(g => {
        return (mixingPlantFilter.indexOf(g.id) !== -1) || mixingPlantFilter.indexOf(g.parent) !== -1
      });
    }

    groups.forEach(group => {
      let ordersByMixingPlant = orders.filter(o => o.mixingPlant == group.id);
      
      let maxTonnageByDay = parseFloat(group.tonday);

      if('root' in group && group.root) {
        let orderMaterialsByMixingPlant = order_materials.filter(om => om.mixingPlant == group.id);

        for (let day of range.by('day')) {
          let ordersByDay = ordersByMixingPlant.filter(o => day.isSame(o.materials.start, 'day'));
          let ordersMaterialsByDay = orderMaterialsByMixingPlant.filter(o => day.isSame(o.start, 'day'));
  
          let total = ordersByDay.reduce((acc, o) => 
            acc += parseFloat(o.materials.amount)
          , 0);

          let totalByOrderMaterials = ordersMaterialsByDay.filter(om => Object.keys(om.order).length === 0).reduce((acc, o) => 
            acc += parseFloat(o.amount === "" ? 0 : o.amount)
          , 0);

          total = total + totalByOrderMaterials;
          
          let percentage = parseFloat((total/maxTonnageByDay) * 100).toFixed(2);

          if(percentage > 100) showMaxCapacityMessage = true;
  
          items.push({
            id: uuid(),
            group: group.id,
            start_time: day.startOf('day').valueOf(),
            end_time: day.endOf('day').valueOf(),
            canMove: false,
            canResize: false,
            isNewOrder: true,
            total,
            percentage,
            tonday: maxTonnageByDay
          })
        }
      }
    });

    const ordersItem = order_materials.map((om, index) => ({
      id: om.id,
      group: om.group_id,
      title: om.amount,
      start_time: moment(om.start).startOf('day'),
      end_time: moment(om.start).endOf('day'),
      canMove: true,
      canResize: false,
      isNewOrder: true
    }));

    items = items.concat(ordersItem);
    const appLocale = localStorage.getItem("language");

    return (
      <React.Fragment>
        <div className='buttonLine'>
          <MixingPlantFilter 
            setMixingPlantFilter={this.setMixingPlantFilter}
            mixingPlantFilterValues={mixingPlantFilter}
          />
          <DeleteOrderModal deleteOrder={this.deleteOrder} selected={selected} />
          <Button.Group>
            <DatePicker
              className="datepickerInput"
              todayButton={t("general.today")}
              customInput={
                <Input
                  label={{ icon: "calendar alternate outline" }}
                  labelPosition="right"
                  placeholder={t("order.buttons.jump_to")}
                  type="text"
                />
              }
              dateFormat={appLocale === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
              selected={moment(visibleTimeStart).toDate()}
              onChange={this.jumpToDate}
              strictParsing
            />
          </Button.Group>
        </div>
        {showMaxCapacityMessage && 
          <Message negative>
            <Message.Header>{t("order.neworder.capacity_error.header")}</Message.Header>
            <p>{t("order.neworder.capacity_error.message")}</p>
          </Message>
        }
        <Timeline
          mixingPlants={this.props.mixingPlants}
          onRowClickFilter={this.onRowClickFilter}
          timelineContext={NEW_ORDER_CONTEXT}
          resizeDetector={containerResizeDetector}
          groups={groups}
          items={items}
          visibleTimeStart={this.state.visibleTimeStart.valueOf()}
          visibleTimeEnd={this.state.visibleTimeEnd.valueOf()}
          dragSnap={1000 * 60 * 60 * 1}
          itemHeightRatio={1}
          minZoom={1 * 24 * 60 * 60 * 1000}
          maxZoom={1 * 24 * 60 * 60 * 1000}
          canChangeGroup={false}
          sidebarWidth={250}
          stackItems={true}
          stickyOffset={51}
          onTimeChange={this.handleTimeChange}
          lineHeight={50}
          itemRenderer={this.itemRenderer}
          groupRenderer={this.groupRenderer}
          onCanvasClick={this.onCanvasClick}
          onItemSelect={this.onItemSelect}
          onItemResize={this.onItemResize}
          onItemMove={this.onItemMove}
          showMaxCapacityMessage={showMaxCapacityMessage}
          stickyHeader={false}
          selected={selected}
          dragSelectAllowed={false}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) { 
  const mixingPlants = state.mixingPlants.filter((mp) => {
    return !mp.readonly || mp.readonly === false;
  });
  const orders = state.orders;
  const materials = state.materials;

  let groups = mixingPlants.map(mp => ({
    id: mp.id,
    title: mp.name,
    tonday: mp.tonday,
    root: true,
    parent: null
  }));

  props.materialsGroups.forEach(mg => {
    let groupIds = groups.map(g => g.id);

    let indexFromParent = groupIds.indexOf(mg.parent);
    if(indexFromParent === -1) return;
    
    let indexToInsert = ++indexFromParent;  
    groups.splice(indexToInsert, 0, mg)
  });

  return {
    groups,
    orders,
    materials,
    mixingPlants
   }
}

export default withNamespaces('translation')(connect(mapStateToProps, {})(MixingPlantsCapacityTimeline));