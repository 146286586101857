import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './components/App/App';
import { isTokenValid, USER_LOCALSTORAGE_TOKEN } from './actions/authentication';

import 'sanitize.css';
import 'semantic-ui-css/semantic.min.css';
import './components/Timeline/lib/Timeline.css';
import 'rc-time-picker/assets/index.css';
import 'leaflet/dist/leaflet.css'
import './index.css';

import './i18n';

import { history } from './store';
import { ConnectedRouter } from "react-router-redux";

const user = localStorage.getItem(USER_LOCALSTORAGE_TOKEN);

async function checkUser() {
  await isTokenValid(user);
}

if(user && user !== '') {
  checkUser();
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>, document.getElementById('root'));
