import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { setFontColor } from '../../../helpers/set-font-color';
import { getCapacityAtTime } from '../../../helpers/silo-capacity-helper';

class SiloDiagram extends React.Component { 
  
  state = {
    time: this.props.time
  }

  componentDidUpdate(prevProps) {
    if(prevProps.time !== this.props.time) {
      this.setState({
        time: this.props.time
      })
    }
  }
  
  render() {
    const {silos, biggerSilo, silosCapacityData} = this.props;
    const {time} = this.state;
    const maxHeight = 120;

    // if(time === 0) return null;
    
    const silosDiagram = [];
    const maxSize = parseFloat(biggerSilo.max);

    silos.forEach((silo, index) => {
      let siloSize = parseFloat(silo.max);
      let percentageFromMax = (siloSize * 100)/maxSize;

      let height = maxHeight * percentageFromMax/100;

      let siloColor = silo.color;
      let fontColor = setFontColor(siloColor);

      let siloCapacity = parseInt(getCapacityAtTime(silosCapacityData, time, silo.id));
      let siloCapacityPercentage = (siloCapacity * 100)/siloSize;

      if(isNaN(siloCapacityPercentage)) siloCapacityPercentage = 0;

      if(siloCapacityPercentage > 100) siloCapacityPercentage = 100;

      let siloCapacityText = null;
      // if(siloCapacity > 0) siloCapacityText = `${siloCapacity}t`;
      
      silosDiagram.push((
        <div className="sd_silowrapper" key={silo.id}>
          <div className="sd_siloname">{silo.name}</div>
          <div className="sd_top">
            <div className="sd_silo" style={{height}}> 
              <p className="sd_silosize">{`${siloSize}t`}</p>
              <div 
                className="sd_filling" 
                style={{background: siloColor, color: fontColor, height: `${siloCapacityPercentage}%`}}
              >
                {siloCapacityText}
              </div>
            </div>
          </div>
          <div className="sd_feets">
            <div className="sd_number"></div>
          </div>
        </div>
      ));
    });

    return (
      <div className="sd_wrapper">
        {silosDiagram}
      </div>
    )
  }
}

const SiloDiagram_i18nWrapper = withNamespaces('translation')(SiloDiagram);

function mapStateToProps(state, props) { 
  let silos = state.silos.filter(s => props.mixingPlants.indexOf(s.mixing_plants_id) !== -1);
  const silosCapacityData = state.app.siloCapacityData.chartdata;
  let biggerSilo = silos.sort((a, b) => {
    if(a.max > b.max) return -1;
    if(a.max < b.max) return 1;
    return 0;
  });

  if(biggerSilo.length === 0) biggerSilo = false;
  if(biggerSilo.length > 0) biggerSilo = biggerSilo[0]

  return {
    silos,
    biggerSilo,
    silosCapacityData
  }
}

export default connect(mapStateToProps, {})(SiloDiagram_i18nWrapper);
