import React from 'react';
import { Dropdown, Icon } from "semantic-ui-react";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { AVAILABLE_LANGUAGES } from "./AvailableLanguages"
import i18n from 'i18next';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB, de } from 'date-fns';


class LanguagePicker extends React.Component {

  state = {
      language: "de"
  }

  componentDidMount() {
    registerLocale('en', enGB);
    registerLocale('de', de);
  }

  handleLanguageChange = (e, v) => {
    let lang = v.value;
    i18n.changeLanguage(lang);
    moment.locale(lang);  
    localStorage.setItem("language", lang);
    setDefaultLocale(lang.toLowerCase());
  }

  render() {
    const { t } = this.props;
    const languages = AVAILABLE_LANGUAGES;
    let languageOptions = [];

    for (let i = 0; i < languages.length; i++) {
        let language = languages[i];
        languageOptions.push({
            key: i,
            value: language,
            text: t(`translations.languages.${language}`)
        });
    }

    return(
        <Dropdown 
            className="icon"
            button
            labeled
            options={languageOptions}
            text={t("translations.select_language")}
            icon="globe"
            onChange={this.handleLanguageChange}
            style={{float: this.props.float}}
        >
        </Dropdown>
    );
  }
}

const LanguagePicker_i18nWrapper = withNamespaces('translation')(LanguagePicker);

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

export default connect()(LanguagePicker_i18nWrapper);
