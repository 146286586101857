import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Popup, Dropdown, Icon } from "semantic-ui-react";
import alphanumerical from "../../../helpers/utils"

class AddMaterialToMixingPlant extends React.Component {
  state = {
    popup: false
  }

  handleChooseMaterial = (e, data) => { 
    const {mixingPlant, materials, groups} = this.props;

    const id = `${data.value}_${mixingPlant.id}`;

    let groupAlreadyAdded = groups.filter(g => g.id == id && g.parent === mixingPlant.id).length > 0;
    if(groupAlreadyAdded) {
      this.setState({popup: false});
      return;
    }

    let material = materials.filter(m => m.id == data.value)[0];
    if(!material) {
      this.setState({popup: false});
      return;
    }

    const newGroup = {
      id,
      title: material.name,
      root: false,
      parent: mixingPlant.id
    }

    this.props.addMaterial(newGroup);
    this.setState({popup: false});
  }

  render() {

    const { t, materialsDropdown, mixingPlant } = this.props;

    return(
      <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div>{mixingPlant.title}</div>
        <Popup
          className="white_popup"
          wide={"very"}
          open={this.state.popup}
          onOpen={_ => this.setState({popup: true})}
          onClose={_ => this.setState({popup: false})}
          on='click'
          trigger={
            <Popup 
              className="white_popup"
              position="bottom left" 
              trigger={
                <div className='div-button' onClick={_ => this.setState({popup: true})}>
                  <Icon name='plus'/>
                </div>
              } 
              content={t('material.newmaterial.buttontitle')}   
            />
          }
          content={(
            <div style={{minWidth: '200px'}}>
              <Dropdown 
                placeholder={t('material.newmaterial.buttontitle')}
                fluid 
                search 
                selection 
                options={materialsDropdown.sort(alphanumerical)} 
                onChange={this.handleChooseMaterial}
              />
            </div>
          )}
          position="bottom left"
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) { 
  const materials = state.materials;

  const materialsDropdown = materials.map(m => ({
    key: m.id,
    value: m.id,
    text: m.name
  }));

  return {
    materials,
    materialsDropdown
  }
}

export default withNamespaces('translation')(connect(mapStateToProps, {})(AddMaterialToMixingPlant));