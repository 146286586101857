import React from 'react';
import { connect } from "react-redux";
import { Popup, Icon } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';

const Refresh = ({ t, openPortal }) => {
  const handleClick = e => {
    openPortal();
  }

  return(
    <Popup trigger={
        <a className="ad" title="" onClick={handleClick}>
            <Icon name="sync"/>
        </a>
    } content={t('topbuttons.refresh')} />
  );
}

const Refresh_i18nWrapper = withNamespaces('translation')(Refresh);

const mapStateToProps = (state, props) => {
  return {};
};

export default connect(mapStateToProps, {Refresh})(Refresh_i18nWrapper);