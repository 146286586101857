import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import LabelledButton from '../LabelledButton/LabelledButton';
import {
  Popup,
  Dropdown,
  Button
} from "semantic-ui-react";
import alphanumerical from "../../helpers/utils"

const inStyle = {
  container: {
    fontSize: '14px',
    minWidth: '250px'
  }
}

class MixingPlantFilter extends React.Component {

  state = {
    filter: [],
    isFilterOpen: false
  }

  handleChooseMixingPlant = (event, data) => {
    this.setState({filter: data.value});
    this.props.setMixingPlantFilter(data.value);
  }

  toggleFilter = () => {
    this.setState((prevState, props) => ({
      isFilterOpen: !prevState.isFilterOpen
    }));
  }

  render() {
    const { isFilterOpen } = this.state;
    const { t, mixingPlants, mixingPlantFilterValues, disabled } = this.props;

    const mixingPlantDropdown = mixingPlants.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    }));

    let color = undefined;
    if(mixingPlantFilterValues.length > 0) color = 'black';

    return(
      <Popup
        wide={"very"}
        trigger={
          <div style={{display: "inline-block"}}>
            <Popup
              className={"white_popup"}
              wide={"very"}
              trigger={
                  <Button
                    disabled={disabled}
                    onClick={this.toggleFilter}
                    type="button"
                    color={color}
                    icon="industry"
                  />
              }
              content={
                <div style={inStyle.container}>
                  <p>{t("order.buttons.filter_mixingplants")}</p>
                  <Dropdown 
                    placeholder={t('mixingplant.header')}
                    fluid 
                    search 
                    selection 
                    multiple
                    options={mixingPlantDropdown} 
                    onChange={this.handleChooseMixingPlant}
                    value={mixingPlantFilterValues}
                  />
                </div>
              }
              position="bottom left"
              on="click"
            />
          </div>
        }
        content={t("order.buttons.filter")}
        position="top center"
        on="hover"
      />
    );
  }
}

const MixingPlantFilter_i18nWrapper = withNamespaces('translation')(MixingPlantFilter);

function mapStateToProps(state, props) {
  const mixingPlants = state.mixingPlants;

  return {mixingPlants};
}

export default withRouter(connect(mapStateToProps, {})(MixingPlantFilter_i18nWrapper));