import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from "react-router-redux";
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import createHistory from "history/createBrowserHistory";

const loggerMiddleware = createLogger();

export const history = createHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
  thunkMiddleware,
  process.env.NODE_ENV === 'development' && loggerMiddleware,
  routerMiddleware(history)
].filter(Boolean);

export const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(...middlewares)
    )
);