import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  Table,
  Form,
  Checkbox
} from "semantic-ui-react";
import { updateSetting } from '../../actions/settings';

class Settings extends Component {
  constructor(props) {
    super(props);

    const tableData = props.tableData;

    this.state = {
      column: null,
      direction: null,
      tableData
    }
  }

  handleSort = (clickedColumn) => {
    const { column, direction, tableData } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tableData: _.sortBy(tableData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      tableData: tableData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  handleChange = (e, {value}, setting) => {

    const updatedSetting = {
      ...setting,
      value
    }

    this.props.updateSetting(updatedSetting)
  }

  settingBody = setting => {
    const { t } = this.props;

    switch (setting.name) {
      case 'show_customer_in_box':
        return (
          <Table.Row key={setting.id} style={{fontSize: '12px'}}>
            <Table.Cell width={2}>{t(`settings.${setting.name}`)}</Table.Cell>
            <Table.Cell width={2}>
              <Form style={{ paddingTop: '5px', paddingBottom: '5px'}}>
                <Form.Field>
                  <Checkbox
                    radio
                    label={t("customer.header")}
                    name='checkboxRadioGroup'
                    value='0'
                    checked={setting.value === '0'}
                    onChange={(e, data) => this.handleChange(e, data, setting)}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label={t("construction_sites.singular")}
                    name='checkboxRadioGroup'
                    value='1'
                    checked={setting.value === '1'}
                    onChange={(e, data) => this.handleChange(e, data, setting)}
                  />
                </Form.Field>
              </Form>
            </Table.Cell>
          </Table.Row>
        )
      default:
        return null
    } 
  }

  render() {
    const { t, settings } = this.props;
    const { column, tableData, direction } = this.state;

    return (
      <div>
        <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
        <Table celled sortable selectable className="settingsList">
          <Table.Header>
            <Table.Row style={{fontSize: '12px'}}>
              <Table.HeaderCell
                sorted={column === 'description' ? direction : null}
                onClick={() => this.handleSort('description')}
                className="textSort">
                  {t("settings.description")}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map(s => this.settingBody(s))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const settings = state.settings;
  const tableData = settings;

  return {
    settings,
    tableData
  };
}

export default withNamespaces('translation')(
  connect(mapStateToProps, {updateSetting})(Settings)
)