import React from 'react';
import LabelledButton from '../LabelledButton/LabelledButton';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { changeProductionView } from '../../actions/app';
import { Button } from "semantic-ui-react";

const views = {
  order: "material",
  material: "order"
}

const icons = {
  order: "book",
  material: "lab"
}

class ChangeProductionView extends React.Component {
  state = {}

  render() {

    const {nextView, label} = this.props;
    const icon = icons[nextView];

    return (
      <Button
        className="buttonPadRight"
        onClick={() => this.props.changeProductionView(nextView)}
        icon={icon}
        content={label}
      />
    )
  }
}

function mapStateToProps(state, props) { 
  const actualView = state.app.view;
  const nextView = views[actualView];

  const labels = {
    order: props.t("order.buttons.order"),
    material: props.t("order.buttons.material")
  }

  return {
    nextView,
    label: labels[nextView]
  }
}

const reduxWrapper = connect(mapStateToProps, {changeProductionView})(ChangeProductionView);
export default withNamespaces('translation')(reduxWrapper);
