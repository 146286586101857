import uuid from 'uuid/v4';
import moment from 'moment';
import { addDeletedEntry } from './deleted';
import { store } from "../store";

export const ADD_PRODUCTION_TAKT = 'productionTakt/ADD_PRODUCTION_TAKT';
export const EDIT_PRODUCTION_TAKT = 'productionTakt/EDIT_PRODUCTION_TAKT';
export const REMOVE_PRODUCTION_TAKT = 'productionTakt/REMOVE_PRODUCTION_TAKT';
export const LOAD_PRODUCTION_TAKT = 'productionTakt/LOAD_PRODUCTION_TAKT';
export const ADD_MULTIPLE_PRODUCTION_TAKT =  'productionTakt/ADD_MULTIPLE_PRODUCTION_TAKT';
export const REMOVE_MULTIPLE_PRODUCTION_TAKT =  'productionTakt/REMOVE_MULTIPLE_PRODUCTION_TAKT';
export const EDIT_MULTIPLE_TAKTS =  'productionTakt/EDIT_MULTIPLE_TAKTS';

export function addProductionTakt(data) {
  return async (dispatch) => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;

    await dispatch({
      type: ADD_PRODUCTION_TAKT,
      data
    });
    
    return data.id;
  }
}

export function addMultipleProductionTakt(data) {
  return async (dispatch) => { 
    data = data.map(d => {
      d.edited = true;
      d.created_at = moment().valueOf();
      d.updated_at = 0;
  
      return d;
    });

    await dispatch({
      type: ADD_MULTIPLE_PRODUCTION_TAKT,
      data
    });
  }
  
}

export function removeMultipleProductionTakt(data) {
  return async (dispatch) => { 
    data = data.map(d => d.id);

    data.forEach(id => {
      addDeletedEntry({
        id,
        table: 'production_takt'
      });
    });

    await dispatch({
      type: REMOVE_MULTIPLE_PRODUCTION_TAKT,
      data
    });
  }
  
}

export function editProductionTakt(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_PRODUCTION_TAKT,
      data
    });
  }
}

export function editProductionTaktWithoutDispatch(data) {
  data.edited = true;
  data.updated_at = moment().valueOf();
  store.dispatch({
    type: EDIT_PRODUCTION_TAKT,
    data
  });
}

export function editMultipleTaktsWithoutDispatch(data) {
  data = data.map(d => {
    d.edited = true;
    d.updated_at = moment().valueOf();
    return d;
  });

  store.dispatch({
    type: EDIT_MULTIPLE_TAKTS,
    data
  });
}

export function removeProductionTakt(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'production_takt'
    });
    
    dispatch({
      type: REMOVE_PRODUCTION_TAKT,
      id
    });
  }
}

export function updateProductionTakt(productionTakt) {
  return dispatch => {
    dispatch({
      type: LOAD_PRODUCTION_TAKT,
      data: productionTakt
    });
  }
}