import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    Table,
    Header,
    Segment,
    Icon,
    Button
} from "semantic-ui-react";
import Buttons from './Buttons';
import ConstructionSitesList from './ConstructionSitesList';
import { CUSTOMER_PERMISSION } from '../../constants/constants';

class ConstructionSites extends React.Component {

    state = {};

    handleInputChange = (field, e) => {
        let state = this.state;
        state[field] = e.target.value;

        this.setState(state);
    };

    handleReturn = _ => {
        this.props.history.push('/auftragsdispo/base-data?tab=customers');
    }

    render() {

        const {t, isCustomer, customer} = this.props;
        let customerName = "";
        if (!!customer)
            customerName = customer.name;

        return (
            <div className='page'>
                <Segment raised>
                    <Header as="h2" floated="left">
                        <Header.Content>{t("customer.construction_sites.header")} - {customerName}</Header.Content>
                    </Header>

                    <div style={{textAlign: 'right'}}>
                        <Button.Group>
                            <Button
                                type="button"
                                onClick={() => this.handleReturn()}
                            >
                                <Icon name="arrow left"/>
                                {t("buttons.return")}
                            </Button>
                        </Button.Group>
                    </div>

                    {!isCustomer && (
                        <div style={{clear: 'both'}}>
                            <Buttons/>
                        </div>
                    )}

                    <Table celled compact>
                        <Table.Header>
                            <Table.Row style={{fontSize: '12px'}}>
                                <Table.HeaderCell>{t("construction_sites.name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("construction_sites.contract_number")}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <ConstructionSitesList/>
                    </Table>
                </Segment>
            </div>
        );
    }
}

const ConstructionSites_i18nWrapper = withNamespaces('translation')(ConstructionSites);

function mapStateToProps(state, props) {
    const customers = state.customers;
    const customerId = 'id' in props.match.params ? props.match.params.id : props.customer;
    const user = state.user;

    let isCustomer = false;
    !!user.permissions && user.permissions.forEach(permission => {

        if (permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
        }

    });


    if (!(customerId) && !isCustomer) props.history.push('/auftragsdispo/base-data?tab=customers');

    const customer = customers.filter(c => c.id == customerId);

    if (customer.length === 0 && !isCustomer) props.history.push('/auftragsdispo/base-data?tab=customers');

    return {
        customer: customer[0],
        history: props.history,
        isCustomer
    };
}

export default withRouter(connect(mapStateToProps, {})(ConstructionSites_i18nWrapper));