import React from 'react';
import { connect } from "react-redux";
import { Popup } from 'semantic-ui-react';
import { withNamespaces } from 'react-i18next';
import { logOut } from '../../actions/authentication';
import LogoutImg from "../../images/logout.png";


const Logout = ({ t, logOut, authtenticated }) => {

  const handleClick = e => {
    if(authtenticated) {
      const result = window.confirm(t("app.logout"));

      if(result) logOut()
    }
  }

  return(
    <Popup trigger={
      <a className="ad" title="" onClick={handleClick}>
				<img alt={t("topbuttons.logout")} src={LogoutImg} />
			</a>
    } content={t('topbuttons.logout')} />
  );
}

const Logout_i18nWrapper = withNamespaces('translation')(Logout);

const mapStateToProps = (state, props) => {
  return { authtenticated: state.auth.authenticated };
};

export default connect(mapStateToProps, {logOut})(Logout_i18nWrapper);