import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { logOut, USER_LOCALSTORAGE_TOKEN, INTENDED_ACTION_AFTER_ERROR, save, isTokenValid } from '../../actions/authentication';
import { Modal, Header, Button, Icon, Input } from 'semantic-ui-react';

const URL = process.env.REACT_APP_SERVER_URL;

class ReLogin extends React.Component {

    constructor(props) {
        super(props);

        this.passwordField = React.createRef();
        this.state = {
            passwordOk: false,
            retryFailed: false,
            isLoading: false,
            success: false,
        };
    }

    componentDidUpdate() {
        const passwordFieldElement = this.passwordField.current;

        if (!!passwordFieldElement) {
            passwordFieldElement.focus();
        }
    }

    handlePasswordKeypress = (event) => {
        const passwordOk = event.target.value.length >= 3;
        const key = event.key;

        if (key === "Enter" && passwordOk)
            this.closePortal(true);
    }
    
    checkPassword = (event, data) => {
        const password = data.value;
        const passwordOk = password.length >= 3;

        this.setState({
            passwordOk,
            retryFailed: false,
            password,
        });
    }

    closePortal = (retry) => {
        const { t, userName, logOut, save, intendedAction } = this.props;
        const { password } = this.state;

        if (!retry) {
            const result = window.confirm(t("app.logout"));
            if(result) logOut();
        }
        else {
            const formData = new FormData();
            formData.append('username', userName);
            formData.append('password', password);

            this.setState({
                isLoading: true,
            });

            axios.post(`${URL}/login`, formData).then((response) => {
                localStorage.setItem(USER_LOCALSTORAGE_TOKEN, response.data.token);

                this.setState({
                    isLoading: false,
                    passwordOk: false,
                    success: true,
                });

                if (intendedAction === "save") save();
                else if (intendedAction === "load") isTokenValid(response.data.token);
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.setState({
                        retryFailed: true,
                        passwordOk: false,
                    });
                }

                this.setState({
                    isLoading: false,
                });
            });
        }
    }

    render() {
        const { t, error, userName, authenticated } = this.props;
        const { passwordOk, retryFailed, isLoading, success } = this.state;

        return (
            <Modal open={error && authenticated}>
                <Header>{t("login.authentication_failed")}</Header>
                <Modal.Content>
                    <p>{t("login.relogin_message")}</p>

                    <div className="inlineDiv">
                        <label htmlFor="username" disabled>
                            {t("login.username")}
                        </label>
                        <Input
                            type="text"
                            placeholder={t("login.username")}
                            id="username"
                            value={userName}
                            disabled/>
                    </div>

                    <div className="inlineDiv">
                        <label htmlFor="password">
                            {t("login.password")}
                        </label>
                        <Input
                            type="password"
                            placeholder={t("login.password")}
                            id="password"
                            onChange={this.checkPassword}
                            error={retryFailed}
                            ref={this.passwordField}
                            onKeyPress={this.handlePasswordKeypress}/>
                   </div>
                </Modal.Content>

                <Modal.Actions>
                    <Button color="red" onClick={() => this.closePortal(false)}>
                        <Icon name="cancel"/>
                        {t("topbuttons.logout")}
                    </Button>

                    <Button
                        color="green"
                        disabled={!passwordOk || isLoading}
                        loading={isLoading}
                        onClick={() => this.closePortal(true)}
                    >
                        { success &&
                            <Icon name="checkmark" />
                        }
                        {t("login.retry")}
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const ReLogin_i18nWrapper = withNamespaces('translation')(ReLogin);

const mapStateToProps = (state, props) => {
    const { error, authenticated, [INTENDED_ACTION_AFTER_ERROR]:intendedAction } = state.auth;
    const { user } = state;
    const userName = user.name;

    return {
        error: !!error,
        userName,
        authenticated,
        intendedAction,
    };
};

export default connect(mapStateToProps, {logOut, save})(ReLogin_i18nWrapper);