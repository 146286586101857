import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import LabelledButton from '../LabelledButton/LabelledButton';
import {withRouter} from "react-router";
import MixingPlantFilter from '../Filters/MixingPlantFilter';
import ChangeProductionView from './ChangeProductionView';
import SetMultiSelect from './SetMultiSelect';
import {setFullScreen} from '../../actions/app';
import CapacityToggleButton from './CapacityToggleButton'
import OrderExportModal from './OrderExportModal';
import {Button, Popup, Input} from "semantic-ui-react";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MISCHMEISTER_PERMISSION, ADMIN_PERMISSION, PRODUCTIONTAKT_PERMISSION } from '../../constants/constants';

class Buttons extends React.Component {

    state = {};

    handleInputChange = (field, e) => {
        let state = this.state;
        state[field] = e.target.value;

        this.setState(state);
    };

    jumpToDate = (date, event) => {
        const {jumpToDate} = this.props;
        const dateMoment = moment(new Date(date)).startOf("day").valueOf();

        if (!!jumpToDate)
            jumpToDate(dateMoment)
    }

    render() {

        const {
          t,
          history,
          canAdd,
          isMixtureMaster,
          admin_view_prod_takt,
          isSiloCapacityGraphButtonActive,
          isCapacityButtonActive,
          showHorizontalCapacity,
          showSiloCapacityGraph,
          visibleTimeStart,
          undoAvailable,
          redoAvailable,
          moreMixingplants,
          seeProductionTakt,
          writeable
        } = this.props;
        const appLocale = localStorage.getItem("language");

        return (
            <div style={{display: 'flex'}}>
                <div className='buttonLineWrapper'>
                    <div className="buttonLine">
                        <Button.Group className="btnGroup">
                            <Popup
                                trigger={
                                    <Button
                                        onMouseDown={this.props.left}
                                        onMouseUp={this.props.stopMoving}
                                        icon="arrow left"
                                        // label={t("order.buttons.left")}
                                    />
                                }
                                content={t("order.buttons.left")}
                                position="top center"
                            />
                            <Popup
                                trigger={
                                    <Button
                                        onMouseDown={this.props.right}
                                        onMouseUp={this.props.stopMoving}
                                        icon="arrow right"
                                        // label={t("order.buttons.right")}
                                    />
                                }
                                content={t("order.buttons.right")}
                                position="top center"
                            />
                        </Button.Group>

                        <Button.Group className="btnGroup">
                            <Popup
                                trigger={
                                    <Button
                                        onClick={this.props.handleZoomOut}
                                        // label={t("Zoom Out")}
                                        icon="zoom out"
                                    />
                                }
                                content={t("order.buttons.zoom_out")}
                                position="top center"
                            />

                            <Button
                                onClick={this.props.handleBookmark}
                                content={t("order.buttons.bookmark")}
                                icon="bookmark"
                            />
                            <Popup
                                trigger={
                                    <Button
                                        onClick={this.props.handleZoomIn}
                                        // label={t("Zoom In")}
                                        icon="zoom in"
                                    />
                                }
                                content={t("order.buttons.zoom_in")}
                                position="top center"
                            />
                        </Button.Group>

                        <Button.Group className="btnGroup">
                            <DatePicker
                                className="datepickerInput"
                                todayButton={t("general.today")}
                                customInput={
                                    <Input
                                        label={{icon: "calendar alternate outline"}}
                                        labelPosition="right"
                                        placeholder={t("order.buttons.jump_to")}
                                        type="text"
                                    />
                                }
                                dateFormat={appLocale === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
                                selected={moment(visibleTimeStart).toDate()}
                                onChange={this.jumpToDate}
                                strictParsing
                            />
                        </Button.Group>

                        {writeable &&
                            <Button.Group className="btnGroup">
                                <Button
                                    disabled={!undoAvailable}
                                    onClick={this.props.undo}
                                    icon="undo"
                                    content={t("order.buttons.undo")}
                                />
                                <Button
                                    disabled={!redoAvailable}
                                    onClick={this.props.redo}
                                    icon="redo"
                                    content={t("order.buttons.redo")}
                                />
                            </Button.Group>
                        }

                        {canAdd && <Button className="buttonPadRight"
                                           content={t("order.neworder.buttontitle")}
                                           icon="plus"
                                           onClick={() => history.push('/auftragsdispo/orders/new')}
                        />}

                        {moreMixingplants &&
                            <MixingPlantFilter
                                disabled={!!admin_view_prod_takt}
                                setMixingPlantFilter={this.props.setMixingPlantFilter}
                                mixingPlantFilterValues={this.props.mixingPlantFilterValues}
                            />
                        }

                        {seeProductionTakt &&
                            <Popup
                                trigger={
                                    <Button
                                        onClick={!!admin_view_prod_takt ? this.props.removeTaktView : this.props.showTaktModal}
                                        icon="clock outline"
                                        // label={t("order.buttons.show_takt")}
                                        active={!!admin_view_prod_takt}
                                    />
                                }
                                content={t("order.buttons.show_takt")}
                                position="top center"
                            />
                        }

                        <CapacityToggleButton
                            handleShowChartCapacity={this.props.handleShowHorizontalCapacity}
                            handleShowSiloCapacity={this.props.showSiloCapacityGraphAction}
                            isChartCapacityActive={showHorizontalCapacity}
                            isSiloCapacityActive={showSiloCapacityGraph}
                            isChartCapacityDisabled={!isCapacityButtonActive}
                            isSiloCapacityDisabled={!isSiloCapacityGraphButtonActive}
                            isMixtureMaster={isMixtureMaster}
                        />

                        {writeable &&
                        <Button.Group className="btnGroup">
                            <Popup
                                trigger={
                                    <Button
                                        onClick={this.props.cutAction}
                                        icon="share alternate"
                                    />
                                }
                                content={t("order.buttons.cut")}
                                position="top center"
                            />

                            <Popup
                                trigger={
                                    <Button
                                        onClick={this.props.mergeAction}
                                        icon="share alternate flipped"
                                    />
                                }
                                content={t("order.buttons.merge")}
                                position="top center"
                            />

                            <SetMultiSelect/>
                        </Button.Group>
                        }

                        <OrderExportModal/>

                        {isMixtureMaster &&
                        <ChangeProductionView/>
                        }
                        <Popup
                            trigger={
                                <Button
                                    className="buttonPadRight"
                                    onClick={this.props.setFullScreen}
                                    icon="expand arrows alternate"
                                    // label={t("order.buttons.set_fullscreen")}
                                />
                            }
                            content={t("order.buttons.set_fullscreen")}
                            position="top center"
                        />
                    </div>

                </div>
                {/* {isMixtureMaster && showSiloCapacityGraph &&
          <div style={{flexGrow: '1', minWidth: '100px'}}>
            <div style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <SiloDiagram time={this.props.time} mixingPlants={this.props.mixingPlantsIds} />
            </div>
          </div>
        } */}
            </div>
        );
    }
}

const Buttons_i18nWrapper = withNamespaces('translation')(Buttons);

function mapStateToProps(state, props) {
    const permissions = state.permissions;
    const user = state.user;
    const admin_view_prod_takt = state.app.admin_view_prod_takt;
    const appLocale = state.app.locale;
    const mixingplants = state.mixingPlants;

    let writeable = false;

    writeable = mixingplants.filter(mp => mp.readonly === false).length > 0;


    let isMixtureMaster = false;
    let moreMixingplants = false;
    let seeProductionTakt = false;

    if(!!state.mixingPlants && state.mixingPlants.length > 1)
    {
        moreMixingplants = true;
    }

    !!user.permissions && user.permissions.forEach(permission => {
        if(permission.id === PRODUCTIONTAKT_PERMISSION.id || permission.id === ADMIN_PERMISSION.id) {
            seeProductionTakt = true;

            if (admin_view_prod_takt) {
                isMixtureMaster = true;
            }
        }
        if (permission.id === MISCHMEISTER_PERMISSION.id) {
            isMixtureMaster = true;
        }
    
      });

    return {
        history: props.history,
        canAdd: permissions.filter(p => p.perm_type === 'ADD_ORDER').length > 0,
        isMixtureMaster,
        moreMixingplants,
        admin_view_prod_takt,
        appLocale,
        seeProductionTakt,
        writeable
    };
}

export default withRouter(connect(mapStateToProps, {setFullScreen})(Buttons_i18nWrapper));