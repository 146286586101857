import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  Form,
  Button,
  Icon,
  Message
} from "semantic-ui-react";
import { saveToStore } from '../../actions/basedata';
import Validate from 'react-validate-form';
import { validationRules } from '../../validationRules';
import { save } from '../../actions/authentication';

class Home extends React.Component {

  state = {
    companyName: '',
    isEditing: false,
    initialState: null,
    didChange: false
  }

  componentWillMount() {
    this.setState({
      companyName: this.props.basedata.companyName
    });
  }

  handleNegative = () => {
    this.setState((prevState, props) => ({
      ...prevState.initialState
    }));
  }

  handlePositive = () => {
    const { authenticated, save } = this.props;

    const tmpState = Object.assign(this.state);
    delete tmpState.isEditing;
    delete tmpState.initialState;
    delete tmpState.didChange;

    this.props.saveToStore(this.state);

    if (authenticated)
      save();
  }

  toggleEditing = () => {
    this.setState((prevState, props) => ({
      isEditing: !prevState.isEditing,
      initialState: prevState,
      didChange: false
    }));
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;
    state['edited'] = true;
    state["didChange"] = true;

    this.setState(state);
  };

  render() {

    const { t } = this.props;
    const { isEditing } = this.state;

    const validations = {
      companyName: ["nameRequired"]
    };

    const shouldDisplayErrors = this.state.didChange && isEditing;

    return (
      <Validate validations={validations} rules={validationRules}>
        {({ validate, errorMessages, allValid, errorCount }) => (
          <div>
            <Form.Field>
              <Form.Input
                label={t("basedata.companyName")}
                name="companyName"
                placeholder={t("basedata.companyName")}
                disabled={!isEditing}
                value={this.state.companyName}
                onChange={(e) => {
                  this.handleInputChange('companyName', e);
                  validate(e);
                }}
                fluid
              />
              {!!errorMessages.companyName && errorMessages.companyName.length > 0 && shouldDisplayErrors &&
                <Message negative>
                  <Message.Header>
                    {t(errorMessages.companyName[0])}
                  </Message.Header>
                </Message>
              }
            </Form.Field>

            <div style={{textAlign: 'right', marginTop: "12px"}}>
              {!isEditing &&
                <Button.Group>
                  <Button
                    type="button"
                    onClick={this.toggleEditing}
                    color="grey">
                      <Icon name="edit outline" />
                      {t("order.actions.edit")}
                  </Button>
                </Button.Group>
              }

              {isEditing &&
                <Button.Group>
                  <Button
                    type="button"
                    onClick={() => this.handleNegative()}
                    color="grey">
                      <Icon name="close" />
                      {t("buttons.cancel")}
                  </Button>

                  <Button disabled={errorCount >= 1} type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                    <Icon name="checkmark" />
                    {t("buttons.ok")}
                  </Button>
                </Button.Group>
              }
            </div>
          </div>
        )}
      </Validate>
    );
  }
}

const Home_i18nWrapper = withNamespaces('translation')(Home);

function mapStateToProps(state) {
  const authenticated = state.auth.authenticated;

  return {
    basedata: state.basedata,
    authenticated
  };
}

export default connect(mapStateToProps, {saveToStore, save})(Home_i18nWrapper);
