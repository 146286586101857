import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Icon, Button, Form, Segment, Header, Message } from "semantic-ui-react";
import { save } from '../../actions/authentication';
import { editMaterial } from '../../actions/materials';
import Validate from "react-validate-form";
import { validationRules } from "../../validationRules";

const inlineStyle = {
  field: {
    marginTop: '10px'
  }
};

class Material extends React.Component {

  state = {
    id: '',
    name: '',
    shortname: ''
  };

  componentDidMount() {
    this.setState({
      id: this.props.material.id,
      name: this.props.material.name,
      shortname: this.props.material.shortname
    });
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;
    state["edited"] = true;

    this.setState(state);
  };

  handlePositive = async (state) => {
    const { authenticated, save } = this.props;

    let data = JSON.parse(JSON.stringify(state));
    await this.props.editMaterial(data);

    if (authenticated)
      await save();

    this.props.history.push('/auftragsdispo/base-data?tab=materials');
  }

  handleNegative = _ => {
    this.props.history.push('/auftragsdispo/base-data?tab=materials');
  }

  render() {

    const { t } = this.props;

    const validations = {
      name: ["nameRequired"]
    };

    return(
      <div className='page'>
        <Validate validations={validations} rules={validationRules}>
          {({ validate, errorMessages, allValid, errorCount }) => (
            <Segment raised>
              <Header as="h2" floated="left">
                <Header.Content>{t("material.edit.header")}</Header.Content>
              </Header>

              <div style={{textAlign: 'right'}}>
                <Button.Group>
                  <Button
                    type="button"
                    onClick={() => this.handleNegative()}
                    color="grey"
                  >
                    <Icon name="close" />
                    {t("buttons.cancel")}
                  </Button>
                  <Button disabled={errorCount >= 1} type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                    <Icon name="checkmark" />
                    {t("buttons.ok")}
                  </Button>
                </Button.Group>
              </div>

              <Form.Group style={{clear: 'both'}}>
                <Form.Input
                  label={t("material.name")}
                  name="name"
                  placeholder={t("material.name")}
                  value={this.state.name}
                  onChange={(e) => {
                    this.handleInputChange('name', e);
                    validate(e);
                  }}
                  fluid
                />
                {!!errorMessages.name && errorMessages.name.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.name[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input
                  label={t("material.shortname")}
                  placeholder={t("material.shortname")}
                  value={this.state.shortname}
                  onChange={(e) => this.handleInputChange('shortname', e)}
                  fluid
                />
              </Form.Group>
            </Segment>
          )}
        </Validate>
      </div>
    );
  }
}

const Material_i18nWrapper = withNamespaces('translation')(Material);

function mapStateToProps(state, props) {
  const authenticated = state.auth.authenticated;
  const materials = state.materials;
  const id = 'id' in props.match.params ? props.match.params.id : undefined;

  const permissions = state.permissions;

  const readOnly = permissions.filter(p => p.perm_type == 'MENU_MATERIAL' && p.perm_desc === 'W').length < 1;

  if(readOnly) props.history.push('/auftragsdispo/login');

  if(!id) props.history.push('/auftragsdispo/materials');

  const material = materials.filter(m => m.id == id)[0];

  if(!material) props.history.push('/auftragsdispo/materials');

  return {
    history: props.history,
    material,
    authenticated
  };
}

export default connect(mapStateToProps, {editMaterial, save})(Material_i18nWrapper);