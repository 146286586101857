import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default function (ComposedComponent) {
  class noRequireAuth extends Component {
    componentWillMount() {
      if (this.props.authenticated) {
        if(this.props.match.url === '/auftragsdispo/login') {
          this.props.history.push('/auftragsdispo/');
          return;
        }
        // this.props.history.push(this.props.match.url);
      }
    }

    componentWillUpdate(nextProps) {
      if (nextProps.authenticated) {
        if(nextProps.match.url === '/auftragsdispo/login') {
          nextProps.history.push('/auftragsdispo/');
          return;
        }
        // nextProps.history.push(nextProps.match.url);
      }
    }

    PropTypes = {
      router: PropTypes.object,
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps)(noRequireAuth);
}