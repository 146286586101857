import { LOAD_PERMISSIONS } from '../actions/permissions';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case LOAD_PERMISSIONS:
      return action.data;
  }
}