import React from 'react';
import { signInAction } from '../../actions/authentication';
import {
  Header,
  Segment,
  Form,
  Button,
  Message
} from "semantic-ui-react";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import LanguagePicker from '../Translation/LanguagePicker'

// remountState is a variable in which the safe can be "cached" when the component is remounted because of changing the language
let remountState = {};

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };
  }

  componentDidMount = () => {
    const hasRemountState = Object.keys(!!remountState ? remountState : {}).length >= 1;

    if (hasRemountState && JSON.stringify(this.state) !== JSON.stringify(remountState)) {
      this.setState({
        ...remountState
      });

      remountState = {};
    }
  }

  componentWillUnmount = () => {
    remountState = this.state;
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state);
  };

  handleKeyPress = (e) => {
    let targetVal = e.target.value;
    let key = e.key;

    if (key === "Enter" && targetVal != "")
      this.handleLogin();
  }

  handleLogin = _ => {
    const { username, password } = this.state;

    this.props.signInAction({username, password}, this.props.history).then(() => {
      if (!this.props.errorMessage)
        remountState = null;
    });
  }

  render() {
    const { t, errorMessage } = this.props;
    const { username, password } = this.state;
    
    return(
      <div className='page'>
        <Segment raised>
          <Form.Group style={{marginBottom: '10px'}}>
            <Header as="h4">
              <Header.Content>{t("login.title")}</Header.Content>
            </Header>

            {errorMessage && (
              <Message error>
                <Message.Header>{t("login.errortitle")}</Message.Header>
                <p>{t("login.errormessage")}</p>
              </Message>
            )}

            <Form.Input
              placeholder={t("login.username")} 
              value={username}
              onChange={(e) => this.handleInputChange('username', e)}
              style={{marginBottom: '12px'}}
              fluid
            />
            
            <Form.Input
              placeholder={t("login.password")} 
              value={password}
              onChange={(e) => this.handleInputChange('password', e)}
              onKeyPress={(e) => {this.handleKeyPress(e)}}
              style={{marginBottom: '12px'}}
              fluid
              type='password'
            />

            <Button type="submit" onClick={this.handleLogin} style={{padding: '25px'}} fluid>
                {t("login.login")}
            </Button>
          </Form.Group> 
        </Segment>
        <LanguagePicker float="right"/>

      </div>
    );
  }
}

const Home_i18nWrapper = withNamespaces('translation')(Login);

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}

export default connect(mapStateToProps, {signInAction})(Home_i18nWrapper);
