import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Modal, Icon, Button, Form, Message} from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import {save} from '../../actions/authentication';
import {addConstructionSite} from '../../actions/constructionSites';
import {withRouter} from "react-router";
import MapLocal from '../MapLocal/MapLocal';
import {CUSTOMER_PERMISSION} from '../../constants/constants';




const inlineStyle = {
    modal: {
        marginTop: "25px !important",
        marginLeft: "auto",
        marginRight: "auto"
    },
    field: {
        marginTop: '10px'
    }
};

class AddConstructionSite extends React.Component {
    
    state = {
        open: 'open' in this.props ? this.props.open : false,
        name: '',
        position: '',
        customer_id: this.props.customer.id,
        contractNumber: '',
        nameError: false
    };
    // console.log(this.props.customer);

    handleInputChange = (field, e) => {
        let state = this.state;
        state[field] = e.target.value;

        this.setState(state, () => {
            this.validateInput()
        });
    };

    handleModal = (open, id = undefined) => {
        let state = {
            open: open,
            name: '',
            position: '',
            contractNumber: '',
            nameError: false
        };

        this.setState(state);

        if ('handleShowAddNewConstructionSite' in this.props) {
            this.props.handleShowAddNewConstructionSite(false, id);
        }
    };

    validateInput = () => {
        const {
            name
        } = this.state;

        let { position } = this.state;

        if (!position || position == "")
            position = "52.520008,13.404954";

        const tmpState = {
            nameError: false
        };

        if (!name)
            tmpState.nameError = true;

        const isValid = !Object.values(tmpState).includes(true);

        this.setState({
            ...tmpState,
            position,
        });

        return isValid;
    }

    handlePositive = async (state) => {
        const isValid = this.validateInput();
        if (!isValid)
            return;

        const {authenticated, save} = this.props;
        let data = JSON.parse(JSON.stringify(state));
        delete data.open;

        const newId = await this.props.addConstructionSite(data);
        await this.handleModal(false, newId);

        if (authenticated)
            await save();
    }

    updatePosition = marker => {
        this.setState({
            position: `${marker.lat},${marker.lng}`
        });
    }

    render() {

        const {t} = this.props;
        const {open, position} = this.state;
        const {nameError} = this.state;

        return (
            <Modal
                style={inlineStyle.modal}
                open={open}
                closeIcon={false}
                closeOnDimmerClick={false}
                onClose={() => this.handleModal(false)}
                trigger={
                    'trigger' in this.props ? this.props.trigger : (
                        <Button
                            content={t("construction_sites.new.buttontitle")}
                            icon="plus"
                            onClick={() => this.handleModal(true)}
                        />
                    )
                }
            >
                <Modal.Header>{t("construction_sites.new.header")}</Modal.Header>
                <Modal.Content>
                    <Form.Group>
                        <Form.Input
                            label={t("construction_sites.name")}
                            placeholder={t("construction_sites.name")}
                            value={this.state.name}
                            onChange={(e) => this.handleInputChange('name', e)}
                            fluid
                        />

                        {nameError &&
                        <Message negative>
                            <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
                        </Message>
                        }

                    </Form.Group>
                    <Form.Group style={inlineStyle.field}>
                        <Form.Input
                            label={t("construction_sites.contract_number")}
                            placeholder={t("construction_sites.contract_number")}
                            value={this.state.contract_number}
                            onChange={(e) => this.handleInputChange('contract_number', e)}
                            fluid
                        />
                    </Form.Group>
                    <Form.Group style={inlineStyle.field}>
                        <MapLocal updatePosition={this.updatePosition} position={position}/>
                    </Form.Group>
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <Button
                            type="button"
                            onClick={() => this.handleModal(false)}
                            color="grey"
                        >
                            <Icon name="close"/>
                            {t("buttons.cancel")}
                        </Button>
                        <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                            <Icon name="checkmark"/>
                            {t("buttons.ok")}
                        </Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        );
    }
}

const AddConstructionSite_i18nWrapper = withNamespaces('translation')(AddConstructionSite);

function mapStateToProps(state, props) {
    const authenticated = state.auth.authenticated;
    const customers = state.customers;
    const user = state.user;

    let isCustomer = false;
!!user.permissions && user.permissions.forEach(permission => {
          if(permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
          }
        });

    let customerId = 'id' in props.match.params ? props.match.params.id : undefined;

    if (isCustomer) {
        try {
            customerId = customers.filter(c => c.user_id == user.id)[0].id;
        } 
        catch (e) {
            console.warn("user is not linked to a customer");
        }
    }

    if (!customerId && !isCustomer) props.history.push('/auftragsdispo/base-data?tab=customers');

    const customer = customers.filter(c => c.id == customerId);

    if (customer.length === 0) props.history.push('/auftragsdispo/base-data?tab=customers');

    return {
        customer: customer[0],
        authenticated
    };
}

export default withRouter(connect(mapStateToProps, {addConstructionSite, save})(AddConstructionSite_i18nWrapper));