import React from 'react';
import TopButtons from './TopButtons';
import LogoImg from "../../images/logo.png";
import preval from 'preval.macro';

const buildDatePreval = preval`module.exports = new Date();`
const buildDate = new Date(buildDatePreval);
const buildDateString = `${buildDate.getDate()}.${buildDate.getMonth()+1}.${buildDate.getFullYear()}`;
console.log(process.env.NODE_ENV, buildDate, process.env.REACT_APP_SERVER_URL.includes("alpha"));

class Header extends React.Component {
  render() {

    let headerClass = [];
    if(this.props.fullscreen) headerClass.push('fullscreen');

    return (
      <div id='header' className={headerClass.join(' ')}>
        <img src={LogoImg} id="logo" alt="BPO Logo" />
        { (process.env.NODE_ENV === "development" || process.env.REACT_APP_SERVER_URL.includes("alpha")) &&
          <p style={{position: "absolute", background: "#00000075", color: "white", padding: "4px"}}>Build [{buildDateString}]</p>
        }
        <TopButtons />
      </div>
    )
  }
}

export default Header;