import React from 'react'
import { Popup, Icon } from 'semantic-ui-react';

export const HourViewItem = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  resizing,
  openModal,
  history,
  selected,
  isAdmin,
  isScaleMaster,
  allMixingplants,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const itemProps = {...getItemProps(item.itemProps)};

  const mixingplant = allMixingplants.filter( mp => mp.id === item.group)[0];
  let readonly = true;
  if(!!mixingplant){
    readonly = mixingplant.readonly;
  }


  if(!resizing && !selected) itemProps.className = `rct-custom ${itemProps.className} shouldHover`;
  if(resizing) itemProps.className = `rct-custom ${itemProps.className} resizing`;
  if(selected) itemProps.className = `${itemProps.className} selected`;

  itemProps.style.transform = "scaleY(0.9)";
  itemProps.style.lineHeight = `${(itemContext.dimensions.height/3) - 5}px`;

  itemProps.style.background = item.color;

  const titleArray = item.title.split('*-*');

  let itemContentStyle = {
    height: `${itemContext.dimensions.height - 1}px`, 
    display: 'flex',
    marginTop: '0px',
    color: 'black',
    paddingLeft: '5px',
    border: '1px solid rgba(0, 0, 0, 0.6)',
    borderRadius: '2px',
    boxShadow: 'inset 0 0 10px #00000038',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 500
  }

  rightResizeProps.style.cursor = 'ew-resize';
  rightResizeProps.style.width = '5px';
  rightResizeProps.style.right = '-2px';

  leftResizeProps.style.cursor = 'ew-resize';
  leftResizeProps.style.width = '5px';
  leftResizeProps.style.right = '-2px';

  if(selected) {
    itemContentStyle.fontWeight = 'bolder';
    itemContentStyle.paddingRight = '5px';

    leftResizeProps.style.width = '10px';
    rightResizeProps.style.width = '10px';
  }


  let nohover = item.obs.replace(/\s/g, "") === ""? "nohover" : "";

  return (
    <div {...itemProps}>
      <div className='rct-drag-left' {...leftResizeProps} />
      <div
        className="rct-item-content"
        style={{ maxHeight: `${itemContext.dimensions.height}px`, width: '100%', padding: '0' }}
      >
        <div style={itemContentStyle}>
          <div onDoubleClick={() => {if (!readonly) openModal(true, item.id)}} style={{overflow: 'hidden', width: '100%'}}>
            <div style={{transform: 'scaleY(1.1)'}}>
              <div style={{transform: 'scaleY(1.1)', paddingTop: '5px'}}>
                {`${titleArray[0]}`} <br />
                {`${titleArray[3]}`} <br />
                {`${titleArray[2]} - ${titleArray[1]}`}
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex', 
            flexDirection: 'column', 
            lineHeight: `${(itemContext.dimensions.height - 1)/3}px`,
            borderLeft: '1px solid rgba(0, 0, 0, 0.6)',
            borderRight: `${selected? "1px solid rgba(0, 0, 0, 0.6)" : "none"}`,
          }}>
            <div 
              style={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)', paddingLeft: '2px', cursor: 'pointer'}}
              onClick={() => readonly ? {} : openModal(true, item.id)}
             className="itemBoxRight">
             { !readonly &&
              <Icon name="edit" color="black" />
             }
            </div>
           {(isAdmin || isScaleMaster)
            ? (
              <div 
                style={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)', paddingLeft: '2px', cursor: 'pointer'}}
                onClick={() => readonly ? {} : history.push(`/auftragsdispo/orders/edit/${item.orderGroup}`)}
               className="itemBoxRight">
               { !readonly &&
                <Icon name="object group" color="black" />
               }
              </div>
            )
            : (
              <div 
                style={{borderBottom: '1px solid rgba(0, 0, 0, 0.6)', paddingLeft: '2px', cursor: 'pointer', flexGrow: '1'}}
               className="itemBoxRight">
              </div>     
            )
                    
           } 
            <div style={{paddingLeft: '2px', cursor: 'pointer'}} className={`itemBoxRight infobutton ${nohover}`}>
              {(item.obs.replace(/\s/g, "") !== "") && !readonly && 
                <Popup trigger={
                  <Icon name="info" color="black" className="filldiv"/>
                } content={item.obs} position='top center' hideOnScroll/>
              }
            </div>
          </div>
        </div>
      </div>

      <div className='rct-drag-right' {...rightResizeProps} />
    </div>
  )
}
