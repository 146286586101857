import React, { Component } from "react";
import moment from "moment";

import { iterateTimes } from "../utility/calendar";
import { _get } from "../utility/generic";

import { Bar, defaults } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

import i18n from '../../../../i18n';
import { getParentPosition } from "../utility/dom-helpers";

let chartOptions = {
  tooltips: {
    mode: 'label',
    callbacks: {
       label: function(tooltipItem, data) {
          if(data.datasets[tooltipItem.datasetIndex].label === 'error') return;
          return data.datasets[tooltipItem.datasetIndex].label + ": " + Math.round(tooltipItem.yLabel);
       }
    }
  },
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  responsive: false,
  scales: {
    yAxes: [
      {
        stacked: true,
        display: false,
        ticks: {
          min: 0,
          max: 0
        },
        maxBarThickness: 100
      }
    ],
    xAxes: [
      {
        stacked: true,
        display: false,
        maxBarThickness: 100
      }
    ]
  },
  plugins: {
    datalabels: {
      display: function(context) {
        return context.dataset.data[context.dataIndex] !== 0 && context.dataset.label[context.dataIndex] !== 'error'; // or >= 1 or ...
      },
      align: 'center',
      anchor: 'center',
      color: "white",
      font: {
        weight: "bold"
      },
      formatter: Math.round
    }
  }
};

export default class TimelineElementsCapacity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touchTarget: null,
      touchActive: false
    };
  }

  handleHeaderMouseDown(evt) {
    //dont bubble so that we prevent our scroll component
    //from knowing about it
    evt.stopPropagation();
  }

  hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  };

  handlePeriodClick = (time, unit) => {
    if (time && unit) {
      this.props.showPeriod(moment(time - 0), unit);
    }
  };

  getGroupOrders() {
    const { groupIdKey } = this.props.keys;

    let groupOrders = {};

    for (let i = 0; i < this.props.groups.length; i++) {
      groupOrders[_get(this.props.groups[i], groupIdKey)] = i;
    }

    return groupOrders;
  }

  getVisibleItems(canvasTimeStart, canvasTimeEnd) {
    const { itemTimeStartKey, itemTimeEndKey } = this.props.keys;

    return this.props.items.filter(item => {
      return (
        _get(item, itemTimeStartKey) <= canvasTimeEnd &&
        _get(item, itemTimeEndKey) >= canvasTimeStart
      );
    });
  }

  handleScroll = () => {
    const scrollX = this.capacityComponent.scrollLeft;
    this.props.onScroll(scrollX)
  }

  refHandler = el => {
    this.capacityComponent = el
    this.props.scrollHeaderRef(el)
  }

  handleWheel = e => {
    const { traditionalZoom } = this.props

    e.preventDefault()

    // zoom in the time dimension
    if (e.ctrlKey || e.metaKey || e.altKey) {
      const parentPosition = getParentPosition(e.currentTarget)
      const xPosition = e.clientX - parentPosition.x

      const speed = e.ctrlKey ? 10 : e.metaKey ? 3 : 1

      // convert vertical zoom to horiziontal
      this.props.onWheelZoom(speed, xPosition, e.deltaY)
    } else if (e.shiftKey) {
      // shift+scroll event from a touchpad has deltaY property populated; shift+scroll event from a mouse has deltaX
      this.capacityComponent.scrollLeft += e.deltaY || e.deltaX

      // no modifier pressed? we prevented the default event, so scroll or zoom as needed
    } else {
      if (e.deltaX !== 0) {
        if (!traditionalZoom) {
          this.capacityComponent.scrollLeft += e.deltaX
        }
      }
      if (e.deltaY !== 0) {
        window.scrollTo(window.pageXOffset, window.pageYOffset + e.deltaY)
        if (traditionalZoom) {
          const parentPosition = getParentPosition(e.currentTarget)
          const xPosition = e.clientX - parentPosition.x

          this.props.onWheelZoom(10, xPosition, e.deltaY)
        }
      }
    }
  }

  handleMouseDown = e => {
    if (e.button === 0) {
      this.dragStartPosition = e.pageX
      this.dragLastPosition = e.pageX
      this.setState({
        isDragging: true
      })
    }
  }

  handleMouseMove = e => {
    // this.props.onMouseMove(e)
    //why is interacting with item important?
    if (this.state.isDragging && !this.props.isInteractingWithItem) {
      this.capacityComponent.scrollLeft += this.dragLastPosition - e.pageX
      this.dragLastPosition = e.pageX
    }
  }

  handleMouseUp = () => {
    this.dragStartPosition = null
    this.dragLastPosition = null

    this.setState({
      isDragging: false
    })
  }

  handleMouseLeave = () => {
    // this.props.onMouseLeave(e)
    this.dragStartPosition = null
    this.dragLastPosition = null
    this.setState({
      isDragging: false
    })
  }

  render() {
    const {
      canvasTimeStart,
      canvasTimeEnd,
      canvasWidth,
      timeSteps,
      headerLabelGroupHeight,
      headerLabelHeight,
      orders,
      mixingPlants,
      visibleTimeStart,
      visibleTimeEnd,
      step
    } = this.props;

    let minUnit = 'day';

    const ratio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
    const twoHeaders = minUnit !== "year";

    let mixingPlantTotal = mixingPlants.reduce((acc, mp) => acc += parseInt(mp.tonday), 0);

    chartOptions.scales.yAxes[0].ticks.max = mixingPlantTotal;

    let capacityBarChart = [];
    let data = [];

    iterateTimes(
      canvasTimeStart,
      canvasTimeEnd,
      minUnit,
      timeSteps,
      (time, nextTime) => {
        const left = Math.round((time.valueOf() - canvasTimeStart) * ratio);
        const minUnitValue = time.get(minUnit === "day" ? "date" : minUnit);
        const firstOfType = minUnitValue === (minUnit === "day" ? 1 : 0);
        const labelWidth = Math.round(
          (nextTime.valueOf() - time.valueOf()) * ratio
        );
        const leftCorrect = firstOfType ? 1 : 0;

        let totalByDay = 0;
        orders.forEach(o => {
          let orderMaterials = o.materials;

          if(moment(orderMaterials.start).isSame(time, 'day')) {
            totalByDay += parseFloat(orderMaterials.amount);
          }
        });

        let used = totalByDay;
        let free = mixingPlantTotal - totalByDay;

        let usedColor = '#004697';
        let freeColor = '#badbff';

        let usedLabel = i18n.t("translation:capacity.used");
        let freeLabel = i18n.t("translation:capacity.free");

        if(totalByDay > mixingPlantTotal) {
          used = mixingPlantTotal;
          free = totalByDay - mixingPlantTotal;

          freeColor = 'red';
          freeLabel = i18n.t("translation:capacity.overflow");

          chartOptions.scales.yAxes[0].ticks.max = used + free;
        } else {
          chartOptions.scales.yAxes[0].ticks.max = undefined;
        }

        data[time.valueOf()] = [
          {
            label: usedLabel,
            data: [used],
            backgroundColor: [usedColor], // dark
            datalabels: {
              align: "center",
              anchor: "center"
            },
            div_layout: {
              width: labelWidth,
              left: left - leftCorrect
            }
          },
          {
            label: freeLabel,
            data: [free],
            backgroundColor: [freeColor], // light
            datalabels: {
              align: "center",
              anchor: "center",
              color: 'black',
            },
            div_layout: {
              width: labelWidth,
              left: left - leftCorrect
            }
          }
        ];
      }
    );


    const diffFromNegativeToAdd = Object.keys(data).reduce((acc, d) => {
      let info = data[d];

      if(info[1].backgroundColor[0] === 'red') {
        const isVisible = moment(parseInt(d)).isBetween(moment(visibleTimeStart), moment(visibleTimeEnd), null, '[]');

        if(isVisible) acc = acc + info[1].data[0];
      }

      return acc;
    }, 0);

    for (let i = 0; i < Object.keys(data).length; i++) {
      const key = Object.keys(data)[i];

      let diffToAdd = diffFromNegativeToAdd;
      
      if(diffFromNegativeToAdd === 0) break;

      if(data[key][1].backgroundColor[0] === 'red') {
        diffToAdd = diffToAdd - data[key][1].data[0];
      }

      data[key].push({
        label: 'error',
        data: [diffToAdd],
        backgroundColor: ['transparent'], // dark
        datalabels: {
          align: "center",
          anchor: "center",
          color: 'transparent'
        }
      });
    }

    capacityBarChart = Object.keys(data).map(key => {
      let info = data[key];

      let width = info[0].div_layout.width;
      let left = info[0].div_layout.left;

      return (
          <div
            key={`label-${key}`}
            className={`rct-custom-capacity rct-label-capacity rct-label`}
            style={{
              left: `${left}px`,
              width: `${width}px`,
              lineHeight: `${headerLabelHeight}px`,
              cursor: "pointer",
              height: "200px",
              paddingTop: "20px",
              background: '#f0f0f0'
            }}
          >
            <Bar key={`bar-${key}`} id={`bar-${key}`} data={{datasets: info}} options={chartOptions} height={180} width={width}  />
          </div>
      )
    })

    let headerStyle = {
      height: "200px",
      overflowX: 'scroll',
      overflowY: 'hidden',
      cursor: this.state.isDragging ? 'move' : 'default',
    };

    return (
      <div
        key="capacity"
        data-test-id="capacity"
        className="rct-header"
        style={headerStyle}
        ref={this.refHandler}
        onScroll={this.handleScroll}
        onWheel={this.handleWheel}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="bottom-header" style={{ width: canvasWidth }}>
          {capacityBarChart}
        </div>
      </div>
    );
  }
}
