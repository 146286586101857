import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Header,
  Segment,
  Table
} from "semantic-ui-react";
import ConstructionSitesList from '../ConstructionSites/ConstructionSitesList';
import Buttons from '../ConstructionSites/Buttons';

class CustomerView extends React.Component {
  state = {}

  render() {

    const {t} = this.props;

    return(
      <div className='page'>
        <Segment raised>
          <Header as="h2">
            <Header.Content>{t("construction_sites.header")}</Header.Content>
          </Header>

          <div style={{clear: 'both'}}>
            <Buttons />
          </div>

          <Table celled compact>
            <Table.Header>
              <Table.Row style={{fontSize: '12px'}}>
                <Table.HeaderCell width={4}>{t("construction_sites.name")}</Table.HeaderCell>
                <Table.HeaderCell width={4}>{t("construction_sites.contract_number")}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <ConstructionSitesList />
          </Table>
        </Segment>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {};
}

export default withNamespaces('translation')(
  connect(mapStateToProps, {})(CustomerView)
);
