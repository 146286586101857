import {
  CUSTOMER_PERMISSION,
  MENU_STAMMDATEN_WRITE_PERMISSION,
  MENU_STAMMDATEN_READ_PERMISSION,
  MENU_SILO_WRITE_PERMISSION,
  MENU_SILO_READ_PERMISSION,
  MENU_MIXING_PLANT_WRITE_PERMISSION,
  MENU_MIXING_PLANT_READ_PERMISSION,
  MENU_MATERIAL_WRITE_PERMISSION,
  MENU_MATERIAL_READ_PERMISSION,
  MENU_CUSTOMERS_WRITE_PERMISSION,
  MENU_CUSTOMERS_READ_PERMISSION,
  MASTERDATA_WRITE_PERMISSION,
  MASTERDATA_READ_PERMISSION,
  MODIFY_SILO_PERMISSION,
  MODIFY_ORDER_PERMISSION,
  MODIFY_MATERIAL_PERMISSION,
  MODIFY_CUSTOMER_PERMISSION,
  MISCHMEISTER_PERMISSION,
  WIEGEMEISTER_PERMISSION
} from "../../constants/constants";

export default {
    "ADMIN": {
        "permissions": "*",         // ALL
    },
    "CUSTOMER": {
        "permissions": [
            CUSTOMER_PERMISSION,
        ],
    },
    "MISCHMEISTER": {
        "permissions": [
            MENU_STAMMDATEN_WRITE_PERMISSION,
            MENU_STAMMDATEN_READ_PERMISSION,
            MENU_SILO_WRITE_PERMISSION,
            MENU_SILO_READ_PERMISSION,
            MENU_MIXING_PLANT_WRITE_PERMISSION,
            MENU_MIXING_PLANT_READ_PERMISSION,
            MENU_MATERIAL_WRITE_PERMISSION,
            MENU_MATERIAL_READ_PERMISSION,
            MENU_CUSTOMERS_WRITE_PERMISSION,
            MENU_CUSTOMERS_READ_PERMISSION,
            MASTERDATA_WRITE_PERMISSION,
            MASTERDATA_READ_PERMISSION,
            MODIFY_SILO_PERMISSION,
            MODIFY_ORDER_PERMISSION,
            MODIFY_MATERIAL_PERMISSION,
            MODIFY_CUSTOMER_PERMISSION,
            MISCHMEISTER_PERMISSION,
        ],
    },
    "WIEGEMEISTER": {
        "permissions": [
            MENU_STAMMDATEN_WRITE_PERMISSION,
            MENU_STAMMDATEN_READ_PERMISSION,
            MENU_SILO_WRITE_PERMISSION,
            MENU_SILO_READ_PERMISSION,
            MENU_MIXING_PLANT_WRITE_PERMISSION,
            MENU_MIXING_PLANT_READ_PERMISSION,
            MENU_MATERIAL_WRITE_PERMISSION,
            MENU_MATERIAL_READ_PERMISSION,
            MENU_CUSTOMERS_WRITE_PERMISSION,
            MENU_CUSTOMERS_READ_PERMISSION,
            MASTERDATA_WRITE_PERMISSION,
            MASTERDATA_READ_PERMISSION,
            MODIFY_SILO_PERMISSION,
            MODIFY_ORDER_PERMISSION,
            MODIFY_MATERIAL_PERMISSION,
            MODIFY_CUSTOMER_PERMISSION,
            WIEGEMEISTER_PERMISSION,
        ],
    },
};