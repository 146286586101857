import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Modal, Icon, Button, Form, Message } from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import { save } from '../../actions/authentication';
import { addSilo } from '../../actions/silos';

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class AddSilo extends React.Component {

  state = {
    open: false,
    name: '',
    max: '',
    actual: 0,
    nameError: false,
    maxError: false
  };

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state, () => {this.validateInput()});
  };

  handleModal = open => {
    let state = {
      open: open,
      name: '',
      max: '',
      actual: 0,
      nameError: false,
      maxError: false
    };

    this.setState(state);
  };

  validateInput = () => {
    const { 
      name,
      max
    } = this.state;

    const tmpState = {
      nameError: false,
      maxError: false
    };

    if (!name)
      tmpState.nameError = true;

    if (!max || isNaN(parseInt(max)) || parseInt(max) <= 0)
      tmpState.maxError = true;

    const isValid = !Object.values(tmpState).includes(true);

    this.setState({
      ...tmpState
    });

    return isValid;
  }

  handlePositive = async (state) => {
    const isValid = this.validateInput();
    if (!isValid)
      return;

    const { authenticated, save } = this.props;
    let data = JSON.parse(JSON.stringify(state));
    delete data.open;

    data.mixing_plants_id = this.props.match.params.id;

    await this.props.addSilo(data);
    await this.handleModal(false);

    if (authenticated)
      await save();
  }

  render() {

    const { t } = this.props;
    const { open } = this.state;
    const { nameError, maxError } = this.state;
    
    return(
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
        trigger={
          <Button
            content={t("silo.new.buttontitle")}
            icon="plus"
            onClick={() => this.handleModal(true)}
          />
        }
      >
        <Modal.Header>{t("silo.new.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <Form.Input 
              label={t("silo.name")} 
              placeholder={t("silo.name")} 
              value={this.state.name}
              onChange={(e) => this.handleInputChange('name', e)}
              fluid
            />

            {nameError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
              </Message> 
            }
            
          </Form.Group>  
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              type="number"
              min="0"
              label={t("silo.max")} 
              placeholder={t("silo.max")} 
              value={this.state.max}
              onChange={(e) => this.handleInputChange('max', e)}
              fluid
            />

            {maxError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.capacity_error")}</Message.Header>
              </Message> 
            }
            
          </Form.Group>    
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              type="number"
              min="0"
              label={t("silo.actual")} 
              placeholder={t("silo.actual")} 
              value={this.state.actual}
              onChange={(e) => this.handleInputChange('actual', e)}
              fluid
            />
          </Form.Group> 
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const AddSilo_i18nWrapper = withNamespaces('translation')(AddSilo);

function mapStateToProps(state, props) {
  const authenticated = state.auth.authenticated;

  return {
    authenticated
  };
}

export default withRouter(connect(mapStateToProps, {addSilo, save})(AddSilo_i18nWrapper));