import uuid from 'uuid/v4';
import moment from 'moment';

import { addDeletedEntry } from './deleted';

export const ADD_CUSTOMER = 'customers/ADD_CUSTOMER';
export const EDIT_CUSTOMER = 'customers/EDIT_CUSTOMER';
export const REMOVE_CUSTOMER = 'customers/REMOVE_CUSTOMER';
export const LOAD_CUSTOMERS = 'customers/LOAD_CUSTOMERS';

export function addCustomer(data) {
  return dispatch => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;
    dispatch({
      type: ADD_CUSTOMER,
      data
    });

    return data.id;
  }
}

export function editCustomer(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_CUSTOMER,
      data
    });
  }
}

export function removeCustomer(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'customers'
    });

    dispatch({
      type: REMOVE_CUSTOMER,
      id
    });
  }
}