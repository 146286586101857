import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Segment, Header, Popup, Icon, Button, Dimmer, Loader } from 'semantic-ui-react';
import moment from 'moment';
import Axios from 'axios';

class Realtime extends React.Component {
    constructor(props) {
        super(props);
        const { orderId } = props.match.params;
        const { orders, constructionSites } = props;

        const order = orders.filter(o => o.id == orderId)[0];
        
        const csId = order.constructionSite;

        const constructionSite = constructionSites.filter(cs => cs.id == csId)[0];
        const csName = constructionSite.name;

        this.state = {
            orderId,
            csName,
            codes: [],
            analysis: {}
        };
    }

    componentDidMount() {
        //MUCH LOGIC HERE!
        const { orders, constructionSites, materials } = this.props;
        const { orderId } = this.state;
        const axiosConfig = { headers: {'Content-Type': 'multipart/form-data' }};
        const origin =  "https://bpo-asphalt.de";
        const requestUrl = `${origin}/common/interfaces/LiteApi.php`;
        let dataObject = {};

        const order = orders.filter(o => o.id == orderId)[0];
        const csId = order.constructionSite;
        const ordersByCS = orders.filter(o => o.constructionSite === csId);

        const constructionSite = constructionSites.filter(cs => cs.id == csId)[0];
        const csName = constructionSite.name;

        dataObject = {
            name: csName,
            id: csId
        };

        const days = {};
        for (let i = 0; i < Object.keys(ordersByCS).length; i++) {
            const csOrder = ordersByCS[i];
            const csOrderStart = moment(csOrder.materials.start).format("YYYY-MM-DD");
            const csOrderMixingplant = csOrder.mixingPlant;
            const csOrderMixingplantId = csOrder.mixingPlant;
            const csOrderMixingplantName = csOrder.mixingPlantName;

            const csOrderMaterialId = csOrder.materials.material_id;
            const csOrderMaterial = materials.filter(m => m.id == csOrderMaterialId)[0];
            const csOrderMaterialName = csOrderMaterial.name;
            const csOrderMaterialAmount = csOrder.materials.amount;

            // Cycle_data might not exist so don't try to load trucks from it without checking first
            let csOrderTruckAmount;
            if (!!csOrder.cycle_data)
                csOrderTruckAmount = csOrder.cycle_data.trucks || (false);
            else 
                csOrderTruckAmount = false;

            //Check if the date has already been added to the object
            if (!days.hasOwnProperty(csOrderStart))
                days[csOrderStart] = {};
            
            //Check if the mixingplant has already been added to the date
            if (!days[csOrderStart].hasOwnProperty(csOrderMixingplantId))
                days[csOrderStart][csOrderMixingplantId] = {};

            //Check if the material(s)-object has already been added to the mixingplant
            if (!days[csOrderStart][csOrderMixingplantId].hasOwnProperty("material"))
                days[csOrderStart][csOrderMixingplantId]["material"] = {};
                
            //Check if the materialname has already been added to the material(s)-object
            if (!days[csOrderStart][csOrderMixingplantId]["material"].hasOwnProperty(csOrderMaterialName))
                days[csOrderStart][csOrderMixingplantId]["material"][csOrderMaterialName] = 0;

            //Check if the trucks amount has already been added to the mixingplant
            if (!days[csOrderStart][csOrderMixingplantId].hasOwnProperty("trucks"))
                days[csOrderStart][csOrderMixingplantId]["trucks"] = 0;

            //Set the mixingplantname
            days[csOrderStart][csOrderMixingplantId]["name"] = csOrderMixingplantName;

            //Accumulate the amounts for the material
            days[csOrderStart][csOrderMixingplantId]["material"][csOrderMaterialName] += csOrderMaterialAmount;

            //Accumulate the amounts of trucks
            days[csOrderStart][csOrderMixingplantId]["trucks"] += csOrderTruckAmount;
        }
        dataObject["days"] = days;

        //Make the data ready to send by converting it into form-data
        const formData = new FormData();
        formData.append("action", "project")
        formData.append("key", "2b593578-1b51-4651-9d60-0c209c968b93")
        formData.append("data", JSON.stringify(dataObject));

        //Make request with the generated data
        Axios.post(
            requestUrl,
            formData,
            axiosConfig
        ).then((res) => this.handleResponse(res))
        .catch((e) => { console.log("Request failed with", e); });
    }

    handleResponse = (res) => {
        const data = res.data;
        const analysis = data.codes.analysis;
        let codes = data.codes.codes;

        //Convert the codes array into a more readable format
        codes = codes.map(c => {
            return {
                label: c[0],
                code: c[1]
            };
        });

        this.setState({
            codes,
            analysis
        });
    }

    handleReturn = _ => {
        this.props.history.push('/auftragsdispo/');
    }

    render() {
        // NO LOGIC HERE!
        const { t } = this.props;
        const { orderId, codes, analysis, csName } = this.state;
        const codeList = codes.map((c, i) => 
            <tr key={i}>
                <td>{c.label}: </td>
                <td>{c.code}</td>
            </tr>
        );

        const analysisKeys = Object.keys(analysis);
        const analysisList = analysisKeys.map((date, i) => {
            const analysisLink = analysis[date];
            const formattedDate = moment(date).format("DD/MM/YYYY");

            return (
                <tr key={i}>
                    <td>{formattedDate}: </td>
                    <td><a href={analysisLink} target="_blank">{t("realtime.analysis.link_text")}</a></td>
                </tr>
            );
        });
        const analysisAvailable = analysisList.length > 0;

        const bpoLiteUrl = "https://bpo-asphalt.de/lite";

        return (
            <div className='page'>
                <Segment raised>
                    <Header as="h2" floated="left">
                        <Header.Content>{t("realtime.header")}</Header.Content>
                    </Header>

                    <div style={{textAlign: 'right'}}>
                        <Button.Group>
                        <Button
                            type="button"
                            onClick={() => this.handleReturn()}
                        >
                            <Icon name="arrow left" />
                            {t("buttons.return")}
                        </Button>
                        </Button.Group>
                    </div>

                    {/* BPO Lite codes list */}
                    <h3 className="realtimeHeader">
                        {`${t("realtime.codes.header")} ${csName}`}
                    </h3>

                    <table className="codeList">
                        <tbody>
                            {codeList}
                        </tbody>
                    </table>

                    <br/>

                    <p>
                        <span>{t("realtime.codes.refferal_note")}</span>
                        <br/>
                        <span>{t("realtime.codes.usage_note")} <a href={bpoLiteUrl} target="_blank">{bpoLiteUrl}</a></span>
                    </p>
                </Segment>

                <Segment raised>
                    {/* BPO Lite analysis */}
                    <h3 className="realtimeHeader">
                        {`${t("realtime.analysis.header")} ${csName}`}
                    </h3>

                    {analysisAvailable?
                        <table className="codeList">
                            <tbody>
                                {analysisList}
                            </tbody>
                        </table>

                        :
                        
                        <span>
                            {t("realtime.analysis.unavailable")}
                        </span>
                    }
                </Segment>
            </div>
        );
    }
}

const Realtime_i18nWrapper = withNamespaces('translation')(Realtime);

function mapStateToProps(state, props) { 
    const t = props.t;
    const constructionSites = state.constructionSites;
    const orders = state.orders;
    const materials = state.materials;

    return {
        t,
        constructionSites,
        orders,
        materials
    }
}

export default withRouter(connect(mapStateToProps, {})(Realtime_i18nWrapper));
