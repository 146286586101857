import React, { Component } from "react";
import moment from "moment";

import { iterateTimes } from "../utility/calendar";
import { _get } from "../utility/generic";

import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { getParentPosition } from "../utility/dom-helpers";

export default class TimelineElementsCapacityLineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touchTarget: null,
      touchActive: false
    };
  }

  handleHeaderMouseDown(evt) {
    //dont bubble so that we prevent our scroll component
    //from knowing about it
    evt.stopPropagation();
  }

  hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  };

  handlePeriodClick = (time, unit) => {
    if (time && unit) {
      this.props.showPeriod(moment(time - 0), unit);
    }
  };

  getGroupOrders() {
    const { groupIdKey } = this.props.keys;

    let groupOrders = {};

    for (let i = 0; i < this.props.groups.length; i++) {
      groupOrders[_get(this.props.groups[i], groupIdKey)] = i;
    }

    return groupOrders;
  }

  getVisibleItems(canvasTimeStart, canvasTimeEnd) {
    const { itemTimeStartKey, itemTimeEndKey } = this.props.keys;

    return this.props.items.filter(item => {
      return (
        _get(item, itemTimeStartKey) <= canvasTimeEnd &&
        _get(item, itemTimeEndKey) >= canvasTimeStart
      );
    });
  }

  refHandler = el => {
    this.capacityComponent = el
    this.props.scrollHeaderRef(el)
  }

  handleWheel = e => {
    const { traditionalZoom } = this.props

    e.preventDefault()

    // zoom in the time dimension
    if (e.ctrlKey || e.metaKey || e.altKey) {
      const parentPosition = getParentPosition(e.currentTarget)
      const xPosition = e.clientX - parentPosition.x

      const speed = e.ctrlKey ? 10 : e.metaKey ? 3 : 1

      // convert vertical zoom to horiziontal
      this.props.onWheelZoom(speed, xPosition, e.deltaY)
    } else if (e.shiftKey) {
      // shift+scroll event from a touchpad has deltaY property populated; shift+scroll event from a mouse has deltaX
      let delta = e.deltaY || e.deltaX
      this.props.onScroll(delta, true)

      // no modifier pressed? we prevented the default event, so scroll or zoom as needed
    } else {
      if (e.deltaX !== 0) {
        if (!traditionalZoom) {
          let delta = e.deltaX
          this.props.onScroll(delta, true)
        }
      }
      if (e.deltaY !== 0) {
        window.scrollTo(window.pageXOffset, window.pageYOffset + e.deltaY)
        if (traditionalZoom) {
          const parentPosition = getParentPosition(e.currentTarget)
          const xPosition = e.clientX - parentPosition.x

          this.props.onWheelZoom(10, xPosition, e.deltaY)
        }
      }
    }
  }

  handleMouseDown = e => {
    if (e.button === 0) {
      this.dragStartPosition = e.pageX
      this.dragLastPosition = e.pageX
      this.setState({
        isDragging: true
      })
    }
  }

  handleMouseMove = e => {
    // this.props.onMouseMove(e)
    //why is interacting with item important?
    if (this.state.isDragging && !this.props.isInteractingWithItem) {

      let delta = this.dragLastPosition - e.pageX
      this.props.onScroll(delta, true)

      // this.capacityComponent.scrollLeft += this.dragLastPosition - e.pageX
      this.dragLastPosition = e.pageX
    }
  }

  handleMouseUp = () => {
    this.dragStartPosition = null
    this.dragLastPosition = null

    this.setState({
      isDragging: false
    })
  }

  handleMouseLeave = () => {
    // this.props.onMouseLeave(e)
    this.dragStartPosition = null
    this.dragLastPosition = null
    this.setState({
      isDragging: false
    })
  }

  render() {
    const {
      canvasTimeStart,
      canvasTimeEnd,
      canvasWidth,
      timeSteps,
      headerLabelGroupHeight,
      headerLabelHeight,
      orders,
      mixingPlants,
      visibleTimeStart,
      visibleTimeEnd
    } = this.props;

    let minUnit = 'hour';
    const ratio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
    let chartData = {};
  
    let labels = [];
    let data = [];

    iterateTimes(
      canvasTimeStart,
      canvasTimeEnd,
      minUnit,
      timeSteps,
      (time, nextTime) => {
        let modVisibleTimeStart = visibleTimeStart + (50/ratio);
        chartData = {};
        let visibileOrders = orders.filter(item => {
          return (
            item.materials.start <= visibleTimeEnd &&
            item.materials.end >= modVisibleTimeStart
          );
        });

        visibileOrders.sort((a, b) => {
          if(a.materials.start > b.materials.start) return 1;
          if(a.materials.start < b.materials.start) return -1;
          return 0;
        });

     

        visibileOrders.forEach(o => {
          let orderMaterials = o.materials;

         
          // modVisibleTimeEnd = visibleTimeEnd + (50/ratio);
          // modOrderEnd = orderMaterials.end + (50/ratio);

          let orderTime = (orderMaterials.end - orderMaterials.start) / 1000 / 60 / 60;
          let tonnageHour = Math.floor(orderMaterials.amount / orderTime);

          if (!(Math.max(modVisibleTimeStart, orderMaterials.start) in chartData))
            chartData[Math.max(modVisibleTimeStart, orderMaterials.start)] = 0;
          if (!(Math.min(visibleTimeEnd, orderMaterials.end) in chartData))
            chartData[Math.min(visibleTimeEnd, orderMaterials.end)] = 0;

           chartData[Math.max(modVisibleTimeStart, orderMaterials.start)] += tonnageHour;
           chartData[Math.min(visibleTimeEnd, orderMaterials.end)] -= tonnageHour;
        });

        let currentValue = 0;
        let timeTicks = Object.keys(chartData).sort();
        
        labels = [];
        data = [];
    
        for (let i = 0; i < timeTicks.length; i++){
            if (i == 0 && !(modVisibleTimeStart in timeTicks)) {
              labels.push(modVisibleTimeStart);
              data.push(0);
            }
            // labels.push(timeTicks[i]-1);     //steppedLine!!!!
            // data.push(currentValue);
            currentValue += chartData[timeTicks[i]];
            labels.push(timeTicks[i]);
            data.push(currentValue);
        }

        if (!(visibleTimeEnd in timeTicks)) {
          labels.push(visibleTimeEnd);
          data.push(0);
        }

      }
    );

    
    let dates = [];
    for (let i = 0; i < labels.length; i++) {
      dates.push(new Date(parseInt(labels[i])))
    }
    let dataSet = {
      labels: dates,
      datasets: [
        {
          label: "t/h",
          lineTension: 0,
          backgroundColor: "rgba(0, 51, 204, 0.6)",
          steppedLine: "before",
          cubicInterpolationMode: "monotone",
          pointRadius: 0,
          pointHitRadius: 0,
          data: data
        }
      ]
    }

    // capacityLineChart.push(
    //   <Line data={{datasets: data}} width={100} height={50} />
    // )

    let headerStyle = {
      height: "200px",
      overflowX: 'scroll',
      overflowY: 'hidden',
      cursor: this.state.isDragging ? 'move' : 'default',
      // width: canvasWidth
    };

    const chartOptions = {
      legend: {
        display: false
      },
      animation: {
        duration: 0,
      },
      tooltips: {
        mode: "nearest",
        intersect: false,
        displayColors: false,
        xPadding: 12,
        yPadding: 12,
        backgroundColor: "rgba(12, 16, 53, 0.7)",
        callbacks: {
          title: function(tooltipItems, data) {
            return moment(tooltipItems[0].xLabel, 'MMM D, YYYY h:mm:ss.SSS a').add(30, 'seconds').startOf('minute').format("ddd Do MMMM, HH:mm:ss");
          }
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        yAxes: [
          {
            afterFit: function(scaleInstance) {
              scaleInstance.width = 50; // sets the width to 100px
            },
            scaleLabel: {
              padding: 24,
            },
            beginAtZero: true,
            suggestedMax: 400,
            stacked: true,
            display: true,
            ticks: {
              display: true,
              maxTicksLimit: 8,
              beginAtZero: true,
              precision: 0,
              callback: function(value, index, values) {
                return value + " t/h \t ";
              }
            },
            gridLines: {
              display: true,
              drawTicks: false,
              borderDash: [5, 10]
            }
          }
        ],
        xAxes: [
          {
            stacked: true,
            display: false,
            type: "time",
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false,
        }
      }
    };

    return (
      <div
        key="capacity"
        data-test-id="capacity"
        className="rct-header"
        style={headerStyle}
        ref={this.refHandler}
        onWheel={this.handleWheel}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="bottom-header" style={{ width: ((canvasTimeEnd - canvasTimeStart) * ratio) / 3, height: "100%"}}>
          {/* <div style={{width: canvasWidth}}></div> */}
          <Line data={dataSet} options={chartOptions}/>
        </div>
      </div>
    );
  }
}
