import React from 'react';
import { withNamespaces } from 'react-i18next';
import {
  Header,
  Icon,
  Segment,
  Form,
  Button
} from "semantic-ui-react";
import { connect } from 'react-redux';
import moment from 'moment';
import MaterialsByDay from './MaterialsByDay';
import uuid from 'uuid/v4';
import { addOrder, editOrder, removeOrder, removeMultipleOrders } from '../../actions/orders';
import { addCustomer } from '../../actions/customers';
import { addConstructionSite } from '../../actions/constructionSites';
import { asyncForEach } from '../../helpers/async-foreach';
import UpdateGroup from './UpdateGroup';
import MixingPlantsCapacityTimeline from './MixingPlantsCapacityTimeline';
import { save } from '../../actions/authentication';

class CustomerOrder extends React.Component {

  state = {
    groups: [],
    items: [],
    // mixingPlant: undefined,
    order_materials: [],
    selected_date: undefined,
    selected_mixingPlant: undefined,
    shouldShowMaterialsByDay: false,
    constructionSite: undefined,
    showAddNewConstructionSite: false,
    customer: '',
    new_cs_local: '',
    new_cs_name: '',
    new_customer_name: '',
    new_customer_tel: '',
    new_customer_address1: '',
    new_customer_address2: '',
    visibleTimeStart: moment().startOf('day'),
    visibleTimeEnd: moment().add(7, 'days'),
    removed: []
  }

  // handleChooseMixingPlant = (event, data) => {
  //   this.setState({mixingPlant: data.value});
  // }  

  componentDidMount() {
    if(this.props.ordersFromUser.length > 0) {
      this.setState({
        constructionSite: this.props.constructionSite.id,
        customer: this.props.constructionSite.customer_id,
        order_materials: this.props.ordersFromUser.map(m => {
          let order_material = {
            order: m,
            material_id: m.materials.material_id,
            amount: m.materials.amount,
            start: moment(m.materials.start),
            end: moment(m.materials.end),
            tonPerHour: 0,
            obs: m.materials.obs,
            status: m.materials.status,
            mixingPlant: m.mixingPlant,
            group_id: `${m.materials.material_id}_${m.mixingPlant}`,
            updated: false
          }
  
          return order_material;
        })
      });
    }
  }

  handleChooseConstructionSite = (event, data) => {
    if(data.value === 'new') {
      this.setState({
        showAddNewConstructionSite: true,
        constructionSite: 'new',
        customer: ''
      });
      return;
    }

    let selectedConstructionSite = this.props.constructionSites.filter(cs => cs.id == data.value);

    this.setState({
      constructionSite: data.value,
      customer: selectedConstructionSite[0].customer_id,
      showAddNewConstructionSite: false
    });
  }  

  handleChooseCustomerForNewConstructionSite = (event, data) => {
    if(data.value === 'new') {
      this.setState({
        showAddNewCustomer: true,
        customer: 'new'
      });
      return;
    }

    this.setState({customer: data.value, showAddNewCustomer: false});
  }

  handleShowAddNewConstructionSite = (showAddNewConstructionSite, newId) => {
    this.setState({
      constructionSite: newId,
      showAddNewConstructionSite
    });
  }

  addMaterial = (material_id, mixingPlant) => {
    let { groups } = this.state;
    let material = this.props.materials.filter(m => m.id == material_id)[0];

    const id = `${material_id}_${mixingPlant}`;

    let groupAlreadyAdded = groups.filter(g => g.id == id).length > 0;
    if(groupAlreadyAdded) return;

    groups = [].concat(groups, [{
      id,
      title: material.name,
      root: false,
      parent: mixingPlant
    }]);

    this.setState({
      groups
    });
  }

  // onCanvasClick = (groupId, time, e) => {
  //   this.addOrderMaterial({
  //     id: uuid(),
  //     material_id: groupId,
  //     amount: 0,
  //     start: moment(time).hour(8).minute(0).valueOf(),
  //     end: moment(time).hour(17).minute(0).valueOf(),
  //     tonPerHour: 0,
  //     obs: '',
  //     status: 'STANDARD'
  //   }, time);
  // }

  addOrderMaterial = (orderMaterial, time) => {
    let { order_materials } = this.state;

    order_materials = order_materials.filter(om => om.amount > 0);

    order_materials = [].concat(order_materials, [orderMaterial]);

    this.setState({
      order_materials,
      selected_date: time,
      selected_mixingPlant: orderMaterial.mixingPlant,
      shouldShowMaterialsByDay: true
    });
  }

  handleUpdateMaterialByDay = orderMaterial => {
    let { order_materials } = this.state;
    const orderAmount = orderMaterial.amount;
    const orderId = orderMaterial.id;

    if (orderAmount == 0) {
      this.removeOrder(orderId);
    }

    if (!!orderAmount) {
      order_materials = order_materials.map(m => {
        if (m.id == orderMaterial.id) {
          return {
            amount: orderMaterial.amount,
            start: moment(orderMaterial.start).valueOf(),
            end: moment(orderMaterial.end).valueOf(),
            material_id: orderMaterial.material_id,
            id: orderMaterial.id,
            tonPerHour: parseFloat(orderMaterial.tonPerHour),
            obs: orderMaterial.obs,
            status: orderMaterial.status,
            mixingPlant: orderMaterial.mixingPlant,
            group_id: orderMaterial.group_id,
            order: orderMaterial.order,
            updated: true
          };
        } else {
          return m;
        }
      });
  
      this.setState({order_materials})
    }
  }

  onItemSelect = (itemId, e, time) => {
    let {order_materials} = this.state;

    let orderMaterial = order_materials.filter(om => om.id == itemId)[0];

    if(!orderMaterial) return;

    order_materials = order_materials.filter(om => om.amount > 0);

    this.setState({
      selected_date: time,
      selected_mixingPlant: orderMaterial.mixingPlant,
      shouldShowMaterialsByDay: true,
      order_materials
    });
  }

  onItemResize = (itemId, time, edge) => {
    const { order_materials } = JSON.parse(JSON.stringify(this.state));

    order_materials.map((om) => {
      if (om.id == itemId) {
        om.end = time;
      } 

      return om;
    });

    this.setState({order_materials});
  }

  onItemMove = (itemId, dragTime, newGroupOrder) => {
    const { order_materials } = JSON.parse(JSON.stringify(this.state));

    let orderMaterial = order_materials.filter(om => om.id == itemId)[0];

    if(!orderMaterial) return;

    order_materials.map((om) => {
      if (om.id == itemId) {
        const diff = moment(om.start).startOf('day').diff(moment(dragTime).startOf('day'), 'days');
        om.start = moment(om.start).add(-1 * diff, 'days').valueOf();
        om.end = moment(om.end).add(-1 * diff, 'days').valueOf();
        om.updated = true;
      } 

      return om;
    });

    this.setState({
      order_materials,
      selected_date: dragTime,
      selected_mixingPlant: orderMaterial.mixingPlant,
    });
  }

  removeOrder = (itemId, e, time) => {
    let { order_materials, removed } = JSON.parse(JSON.stringify(this.state));

    let removedItem = order_materials.filter(om => om.id == itemId)[0];

    if(!removedItem) return;

    order_materials = order_materials.filter(om => om.id !== itemId);

    if(Object.keys(removedItem.order).length > 0) removed.push(removedItem.order.id);

    this.setState({
      order_materials,
      removed
    });
  }

  closeMaterialsByDay = _ => {
    this.setState({
      shouldShowMaterialsByDay: false
    });
  }

  handlePositive = async (state) => {
    let { order_materials, removed } = state;
    const {orders, constructionSite, user} = this.props;

    if(order_materials.length == 0) return;

    const ordersToAdd = state.order_materials
      .filter(om => Object.keys(om.order).length == 0 && om.amount > 0);

    const ordersToEdit = state.order_materials
      .filter(om => Object.keys(om.order).length > 0 && om.amount > 0);
    
    if(ordersToAdd.length == 0 && ordersToEdit.length == 0) return;
    
    const orderGroup = constructionSite.id;
    await asyncForEach(ordersToAdd, async orderMaterial => {
      let materials = orderMaterial;

      materials.end = moment(materials.end).valueOf();
      materials.start = moment(materials.start).valueOf();

      materials.status = 'CUSTOMER';
      
      delete materials['group_id'];
      delete materials['updated'];

      let cycle_data = {  // HARDCODED DEFAULT CYCLE_DATA VALUES FOR NOW /TODO/FIXME
        load: 10,
        wayto: "",
        paver: 10,
        installation: 10,
        leave: 10,
        wayback: "",
      };

      let entry = {
        constructionSite: constructionSite.id,
        mixingPlant: orderMaterial.mixingPlant,
        materials,
        orderGroup,
        created_by: user.id,
        waypoints: null,
        cycle_data
      }

      await this.props.addOrder(entry);
    });

    await asyncForEach(ordersToEdit, async orderMaterial => {
      let materials = orderMaterial;

      materials.end = moment(materials.end).valueOf();
      materials.start = moment(materials.start).valueOf();

      if(materials.updated) {
        materials.status = 'CUSTOMER';
      }

      delete materials['group_id'];
      delete materials['updated'];

      let cycle_data = {  // HARDCODED DEFAULT CYCLE_DATA VALUES FOR NOW /TODO/FIXME
        load: 10,
        wayto: "",
        paver: 10,
        installation: 10,
        leave: 10,
        wayback: "",
      };

      let entry = {
        id: orderMaterial.order.id,
        constructionSite: constructionSite.id,
        mixingPlant: orderMaterial.mixingPlant,
        materials,
        orderGroup: orderMaterial.order.orderGroup,
        created_at: orderMaterial.order.created_at,
        updated_at: orderMaterial.updated_at,
        waypoints: null,
        cycle_data
      }

      await this.props.editOrder(entry);
    });

    if(removed.length > 0) {
      const ordersToRemove = orders.filter(o => removed.indexOf(o.id) !== -1);
      await this.props.removeMultipleOrders(ordersToRemove);
    }

    await this.props.save();
    await this.props.history.push('/auftragsdispo/');
  }

  handleNegative = _ => {
    this.props.history.push('/auftragsdispo/');
  }

  addMaterialForDay = (material_id, mixingPlant) => {
    const { selected_date, groups } = this.state;

    let hasGroup = groups.filter(g => g.id == material_id).length > 0;

    if(!hasGroup) {
      this.addMaterial(material_id, mixingPlant);
    }

    const group_id = `${material_id}_${mixingPlant}`;

    this.addOrderMaterial({
      id: uuid(),
      material_id: material_id,
      group_id: group_id,
      amount: 0,
      start: moment(selected_date).hour(8).minute(0).valueOf(),
      end: moment(selected_date).hour(17).minute(0).valueOf(),
      tonPerHour: 0,
      obs: '',
      status: 'STANDARD',
      mixingPlant: mixingPlant,
      order: {},
      updated: false,
      edited: true,
    }, selected_date);
  }

  handleInputChange = (field, e) => {
    let state = JSON.parse(JSON.stringify(this.state));
    state[field] = e.target.value;

    this.setState(state);
  };

  groupRenderer = ({ group }) => { 
    return (
      <div 
        className="rct-sidebar-row rct-sidebar-row-even" 
        style={{
          height: '38px', 
          lineHeight: '38px',
          display: 'flex'
        }}
        key={group.id}
      >
        <UpdateGroup  
          updateMaterial={this.updateMaterial} 
          group={group} 
          removeMaterial={this.removeMaterial} 
          closeMaterialsByDay={this.closeMaterialsByDay}
        />
      </div>
    );
  }

  // updateMaterial = (newGroup, oldGroup) => {
  //   let {groups, order_materials} = JSON.parse(JSON.stringify(this.state));
  //   let material = this.props.materials.filter(m => m.id == newGroup)[0];

  //   groups = groups.map(g => {
  //     if(g.id == oldGroup) {
  //       g.id = material.id;
  //       g.title = material.name

  //       return g;
  //     }

  //     return g;
  //   });

  //   order_materials = order_materials.map(om => {
  //     if(om.material_id == oldGroup) {
  //       om.material_id = newGroup;
  //       return om;
  //     }

  //     return om;
  //   })

  //   this.setState({
  //     groups,
  //     order_materials
  //   });
  // }

  // removeMaterial = groupId =>{
  //   let {groups, items} = this.state;

  //   groups = groups.filter(g => g.id !== groupId);
  //   items = items.filter(i => i.group !== groupId);

  //   this.setState({
  //     groups,
  //     items,
  //     shouldShowMaterialsByDay: false
  //   });
  // }

  updateTime = (visibleTimeStart, visibleTimeEnd) => {
    this.setState({ visibleTimeStart, visibleTimeEnd });
  };

  handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.setState({ visibleTimeStart, visibleTimeEnd });
  };

  updateOrderMaterials = order_materials => {
    this.setState({
      order_materials
    });
  }

  duplicate = (mat, range) => {
    const { order_materials, selected_date } = this.state;
    const tmpOrderMaterials = [...order_materials];

    for (let day of range.by("day")) {
      const dayStartValue = day.startOf("day").valueOf();
      const selectedDateStartValue = moment(selected_date).startOf("day").valueOf();
      if (dayStartValue == selectedDateStartValue)
        continue;

      const UUID = uuid();
      const matStartDay = moment(mat.start).startOf("day");
      const matStartTime = -matStartDay.diff(mat.start);

      const matEndDay = moment(mat.end).startOf("day");
      const matEndTime = -matEndDay.diff(mat.end);
      
      const startTime = moment(day).add(matStartTime, "milliseconds").valueOf();
      const endTime = moment(day).add(matEndTime, "milliseconds").valueOf();
      
      const newMat = {
        amount: mat.amount,
        group_id: mat.group_id,
        id: UUID,
        material_id: mat.material_id,
        mixingPlant: mat.mixingPlant,
        obs: mat.obs,
        order: {},
        status: mat.status,
        tonPerHour: mat.tonPerHour,
        start: startTime,
        end: endTime
      };
      
      tmpOrderMaterials.push(newMat);
    }

    this.setState({
      order_materials: tmpOrderMaterials
    });
  } 

  render() {

    const { t, constructionSites, customers, constructionSite } = this.props;
    const { 
      order_materials, 
      selected_date, 
      selected_mixingPlant
    } = this.state;

    const constructionSitesDropdown = constructionSites.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    }));

    constructionSitesDropdown.push({
      key: 'new',
      value: 'new',
      text: t("construction_sites.new.header")
    });

    const customersDropdown = customers.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    }));

    customersDropdown.push({
      key: 'new',
      value: 'new',
      text: t("customer.newcustomer.header")
    });

    return(
      <div className='page'>
        <Segment raised>
          <Header as="h2" style={{marginBottom: '25px'}} floated="left">
            <Header.Content>{t("customer.neworder.header")} - {constructionSite.name}</Header.Content>
          </Header>

          <div style={{textAlign: 'right'}}>
            <Button.Group>
              <Button
                type="button"
                onClick={() => this.handleNegative()}
                color="grey"
              >
                <Icon name="close" />
                {t("buttons.cancel")}
              </Button>
              <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                <Icon name="checkmark" />
                {t("buttons.ok")}
              </Button>
            </Button.Group>
          </div>

          <Form.Group style={{marginBottom: '10px', 'clear': 'both'}}>
            <MixingPlantsCapacityTimeline 
              visibleTimeStart={this.state.visibleTimeStart}
              visibleTimeEnd={this.state.visibleTimeEnd}
              updateTime={this.updateTime}
              addOrderMaterial={this.addOrderMaterial}
              order_materials={order_materials}
              removeOrder={this.removeOrder}
              onItemSelect={this.onItemSelect}
              onItemResize={this.onItemResize}
              onItemMove={this.onItemMove}
              closeMaterialsByDay={this.closeMaterialsByDay}
              updateOrderMaterials={this.updateOrderMaterials}
              materialsGroups={this.props.groups}
            />
          </Form.Group>
        </Segment>
        
        {(order_materials.length > 0 && this.state.shouldShowMaterialsByDay) &&
          <Segment raised>
            <div style={{textAlign: 'right', marginTop: '15px', cursor: 'pointer'}}>
              <Icon name='close' onClick={this.closeMaterialsByDay} />
            </div>
            <MaterialsByDay 
              order_materials={order_materials} 
              selected_date={selected_date} 
              selected_mixingPlant={selected_mixingPlant}
              handleUpdateMaterialByDay={this.handleUpdateMaterialByDay}
              addMaterialForDay={this.addMaterialForDay}
              duplicate={this.duplicate}
            />
          </Segment>
        }
      </div>
    );
  }
}

const CustomerOrder_i18nWrapper = withNamespaces('translation')(CustomerOrder);

function mapStateToProps(state, props) {
  const mixingPlants = state.mixingPlants.filter((mp) => {
    return !mp.readonly || mp.readonly === false;
  });
  const materials = state.materials;
  const constructionSites = state.constructionSites;
  const customers = state.customers;
  const orders = state.orders;

  const user = state.user;

  const id = 'id' in props.match.params ? props.match.params.id : undefined;
  
  let order = null;
  let groups = [];
  let ordersGroup = [];
  let constructionSite = null;
  let ordersFromUser = [];
  if(id) {
    constructionSite = constructionSites.filter(cs => cs.id == id)[0];
    ordersFromUser = orders.filter(m => m.created_by == user.id && m.constructionSite == constructionSite.id);

    ordersFromUser.forEach(om => {
      let material = materials.filter(m => m.id == om.materials.material_id);
  
      if(material.length > 0) {
        groups.push({
          id: `${om.materials.material_id}_${om.mixingPlant}`,
          title: material[0].name,
          root: false,
          parent: om.mixingPlant
        });
      }
    });
  
    groups = groups.filter(
      (group, index, self) =>
        index ==
        self.findIndex(
          i =>
            i.id == group.id
        )
    );
    
  }
  
  return {
    mixingPlants,
    materials,
    constructionSites,
    customers,
    history: props.history,
    order,
    orders,
    groups,
    ordersGroup,
    constructionSite,
    user,
    ordersFromUser
  };
}

export default connect(mapStateToProps, {
  addOrder, 
  addCustomer, 
  addConstructionSite, 
  editOrder, 
  removeOrder,
  removeMultipleOrders,
  save
})(CustomerOrder_i18nWrapper);