import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Modal, Button, Icon, Form, Dropdown, TextArea, Input, Message} from 'semantic-ui-react';
import moment from 'moment';
import TimePicker from 'rc-time-picker'
import {editOrder, orderStatus, DEFAULT_MIXING_PLANT_ID} from '../../actions/orders';
import RemoveOrderMessage from './RemoveOrderMessage';
import {save} from '../../actions/authentication';
import alphanumerical from "../../helpers/utils"
import { WIEGEMEISTER_PERMISSION, MISCHMEISTER_PERMISSION, ADMIN_PERMISSION } from '../../constants/constants';


const inlineStyle = {
    modal: {
        marginTop: "25px !important",
        marginLeft: "auto",
        marginRight: "auto"
    },
    field: {
        marginTop: '10px'
    }
};

class OrderModal extends React.Component {

    state = {
        open: true,
        isValid: true,
        formError: false,
        amountError: false,
        amountOverflow: false,
        timeError: false,
        tphError: false,
    };

    componentWillMount() {
        this.setState({
            ...this.props.order,
            tonPerHour: 0
        }, () => this.updateTonsPerHour(this.state));
    }

    handleModal = open => {
        this.props.openModalAction(open);
    }

    validateInput = (field, ...data) => {
        const mixingplant = this.props.mixingplant;
        const siblingOrders = this.props.siblingOrders;

        if (!mixingplant || !siblingOrders) return;

        let tmpState = {};


        switch (field) {
            case "amount":
                const [amount] = data;
                let total = 0;

                if (isNaN(amount))
                    amount = '';

                if (amount === '') {
                    tmpState.amountError = true;
                } else {
                    tmpState.amountError = false;
                    total = amount;
                }

                for (let i = 0; i < siblingOrders.length; i++) {
                    let o = siblingOrders[i];
                    let oAmount = o.materials.amount

                    total += oAmount;
                }

                if (total > mixingplant.tonday)
                    tmpState.amountOverflow = true;
                else
                    tmpState.amountOverflow = false;

                break;

            case "tph":
                const [tph] = data;
                if (tph > this.props.mixingplant.maxmix)
                    tmpState["timeError"] = true;
                else
                    tmpState["timeError"] = false;
            case "time":
                const [tpht] = data;

                if (tpht > this.props.mixingplant.maxmix) {
                    tmpState[field + "Error"] = true;
                    tmpState["tphError"] = true;
                } else {
                    tmpState[field + "Error"] = false;
                    tmpState["tphError"] = false;
                }
                break;
        }

        if (Object.values(tmpState).includes(true))
            tmpState.formError = true;
        else
            tmpState.formError = false;


        this.setState({
            ...tmpState
        });
    }

    handleInputChange = (field, e) => {
        let state = JSON.parse(JSON.stringify(this.state));
        state.materials[field] = e.target.value;
        state.materials['start'] = moment(state.materials.start).valueOf();
        state.materials['end'] = moment(state.materials.end).valueOf();
        let amount;

        if (field === 'amount') {
            amount = Math.abs(parseFloat(e.target.value));

            if (isNaN(amount))
                amount = '';

            state.materials[field] = amount;
            this.validateInput(field, amount);
        }

        this.setState({materials: state.materials});

        if (field !== 'obs' && amount !== '' && !this.state.amountError) {
            this.updateTonsPerHour(state);
        }
    };

    handleUpdateTonsPerHour = e => {
        let state = JSON.parse(JSON.stringify(this.state));

        let {amount, start} = state.materials;

        if (amount === "") amount = 0;

        let tonPerHour = Math.abs(parseFloat(e.target.value));
        if (isNaN(tonPerHour)) tonPerHour = '';

        if (tonPerHour === "" || parseInt(tonPerHour) === 1) {
            this.setState({tonPerHour});
            return;
        }

        let diff = amount / tonPerHour;

        if (diff > 24) {
            this.setState({tonPerHour});
            return;
        }

        state.materials["end"] = moment(start).add(diff, 'hours').valueOf();
        state.materials["start"] = moment(start).valueOf();
        state["tonPerHour"] = tonPerHour;

        this.setState(state);
        this.validateInput("tph", tonPerHour);
    }

    onChangeStart = value => {
        let state = JSON.parse(JSON.stringify(this.state));

        state.materials['start'] = moment(value).valueOf();
        state.materials['end'] = moment(state.materials.end).valueOf();

        this.setState(state);
        this.updateTonsPerHour(state, "time");
    }

    onChangeEnd = value => {
        let state = JSON.parse(JSON.stringify(this.state));

        state.materials['end'] = moment(value).valueOf();
        state.materials['start'] = moment(state.materials.start).valueOf();

        this.setState(state);
        this.updateTonsPerHour(state, "time");
    }

    updateTonsPerHour = (state, field = "") => {
        let {materials} = state;

        let amount = materials.amount;
        if (amount === "") amount = 0;

        const diff = moment(materials.end).diff(materials.start, 'hours', true);
        let tonPerHour = Math.round(parseFloat(parseFloat(amount) / diff));

        if (field === "time")
            this.validateInput("time", tonPerHour);
        else
            this.validateInput("tph", tonPerHour);


        if (parseFloat(parseInt(amount, 10) / diff) < 0) tonPerHour = 0;

        this.setState({tonPerHour});
    }

    handleChooseMaterial = (event, data) => {
        let state = JSON.parse(JSON.stringify(this.state));

        state.materials.material_id = data.value;

        this.setState(state);
    }

    handleChooseStatus = (event, data) => {
        let state = JSON.parse(JSON.stringify(this.state));

        state.materials.status = data.value;

        this.setState(state);
    }

    handlePositive = async (state) => {
        const {authenticated, save} = this.props;
        if (this.state.formError) return false;
        let entry = {
            ...state
        }

        delete entry.open;
        delete entry.tonPerHour;

        await this.props.editOrder(entry);
        await this.handleModal(false);

        if (authenticated)
            await save();
    }

    render() {
        const {t, materialsDropdown, constructionSite, order} = this.props;
        const {open, materials, tonPerHour} = this.state;

        const notAssigned = DEFAULT_MIXING_PLANT_ID;
        const status = "PREPLANNING";
        const isPrePlanning = (order.materials.status).localeCompare(status) === 0;

        let canEditStatus = true;

        if (isPrePlanning && order.mixingPlant === notAssigned) {
            canEditStatus = false;
        }

        let orderStatusDropdown = orderStatus();

        return (
            <Modal
                style={inlineStyle.modal}
                open={open}
                closeIcon={false}
                closeOnDimmerClick={false}
                onClose={() => this.handleModal(false)}
            >
                <Modal.Header>{t("order.edit.header")}</Modal.Header>
                <Modal.Content>
                    <Form.Group>
                        <label>{t("construction_sites.singular")}</label>
                        <div className="field">
                            <div className="field">
                                <div className="ui fluid input">
                                    {constructionSite.name}
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group style={inlineStyle.field}>
                        <label>{t("material.header")}</label>
                        <Dropdown
                            placeholder={t('material.newmaterial.buttontitle')}
                            fluid
                            search
                            selection
                            options={materialsDropdown.sort(alphanumerical)}
                            onChange={this.handleChooseMaterial}
                            value={materials.material_id}
                            disabled={!this.props.canUpdate}
                        />
                    </Form.Group>

                    <Form.Group style={inlineStyle.field}>
                        <label>{t("order.neworder.materials.amount")}</label>
                        <Input
                            min="0"
                            error={this.state.amountError || this.state.amountOverflow}
                            placeholder={t("order.neworder.materials.amount")}
                            type="number"
                            fluid
                            value={materials.amount}
                            onInput={(e) => this.handleInputChange('amount', e)}
                            disabled={!this.props.canUpdate}
                        />
                    </Form.Group>

                    {this.state.amountOverflow &&
                    <Message negative>
                        <Message.Header>{t("order.neworder.capacity_error.header")}</Message.Header>
                        <p>{t("order.neworder.capacity_error.message")}</p>
                    </Message>
                    }

                    <Form.Group style={inlineStyle.field}>
                        <label>{t("order.neworder.materials.start")}</label>
                        <TimePicker
                            focusOnOpen={true}
                            className={this.state.timeError ? "customError" : null}
                            style={{width: '100%'}}
                            showSecond={false}
                            value={moment(materials.start)}
                            onChange={this.onChangeStart}
                            disabled={!this.props.canUpdate}
                        />
                    </Form.Group>

                    <Form.Group style={inlineStyle.field}>
                        <label>{t("order.neworder.materials.end")}</label>
                        <TimePicker
                            focusOnOpen={true}
                            className={this.state.timeError ? "customError" : null}
                            style={{width: '100%'}}
                            showSecond={false}
                            value={moment(materials.end)}
                            onChange={this.onChangeEnd}
                            disabled={!this.props.canUpdate}
                        />
                    </Form.Group>

                    {this.state.timeError &&
                    <Message negative>
                        <Message.Header>{t("general.errormessages.time_error")}</Message.Header>
                        <p></p>
                    </Message>
                    }

                    <Form.Group style={inlineStyle.field}>
                        <label>{t("order.neworder.materials.tonsperhour")}</label>
                        <Input
                            min="0"
                            error={this.state.tphError}
                            placeholder={t("order.neworder.materials.tonsperhour")}
                            type="number"
                            fluid
                            value={tonPerHour}
                            onChange={this.handleUpdateTonsPerHour}
                            disabled={!this.props.canUpdate}
                        />
                    </Form.Group>

                    {this.state.tphError &&
                    <Message negative>
                        <Message.Header>{t("general.errormessages.tph_error")}</Message.Header>
                        <p></p>
                    </Message>
                    }

                    {canEditStatus &&
                    <Form style={inlineStyle.field}>
                        <label>{t("order.status.placeholder")}</label>
                        <Dropdown
                            placeholder={t('order.status.placeholder')}
                            fluid
                            search
                            selection
                            options={orderStatusDropdown.sort(alphanumerical)}
                            onChange={this.handleChooseStatus}
                            value={materials.status}
                        />
                    </Form>
                    }

                    <Form style={inlineStyle.field}>
                        <label>{t("order.neworder.materials.obs")}</label>
                        <TextArea
                            placeholder={t("order.neworder.materials.obs")}
                            value={materials.obs}
                            onChange={(e) => this.handleInputChange('obs', e)}
                            disabled={!this.props.canUpdate}
                        />
                    </Form>

                    {this.state.formError && !this.state.amountOverflow && !this.state.timeError && !this.state.tphError &&
                    <Message negative>
                        <Message.Header>{t("general.errormessages.form_error")}</Message.Header>
                        <p></p>
                    </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button.Group>
                        <RemoveOrderMessage id={this.props.item} closeModal={this.handleModal}
                                            removeOrder={this.props.removeOrder}/>
                        <Button
                            type="button"
                            onClick={() => this.handleModal(false)}
                            color="grey"
                        >
                            <Icon name="close"/>
                            {t("buttons.cancel")}
                        </Button>
                        <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green"
                                disabled={this.state.formError}>
                            <Icon name="checkmark"/>
                            {t("buttons.ok")}
                        </Button>
                    </Button.Group>
                </Modal.Actions>
            </Modal>
        );
    }
}

const OrderModal_i18nWrapper = withNamespaces('translation')(OrderModal);

function mapStateToProps(state, props) {
    const authenticated = state.auth.authenticated;
    const orders = state.orders;
    const materials = state.materials;
    const customers = state.customers;
    const constructionSites = state.constructionSites;
    const mixingplants = state.mixingPlants;

    let order = orders.filter(o => o.id == props.item)[0];
    let constructionSite = constructionSites.filter(cs => cs.id == order.constructionSite)[0];
    let customer = customers.filter(c => c.id == constructionSite.customer_id)[0];
    let mixingplant = mixingplants.filter(mp => mp.id == order.mixingPlant)[0];
    let siblingOrders = null;

    if (mixingplant) {
        siblingOrders = orders.filter(
            o => o.mixingPlant === mixingplant.id
                && o.materials.start >= order.materials.start
                && o.materials.end <= order.materials.end
                && o.id !== order.id
        );
    }

    const materialsDropdown = materials.map(m => ({
        key: m.id,
        value: m.id,
        text: m.name
    }));

    const admin_view_prod_takt = state.app.admin_view_prod_takt;
    const user = state.user;
    let isMixtureMaster = false;
    let isAdmin = false;
    let isScaleMaster = false;

    !!user.permissions && user.permissions.forEach(permission => {
        if (permission.id === WIEGEMEISTER_PERMISSION.id) {
            isScaleMaster = true;
        } else if (permission.id === MISCHMEISTER_PERMISSION.id) {
            isMixtureMaster = true;
        } else if (permission.id === ADMIN_PERMISSION.id) {
            isAdmin = true;
            if (admin_view_prod_takt) {
                isMixtureMaster = true;
            }
        }
      });


    return {
        order,
        materialsDropdown,
        customer,
        constructionSite,
        canUpdate: isAdmin || isScaleMaster,
        isMixtureMaster,
        mixingplant,
        siblingOrders,
        authenticated
    }
}

export default connect(mapStateToProps, {editOrder, save})(OrderModal_i18nWrapper);