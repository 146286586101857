import moment from 'moment';
import { store } from '../store';
import { setSiloCapacityData } from '../actions/app';

export function getOrders(orders, productionTakt, silos, starttime, endtime) {
    let vOrders = orders.filter(o => {
        return o.materials.start <= endtime && o.materials.end >= starttime;
    });

    let data = [];

    for (let i = 0; i < vOrders.length; i++) {
        let orderId = vOrders[i].id;
        let orderStart = vOrders[i].materials.start;
        let orderEnd = vOrders[i].materials.end;
        let orderAmount = vOrders[i].materials.amount;
        let orderTonnage = orderAmount;

        let productions = [];
        let p = getProductionsFromOrder(productionTakt, orderId);

        for (let j = 0; j < p.length; j++) {
            let offset = p[j].offset;
            let duration = p[j].duration;
            let amount = p[j].amount;
            let silo = p[j].silo;
            
            let start = moment(orderStart).add(offset, "minutes").valueOf();
            let end = moment(start).add(duration, "minutes").valueOf();
            let tonnage = orderAmount * (amount / 100);

            productions.push(
                {
                    start: start,
                    end: end,
                    tonnage: tonnage,
                    silo: silo
                }
            )
        }

        data.push(
            {
                start: orderStart,
                end: orderEnd,
                tonnage: orderTonnage,
                productions: productions
            }
        )
    }
    return data;
}

function getProductionsFromOrder(productions, orderId) {
    let r = []; 
    for (let i = 0; i < productions.length; i++) {
        if (productions[i].orders_id == orderId)
            r.push(productions[i]);
    }
    return r;
}

export function getSiloCapacityData(data) {
    let s = {};
    let l = {};

    for (let i = 0; i < data.length; i++) {
        let order = data[i];
        order.productions.sort(function(a, b) {
        return a.start < b.start ? -1 : a.start > b.start ? 1 : 0;
        });
        let prsum = 0;
        for (let j = 0; j < order.productions.length; j++) {
        let production = order.productions[j];
        if (!(production.silo in s)) {
            s[production.silo] = {};
            l[production.silo] = [];
        }
        s[production.silo][production.start] = true;
        s[production.silo][production.end] = true;

        l[production.silo].push({
            a: production.start,
            b: production.end,
            c: production.tonnage / (production.end - production.start)
        });

        let starttime =
            order.start + (order.end - order.start) / order.tonnage * prsum;
        prsum += production.tonnage;
        let endtime =
            order.start + (order.end - order.start) / order.tonnage * prsum;

        s[production.silo][starttime] = true;
        s[production.silo][endtime] = true;

        l[production.silo].push({
            a: starttime,
            b: endtime,
            c: -1 * order.tonnage / (order.end - order.start)
        });
        }
    }

    let chartdata = {};

    for (let silo in s) {
        chartdata[silo] = [];
        let times = Object.keys(s[silo]).map(function(x) {
        return parseInt(x);
        });
        times.sort(function(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
        });
        let lastval = 0;
        for (let i = 0; i < times.length; i++) {
        if (i === 0) {
            chartdata[silo].push([times[i], 0]);
            continue;
        }
        let factor = times[i] - times[i - 1];
        let newval = l[silo]
            .filter(function(x) {
            return times[i] > x.a && times[i] <= x.b;
            })
            .reduce(function(acc, x) {
            return x.c * factor + acc;
            }, lastval);
        chartdata[silo].push([times[i], newval]);

        lastval = newval;
        }
    }

    return chartdata;
}

export function getCapacityAtTime(data, time, silo) {   
  try {
    for (let i = 0; i < data[silo].length; i++) {
      if (data[silo][i][0] < time) continue;
      if (data[silo][i][0] === time) return data[silo][i][1];

      let value = data[silo][i - 1][1] +
        (data[silo][i][1] - data[silo][i - 1][1]) /
          (data[silo][i][0] - data[silo][i - 1][0]) *
          (time - data[silo][i - 1][0]);
      
      if(!value) value = 0;

      return value;
    }
  } catch (e) {
    return 0;
  }
}

export function capSiloCapacityData(data, silos){
    console.log(data,'capSiloCapacityData');
    let out = {};
    let errors = [];
    
    for (let silo in data){
        let siloData = data[silo];

        let outPre = [];
        let errorStart = false;
        for (let i = 0; i < siloData.length; i++){
            let x = moment(siloData[i][0]).valueOf();
            let y = Math.round(siloData[i][1]*10)/10; //Round to 1 decimal

            if (siloData[i-1] !== undefined){
                let xalt = moment(siloData[i-1][0]).valueOf();
                let yalt = siloData[i-1][1];

                if (yalt <= 0 && y < 0)
                    continue;
                if (yalt > 0 && y < 0){
                    y = 0;
                    x = xalt + ((x-xalt)/(y-yalt))*(0 -yalt);
                    errorStart = x;                    
                } else if (yalt < 0 && y > 0){
                    let yneu= 0;
                    let xneu = xalt + ((x-xalt)/(y-yalt))*(0 -yalt);
                    outPre.push([xneu,yneu]);
                    if (errorStart){
                        console.log(errorStart,"underflowFound");
                        errors.push({start: errorStart, debug: xalt+","+x+","+yalt+","+y+","+i, end: xneu, type: "underflow", silo});
                    }
                    errorStart = false;
                }
                
            }
            outPre.push([x,y]);
        }

        errorStart = false;
         
        let max = false;

        for (let i = 0; i < silos.length; i++){
            if (silos[i].id == silo){
                max = silos[i].max;
                break;
            }
        }

        if (!max) out[silo] = outPre;

        out[silo] = [];

        for (let i = 0; i < outPre.length; i++){
            let x = outPre[i][0];
            let y = outPre[i][1]; //Round to 1 decimal

            if (outPre[i-1] !== undefined){
                let xalt = outPre[i-1][0];
                let yalt = outPre[i-1][1];

                if (yalt >= max && y > max)
                    continue;
                if (yalt < max && y > max){
                    y = max;
                    x = xalt + ((x-xalt)/(y-yalt))*(max -yalt);
                    errorStart = x;
                    
                } else if (yalt > max && y < max){
                    let yneu= max;
                    let xneu = xalt + ((x-xalt)/(y-yalt))*(max -yalt);
                    out[silo].push([xneu,yneu]);
                    if (errorStart){
                    errors.push({start: errorStart, end: xneu, type: "overflow", silo});
                    }
                    errorStart = false;
                }
                
            }
            out[silo].push([x,y]);
        }
    }
    return {chartdata: out, errors};
}

export async function loadSiloCapacityData(start, end) {
  let state = store.getState();
  let orders = state.orders;
  let productionTakt = state.productionTakt;
  let silos = state.silos;

  end = moment(end).add(1, 'd').valueOf();

  let data = getOrders(orders, productionTakt, silos, start, end);
  let rawChartDataUncapped = getSiloCapacityData(data);
  let rawChartData = capSiloCapacityData(rawChartDataUncapped, silos);

  if(Object.keys(rawChartData).length === 0) return;
  
  setSiloCapacityData(rawChartData);

  return rawChartData
}

export function siloCapacityData(start, end, takts = []) {
  let state = store.getState();
  let orders = state.orders;
  let productionTakt = takts.length > 0 ? takts : state.productionTakt;
  let silos = state.silos;

  end = moment(end).add(1, 'd').valueOf();

  let data = getOrders(orders, productionTakt, silos, start, end);
  let rawChartDataUncapped = getSiloCapacityData(data);
  let rawChartData = capSiloCapacityData(rawChartDataUncapped, silos);

  if(Object.keys(rawChartData).length === 0) return {};
  
  return rawChartData
}