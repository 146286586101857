import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Icon, Button, Form, Segment, Header, Message } from "semantic-ui-react";
import { editMixingPlant } from '../../actions/mixingPlants';
import { editMultipleOrders } from '../../actions/orders';
import { save } from '../../actions/authentication';
import MapLocal from '../MapLocal/MapLocal';
import Validate from "react-validate-form";
import { validationRules } from "../../validationRules";

const inlineStyle = {
  field: {
    marginTop: '10px'
  }
};

class MixingPlant extends React.Component {

  state = {
    id: '',
    name: '',
    tel: '',
    fax: '',
    email: '',
    tonday: 0,
    standard: 0,
    maxmix: 0,
    position: '',
  };

  componentDidMount() {
    this.setState({
      id: this.props.mixingPlant.id,
      name: this.props.mixingPlant.name,
      tel: this.props.mixingPlant.tel,
      fax: this.props.mixingPlant.fax,
      email: this.props.mixingPlant.email,
      tonday: this.props.mixingPlant.tonday,
      standard: this.props.mixingPlant.standard,
      maxmix: this.props.mixingPlant.maxmix,
      position: this.props.mixingPlant.position ? this.props.mixingPlant.position : "",
    });
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;
    state['edited'] = true;

    this.setState(state);
  };

  handlePositive = async (state) => {
    let data = JSON.parse(JSON.stringify(state));
    const { orders, authenticated, save } = this.props;
    const { id:mpId, position:mpPos } = this.state;
        
    // Get all orders linked to the selected mixingplant site
    const csOrders = orders.filter(o => {
      return o.mixingPlant === mpId;
    });

    try {
      // Change the mixingplant position for each order
      const editedOrders = csOrders.map(o => {
        const currentWaypoints = o.waypoints;
        let newTo, newBack;
  
        if (!!currentWaypoints) {
          // Keep all waypoints except the first one, which is the constructionsite position
          currentWaypoints.to.shift();
          currentWaypoints.back.pop();

          newTo = [
            mpPos, ...currentWaypoints.to
          ];
  
          newBack = [
            ...currentWaypoints.back, mpPos
          ];
        }
        else {
          newTo = [mpPos];
          newBack = [mpPos];
        }
  
        const updatedWaypoints = {
          to: newTo,
          back: newBack
        }
  
        o.waypoints = updatedWaypoints;
        return o;
      });
  
      // Update all affected orders
      await this.props.editMultipleOrders(editedOrders);
    } catch (error) {
      console.log("Could not update order positions: ", error);
    }

    await this.props.editMixingPlant(data);

    if (authenticated)
      await save();
      
    this.props.history.push('/auftragsdispo/base-data?tab=mixingplants');
  }

  handleNegative = _ => {
    this.props.history.push('/auftragsdispo/base-data?tab=mixingplants');
  }

  updatePosition = marker => {
    this.setState({
      position: `${marker.lat},${marker.lng}`
    });
  }

  render() {
    const { t } = this.props;
    const { position } = this.state;

    const validations = {
      name: ["nameRequired"],
      tonday: ["gt0"],
      standard: ["gt0"],
      maxmix: ["gt0"],
    };

    return(
      <div className='page'>
        <Validate validations={validations} rules={validationRules}>
          {({ validate, errorMessages, allValid, errorCount }) => (
            <Segment raised>
              <Header as="h2" floated="left">
                <Header.Content>{t("mixingplant.edit.header")}</Header.Content>
              </Header>

              <div style={{textAlign: 'right'}}>
                <Button.Group>
                  <Button
                    type="button"
                    onClick={() => this.handleNegative()}
                    color="grey"
                  >
                    <Icon name="close" />
                    {t("buttons.cancel")}
                  </Button>
                  <Button disabled={errorCount >= 1} type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                    <Icon name="checkmark" />
                    {t("buttons.ok")}
                  </Button>
                </Button.Group>
              </div>

              <Form.Group style={{clear: 'both'}}>
                <React.Fragment>
                  <Form.Input 
                    name="name"
                    label={t("mixingplant.name")} 
                    placeholder={t("mixingplant.name")} 
                    value={this.state.name}
                    onChange={(e) => {
                      this.handleInputChange('name', e);
                      validate(e);
                    }}
                    fluid
                  />
                  {!!errorMessages.name && errorMessages.name.length > 0 &&
                    <Message negative>
                      <Message.Header>
                        {t(errorMessages.name[0])}
                      </Message.Header>
                    </Message>
                  }
                </React.Fragment>
              </Form.Group>  

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  label={t("mixingplant.tel")} 
                  placeholder={t("mixingplant.tel")} 
                  value={this.state.tel}
                  onChange={(e) => this.handleInputChange('tel', e)}
                  fluid
                />
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  label={t("mixingplant.fax")} 
                  placeholder={t("mixingplant.fax")} 
                  value={this.state.fax}
                  onChange={(e) => this.handleInputChange('fax', e)}
                  fluid
                />
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  label={t("mixingplant.email")} 
                  placeholder={t("mixingplant.email")} 
                  value={this.state.email}
                  onChange={(e) => this.handleInputChange('email', e)}
                  fluid
                />
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  min="0"
                  name="tonday"
                  label={t("mixingplant.tonday")} 
                  placeholder={t("mixingplant.tonday")} 
                  value={this.state.tonday}
                  onChange={(e) => {
                    this.handleInputChange('tonday', e);
                    validate(e);
                  }}
                  fluid
                  type='number'
                />
                {!!errorMessages.tonday && errorMessages.tonday.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.tonday[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  min="0"
                  name="standard"
                  label={t("mixingplant.standard")} 
                  placeholder={t("mixingplant.standard")} 
                  value={this.state.standard}
                  onChange={(e) => {
                    this.handleInputChange('standard', e);
                    validate(e);
                  }}
                  fluid
                  type='number'
                />
                {!!errorMessages.standard && errorMessages.standard.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.standard[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <Form.Input 
                  min="0"
                  name="maxmix"
                  label={t("mixingplant.maxmix")} 
                  placeholder={t("mixingplant.maxmix")} 
                  value={this.state.maxmix}
                  onChange={(e) => {
                    this.handleInputChange('maxmix', e);
                    validate(e);
                  }}
                  fluid
                  type='number'
                />
                {!!errorMessages.maxmix && errorMessages.maxmix.length > 0 &&
                  <Message negative>
                    <Message.Header>
                      {t(errorMessages.maxmix[0])}
                    </Message.Header>
                  </Message>
                }
              </Form.Group>

              <Form.Group style={inlineStyle.field}>
                <label>{t("mixingplant.position")}</label>
                <MapLocal updatePosition={this.updatePosition} position={position}/>
              </Form.Group>

            </Segment>  
          )}
        </Validate>
      </div>
    );
  }
}

const MixingPlant_i18nWrapper = withNamespaces('translation')(MixingPlant);

function mapStateToProps(state, props) {
  const authenticated = state.auth.authenticated;
  const mixingPlants = state.mixingPlants;
  const id = 'id' in props.match.params ? props.match.params.id : undefined;
  const permissions = state.permissions;
  const orders = state.orders;

  const readOnly = permissions.filter(p => p.perm_type === 'MENU_MIXING_PLANT' && p.perm_desc === 'W').length < 1;

  if(readOnly) props.history.push('/auftragsdispo/login');

  if(!id) props.history.push('/auftragsdispo/mixing-plants');

  const mixingPlant = mixingPlants.filter(m => m.id == id)[0];

  if(!mixingPlant) props.history.push('/auftragsdispo/mixing-plants');

  return {
    history: props.history,
    mixingPlant,
    orders,
    authenticated
  };
}

export default connect(mapStateToProps, {editMixingPlant, editMultipleOrders, save})(MixingPlant_i18nWrapper);