import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import AddMixingPlant from './AddMixingPlant';

class Buttons extends React.Component {

  state = {};

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state);
  };

  render() {

    const { canAdd } = this.props;

    return(
      <div className='buttonLine'>
        {canAdd && <AddMixingPlant />}
      </div>
    );
  }
}

const Buttons_i18nWrapper = withNamespaces('translation')(Buttons);

function mapStateToProps(state) {
  const permissions = state.permissions;
  return {
    canAdd: permissions.filter(p => p.perm_type === 'ADD_MIXING_PLANT').length > 0
  };
}

export default connect(mapStateToProps, {})(Buttons_i18nWrapper);