import { ADD_SILO, EDIT_SILO, REMOVE_SILO, LOAD_SILOS } from '../actions/silos';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_SILO:
      return [...state, action.data];
    case EDIT_SILO:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
    case REMOVE_SILO:
      return state.filter(({ id }) => id !== action.id);
    case LOAD_SILOS:
      return action.data;
  }
}