import React from 'react';
import { withNamespaces } from 'react-i18next';
import {
    Header,
    Icon,
    Segment,
    Form,
    Dropdown,
    Table,
    Button,
    Message,
    Input
} from "semantic-ui-react";
import { connect } from 'react-redux';
import moment from 'moment';
import MaterialsByDay from './MaterialsByDay';
import uuid from 'uuid/v4';
import { addOrder, editOrder, removeOrder, removeMultipleOrders } from '../../../actions/orders';
import { addCustomer } from '../../../actions/customers';
import { addConstructionSite } from '../../../actions/constructionSites';
import { asyncForEach } from '../../../helpers/async-foreach';
import UpdateGroup from './UpdateGroup';
import MixingPlantsCapacityTimeline from '../../MixingPlants/MixingPlantsCapacityTimeline';
import MapLocal from '../../MapLocal/MapLocal';
import { save } from '../../../actions/authentication';
import alphanumerical from "../../../helpers/utils"
import { WIEGEMEISTER_PERMISSION, MISCHMEISTER_PERMISSION, ADMIN_PERMISSION } from '../../../constants/constants';

class NewOrder extends React.Component {

    state = {
        groups: [],
        items: [],
        // mixingPlant: undefined,
        order_materials: [],
        selected_date: undefined,
        selected_mixingPlant: undefined,
        shouldShowMaterialsByDay: false,
        constructionSite: undefined,
        showAddNewConstructionSite: false,
        customer: '',
        new_cs_name: '',
        new_customer_name: '',
        new_customer_tel: '',
        new_customer_address1: '',
        new_customer_address2: '',
        visibleTimeStart: moment().startOf('day'),
        visibleTimeEnd: moment().add(7, 'days'),
        removed: [],
        csMissingError: false,
        customerMissingError: false,
        customerNameMissingError: false,
        csNameMissingError: false,
        orderMissingError: false,
        formError: false,
        position: '',
        waypoints: {},
    }

    // handleChooseMixingPlant = (event, data) => {
    //   this.setState({mixingPlant: data.value});
    // }

  componentDidMount() {
    if(this.props.ordersGroup.length > 0) {
      this.setState({
        constructionSite: this.props.order.constructionSite,
        customer: this.props.constructionSite.customer_id,
        mixingPlant: this.props.order.mixingPlant,
        order_materials: this.props.ordersGroup.map(m => {
          let order_material = {
            order: m,
            id: m.id,
            material_id: m.materials.material_id,
            amount: m.materials.amount,
            start: moment(m.materials.start),
            end: moment(m.materials.end),
            tonPerHour: 0,
            obs: m.materials.obs,
            status: m.materials.status,
            mixingPlant: m.mixingPlant,
            group_id: `${m.materials.material_id}_${m.mixingPlant}`
          }

          return order_material;
        })
      });
    }
  }

  updatePosition = marker => {
    this.setState({
      position: `${marker.lat},${marker.lng}`
    });
  }

  handleChooseConstructionSite = (event, data) => {
    console.log(data.value,'datavalueee');
    if (data.value == this.state.constructionSite)
      return;
      
    const { order_materials } = this.state;
    const updatedOrderMaterials = order_materials.map(om => {
      om.edited = true;
      return om;
    });

    if (data.value === 'new') {
      this.setState({
        showAddNewConstructionSite: true,
        constructionSite: 'new',
        customer: ''
      }, this.validateInput);
      return;
    }

    let selectedConstructionSite = this.props.constructionSites.filter(cs => cs.id == data.value);

    this.setState({
      constructionSite: data.value,
      customer: selectedConstructionSite[0].customer_id,
      showAddNewConstructionSite: false,
      order_materials: updatedOrderMaterials,
    }, this.validateInput);

  }  

    handleChooseCustomerForNewConstructionSite = (event, data) => {
        if (data.value === 'new') {
            this.setState({
                showAddNewCustomer: true,
                customer: 'new'
            }, this.validateInput);
            return;
        }

    this.setState({customer: data.value, showAddNewCustomer: false}, this.validateInput);
  }

  handleShowAddNewConstructionSite = (showAddNewConstructionSite, newId) => {
    this.setState({
      constructionSite: newId,
      showAddNewConstructionSite
    });
  }

    addMaterial = (material_id, mixingPlant) => {
        let {groups} = this.state;
        let material = this.props.materials.filter(m => m.id == material_id)[0];

    const id = `${material_id}_${mixingPlant}`;

        let groupAlreadyAdded = groups.filter(g => g.id == id).length > 0;
        if (groupAlreadyAdded) return;

    groups = [].concat(groups, [{
      id,
      title: material.name,
      root: false,
      parent: mixingPlant
    }]);

    this.setState({
      groups
    });
  }

  // onCanvasClick = (groupId, time, e) => {
  //   this.addOrderMaterial({
  //     id: uuid(),
  //     material_id: groupId,
  //     amount: 0,
  //     start: moment(time).hour(8).minute(0).valueOf(),
  //     end: moment(time).hour(17).minute(0).valueOf(),
  //     tonPerHour: 0,
  //     obs: '',
  //     status: 'STANDARD'
  //   }, time);
  // }

    addOrderMaterial = (orderMaterial, time) => {
        let {order_materials} = this.state;

    order_materials = order_materials.filter(om => om.amount > 0);
    
    order_materials = [].concat(order_materials, [orderMaterial]);

    this.setState({
      order_materials,
      selected_date: time,
      selected_mixingPlant: orderMaterial.mixingPlant,
      shouldShowMaterialsByDay: true
    }, this.validateInput);
  }

    handleUpdateMaterialByDay = orderMaterial => {
        let {order_materials} = this.state;
        order_materials = order_materials.map(m => {
            if (m.id == orderMaterial.id) {
                return {
                    amount: orderMaterial.amount,
                    start: moment(orderMaterial.start).valueOf(),
                    end: moment(orderMaterial.end).valueOf(),
                    material_id: orderMaterial.material_id,
                    id: orderMaterial.id,
                    tonPerHour: parseFloat(orderMaterial.tonPerHour),
                    obs: orderMaterial.obs,
                    status: orderMaterial.status,
                    mixingPlant: orderMaterial.mixingPlant,
                    group_id: orderMaterial.group_id,
                    order: orderMaterial.order
                };
            } else {
                return m;
            }
        });

    this.setState({order_materials})
  }

  onItemSelect = (itemId, e, time) => {
    let {order_materials} = this.state;

    let orderMaterial = order_materials.filter(om => om.id == itemId)[0];

        if (!orderMaterial) return;

    order_materials = order_materials.filter(om => om.amount > 0);

    this.setState({
      selected_date: time,
      selected_mixingPlant: orderMaterial.mixingPlant,
      shouldShowMaterialsByDay: true,
      order_materials
    });
  }

    onItemResize = (itemId, time, edge) => {
        const {order_materials} = JSON.parse(JSON.stringify(this.state));

        order_materials.map((om) => {
            if (om.id == itemId) {
                om.end = time;
            }

      return om;
    });

    this.setState({order_materials});
  }

    onItemMove = (itemId, dragTime, newGroupOrder) => {
        let {order_materials} = JSON.parse(JSON.stringify(this.state));

        let orderMaterial = order_materials.filter(om => om.id == itemId)[0];

        if (!orderMaterial) return;

        order_materials = order_materials.map((om) => {
            if (om.id == itemId) {
                const diff = moment(om.start).startOf('day').diff(moment(dragTime).startOf('day'), 'days');
                om.start = moment(om.start).add(-1 * diff, 'days').valueOf();
                om.end = moment(om.end).add(-1 * diff, 'days').valueOf();
            }

      return om;
    });

    this.setState({
      order_materials,
      selected_date: dragTime,
      selected_mixingPlant: orderMaterial.mixingPlant,
    });
  }

    removeOrder = (itemId, e, time) => {
        let {order_materials, removed} = JSON.parse(JSON.stringify(this.state));

        let removedItem = order_materials.filter(om => om.id == itemId)[0];

        if (!removedItem) return;

    order_materials = order_materials.filter(om => om.id != itemId);

        if (Object.keys(removedItem.order).length > 0) removed.push(removedItem.order.id);

    this.setState({
      order_materials,
      removed
    });
  }

  closeMaterialsByDay = _ => {
    this.setState({
      shouldShowMaterialsByDay: false
    });
  }

  validateInput = () => {
    const {customer, constructionSite, order_materials} = this.state;
    let csName = this.state.new_cs_name;
    let customerName = this.state.new_customer_name;
    let tmpState = {};

    if (!customer)
      tmpState.customerMissingError = true;
    else
      tmpState.customerMissingError = false;

    if (constructionSite === "new" && csName === "")
      tmpState.csNameMissingError = true;
    else
      tmpState.csNameMissingError = false;

      
    if (customer === "new" && customerName === "")
      tmpState.customerNameMissingError = true;
    else
      tmpState.customerNameMissingError = false;

    if (!constructionSite)
      tmpState.csMissingError = true;
    else
      tmpState.csMissingError = false;


    if (order_materials.length === 0)
      tmpState.orderMissingError = true;
    else
      tmpState.orderMissingError = false;


    if (Object.values(tmpState).includes(true))
      tmpState.formError = true;
    else
      tmpState.formError = false;

    this.setState({
      ...tmpState
    });
  }

  handlePositive = async (state) => {
    let {customer, constructionSite, order_materials, removed, position} = state;

    if (!customer || !constructionSite || order_materials.length < 1)
      return;

    const { orders, constructionSites , mixingPlants, authenticated, save} = this.props;
    let constructionSitePosition;
    if (position && position !== "") {
      constructionSitePosition = position;
    }
    else {
      let csObject = constructionSites.filter((cs) => {
        return cs.id == constructionSite;
      })[0];

      constructionSitePosition = "48.958939937786244,8.804294764995344";
      if (typeof csObject !== "undefined") constructionSitePosition = csObject.position;
    }

    this.validateInput();

    if (order_materials.length === 0) return;

    if (customer === 'new') {
      let newCustomer = {
        name: this.state.new_customer_name,
        tel: this.state.new_customer_tel,
        fax: '',
        email: '',
        address1: this.state.new_customer_address1,
        address2: this.state.new_customer_address2
      }

      customer = await this.props.addCustomer(newCustomer);
    }

    if (!customer) return;

    if (constructionSite === 'new') {
      let newConstructionSite = {
        name: this.state.new_cs_name,
        local: constructionSitePosition,
        position: constructionSitePosition,
        customer_id: customer
      };

      constructionSite = await this.props.addConstructionSite(newConstructionSite);
    }

    if (!constructionSite) return;

    const ordersToAdd = state.order_materials.filter(om => Object.keys(om.order).length === 0 && om.amount > 0);

    const ordersToEdit = state.order_materials.filter(om => Object.keys(om.order).length > 0 && om.amount > 0);

    if (ordersToAdd.length === 0 && ordersToEdit.length === 0) return;

    let orderGroup = uuid();
    let created_by = "SYSTEM";

    if (ordersToEdit.length > 0) {
        orderGroup = ordersToEdit[0].order.orderGroup;
        created_by = ordersToEdit[0].order.created_by;
    }

    await asyncForEach(ordersToAdd, async orderMaterial => {
      let mixingplant = mixingPlants.filter(mp => {
        return mp.id == orderMaterial.mixingPlant
      })[0];
      let mixingPlantPosition = mixingplant.position || "48.948939937786866,8.814294764995577"; //HARDCODED DEFAULT VALUE /TODO /FIXME;
      let waypoints = {
        to: [mixingPlantPosition, constructionSitePosition],
        back: [constructionSitePosition, mixingPlantPosition],
      };

      let cycle_data = {  // HARDCODED DEFAULT CYCLE_DATA VALUES FOR NOW /TODO/FIXME
        load: 10,
        wayto: "",
        paver: 10,
        installation: 10,
        leave: 10,
        wayback: "",
      };

      let materials = orderMaterial;

      materials.end = moment(materials.end).valueOf();
      materials.start = moment(materials.start).valueOf();

      delete materials['group_id'];
      
      let entry = {
        constructionSite: constructionSite,
        mixingPlant: orderMaterial.mixingPlant,
        materials,
        orderGroup,
        created_by,
        waypoints,
        cycle_data,
        edited: true,
      }

      await this.props.addOrder(entry);
    });

    await asyncForEach(ordersToEdit, async orderMaterial => {
      let mixingplant = mixingPlants.filter(mp => {
        return mp.id == orderMaterial.mixingPlant
      })[0];
      let mixingPlantPosition = mixingplant.position || "48.948939937786866,8.814294764995577"; //HARDCODED DEFAULT VALUE /TODO /FIXME;
      let waypoints = {
        to: [mixingPlantPosition, constructionSitePosition],
        back: [constructionSitePosition, mixingPlantPosition],
      };

      let cycle_data = {  // HARDCODED DEFAULT CYCLE_DATA VALUES FOR NOW /TODO/FIXME
        load: 10,
        wayto: "",
        paver: 10,
        installation: 10,
        leave: 10,
        wayback: "",
      };
      
      let materials = orderMaterial;

      materials.end = moment(materials.end).valueOf();
      materials.start = moment(materials.start).valueOf();
      
      let entry = {
        id: orderMaterial.order.id,
        constructionSite: constructionSite,
        mixingPlant: orderMaterial.mixingPlant,
        materials,
        orderGroup: orderMaterial.order.orderGroup,
        created_at: orderMaterial.order.created_at,
        updated_at: orderMaterial.updated_at,
        waypoints: orderMaterial.order.waypoints || waypoints,
        cycle_data: orderMaterial.order.cycle_data || cycle_data
      }

      await this.props.editOrder(entry);
    });

    if (removed.length > 0) {
      const ordersToRemove = orders.filter(o => removed.indexOf(o.id) !== -1);
      await this.props.removeMultipleOrders(ordersToRemove);
    }

    if (authenticated)
      await save();

    await this.props.history.push('/auftragsdispo/');
  }

  handleNegative = _ => {
    this.props.history.push('/auftragsdispo/');
  }

    addMaterialForDay = (material_id, mixingPlant) => {
        const {selected_date, groups} = this.state;

        let hasGroup = groups.filter(g => g.id == material_id).length > 0;

        if (!hasGroup) {
            this.addMaterial(material_id, mixingPlant);
        }

    const group_id = `${material_id}_${mixingPlant}`;

    this.addOrderMaterial({
      id: uuid(),
      material_id: material_id,
      group_id: group_id,
      amount: 0,
      start: moment(selected_date).hour(8).minute(0).valueOf(),
      end: moment(selected_date).hour(17).minute(0).valueOf(),
      tonPerHour: 0,
      obs: '',
      status: 'STANDARD',
      mixingPlant: mixingPlant,
      order: {}
    }, selected_date);
  }

    duplicate = (mat, range) => {
        const {order_materials, selected_date} = this.state;
        const tmpOrderMaterials = [...order_materials];

    for (let day of range.by("day")) {
      const dayStartValue = day.startOf("day").valueOf();
      const selectedDateStartValue = moment(selected_date).startOf("day").valueOf();
      if (dayStartValue === selectedDateStartValue)
        continue;

      const UUID = uuid();
      const matStartDay = moment(mat.start).startOf("day");
      const matStartTime = -matStartDay.diff(mat.start);

      const matEndDay = moment(mat.end).startOf("day");
      const matEndTime = -matEndDay.diff(mat.end);
      
      const startTime = moment(day).add(matStartTime, "milliseconds").valueOf();
      const endTime = moment(day).add(matEndTime, "milliseconds").valueOf();
      
      const newMat = {
        amount: mat.amount,
        group_id: mat.group_id,
        id: UUID,
        material_id: mat.material_id,
        mixingPlant: mat.mixingPlant,
        obs: mat.obs,
        order: {},
        status: mat.status,
        tonPerHour: mat.tonPerHour,
        start: startTime,
        end: endTime
      };
      
      tmpOrderMaterials.push(newMat);
    }

    this.setState({
      order_materials: tmpOrderMaterials
    });
  } 

  handleInputChange = (field, e) => {
    let tmpState = {};
    tmpState[field] = e.target.value;

    this.setState(tmpState, this.validateInput);
  };

    groupRenderer = ({group}) => {
        return (
            <div
                className="rct-sidebar-row rct-sidebar-row-even"
                style={{
                    height: '38px',
                    lineHeight: '38px',
                    display: 'flex'
                }}
                key={group.id}
            >
                <UpdateGroup
                    updateMaterial={this.updateMaterial}
                    group={group}
                    removeMaterial={this.removeMaterial}
                    closeMaterialsByDay={this.closeMaterialsByDay}
                />
            </div>
        );
    }

    updateTime = (visibleTimeStart, visibleTimeEnd) => {
        this.setState({visibleTimeStart, visibleTimeEnd});
    };

    handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
        this.setState({visibleTimeStart, visibleTimeEnd});
    };

  updateOrderMaterials = order_materials => {
    this.setState({
      order_materials
    });
  }

    render() {
        const {t, constructionSites, customers} = this.props;
        const {
            order_materials,
            selected_date,
            selected_mixingPlant,
            showAddNewConstructionSite,
            constructionSite,
            showAddNewCustomer,
            customer,
            position
        } = this.state;

    const constructionSitesDropdown = constructionSites.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    })).sort(alphanumerical);

    constructionSitesDropdown.push({
      key: 'new',
      value: 'new',
      text: t("construction_sites.new.header"),
      icon: 'plus circle'
    });

    const customersDropdown = customers.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    })).sort(alphanumerical);

    customersDropdown.push({
      key: 'new',
      value: 'new',
      text: t("customer.newcustomer.header"),
      icon: 'plus circle'
    });

        let customerInfo = undefined;
        if (customer) {
            let customerFromSelectedConstructionSite = customers.filter(c => c.id == customer);

            if (customerFromSelectedConstructionSite.length > 0) {
                customerInfo = customerFromSelectedConstructionSite[0];
            }
        }

        return (
            <div className='page'>
                <Segment raised>
                    <Header as="h2" style={{marginBottom: '25px'}} floated="left">
                        <Header.Content>{this.props.groups.length > 0 ? t("order.edit.header") : t("order.neworder.header")}</Header.Content>
                    </Header>

                    <div style={{textAlign: 'right'}}>
                        <Button.Group>
                            <Button
                                type="button"
                                onClick={() => this.handleNegative()}
                                color="grey"
                            >
                                <Icon name="close"/>
                                {t("buttons.cancel")}
                            </Button>
                            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
                                <Icon name="checkmark"/>
                                {t("buttons.ok")}
                            </Button>
                        </Button.Group>
                    </div>

          <Form.Group style={{marginBottom: '10px', 'clear': 'both'}}>
            <Header as="h4">
              <Header.Content>{t("construction_sites.header")}</Header.Content>
            </Header>
            <Dropdown 
              placeholder={t('construction_sites.header')}
              fluid 
              search 
              selection 
              options={constructionSitesDropdown} 
              onChange={this.handleChooseConstructionSite}
              value={constructionSite}
            />
          </Form.Group>

          {this.state.csMissingError && 
            <Message negative>
              <Message.Header>{t("general.errormessages.constructionsite_missing_error")}</Message.Header>
              <p></p>
            </Message> 
          }

          {customerInfo && 
            <Form.Group style={{marginBottom: '10px'}}>
              <Header as="h4">
                <Header.Content>{t("customer.header")}</Header.Content>
              </Header>
              <Table celled compact>
                <Table.Header>
                  <Table.Row style={{fontSize: '12px'}}>
                    <Table.HeaderCell>{t("customer.name")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("customer.tel")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("customer.address1")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("customer.address2")}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{fontSize: '12px'}}>
                    <Table.Cell width={2}>{customerInfo.name}</Table.Cell>
                    <Table.Cell width={2}>{customerInfo.tel}</Table.Cell>
                    <Table.Cell width={2}>{customerInfo.address1}</Table.Cell>
                    <Table.Cell width={2}>{customerInfo.address2}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form.Group>
          }
          
          {showAddNewConstructionSite && 
            <Form.Group style={{marginBottom: '10px'}}>
              <Header as="h4">
                <Header.Content>{t("construction_sites.new.header")}</Header.Content>
              </Header>
              <label>{t("customer.header")}</label>
              <Dropdown 
                placeholder={t('customer.header')}
                fluid 
                search 
                selection 
                options={customersDropdown} 
                onChange={this.handleChooseCustomerForNewConstructionSite}
              />

              {this.state.customerMissingError && this.state.constructionSite === "new" &&
                <Message negative>
                  <Message.Header>{t("general.errormessages.customer_missing_error")}</Message.Header>
                  <p></p>
                </Message> 
              }

              <Form.Input 
                label={t("order.neworder.newconstructionsite.name")} 
                placeholder={t("order.neworder.newconstructionsite.name")} 
                value={this.state.new_cs_name}
                onChange={(e) => this.handleInputChange('new_cs_name', e)}
                fluid
              />

              {this.state.csNameMissingError && 
                <Message negative>
                  <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
                  <p></p>
                </Message> 
              }            

              <label>{t("order.neworder.newconstructionsite.local")}</label>
              <MapLocal updatePosition={this.updatePosition} position={position}/>
            </Form.Group> 
          }

          {showAddNewCustomer && 
            <Form.Group style={{marginBottom: '10px'}}>              
              <Form.Input 
                label={t("order.neworder.newcustomer.name")} 
                placeholder={t("order.neworder.newcustomer.name")} 
                value={this.state.new_customer_name}
                onChange={(e) => this.handleInputChange('new_customer_name', e)}
                fluid
              />

              {this.state.customerNameMissingError && 
                <Message negative>
                  <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
                  <p></p>
                </Message> 
              }

              <Form.Input 
                label={t("order.neworder.newcustomer.tel")} 
                placeholder={t("order.neworder.newcustomer.tel")} 
                value={this.state.new_customer_tel}
                onChange={(e) => this.handleInputChange('new_customer_tel', e)}
                fluid
              />
              <Form.Input 
                label={t("order.neworder.newcustomer.address1")} 
                placeholder={t("order.neworder.newcustomer.address1")} 
                value={this.state.new_customer_address1}
                onChange={(e) => this.handleInputChange('new_customer_address1', e)}
                fluid
              />
              <Form.Input 
                label={t("order.neworder.newcustomer.address2")} 
                placeholder={t("order.neworder.newcustomer.address2")} 
                value={this.state.new_customer_address2}
                onChange={(e) => this.handleInputChange('new_customer_address2', e)}
                fluid
              />
            </Form.Group>
          }

          <Form.Group style={{marginBottom: '10px'}}>
            <Header as="h4">
              <Header.Content>{t("mixingplant.header")}</Header.Content>
            </Header>

            <MixingPlantsCapacityTimeline 
              visibleTimeStart={this.state.visibleTimeStart}
              visibleTimeEnd={this.state.visibleTimeEnd}
              updateTime={this.updateTime}
              addOrderMaterial={this.addOrderMaterial}
              order_materials={order_materials}
              removeOrder={this.removeOrder}
              onItemSelect={this.onItemSelect}
              onItemResize={this.onItemResize}
              onItemMove={this.onItemMove}
              closeMaterialsByDay={this.closeMaterialsByDay}
              updateOrderMaterials={this.updateOrderMaterials}
              materialsGroups={this.props.groups}
            />
          </Form.Group>

                    {this.state.orderMissingError &&
                    <Message negative>
                        <Message.Header>{t("general.errormessages.order_missing_error")}</Message.Header>
                        <p></p>
                    </Message>
                    }
                </Segment>

                {(order_materials.length > 0 && this.state.shouldShowMaterialsByDay) &&
                <Segment raised>
                    <div style={{textAlign: 'right', marginTop: '15px', cursor: 'pointer'}}>
                        <Icon name='close' onClick={this.closeMaterialsByDay}/>
                    </div>
                    <MaterialsByDay
                        order_materials={order_materials}
                        selected_date={selected_date}
                        selected_mixingPlant={selected_mixingPlant}
                        handleUpdateMaterialByDay={this.handleUpdateMaterialByDay}
                        addMaterialForDay={this.addMaterialForDay}
                        duplicate={this.duplicate}
                    />
                </Segment>
                }
            </div>
        );
    }
}

const NewOrder_i18nWrapper = withNamespaces('translation')(NewOrder);

function mapStateToProps(state, props) {
  const authenticated = state.auth.authenticated;
  const mixingPlants = state.mixingPlants.filter((mp) => {
    return !mp.readonly || mp.readonly === false;
  });

  const materials = state.materials;
  const constructionSites = state.constructionSites;
  const customers = state.customers;
  const orders = state.orders;

  const permissions = state.permissions;
  const canAdd = permissions.filter(p => p.perm_type === 'ADD_ORDER').length > 0;

    if (!canAdd) props.history.push("/auftragsdispo/login");

  const admin_view_prod_takt = state.app.admin_view_prod_takt;
  const user = state.user;
  let isMixtureMaster = false;
  let isAdmin = false;
  let isScaleMaster = false;

  !!user.permissions && user.permissions.forEach(permission => {
    if (permission.id === WIEGEMEISTER_PERMISSION.id) {
      isScaleMaster = true;
  }

if(permission.id === MISCHMEISTER_PERMISSION.id) {
  isMixtureMaster = true;
}

if(permission.id === ADMIN_PERMISSION.id) {
  isAdmin = true;

  if(admin_view_prod_takt) {
      isMixtureMaster = true;
  }
}
  });

  const canUpdate = isAdmin || isScaleMaster;

    const id = 'id' in props.match.params ? props.match.params.id : undefined;

    let order = null;
    let groups = [];
    let ordersGroup = [];
    let constructionSite = null;
    if (id) {
        order = orders.filter(m => m.orderGroup === id)[0];
        if (order) {
            constructionSite = constructionSites.filter(cs => cs.id == order.constructionSite)[0];

            ordersGroup = orders.filter(o => o.orderGroup === order.orderGroup);

            ordersGroup.forEach(om => {
                let material = materials.filter(m => m.id == om.materials.material_id);

                if (material.length > 0) {
                    groups.push({
                        id: `${om.materials.material_id}_${om.mixingPlant}`,
                        title: material[0].name,
                        root: false,
                        parent: om.mixingPlant
                    });
                }
            });

            groups = groups.filter(
                (group, index, self) =>
                    index ===
                    self.findIndex(
                        i =>
                            i.id === group.id
                    )
            );
        }
    }

    return {
        mixingPlants,
        materials,
        constructionSites,
        customers,
        history: props.history,
        order,
        orders,
        groups,
        canUpdate,
        ordersGroup,
        constructionSite,
        isMixtureMaster,
        authenticated
    };
}

export default connect(mapStateToProps, {
  addOrder, 
  addCustomer, 
  addConstructionSite, 
  editOrder, 
  removeOrder,
  removeMultipleOrders,
  save
})(NewOrder_i18nWrapper);