import { combineReducers } from 'redux';
import authentication from './authentication';
import mixingPlants from './mixingPlants';
import materials from './materials';
import orders from './orders';
import basedata from './basedata';
import constructionSites from './constructionSites';
import customers from './customers';
import app from './app';
import permissions from './permissions';
import user from './user';
import silos from './silos';
import productionTakt from './productionTakt';
import deleted from './deleted';
import settings from './settings';

export const CLEAN_DATA = "rootreducer/CLEAN_DATA";
export const LOAD_DATA = "rootreducer/LOAD_DATA";

const appReducer = combineReducers({
  auth: authentication,
  mixingPlants,
  materials,
  orders,
  basedata,
  constructionSites,
  customers,
  app,
  permissions,
  user,
  silos,
  productionTakt,
  deleted,
  settings
});

const rootReducer = (state, action) => {
  if (action.type === CLEAN_DATA) {
    state = undefined
  }

  if(action.type === LOAD_DATA) {
    let updatedState = action.state;
    updatedState.app = state.app;
    state = Object.assign({},updatedState);
  }

  return appReducer(state, action)
}

export default rootReducer;