export const ADMIN_PERMISSION = {
    id: "956b11da-423b-4186-b4a8-4b168b9efb56",
    perm_type: "ADMIN",
    translation: "rolesmanagement.roles.admin_permission",
};

export const CUSTOMER_PERMISSION = {
    id: "ec064361-7102-4b21-b1e5-466e4f179787",
    perm_type: "CUSTOMER",
    translation: "rolesmanagement.roles.customer_permission",
};

export const WIEGEMEISTER_PERMISSION = {
    id: "b84a13f0-dc19-4e4d-9d46-46df1abfc868",
    perm_type: "WIEGEMEISTER",
    translation: "rolesmanagement.roles.wiegemeister_permission",
};

export const MISCHMEISTER_PERMISSION = {
    id: "6eeb9ab9-ba06-4d0a-9e9e-4f4b0f38bb1e",
    perm_type: "MISCHMEISTER",
    translation: "rolesmanagement.roles.mischmeister_permission",
};

export const PRODUCTIONTAKT_PERMISSION = {
         id: "1617d019-1570-44a3-8586-6a4a3d9cc337",
         perm_type: "PRODUCTIONTAKT",
         translation: "rolesmanagement.roles.productiontakt_permission"
       };




export const MODIFY_CUSTOMER_PERMISSION = {
    id: "1ddb4670-f60d-423d-875c-aa4ef40ac4b1",
    perm_type: "ADD_CUSTOMER",
    translation: "rolesmanagement.roles.modify_customer_permission",
};

export const MODIFY_MATERIAL_PERMISSION = {
    id: "3f59993f-3054-447d-80cb-49f02b5f13e0",
    perm_type: "ADD_MATERIAL",
    translation: "rolesmanagement.roles.modify_material_permission",
};

export const MODIFY_MIXING_PLANT_PERMISSION = {
    id: "91270c27-0e93-4aaa-99b5-cd50727932e0",
    perm_type: "ADD_MIXING_PLANT",
    translation: "rolesmanagement.roles.modify_mixingplant_permission",
};

export const MODIFY_ORDER_PERMISSION = {
    id: "fa179898-7710-4868-ae5d-455cc28759fe",
    perm_type: "ADD_ORDER",
    translation: "rolesmanagement.roles.modify_order_permission",
};

export const MODIFY_SILO_PERMISSION = {
    id: "bf1a010a-27a1-4d52-abf5-89d1d784c3dc",
    perm_type: "ADD_SILO",
    translation: "rolesmanagement.roles.modify_silo_permission",
};

export const MASTERDATA_WRITE_PERMISSION = {
    id: "27ea572d-6783-428f-86c9-e2e3c23289a4",
    perm_type: "MASTERDATA",
    rw: "W",
    translation: "rolesmanagement.roles.stammdaten_write_permission",
};

export const MASTERDATA_READ_PERMISSION = {
    id: "996d32de-6db4-4606-abcb-c38206961ab9",
    perm_type: "MASTERDATA",
    rw: "R",
    translation: "rolesmanagement.roles.stammdaten_read_permission",
};

export const MENU_CUSTOMERS_WRITE_PERMISSION = {
    id: "2ab0da53-b038-40f1-baa5-6dd75e88fe82",
    perm_type: "MENU_CUSTOMERS",
    rw: "W",
    translation: "rolesmanagement.roles.customer_menu_write_permission",
};

export const MENU_CUSTOMERS_READ_PERMISSION = {
    id: "697016f9-947c-418b-b304-7a1107743497",
    perm_type: "MENU_CUSTOMERS",
    rw: "R",
    translation: "rolesmanagement.roles.customer_menu_read_permission",
};

export const MENU_MATERIAL_WRITE_PERMISSION = {
    id: "eb7c3e43-e92e-4eb7-95a5-a697d2e1890b",
    perm_type: "MENU_MATERIAL",
    rw: "W",
    translation: "rolesmanagement.roles.material_menu_write_permission",
};

export const MENU_MATERIAL_READ_PERMISSION = {
    id: "1b76a1b2-12ec-4af5-a539-a0deb10db84c",
    perm_type: "MENU_MATERIAL",
    rw: "R",
    translation: "rolesmanagement.roles.material_menu_read_permission",
};

export const MENU_MIXING_PLANT_WRITE_PERMISSION = {
    id: "1e5b0cbd-b145-4237-8bad-e4b5f8a86837",
    perm_type: "MENU_MIXING_PLANT",
    rw: "W",
    translation: "rolesmanagement.roles.mixingplant_menu_write_permission",
};

export const MENU_MIXING_PLANT_READ_PERMISSION = {
    id: "47dcdf83-b961-49a9-aa56-5f4e340a9ebe",
    perm_type: "MENU_MIXING_PLANT",
    rw: "R",
    translation: "rolesmanagement.roles.mixingplant_menu_read_permission",
};

export const MENU_SILO_WRITE_PERMISSION = {
    id: "b9326acb-4cb6-4eae-9818-01244d83fb46",
    perm_type: "MENU_SILO",
    rw: "W",
    translation: "rolesmanagement.roles.silo_menu_write_permission",
};

export const MENU_SILO_READ_PERMISSION = {
    id: "139cc7bc-4cc7-406f-b536-21e13f2c8045",
    perm_type: "MENU_SILO",
    rw: "R",
    translation: "rolesmanagement.roles.silo_menu_read_permission",
};

export const MENU_STAMMDATEN_WRITE_PERMISSION = {
    id: "94b146bf-8c70-4767-8cf7-c77fc5fd79ae",
    perm_type: "MENU_STAMMDATEN",
    rw: "W",
    translation: "rolesmanagement.roles.stammdaten_menu_write_permission",
};

export const MENU_STAMMDATEN_READ_PERMISSION = {
    id: "96c2dc17-0512-45de-b914-2800e7075ad6",
    perm_type: "MENU_STAMMDATEN",
    rw: "R",
    translation: "rolesmanagement.roles.stammdaten_menu_read_permission",
};



export const AVAILABLE_PERMISSIONS_ARRAY = [
    ADMIN_PERMISSION,
    CUSTOMER_PERMISSION,
    WIEGEMEISTER_PERMISSION,
    MISCHMEISTER_PERMISSION,
    MODIFY_CUSTOMER_PERMISSION,
    MODIFY_MATERIAL_PERMISSION,
    MODIFY_MIXING_PLANT_PERMISSION,
    MODIFY_ORDER_PERMISSION,
    MODIFY_SILO_PERMISSION,
    MASTERDATA_WRITE_PERMISSION,
    MASTERDATA_READ_PERMISSION,
    MENU_CUSTOMERS_WRITE_PERMISSION,
    MENU_CUSTOMERS_READ_PERMISSION,
    MENU_MATERIAL_WRITE_PERMISSION,
    MENU_MATERIAL_READ_PERMISSION,
    MENU_MIXING_PLANT_WRITE_PERMISSION,
    MENU_MIXING_PLANT_READ_PERMISSION,
    MENU_SILO_WRITE_PERMISSION,
    MENU_SILO_READ_PERMISSION,
    MENU_STAMMDATEN_WRITE_PERMISSION,
    MENU_STAMMDATEN_READ_PERMISSION,
];



export const AVAILABLE_PERMISSIONS_DEEP_ARRAY = [
    [ADMIN_PERMISSION],
    [CUSTOMER_PERMISSION],
    [WIEGEMEISTER_PERMISSION],
    [MISCHMEISTER_PERMISSION],
    [MODIFY_CUSTOMER_PERMISSION],
    [MODIFY_MATERIAL_PERMISSION],
    [MODIFY_MIXING_PLANT_PERMISSION],
    [MODIFY_ORDER_PERMISSION],
    [MODIFY_SILO_PERMISSION],
    [MASTERDATA_WRITE_PERMISSION, MASTERDATA_READ_PERMISSION],
    [MENU_CUSTOMERS_WRITE_PERMISSION, MENU_CUSTOMERS_READ_PERMISSION],
    [MENU_MATERIAL_WRITE_PERMISSION, MENU_MATERIAL_READ_PERMISSION],
    [MENU_MIXING_PLANT_WRITE_PERMISSION, MENU_MIXING_PLANT_READ_PERMISSION],
    [MENU_SILO_WRITE_PERMISSION, MENU_SILO_READ_PERMISSION],
    [MENU_STAMMDATEN_WRITE_PERMISSION, MENU_STAMMDATEN_READ_PERMISSION],
    [PRODUCTIONTAKT_PERMISSION]
];