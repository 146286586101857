import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Form, Dropdown } from 'semantic-ui-react';
import { viewProductionTakt } from '../../actions/app';
import alphanumerical from "../../helpers/utils"

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class CutOrderModal extends React.Component {
  state = {
    open: true,
    mixing_plant: !!this.props.admin_view_prod_takt ? this.props.admin_view_prod_takt : undefined
  }

  handleModal = open => {
    this.props.showTaktModal();
  }

  handleInputChange = (field, e) => {
    let state = JSON.parse(JSON.stringify(this.state));
    state[field] = e.target.value;

    this.setState(state);
  };

  handleChooseMixingPlant = (event, data) => {
    this.setState({mixing_plant: data.value});
  }  

  handlePositive = async (state) => {
    const { mixing_plant } = state;

    if(!mixing_plant) return;

    await this.props.viewProductionTakt(mixing_plant);
    await this.handleModal();
  }

  render() {

    const { t, mixingPlantsDropdown } = this.props;
    const { open, mixing_plant } = this.state;

    return (
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
      >
        <Modal.Header>{t("order.show_takt.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <label>{t("order.show_takt.text")}</label>
            <Dropdown 
              placeholder={t('order.show_takt.header')}
              fluid 
              search 
              selection 
              options={mixingPlantsDropdown} 
              onChange={this.handleChooseMixingPlant}
              value={mixing_plant}
            />
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const CutOrderModal_i18nWrapper = withNamespaces('translation')(CutOrderModal);

function mapStateToProps(state, props) { 
  const mixingPlants = state.mixingPlants;
  const admin_view_prod_takt = state.app.admin_view_prod_takt;

  const mixingPlantsDropdown = mixingPlants.map(mp => ({
    key: mp.id,
    value: mp.id,
    text: mp.name
  }));

  return {
    mixingPlantsDropdown,
    admin_view_prod_takt
  }
}

export default connect(mapStateToProps, {viewProductionTakt})(CutOrderModal_i18nWrapper);