import React from "react";
import {withNamespaces} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {
	Button,
	Menu,
	Modal,
	Icon,
	Form,
    Dropdown,
    Input
} from "semantic-ui-react";
import DatePicker from "react-datepicker";

class OrderExportModal extends React.Component{
    constructor(props){
        console.log(props, 'consolelog OrderExportModal props');
        super(props);
        this.state = {
			open: false,
			filter: "", 
			start: new Date(),
			duration: "0", 
		};
    }

    open() {
		this.setState({ open: true });
	}

	close() {
		this.setState({ open: false, filter: "" });
	}   
    
    jumpToDate = (date, event) => {
        this.setState({
            start: date ? date : new Date()
        })
    }

    render() {        
        const appLocale = localStorage.getItem("language");
		const origin =  "https://bpo-projekt.de";
        return (
            <Modal
				centered={false}
				className="orderexportmodel"
				trigger={
					<Button icon="file excel outline" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("order.exports.heading")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form as="div"> 
							<Form.Field>
								<label>{this.props.t("order.exports.start")}</label>	
                                <DatePicker
                                    className="datepickerInput"
                                    todayButton={this.props.t("general.today")}
                                    customInput={
                                        <Input
                                            label={{icon: "calendar alternate outline"}}
                                            labelPosition="right"
                                            placeholder={this.props.t("order.exports.initial")}
                                            type="text"
                                        />
                                    }
                                    dateFormat={appLocale === "de" ? "dd.MM.yyyy" : "MM/dd/yyyy"}
                                    selected={this.state.start}
                                    onChange={this.jumpToDate}
                                    strictParsing
                                />
							</Form.Field>
							<Form.Field>
								<label>{this.props.t("order.exports.duration")}</label>
								<Dropdown
									placeholder={this.props.t("order.exports.initial")}
									fluid
									selection
									onChange={(e, { value }) => this.setState({duration: value})}
									value={this.state.duration}
									options={
										[{
											key: "0",
											text: this.props.t("order.exports.initial"),
											value: "0",
										},
                                        {
											key: "2",
											text: this.props.t("order.exports.weeks",{weeks: "2"}),
											value: "2",
										},
										{
											key: "4",
											text: this.props.t("order.exports.weeks",{weeks: "4"}),
											value: "4",
										},{
											key: "6",
											text: this.props.t("order.exports.weeks",{weeks: "6"}),
											value: "6",
										},
										{
											key: "8",
											text: this.props.t("order.exports.weeks",{weeks: "8"}),
											value: "8",
										}]
									}
								/>
							</Form.Field>
						</Form>
						<form
							className="exportFromWrapper"
							method="post"
							action={""+origin+"/ressourcen/auftragsdispoExport.php"}
							target="_blank"
						>
							<input
								type="hidden"
								name="start"
								value={moment(this.state.start).format("YYYY-MM-DD")}
							/> 
							<input type="hidden" name="cid" value="" />
							<input type="hidden" name="duration" value={this.state.duration} />
							<input type="hidden" name="cid" value={this.props.companyID} />
							<Button type="submit" positive fluid>
								{this.props.t("order.exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
        )
    }
}
const OrderExportModal_i18nWrapper = withNamespaces('translation')(OrderExportModal);
function mapStateToProps(state, props) {
	const companyID = state.user.company_id;
    return {
		companyID
	};
}
export default withRouter(connect(mapStateToProps, {})(OrderExportModal_i18nWrapper));