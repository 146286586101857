import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Icon, Button, Form, Message } from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import { save } from '../../actions/authentication';
import { addCustomer } from '../../actions/customers';

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class AddCustomer extends React.Component {

  state = {
    open: false,
    name: '',
    tel: '',
    fax: '',
    email: '',
    address1: '',
    address2: '',
    nameError: false
  };

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state, () => {this.validateInput()});
  };

  handleModal = open => {
    let state = {
      open: open,
      name: '',
      tel: '',
      fax: '',
      email: '',
      address1: '',
      address2: '',
      nameError: false
    };

    this.setState(state);
  };

  validateInput = () => {
    const { 
      name
    } = this.state;

    const tmpState = {
      nameError: false
    };

    if (!name)
      tmpState.nameError = true;

    const isValid = !Object.values(tmpState).includes(true);

    this.setState({
      ...tmpState
    });

    return isValid;
  }

  handlePositive = async (state) => {
    const isValid = this.validateInput();
    if (!isValid) 
      return;

    const { authenticated, save } = this.props;

    let data = JSON.parse(JSON.stringify(state));
    delete data.open;

    await this.props.addCustomer(data);
    await this.handleModal(false);

    if(authenticated) 
      save();
  }

  render() {

    const { t } = this.props;
    const { open } = this.state;
    const { nameError } = this.state;
    
    return(
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
        trigger={
          <Button
            content={t("customer.newcustomer.buttontitle")}
            icon="plus"
            onClick={() => this.handleModal(true)}
          />
        }
      >
        <Modal.Header>{t("customer.newcustomer.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <Form.Input 
              label={t("customer.name")} 
              placeholder={t("customer.name")} 
              value={this.state.name}
              onChange={(e) => this.handleInputChange('name', e)}
              fluid
            />

            {nameError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
                <p></p>
              </Message> 
            }
            
          </Form.Group>  
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("customer.tel")} 
              placeholder={t("customer.tel")} 
              value={this.state.tel}
              onChange={(e) => this.handleInputChange('tel', e)}
              fluid
            />
          </Form.Group>    
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("customer.fax")} 
              placeholder={t("customer.fax")} 
              value={this.state.fax}
              onChange={(e) => this.handleInputChange('fax', e)}
              fluid
            />
          </Form.Group> 
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("customer.email")} 
              placeholder={t("customer.email")} 
              value={this.state.email}
              onChange={(e) => this.handleInputChange('email', e)}
              fluid
            />
          </Form.Group> 
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("customer.address1")} 
              placeholder={t("customer.address1")} 
              value={this.state.address1}
              onChange={(e) => this.handleInputChange('address1', e)}
              fluid
            />
          </Form.Group> 
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("customer.address2")} 
              placeholder={t("customer.address2")} 
              value={this.state.address2}
              onChange={(e) => this.handleInputChange('address2', e)}
              fluid
            />
          </Form.Group>             
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const AddCustomer_i18nWrapper = withNamespaces('translation')(AddCustomer);

function mapStateToProps(state) {
  const authenticated = state.auth.authenticated;

  return {
    authenticated
  };
}

export default connect(mapStateToProps, {addCustomer, save})(AddCustomer_i18nWrapper);