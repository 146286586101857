import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Button, Icon, Form, Dropdown, Message } from 'semantic-ui-react';
import alphanumerical from "../../helpers/utils";
import { isSiloAvailable } from "../../helpers/is-silo-empty";

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class UpdateSiloModal extends React.Component {
  state = {
    open: true,
    silo: this.props.silo,
    isSiloAvailable: true,
  }

  componentWillMount = () => {
    const { taktId, taktObject } = this.props;
    const siloId = taktObject.silo;

    isSiloAvailable(taktId, siloId).then((value) => {
      this.setState({
        isSiloAvailable: value
      });
    });
  }

  handleModal = open => {
    this.props.showUpdateSiloModal();
  }

  handleInputChange = (field, e) => {
    let state = JSON.parse(JSON.stringify(this.state));
    state[field] = e.target.value;

    this.setState(state);
  };

  handlePositive = state => {
    const { silo } = state;
    this.props.updateSilo(silo);
  }

  handleChooseSilo = (event, data) => {
    const { taktId } = this.props;
    const siloId = data.value;

    isSiloAvailable(taktId, siloId).then((value) => {
      this.setState({
        silo: data.value,
        isSiloAvailable: value
      });
    });
  }  

  render() {

    const { t, silosDropdown } = this.props;
    const { open, silo, isSiloAvailable } = this.state;

    return (
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
      >
        <Modal.Header>{t("order.update_silo.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <label>{t("order.update_silo.text")}</label>
            <Dropdown 
              placeholder={t('order.update_silo.header')}
              fluid 
              search 
              selection 
              options={silosDropdown.sort(alphanumerical)} 
              onChange={this.handleChooseSilo}
              value={silo}
            />
          </Form.Group>
          {!isSiloAvailable &&
            <Message negative>
              <Message.Header>
                {t("order.update_silo.error")}
              </Message.Header>
            </Message>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const UpdateSiloModal_i18nWrapper = withNamespaces('translation')(UpdateSiloModal);

function mapStateToProps(state, props) { 
  let productionTakt = state.productionTakt;
  let orders = state.orders;
  let takt = productionTakt.filter(pt => pt.id == props.taktId)[0];
  let order = orders.filter(o => o.id == takt.orders_id);
  
  
  let silos = state.silos;
  let silo = silos.filter(s => s.id == takt.silo)[0];

  silos = silos.filter(s => s.mixing_plants_id == order[0].mixingPlant)

  let silosDropdown = silos.map(s => ({
    key: s.id,
    value: s.id,
    text: s.name + ` - [${s.max}t]`
  }));

  return {
    silo: takt.silo,
    taktObject: takt,
    taktId: takt.id,
    siloObject: silo,
    silosDropdown,
    productionTakt
  }
}

export default connect(mapStateToProps, {})(UpdateSiloModal_i18nWrapper);