import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { withNamespaces } from 'react-i18next';

const LabelledButton = props => {
  let color = 'color' in props ? props.color : null;

  if('active' in props && props.active) color = 'black';

  return (
    <div className="labelledButtonWrapper">
      <Button
        type="button"
        icon
        onClick={props.onClick}
        onMouseDown={props.onMouseDown}
        onMouseUp={props.onMouseUp}
        color={color}
        disabled={'disabled' in props ? props.disabled : false}
      >
        {props.image ? (
          props.image
        ) : (
          <Icon name={props.icon} flipped={props.flipped} />
        )}
      </Button>
      <div className="labelledButtonLabel">
        {props.label ? props.label : props.t("labelledbutton.nolabel")}
      </div>
    </div>
  );
};

const LabelledButton_i18nWrapper = withNamespaces('translation')(LabelledButton);


export default LabelledButton_i18nWrapper;
