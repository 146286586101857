export function setFontColor(hex) {    // assumes "rgb(R,G,B)" string
  let rgbValues = hexToRgb(hex);
  let rgb = `rgb(${rgbValues.r}, ${rgbValues.g}, ${rgbValues.b})`;
  let hsl = rgb2hsl(rgb);
  hsl[0] = (hsl[0]+0.5) % 1; // Hue
  hsl[1] = (hsl[1]+0.5) % 1; // Saturation
  hsl[2] = (hsl[2]+0.5) % 1; // Luminocity
  return 'hsl('+(hsl[0]*360)+','+(hsl[1]*100)+'%,'+(hsl[2]*100)+'%)';
}

function rgb2hsl(clr) {
  let rgb = clr.substring(4, clr.length-1).replace(/ /g, '').split(',');
  return rgbToHsl(rgb[0],rgb[1],rgb[2]);
}

// https://gist.github.com/mjackson/5311256
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;

  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      default: h = 0; break;
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }

    h /= 6;
  }

  return [ h, s, l ];
}

function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};