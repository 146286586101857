import uuid from 'uuid/v4';
import moment from 'moment';
import i18n from '../i18n';
import { store } from "../store";

import { addDeletedEntry, addMultipleDeletedEntry } from './deleted';

export const ADD_ORDER = 'orders/ADD_ORDER';
export const EDIT_ORDER = 'orders/EDIT_ORDER';
export const EDIT_MULTIPLE_ORDERS = "orders/EDIT_MULTIPLE_ORDERS"
export const REMOVE_ORDER = 'orders/REMOVE_ORDER';
export const LOAD_ORDERS = 'orders/LOAD_ORDERS';
export const ADD_MULTIPLE_ORDERS = 'orders/ADD_MULTIPLE_ORDERS';
export const REMOVE_MULTIPLE_ORDERS = 'orders/REMOVE_MULTIPLE_ORDERS';

export const TAKT_GROUP_ID = "3533cc3d-61ca-419d-8136-ace7b586f833";
export const DEFAULT_MIXING_PLANT_ID = "20667376-7ffa-4641-a469-c6450bf1510a"; // unknow mixing plant id
export const DAYVIEW_CAPACITY_GROUP = "0bb630a8-a6d6-4795-915a-bab0089453ac";

export const DEFAULT_OFFSET = 5;

export function orderStatus() {
  return [
    {
      key: 'PREPLANNING',
      value: 'PREPLANNING',
      text: i18n.t("translation:order.status.1")
    },
    {
      key: 'STANDARD',
      value: 'STANDARD',
      text: i18n.t("translation:order.status.2")
    },
    {
      key: 'FIXED',
      value: 'FIXED',
      text: i18n.t("translation:order.status.3")
    }
  ];
}

export function addOrder(data) {
  return dispatch => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;
    dispatch({
      type: ADD_ORDER,
      data
    });
  }
}

export function addMultipleOrders(data) {
  return async (dispatch) => { 
    data = data.map(d => {
      d.edited = true;
      d.created_at = moment().valueOf();
      d.updated_at = 0;
  
      return d;
    });

    await dispatch({
      type: ADD_MULTIPLE_ORDERS,
      data
    });
  }
}

export function editOrder(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_ORDER,
      data
    });
  }
}

export function editMultipleOrders(data) {
  return dispatch => {
    data.edited = true;
    dispatch({
      type: EDIT_MULTIPLE_ORDERS,
      data
    });
  }
}

export function editOrderWithoutDispatch(data) {
  data.updated_at = moment().valueOf();
  data.edited = true;
  store.dispatch({
    type: EDIT_ORDER,
    data
    });
}

export function removeOrder(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'orders'
    });
    
    dispatch({
      type: REMOVE_ORDER,
      id
    });
  }
}

export function removeMultipleOrders(data) {
  return async (dispatch) => { 
    data = data.map(d => d.id);

    const forRemoving = data.map(id => ({
      id,
      table: 'orders'
    }));

    await addMultipleDeletedEntry(forRemoving);

    await dispatch({
      type: REMOVE_MULTIPLE_ORDERS,
      data
    });
  }
  
}

export function updateOrders(orders) {
  return dispatch => {
    dispatch({
      type: LOAD_ORDERS,
      data: orders
    });
  }
}