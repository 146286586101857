import _ from "lodash";
import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Table} from "semantic-ui-react";
import Buttons from './Buttons';
import {withRouter} from "react-router";
import LabelledButton from '../LabelledButton/LabelledButton';
import RemoveCustomerMessage from './RemoveCustomerMessage';
import {CUSTOMER_PERMISSION} from '../../constants/constants';


class Customers extends React.Component {

    constructor(props) {
        super(props);

        const tableData = props.tableData;

        this.state = {
            column: null,
            direction: null,
            tableData
        }
    }

    handleSort = (clickedColumn) => {
        const {column, direction, tableData} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                tableData: _.sortBy(tableData, [clickedColumn]),
                direction: 'ascending',
            });

            return;
        }

        this.setState({
            tableData: tableData.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });
    }

    render() {

        const {t, readOnly, customers, history, isCustomer} = this.props;
        const {column, tableData, direction} = this.state;

        return (
            <div>
                <Buttons/>
                <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
                <Table celled sortable selectable className="customersList">
                    <Table.Header>
                        <Table.Row style={{fontSize: '12px'}}>
                            <Table.HeaderCell
                                sorted={column === 'name' ? direction : null}
                                onClick={() => this.handleSort('name')}
                                className="textSort">
                                {t("customer.name")}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'tel' ? direction : null}
                                onClick={() => this.handleSort('tel')}
                                className="numberSort">
                                {t("customer.tel")}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'fax' ? direction : null}
                                onClick={() => this.handleSort('fax')}
                                className="numberSort">
                                {t("customer.fax")}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'email' ? direction : null}
                                onClick={() => this.handleSort('email')}
                                className="textSort">

                                {t("customer.email")}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'address1' ? direction : null}
                                onClick={() => this.handleSort('address1')}
                                className="textSort">
                                {t("customer.address1")}
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === 'address2' ? direction : null}
                                onClick={() => this.handleSort('address2')}
                                className="numberSort">
                                {t("customer.address2")}
                            </Table.HeaderCell>

                            {!readOnly && <Table.HeaderCell></Table.HeaderCell>}

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {tableData.map(c => (
                            <Table.Row key={c.id} style={{fontSize: '12px'}}>
                                <Table.Cell>
                                    {c.name}
                                </Table.Cell>

                                <Table.Cell>
                                    {c.tel}
                                </Table.Cell>

                                <Table.Cell>
                                    {c.fax}
                                </Table.Cell>

                                <Table.Cell>
                                    {c.email}
                                </Table.Cell>

                                <Table.Cell>
                                    {c.address1}
                                </Table.Cell>

                                <Table.Cell>
                                    {c.address2}
                                </Table.Cell>

                                {!readOnly &&
                                <Table.Cell>
                                    <div className="buttonLine" style={{justifyContent: 'center'}}>
                                        <React.Fragment>
                                            {!isCustomer &&
                                            <LabelledButton
                                                label={t("customer.construction_sites.new.buttontitle")}
                                                icon="edit outline"
                                                onClick={() => history.push(`/auftragsdispo/customers/${c.id}/construction-sites`)}
                                            />
                                            }

                                            <LabelledButton
                                                label={t("customer.actions.edit")}
                                                icon="edit outline"
                                                onClick={() => history.push(`/auftragsdispo/customers/${c.id}`)}
                                            />

                                            {!isCustomer &&
                                            <RemoveCustomerMessage id={c.id}/>
                                            }
                                        </React.Fragment>
                                    </div>
                                </Table.Cell>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

const Customers_routerWrapper = withRouter(Customers);
const Customers_i18nWrapper = withNamespaces('translation')(Customers_routerWrapper);

function mapStateToProps(state, props) {
    const permissions = state.permissions;
    const history = props.history;
    const user = state.user;
    let customers = state.customers;
    let isCustomer = false;
    !!user.permissions && user.permissions.forEach(permission => {
          if(permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
          }
        });

    if (isCustomer) {
        customers = customers.filter(c => c.user_id == user.id)
    }

    const tableData = customers.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return {
        readOnly: permissions.filter(p => p.perm_type === 'MENU_CUSTOMERS' && p.perm_desc === 'W').length < 1,
        customers,
        history,
        isCustomer,
        tableData
    };
}

export default connect(mapStateToProps, {})(Customers_i18nWrapper);