import { ADD_ORDER, EDIT_ORDER, EDIT_MULTIPLE_ORDERS, REMOVE_ORDER, LOAD_ORDERS, ADD_MULTIPLE_ORDERS, REMOVE_MULTIPLE_ORDERS } from '../actions/orders';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_ORDER:
      return [...state, action.data];
    case EDIT_ORDER:
      return state.map(m => {
        if (m.id == action.data.id) {
          let edited = {
            ...m,
            ...action.data
          };

          edited.materials = action.data.materials;

          return edited;
        } else {
          return m;
        }
      });
    case EDIT_MULTIPLE_ORDERS:
      let allOrders = state;
      let editedOrders = action.data;

      for (let i = 0; i < editedOrders.length; i++) {
        const editedOrder = editedOrders[i];
    
        allOrders.map(o => {
          if (o.id == editedOrder.id)
            return editedOrder;
          else
            return o;
        });
      }

      return allOrders;
    case ADD_MULTIPLE_ORDERS: 
      return [...state, ...action.data];
    case REMOVE_ORDER:
      return state.filter(({ id }) => id !== action.id);
    case REMOVE_MULTIPLE_ORDERS:
      return state.filter(({ id }) => action.data.indexOf(id) === -1);
    case LOAD_ORDERS:
      return action.data;
  }
}