import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import {
  Form,
  Table,
  TextArea,
  Dropdown
} from "semantic-ui-react";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { orderStatus } from '../../../actions/orders';
import alphanumerical from "../../../helpers/utils";

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    marginTop: '0px'
  }
};

class Material extends React.Component {

  state = {
    amount: 0,
    start: undefined,
    end: undefined,
    id: 0,
    materialName: '',
    tonPerHour: 0,
    obs: ''
  }

  constructor() {
    super();
    this.textInput = React.createRef();
  }

  componentDidMount() {

    let material = this.props.materials.filter(m => m.id == this.props.data.material_id)[0];
    let amount, start, end, duration, tonnagePerHour;

    if (this.props.data.order.materials) {
      amount = this.props.data.order.materials.amount;
      start = this.props.data.order.materials.start;
      end = this.props.data.order.materials.end;

      duration = moment(end).diff(moment(start), "hours", true);
  
      tonnagePerHour = amount / duration;
      if (isNaN(tonnagePerHour) || !tonnagePerHour)
        tonnagePerHour = 0;
    }
    
    this.setState({
      amount: this.props.data.amount,
      start: moment(this.props.data.start),
      end: moment(this.props.data.end),
      material_id: this.props.data.material_id,
      id: this.props.data.id,
      materialName: material.name,
      obs: !!this.props.data.obs ? this.props.data.obs : "",
      tonPerHour: tonnagePerHour || 0,
      order: !!this.props.data.order ? this.props.data.order : {},
      status: this.props.data.status,
      mixingPlant: this.props.data.mixingPlant,
      group_id: this.props.data.group_id
    }, _ => {
      this.updateTonsPerHour(this.state)
    });
  }

  // static getDerivedStateFromProps(nextProps, prevState) { 
  //   if(
  //     nextProps.id !== prevState.id || 
  //     (prevState.end && prevState.end.valueOf() !== nextProps.data.end)
  //   ) {
  //   // if(nextProps.id !== prevState.id) {
  //     let material = nextProps.materials.filter(m => m.id == nextProps.data.material_id)[0];

  //     return {
  //       amount: nextProps.data.amount,
  //       start: moment(nextProps.data.start),
  //       end: moment(nextProps.data.end),
  //       material_id: nextProps.data.material_id,
  //       id: nextProps.data.id,
  //       materialName: material.name,
  //       tonPerHour: nextProps.data.tonPerHour,
  //       obs: nextProps.data.obs,
  //       order: !!nextProps.data.order ? nextProps.data.order : {},
  //       status: nextProps.data.status,
  //       mixingPlant: nextProps.data.mixingPlant,
  //       group_id: nextProps.data.group_id
  //     };
  //   } 

  //   return null;
  // }

  handleInputChange = (field, e) => {
    let state = JSON.parse(JSON.stringify(this.state));
    state[field] = e.target.value;
    state['start'] = moment(state.start);
    state['end'] = moment(state.end);

    if(field === 'amount') {
      state[field] = Math.abs(parseFloat(e.target.value));
    }

    this.setState(state);

    if(field !== 'obs') {
      const promiseAction = new Promise((resolve, reject) => {
        this.updateTonsPerHour(state);
        resolve();
      });

      promiseAction.then(_ => {
        const state = JSON.parse(JSON.stringify(this.state));
        this.update(state);
      });
    } else {
      this.update(state);
    }
   
  };

  updateTonsPerHour = state => {
    let {amount, start, end} = state;
    const diff = moment(end).diff(moment(start), 'hours', true);

    if(isNaN(amount) || amount === "") 
      amount = 0;

    let tonPerHour = Math.round(parseFloat(parseInt(amount)/diff));

    if(parseFloat(parseInt(amount)/diff) < 0) tonPerHour = 0;

    this.setState({tonPerHour});
  }

  onChangeStart = value => {
    let state = JSON.parse(JSON.stringify(this.state));
    state['start'] = moment(value);
    state['end'] = moment(state.end);

    this.setState(state);
    const promiseAction = new Promise((resolve, reject) => {
      this.updateTonsPerHour(state);
      resolve();
    });

    promiseAction.then(_ => {
      const state = JSON.parse(JSON.stringify(this.state));
      this.update(state);
    });
  }

  onChangeEnd = async value => {
    let state = JSON.parse(JSON.stringify(this.state));
    state['end'] = moment(value);
    state['start'] = moment(state.start);

    this.setState(state);
    const promiseAction = new Promise((resolve, reject) => {
      this.updateTonsPerHour(state);
      resolve();
    });

    promiseAction.then(_ => {
      const state = JSON.parse(JSON.stringify(this.state));
      this.update(state);
      this.updateTonsPerHour(state);
    });

  }

  update = data => {
    delete data.materialName;
    this.props.handleUpdateMaterialByDay(data);
  }
  
  handleRef = el => {
    this.inputAmount = el;
    if(el) el.focus();
  }

  handleUpdateTonsPerHour = e => {
    let state = JSON.parse(JSON.stringify(this.state));
    let value = parseInt(e.target.value);
      
    if (isNaN(value) || value < 1)
      value = 1; 

    let {amount, start} = state;
    let tonPerHour = value;

    // if(tonPerHour === "" || parseInt(tonPerHour) === 1) {
    //   const promiseAction = new Promise((resolve, reject) => {
    //     this.setState({tonPerHour});
    //     resolve();
    //   });
  
    //   promiseAction.then(_ => {
    //     const state = JSON.parse(JSON.stringify(this.state));
    //     this.update(state);
    //   });

    //   return;
    // }

    let diff = amount / tonPerHour;

    state["end"] = moment(start).add(diff, 'hours');
    state["start"] = moment(state.start);
    state["tonPerHour"] = tonPerHour;

    const promiseAction = new Promise((resolve, reject) => {
      this.setState(state);
      resolve();
    });

    promiseAction.then(_ => {
      const state = JSON.parse(JSON.stringify(this.state));
      this.update(state);
    });
  }

  handleChooseStatus = (event, data) => { 
    this.setState({
      status: data.value
    }, _ => {
      const state = JSON.parse(JSON.stringify(this.state));
      this.update(state);
    });
  }

  render() {

    const { t } = this.props;
    const { materialName } = this.state;

    let orderStatusDropdown = orderStatus();

    return (
      <Table.Row style={{fontSize: '12px'}}>
        <Table.Cell className="MaterialInfo">{materialName}</Table.Cell>
        <Table.Cell className="MaterialInfo">
          <div className="field">
            <div className="field">
              <div className="ui fluid input">
                <input 
                  placeholder={t("order.neworder.materials.amount")} 
                  type="number" 
                  min="0"
                  value={this.state.amount}
                  autoFocus 
                  onChange={(e) => this.handleInputChange('amount', e)}
                  ref={this.handleRef}
                />
              </div>
            </div>
          </div>
        </Table.Cell>
        <Table.Cell className="MaterialInfo">
          <Form.Field style={inlineStyle.field}>
            <TimePicker
              focusOnOpen={true}
              style={{ width: 100 }}
              showSecond={false}
              value={moment(this.state.start)}
              onChange={this.onChangeStart}
              allowEmpty={false}
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell className="MaterialInfo">
          <Form.Field style={inlineStyle.field}>
            <TimePicker
              focusOnOpen={true}
              style={{ width: 100 }}
              showSecond={false}
              value={moment(this.state.end)}
              onChange={this.onChangeEnd}
              allowEmpty={false}
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell className="MaterialInfo">
          <Form.Field style={inlineStyle.field}>
            <div className="field">
              <div className="field">
                <div className="ui fluid input">
                  <input 
                    type="number"
                    min="5"
                    placeholder={t("order.neworder.materials.tonsperhour")} 
                    value={this.state.tonPerHour} 
                    onChange={this.handleUpdateTonsPerHour}
                  />
                </div>
              </div>
            </div>
          </Form.Field>
        </Table.Cell>
        <Table.Cell className="MaterialInfo">
          <Dropdown 
            placeholder={t('order.status.placeholder')}
            fluid 
            search 
            selection 
            options={orderStatusDropdown.sort(alphanumerical)} 
            onChange={this.handleChooseStatus}
            value={this.state.status}
          />
        </Table.Cell>
        <Table.Cell className="MaterialInfo">
          <Form>
            <TextArea
              placeholder={t("order.neworder.materials.obs")} 
              value={this.state.obs} 
              onChange={(e) => this.handleInputChange('obs', e)}
            />
          </Form>
        </Table.Cell>
      </Table.Row>
    );
  }
}

function mapStateToProps(state, props) {
  return { materials: state.materials };
}

export default withNamespaces('translation')(connect(mapStateToProps, {})(Material));
