import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { save } from '../../actions/authentication';
import { Modal, Icon, Button } from "semantic-ui-react";

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class RemoveOrderMessage extends React.Component {

  state = {
    open: false
  };

  handleModal = open => {
    let state = {
      open: open
    };

    this.setState(state);
  };

  handlePositive = async (state) => {
    const { authenticated, save } = this.props;
    
    await this.handleModal(false);
    await this.props.closeModal(false);
    await this.props.removeOrder(this.props.id);

    if (authenticated)
      await save();
  }

  render() {

    const { t } = this.props;
    const { open } = this.state;
    
    return(
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
        trigger={
          <Button 
            color="red"
            onClick={() => this.handleModal(true)}
          >
            <Icon name="trash" />
            {t("order.actions.delete")}
          </Button>
        }
      >
        <Modal.Header>{t("order.remove.header")}</Modal.Header>
        <Modal.Content>
          {t("order.remove.message")}
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const RemoveOrderMessage_i18nWrapper = withNamespaces('translation')(RemoveOrderMessage);

function mapStateToProps(state) {
  const authenticated = state.auth.authenticated;
  return {
    authenticated
  };
}

export default connect(mapStateToProps, {save})(RemoveOrderMessage_i18nWrapper);