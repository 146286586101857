import React from 'react';
import Leaflet from 'leaflet'
import { Map, TileLayer, Marker } from 'react-leaflet';
import { withNamespaces } from 'react-i18next';
import MapSearch from './MapSearch';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/'

class MapLocal extends React.Component {
  
  state = {
    center: {
      lat: 52.520008,
      lng: 13.404954,
    },
    marker: {
      lat: 52.520008,
      lng: 13.404954,
    },
    zoom: 13,
    draggable: true
  }

  componentDidMount() {
    if(!this.props.position) return null;

    let position = this.props.position.split(',');

    if(position) {
      this.setState({
        marker: {
          lat: position[0],
          lng: position[1]
        },
        center: {
          lat: position[0],
          lng: position[1]
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!this.props.position) return null;

    if(prevProps.position !== this.props.position) {
      let position = this.props.position.split(',');

      if(position) {
        this.setState({
          marker: {
            lat: position[0],
            lng: position[1]
          },
          center: {
            lat: position[0],
            lng: position[1]
          }
        });
      }
    }
  }

  handleClick = e => {
    let {marker} = this.state;

    marker.lat = e.latlng.lat;
    marker.lng = e.latlng.lng;


    this.setState({
      marker,
      center: marker
    });
    this.props.updatePosition(marker);
  }

  handleDragend = e => {
    let {marker} = this.state;

    marker.lat = e.target._latlng.lat;
    marker.lng = e.target._latlng.lng;
    
    this.setState({
      marker,
      center: marker
    });
    this.props.updatePosition(marker);
  }

  handleZoom = e => this.setState({zoom: e.target._zoom})

  render() {
    const { t } = this.props;

    const position = [this.state.center.lat, this.state.center.lng]
    const markerPosition = [this.state.marker.lat, this.state.marker.lng]
    
    return (
      <div style={{position: 'relative'}}>
        <MapSearch updatePosition={this.props.updatePosition} />
        <Map 
          center={position} 
          zoom={this.state.zoom} 
          style={{height: '400px', zIndex: 1}} 
          onDblClick={this.handleClick} 
          doubleClickZoom={false}
          onzoomend={this.handleZoom}
        >
        <div className="mapLegend">
          <h5>{t("general.hint")}:</h5>
          - {t("general.map_hint_dblclick")}
        </div>
        <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">Here Maps</a> contributors'
            url="https://bpo-asphalt.de/common/interfaces/tile.php?z={z}&x={x}&y={y}&key=auftrafsdispo&type=truck"
          />

          <Marker
            draggable={true}
            onDragend={this.handleDragend}
            position={markerPosition}
          />
        </Map>
      </div>

    )
  }
}

export default withNamespaces('translation')(MapLocal);