import { ADD_MIXING_PLANT, EDIT_MIXING_PLANT, REMOVE_MIXING_PLANT, LOAD_MIXING_PLANT } from '../actions/mixingPlants';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_MIXING_PLANT:
      return [...state, action.data];
    case EDIT_MIXING_PLANT:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
    case REMOVE_MIXING_PLANT:
      return state.filter(({ id }) => id !== action.id);
    case LOAD_MIXING_PLANT:
      return action.data;
  }
}