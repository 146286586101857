import moment from 'moment';

export const UPDATE_SETTING = 'settings/UPDATE_SETTING';

export function updateSetting(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: UPDATE_SETTING,
      data
    });
  }
}
