import React from 'react';
import {NavLink} from "react-router-dom";
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {withRouter} from "react-router";
import {CUSTOMER_PERMISSION, ADMIN_PERMISSION} from '../../constants/constants';

class Navigation extends React.Component {

    render() {

        let {t, permissions, authenticated, fullscreen, user} = this.props;

        let isCustomer = false;
        !!user.permissions && user.permissions.forEach(permission => {
          if(permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
          }
        });

        let links = [
            {to: "/auftragsdispo/", name: isCustomer ? t('menu.item5') : t('menu.item4'), icon: ""}
        ];

        let canSeeMasterdata = permissions.filter(p => p.perm_type === 'MASTERDATA');

        if (canSeeMasterdata.length > 0 || isCustomer) {
            links.push({to: "/auftragsdispo/base-data", name: t('menu.item1'), icon: ""});
        }

        if (!!user.permissions) { 
            user.permissions.forEach(permission => {
                if(permission.id === ADMIN_PERMISSION.id) {
                    links.push({to: '/auftragsdispo/rolesmanagement', name: t('menu.userManaging'), icon: ''});
                }
            });
        }

        if (!authenticated) links = [];

        let navigationClasses = ['navigation'];
        if (fullscreen) navigationClasses.push('fullscreen')

        let els = [];
        for (let i = 0; i < links.length; i++) {
            els.push(
                <NavLink
                    style={{position: 'relative'}}
                    key={i}
                    // activeClassName={window.location.pathname !== links[i].to ? null : "highlighted"}
                    activeClassName="highlighted"
                    className="linker"
                    to={links[i].to}
                    exact={true}
                >
                    {links[i].name}
                </NavLink>
            );
        }

        if (fullscreen) {
            els = [];
        }

        return (
            <div className={navigationClasses.join(' ')}>
                {els}
            </div>
        )
    }
}

const Navigation_i18nWrapper = withNamespaces('translation')(Navigation);

function mapStateToProps(state, props) {
    const user = state.user;

    return {
        permissions: state.permissions,
        authenticated: state.auth.authenticated,
        fullscreen: state.app.full_screen,
        user
    };
}

export default withRouter(connect(mapStateToProps, {})(Navigation_i18nWrapper));