import { 
  UPDATE_SETTING
} from '../actions/settings';

const defaultState = []

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case UPDATE_SETTING:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
  }
}