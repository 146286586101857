import axios from 'axios';
import { store } from '../store';
import uuid from 'uuid/v4';
import * as material from 'material-colors'
import { setLoadingState } from './app';
import { CLEAN_DATA, LOAD_DATA } from '../reducers';

export const AUTHENTICATED = 'authenticated_user';
export const UNAUTHENTICATED = 'unauthenticated_user';
export const AUTHENTICATION_ERROR = 'authentication_error';
export const INTENDED_ACTION_AFTER_ERROR = "intended_action_after_error"
export const USER_LOCALSTORAGE_TOKEN = 'ymwn3QYan4ahUUJXb5MQ';

const URL = process.env.REACT_APP_SERVER_URL;

const colors = [
  material.purple['200'],
  material.purple['300'],
  material.purple['400'],
  material.purple['500'],
  material.purple['600'],
  material.deepPurple['200'],
  material.deepPurple['300'],
  material.deepPurple['400'],
  material.deepPurple['500'],
  material.deepPurple['600'],
  material.blue['200'],
  material.blue['300'],
  material.blue['400'],
  material.blue['500'],
  material.blue['600'],
  material.indigo['200'],
  material.indigo['300'],
  material.indigo['400'],
  material.indigo['500'],
  material.indigo['600'],
  material.lightBlue['200'],
  material.lightBlue['300'],
  material.lightBlue['400'],
  material.lightBlue['500'],
  material.lightBlue['600'],
  material.cyan['200'],
  material.cyan['300'],
  material.cyan['400'],
  material.cyan['500'],
  material.cyan['600'],
  material.teal['200'],
  material.teal['300'],
  material.teal['400'],
  material.teal['500'],
  material.teal['600'],
  material.lightGreen['200'],
  material.lightGreen['300'],
  material.lightGreen['400'],
  material.lightGreen['500'],
  material.lightGreen['600'],
  material.green['200'],
  material.green['300'],
  material.green['400'],
  material.green['500'],
  material.green['600'],
  material.lime['200'],
  material.lime['300'],
  material.lime['400'],
  material.lime['500'],
  material.lime['600'],
  material.yellow['200'],
  material.yellow['300'],
  material.yellow['400'],
  material.yellow['500'],
  material.yellow['600'], 
  material.amber['200'],
  material.amber['300'],
  material.amber['400'],
  material.amber['500'],
  material.amber['600'],
  material.orange['200'],
  material.orange['300'],
  material.orange['400'],
  material.orange['500'],
  material.orange['600'],
  material.deepOrange['200'],
  material.deepOrange['300'],
  material.deepOrange['400'],
  material.deepOrange['500'],
  material.deepOrange['600'],
  material.brown['200'],
  material.brown['300'],
  material.brown['400'],
  material.brown['500'],
  material.brown['600'],
  material.blueGrey['200'],
  material.blueGrey['300'],
  material.blueGrey['400'],
  material.blueGrey['500'],
  material.blueGrey['600']
];

export function signInAction({ username, password }, history) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const res = await axios.post(`${URL}/login`, formData);

      // await dispatch({ type: AUTHENTICATED });
      await loadData(res.data);
      await localStorage.setItem(USER_LOCALSTORAGE_TOKEN, res.data.token);

      history.push('/auftragsdispo/');
    } catch(error) {
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: true
      });
    }
  };
}

export async function isTokenValid(token) {
  const state = store.getState();

  try {
    const formData = new FormData();
    formData.append('userid', state.user.id);
    formData.append('token', token);

    const res = await axios.post(`${URL}/load`, formData);
    await loadData(res.data);    

    if (state.auth[AUTHENTICATION_ERROR] === true) {
      store.dispatch({
        type: AUTHENTICATION_ERROR,
        payload: false
      });
    }

  } catch(error) {
    store.dispatch({
      type: AUTHENTICATION_ERROR,
      payload: true
    });
    
    store.dispatch({
      type: INTENDED_ACTION_AFTER_ERROR,
      payload: "load"
    });
  }
}

export function logOut() {
  return dispatch => {
    localStorage.removeItem(USER_LOCALSTORAGE_TOKEN);
    dispatch({ type: UNAUTHENTICATED });
    dispatch({ type: CLEAN_DATA });
  }
}

export function save() {
  return async (dispatch) => {
    const state = store.getState();
    const token = getLoggedUser();

    try {
      const formData = new FormData();

      const data = {
        mixingPlants: [],
        materials: [],
        orders: [],
        basedata: [],
        constructionSites: [],
        customers: [],
        permissions: [],
        silos: [],
        productionTakt: [],
        deleted: state.deleted,
        settings: state.settings,
      }

      state.mixingPlants.forEach( mixingplant => {
        if(!!mixingplant.edited) data.mixingPlants.push(mixingplant);
      });

      state.materials.forEach( material => {
        if(!!material.edited) data.materials.push(material);
      });

      state.orders.forEach( order => {
        if(!!order.edited) data.orders.push(order);
      });

        if(!!state.basedata.edited) data.basedata = state.basedata;

      state.constructionSites.forEach( constructionSite => {
        if(!!constructionSite.edited) data.constructionSites.push(constructionSite);
      });

      state.customers.forEach( customer => {
        if(!!customer.edited) data.customers.push(customer);
      });

      state.permissions.forEach( permission => {
        if(!!permission.edited) data.permissions.push(permission);
      });

      state.silos.forEach( silo => {
        if(!!silo.edited) data.silos.push(silo);
      });

      state.productionTakt.forEach( proTakt => {
        if(!!proTakt.edited) data.productionTakt.push(proTakt);
      });


      formData.append('token', token);
      formData.append('userid', state.user.id);
      formData.append('data', JSON.stringify(data));

      await setLoadingState(true);
      await axios.post(`${URL}/save`, formData);
      setLoadingState(false);
      await isTokenValid(getUserToken());
      if (state.auth[AUTHENTICATION_ERROR] === true) {
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: false
        });
      }
    } catch(error) {
      setLoadingState(false);

      if (error.response.status === 401) {
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: true
        });

        dispatch({
          type: INTENDED_ACTION_AFTER_ERROR,
          payload: "save"
        });
      }
    }
  }
}

export function getLoggedUser() {
  return localStorage.getItem(USER_LOCALSTORAGE_TOKEN);
}

export async function loadData(data) {
  data.basedata["companyName"] = data.basedata.name;

  delete data.basedata.name;
  delete data.basedata.id;

  let constructionSites = [];
  data.constructionSites.forEach(lcs => {
    constructionSites.push({
      customer_id: lcs.customer_id,
      created_at: lcs.created_at,
      id: lcs.id,
      name: lcs.name,
      position: lcs.position,
      updated_at: lcs.updated_at,
      contractNumber: lcs.contract_number
    });
  });

  let orders = [];
  data.orders.forEach(lo => {
    orders.push({
      mixingPlantName: lo.mixingPlantName,
      constructionSite: lo.constructionSite,
      mixingPlant: lo.mixingPlant,
      orderGroup: lo.order_group,
      id: lo.id,
      created_at: lo.created_at,
      updated_at: lo.updated_at,
      created_by: lo.created_by,
      materials: {
        id: lo.materials_id,
        material_id: lo.materials_id,
        amount: parseFloat(lo.materials.amount),
        start: parseInt(lo.materials.start),
        end: parseInt(lo.materials.end),
        obs: lo.materials.obs,
        status: lo.materials.status,
      },
      waypoints: JSON.parse(lo.waypoints),
      cycle_data: JSON.parse(lo.cycle_data)
    });
  });

  data.mixingPlants = data.mixingPlants.map(m => {
    m.tonday = parseFloat(m.tonday);
    m.standard = parseFloat(m.standard);
    m.maxmix = parseFloat(m.maxmix);

    return m;
  });

  data.mixingPlants.sort((a, b) => {
    const aSortName = a.name.replace(/\s/g, "").toUpperCase();
    const bSortName = b.name.replace(/\s/g, "").toUpperCase();

    if (aSortName < bSortName) return -1;
    if (aSortName > bSortName) return 1;
    return 0
  });

  data.silos = data.silos.map(s => {
    s.max = parseFloat(s.max);
    s.actual = parseFloat(s.actual);

    return s;
  });

  data.materials = data.materials.map((m, index) => {
    if(!m.color) m.color = colors[index % colors.length];
    return m;
  });

  data.materials.sort((a, b) => {
    const aSortName = a.name.replace(/\s/g, "").toUpperCase();
    const bSortName = b.name.replace(/\s/g, "").toUpperCase();

    if (aSortName < bSortName) return -1;
    if (aSortName > bSortName) return 1;
    return 0
  });

  data.silos = data.silos.map((s, index) => {
    if(!s.color) s.color = colors[index % colors.length];
    return s;
  });  

  let state = {
    basedata: data.basedata,
    mixingPlants: data.mixingPlants,
    materials: data.materials,
    customers: data.customers,
    constructionSites: constructionSites,
    orders: orders,
    user: data.user,
    silos: data.silos,
    productionTakt: data.productionTakt,
    permissions: data.permission,
    auth: {
      authenticated: true
    },
    settings: data.settings
  }

  await store.dispatch({type: LOAD_DATA, state});


  // await store.dispatch({type: LOAD_BASEDATA, data: data.basedata});
  // await store.dispatch({type: LOAD_MIXING_PLANT, data: data.mixingPlants});
  // await store.dispatch({type: LOAD_MATERIALS, data: data.materials});
  // await store.dispatch({type: LOAD_CUSTOMERS, data: data.customers});
  // await store.dispatch({type: LOAD_CONSTRUCTION_SITES, data: constructionSites});
  // await store.dispatch({type: LOAD_ORDERS, data: orders});
  // await store.dispatch({type: LOAD_PERMISSIONS, data: data.permission});
  // await store.dispatch({type: LOAD_USER, data: data.user});
  // await store.dispatch({type: LOAD_SILOS, data: data.silos});
  // await store.dispatch({type: LOAD_PRODUCTION_TAKT, data: data.productionTakt});
}

export function getUserToken() {
  return localStorage.getItem(USER_LOCALSTORAGE_TOKEN);
}
