import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Icon,
  Dropdown
} from "semantic-ui-react";
import alphanumerical from "../../../helpers/utils"

class UpdateGroup extends React.Component {
  state = {}

  componentWillMount() {
    this.setState({
      group: this.props.group.id,
      old_group: this.props.group.id,
      updating: false,
      mixingPlantId: this.props.group.id.split('_')[1]
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.group !== this.props.group.id && prevState.updating !== this.state.updating) {
      this.setState({
        group: this.props.group.id,
        old_group: this.props.group.id,
        updating: false,
        mixingPlantId: this.props.group.id.split('_')[1]
      });
    }
  }

  update = _ => {
    const {group, old_group} = this.state;

    const alreadyAddedGroup = this.props.groups.some(g => g.id == group);

    if(alreadyAddedGroup) { 
      this.setUpdateOrderMaterial();
      return;
    }

    this.props.updateMaterial(group, old_group);
    this.setUpdateOrderMaterial();
  }

  remove = _ => {
    const {group} = this.state;

    this.props.removeMaterial(group);
  }

  handleChooseUpdateOrderGroup = (e, data) => { 
    const {mixingPlantId} = this.state;

    const id = `${data.value}_${mixingPlantId}`;
    
    this.setState({
      group: id
    }, this.update);
  }

  setUpdateOrderMaterial = _ => {
    this.setState(prevState => ({
      updating: !prevState.updating
    }));

    this.props.closeMaterialsByDay()
  }

  render () {    
    const {group, t, materials} = this.props;

    const materialsDropdown = materials.map(m => ({
      key: m.id,
      value: m.id,
      text: m.name
    }));

    return this.state.updating 
      ? (
        <React.Fragment>
          <div style={{flexGrow: '1'}}>
            <div style={{position: 'fixed', minWidth: '145px'}}>
              <Dropdown 
                placeholder={t('material.newmaterial.buttontitle')}
                fluid 
                search 
                selection 
                options={materialsDropdown.sort(alphanumerical)} 
                value={parseInt(this.state.group.split('_')[0])}
                onChange={this.handleChooseUpdateOrderGroup}
              />
            </div>
          </div>
          <div className='div-button' style={{cursor: 'pointer'}} onClick={this.setUpdateOrderMaterial}><Icon name='close'/></div>
        </React.Fragment>
      )
      :(
        <React.Fragment>
          <div style={{flexGrow: '1'}}>{group.title}</div>
          <div className='div-button' style={{cursor: 'pointer'}} onClick={this.setUpdateOrderMaterial}><Icon name='edit' /></div>
          <div className='div-button' style={{cursor: 'pointer'}} onClick={this.remove}><Icon name='close'/></div>
        </React.Fragment>
      );
  }
}

const UpdateGroup_i18nWrapper = withNamespaces('translation')(UpdateGroup);

function mapStateToProps(state) {
  const materials = state.materials;
  
  return {
    materials
  };
}

export default connect(mapStateToProps, {})(UpdateGroup_i18nWrapper);