import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Icon, Button, Form, Segment, Header, Message} from "semantic-ui-react";
import {editConstructionSite} from '../../actions/constructionSites';
import {save} from '../../actions/authentication';
import {editMultipleOrders} from '../../actions/orders';
import MapLocal from '../MapLocal/MapLocal';
import Validate from "react-validate-form";
import {validationRules} from "../../validationRules";
import {CUSTOMER_PERMISSION} from '../../constants/constants';


const inlineStyle = {
    field: {
        marginTop: '10px'
    }
};

class ConstructionSite extends React.Component {

    state = {
        id: '',
        name: '',
        position: '',
        contract_number: ''
    };

    componentDidMount() {
        this.setState({
            id: this.props.constructionSite.id,
            name: this.props.constructionSite.name,
            position: this.props.constructionSite.position ? this.props.constructionSite.position : "",
            contract_number: this.props.constructionSite.contract_number ? this.props.constructionSite.contract_number : "",
        });
    }

    handleInputChange = (field, e) => {
        let state = this.state;
        state[field] = e.target.value;
        state['edited'] = true;

        this.setState(state);
    };

    handlePositive = async (state) => {
        const {orders, authenticated, save} = this.props;
        const {id: csId, position: csPos} = this.state;

        // Get all orders linked to the selected construction site
        const csOrders = orders.filter(o => {
            return o.constructionSite === csId;
        });

        try {
            // Change the construction site position for each order
            const editedOrders = csOrders.map(o => {
                const currentWaypoints = o.waypoints;
                let newTo, newBack;
                if (!!currentWaypoints) {
                    // Keep all waypoints except the first one, which is the constructionsite position
                    currentWaypoints.to.pop();
                    currentWaypoints.back.shift();

                    newTo = [
                        ...currentWaypoints.to, csPos
                    ];

                    newBack = [
                        csPos, ...currentWaypoints.back
                    ];
                } else {
                    newTo = [csPos];
                    newBack = [csPos];
                }

                const updatedWaypoints = {
                    to: newTo,
                    back: newBack
                }

                o.waypoints = updatedWaypoints;
                return o;
            });

            // Update all affected orders
            await this.props.editMultipleOrders(editedOrders);
        } catch (error) {
            console.log("Could not update order positions: ", error);
        }

        let data = JSON.parse(JSON.stringify(state));

        await this.props.editConstructionSite(data);

        if (authenticated)
            await save();

        if (this.props.isCustomer) {
            this.props.history.push(`/auftragsdispo/`);
            return;
        }

        this.props.history.push(`/auftragsdispo/customers/${this.props.customer_id}/construction-sites`);
    }

    handleNegative = _ => {

        if (this.props.isCustomer) {
            this.props.history.push(`/auftragsdispo/`);
            return;
        }

        this.props.history.push(`/auftragsdispo/customers/${this.props.customer_id}/construction-sites`);
    }

    updatePosition = marker => {
        this.setState({
            position: `${marker.lat},${marker.lng}`
        });
    }

    render() {
        const {t} = this.props;
        const {position} = this.state;

        const validations = {
            name: ["nameRequired"]
        };

        return (
            <div className='page'>
                <Validate validations={validations} rules={validationRules}>
                    {({validate, errorMessages, allValid, errorCount}) => (
                        <Segment raised>
                            <Header as="h2" floated="left">
                                <Header.Content>{t("construction_sites.edit.header")}</Header.Content>
                            </Header>

                            <div style={{textAlign: 'right'}}>
                                <Button.Group>
                                    <Button
                                        type="button"
                                        onClick={() => this.handleNegative()}
                                        color="grey"
                                    >
                                        <Icon name="close"/>
                                        {t("buttons.cancel")}
                                    </Button>
                                    <Button disabled={errorCount >= 1} type="button"
                                            onClick={_ => this.handlePositive(this.state)} color="green">
                                        <Icon name="checkmark"/>
                                        {t("buttons.ok")}
                                    </Button>
                                </Button.Group>
                            </div>

                            <Form.Group style={{clear: 'both'}}>
                                <Form.Input
                                    label={t("construction_sites.name")}
                                    name="name"
                                    placeholder={t("construction_sites.name")}
                                    value={this.state.name}
                                    onChange={(e) => {
                                        this.handleInputChange('name', e);
                                        validate(e);
                                    }}
                                    fluid
                                />
                                {!!errorMessages.name && errorMessages.name.length > 0 &&
                                <Message negative>
                                    <Message.Header>
                                        {t(errorMessages.name[0])}
                                    </Message.Header>
                                </Message>
                                }
                            </Form.Group>
                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("construction_sites.contract_number")}
                                    placeholder={t("construction_sites.contract_number")}
                                    value={this.state.contractNumber}
                                    onChange={(e) => this.handleInputChange('contractNumber', e)}
                                    fluid
                                />
                            </Form.Group>
                            <Form.Group style={inlineStyle.field}>
                                <label>{t("construction_sites.local")}</label>
                                <MapLocal updatePosition={this.updatePosition} position={position}/>
                            </Form.Group>
                        </Segment>
                    )}
                </Validate>
            </div>
        );
    }
}

const ConstructionSite_i18nWrapper = withNamespaces('translation')(ConstructionSite);

function mapStateToProps(state, props) {
    const constructionSites = state.constructionSites;
    const authenticated = state.auth.authenticated;
    const orders = state.orders;
    const id = 'id' in props.match.params ? props.match.params.id : undefined;
    const user = state.user;

    let isCustomer = false;
    !!user.permissions && user.permissions.forEach(permission => {
          if(permission.id === CUSTOMER_PERMISSION.id) {
            isCustomer = true;
          }
        });

    if (!id) props.history.push('/auftragsdispo/');

    const constructionSite = constructionSites.filter(m => m.id == id)[0];

    if (!constructionSite) props.history.push('/auftragsdispo/');

    return {
        history: props.history,
        constructionSite,
        customer_id: props.match.params.customer_id,
        isCustomer,
        orders,
        authenticated
    };
}

export default connect(mapStateToProps, {editConstructionSite, editMultipleOrders, save})(ConstructionSite_i18nWrapper);