import React from 'react'
import moment from 'moment'

export const ProductionTaktItem = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  resizing,
  time_end,
  time_start,
  selectedQuantity,
  selected
}) => {

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

  const itemProps = getItemProps(item.itemProps);

  itemProps.className = `rct-custom ${itemProps.className}`;

  if(!resizing && !selected) itemProps.className = `${itemProps.className} shouldHover show-time production_takt`;
  if(resizing) itemProps.className = `${itemProps.className} resizing production_takt`;
  if(selected) itemProps.className = `${itemProps.className} selected`;

  // rightResizeProps.style.minWidth = '50%';
  rightResizeProps.style.border = '1px solid rgba(0, 0, 0, 0.6)';
  leftResizeProps.style.border = '1px solid rgba(0, 0, 0, 0.6)';

  delete leftResizeProps.style["maxWidth"];
  delete leftResizeProps.style["minWidth"];
  leftResizeProps.style.left = '-42px';
  leftResizeProps.style.backgroundColor = '#e4e4e4';
  leftResizeProps.style.color = 'black';
  leftResizeProps.style.transform = 'rotate(-90deg)';
  leftResizeProps.style.width = '60px';
  leftResizeProps.style.height = '24px';
  leftResizeProps.style.top = '19px';
  leftResizeProps.style.lineHeight = '24px';
  leftResizeProps.style.textAlign = 'center';
  leftResizeProps.style.borderRadius = '2px 2px 0px 0px';
  
  delete rightResizeProps.style["maxWidth"];
  delete rightResizeProps.style["minWidth"];
  rightResizeProps.style.right = '-42px';
  rightResizeProps.style.backgroundColor = '#e4e4e4';
  rightResizeProps.style.color = 'black';
  rightResizeProps.style.transform = 'rotate(-90deg)';
  rightResizeProps.style.width = '60px';
  rightResizeProps.style.height = '24px';
  rightResizeProps.style.top = '19px';
  rightResizeProps.style.lineHeight = '24px';
  rightResizeProps.style.textAlign = 'center';
  rightResizeProps.style.borderRadius = '0px 0px 2px 2px';

  let start = undefined;
  if(time_start) {
    start = moment(time_start).format('HH:mm');
  }

  let end = undefined;
  if(time_end) {
    end = moment(time_end).format('HH:mm');
  }

  if(itemContext.resizeEdge === 'left') {
    start = moment(time_end).format('HH:mm');
    end = moment(item.end_time).format('HH:mm');
  }

  itemProps.style.borderRight = '0px';
  itemProps.style.borderLeft = '0px';
  itemProps.style.lineHeight = `${(itemContext.dimensions.height/3) - 5}px`;

  itemProps.style.transform = "scaleY(0.9)";

  itemProps.style = {
    ...itemProps.style,
    ...item.style
  }

  const titleArray = item.title.split('*-*');

  let itemContentStyle = { 
    maxHeight: `${itemContext.dimensions.height}`,
    height: `${itemContext.dimensions.height - 1}px`, 
    display: 'flex',
    marginTop: '0px',
    color: 'black',
    paddingLeft: '5px',
    border: '1px solid rgba(0, 0, 0, 0.6)',
    borderRadius: '0px',
    boxShadow: 'inset 0 0 10px #00000038',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }

  if(selected) {
    itemContentStyle.border = '2.5px solid rgb(1, 69, 148)';
    // itemContentStyle.borderRadius = '5px';
  }

  return (
    <div {...itemProps}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

      {start && <div {...leftResizeProps} className='item-time'>{start}</div>}

      <div
        className="rct-item-content"
        style={itemContentStyle}
      >
        <div style={{transform: 'scaleY(1.1)', paddingTop: '5px'}}>
          {`${titleArray[2]} - ${titleArray[1]}`} <br />
          {`${titleArray[0]}${titleArray[4]}`} <br />
          {`${titleArray[3]}`}
        </div>
      </div>

      {end && <div {...rightResizeProps} className='item-time'>{end}</div>}

      {item.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  )
}