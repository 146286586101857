import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Modal, Icon, Button, Form, Message } from "semantic-ui-react";
import LabelledButton from '../LabelledButton/LabelledButton';
import { addMixingPlant } from '../../actions/mixingPlants';
import { save } from '../../actions/authentication';
import MapLocal from '../MapLocal/MapLocal';

const inlineStyle = {
  modal: {
    marginTop: "25px !important",
    marginLeft: "auto",
    marginRight: "auto"
  },
  field: {
    marginTop: '10px'
  }
};

class AddMixingPlant extends React.Component {

  state = {
    open: false,
    name: '',
    tel: '',
    fax: '',
    email: '',
    tonday: '',
    standard: '',
    maxmix: '',
    position: '',
    nameError: false,
    tondayError: false,
    standardError: false,
    maxmixError: false 
  };

  handleInputChange = (field, e) => {
    
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state, () => {this.validateInput()});
  };

  updatePosition = marker => {
    this.setState({
      position: `${marker.lat},${marker.lng}`
    });
  }

  handleModal = open => {
    let state = {
      open: open,
      name: '',
      tel: '',
      fax: '',
      email: '',
      tonday: '',
      standard: '',
      maxmix: '',
      nameError: false,
      tondayError: false,
      standardError: false,
      maxmixError: false 
    };

    this.setState(state);
  };

  validateInput = () => {
    const { 
      name,
      tel,
      fax,
      email,
      tonday,
      standard,
      maxmix 
    } = this.state;

    const tmpState = {
      nameError: false,
      tondayError: false,
      standardError: false,
      maxmixError: false 
    };

    if (!name)
      tmpState.nameError = true;

    if (!tonday || isNaN(parseInt(tonday)) || parseInt(tonday) <= 0)
      tmpState.tondayError = true;

    if (!standard || isNaN(parseInt(standard)) || parseInt(standard) <= 0)
      tmpState.standardError = true;

    if (!maxmix || isNaN(parseInt(maxmix)) || parseInt(maxmix) <= 0)
      tmpState.maxmixError = true;

    const isValid = !Object.values(tmpState).includes(true);

    this.setState({
      ...tmpState
    });

    return isValid;
  }

  handlePositive = async (state) => {
    const isValid = this.validateInput();
    if (!isValid) 
      return;

    const { authenticated, save } = this.props;

    let data = JSON.parse(JSON.stringify(state));
    delete data.open;

    await this.props.addMixingPlant(data);
    await this.handleModal(false);

    if (authenticated)
      await save();
  }

  render() {

    const { t } = this.props;
    const { open, position } = this.state;
    const {
      nameError,
      tondayError,
      standardError,
      maxmixError
    } = this.state;
    
    return(
      <Modal
        style={inlineStyle.modal}
        open={open}
        closeIcon={false}
        closeOnDimmerClick={false}
        onClose={() => this.handleModal(false)}
        trigger={
          <Button
            content={t("mixingplant.newmixingplant.buttontitle")}
            icon="plus"
            onClick={() => this.handleModal(true)}
          />
        }
      >
        <Modal.Header>{t("mixingplant.newmixingplant.header")}</Modal.Header>
        <Modal.Content>
          <Form.Group>
            <Form.Input 
              label={t("mixingplant.name")} 
              placeholder={t("mixingplant.name")} 
              value={this.state.name}
              onChange={(e) => this.handleInputChange('name', e)}
              fluid
            />

            {nameError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.name_missing_error")}</Message.Header>
                <p></p>
              </Message> 
            }

          </Form.Group>  
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("mixingplant.tel")} 
              placeholder={t("mixingplant.tel")} 
              value={this.state.tel}
              onChange={(e) => this.handleInputChange('tel', e)}
              fluid
            />
          </Form.Group>
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("mixingplant.fax")} 
              placeholder={t("mixingplant.fax")} 
              value={this.state.fax}
              onChange={(e) => this.handleInputChange('fax', e)}
              fluid
            />
          </Form.Group>   
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              label={t("mixingplant.email")} 
              placeholder={t("mixingplant.email")} 
              value={this.state.email}
              onChange={(e) => this.handleInputChange('email', e)}
              fluid
            />
          </Form.Group>
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              min="0"
              label={t("mixingplant.tonday")} 
              placeholder={t("mixingplant.tonday")} 
              value={this.state.tonday}
              onChange={(e) => this.handleInputChange('tonday', e)}
              fluid
              type='number'
            />

            {tondayError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.capacity_error")}</Message.Header>
                <p></p>
              </Message> 
            }

          </Form.Group>
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              min="0"
              label={t("mixingplant.standard")} 
              placeholder={t("mixingplant.standard")} 
              value={this.state.standard}
              onChange={(e) => this.handleInputChange('standard', e)}
              fluid
              type='number'
            />

            {standardError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.capacity_error")}</Message.Header>
                <p></p>
              </Message> 
            }

          </Form.Group>  
          <Form.Group style={inlineStyle.field}>
            <Form.Input 
              min="0"
              label={t("mixingplant.maxmix")} 
              placeholder={t("mixingplant.maxmix")} 
              value={this.state.maxmix}
              onChange={(e) => this.handleInputChange('maxmix', e)}
              fluid
              type='number'            
            />

            {maxmixError && 
              <Message negative>
                <Message.Header>{t("general.errormessages.capacity_error")}</Message.Header>
                <p></p>
              </Message> 
            }
            
          </Form.Group>      
          <Form.Group style={inlineStyle.field}>
            <label>{t("mixingplant.position")}</label>
            <MapLocal updatePosition={this.updatePosition} position={position}/>
          </Form.Group>           
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button
              type="button"
              onClick={() => this.handleModal(false)}
              color="grey"
            >
              <Icon name="close" />
              {t("buttons.cancel")}
            </Button>
            <Button type="button" onClick={_ => this.handlePositive(this.state)} color="green">
              <Icon name="checkmark" />
              {t("buttons.ok")}
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

const AddMixingPlant_i18nWrapper = withNamespaces('translation')(AddMixingPlant);

function mapStateToProps(state) {
  const authenticated = state.auth.authenticated;
  return {
    authenticated
  };
}

export default connect(mapStateToProps, {addMixingPlant, save})(AddMixingPlant_i18nWrapper);