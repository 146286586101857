import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { DEFAULT_MIXING_PLANT_ID } from '../../../../actions/orders';
import { _get, arraysEqual } from '../utility/generic'

export default class Sidebar extends Component {
  static propTypes = {
    groups: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    groupHeights: PropTypes.array.isRequired,
    keys: PropTypes.object.isRequired,
    groupRenderer: PropTypes.func,
    isRightSidebar: PropTypes.bool,
  }

  shouldComponentUpdate(nextProps) {
    return !(
      arraysEqual(nextProps.groups, this.props.groups) &&
      nextProps.keys === this.props.keys &&
      nextProps.width === this.props.width &&
      nextProps.groupHeights === this.props.groupHeights &&
      nextProps.height === this.props.height
    )
  }

  renderGroupContent(group, isRightSidebar, groupTitleKey, groupRightTitleKey) {
    if (this.props.groupRenderer) {
      return React.createElement(this.props.groupRenderer, {
        group,
        isRightSidebar
      })
    } else {
      return _get(group, isRightSidebar ? groupRightTitleKey : groupTitleKey)
    }
  }

  onRowClickFilter(mpId, e) {
    try {
      const { onRowClickFilter } = this.props;
      e.persist();

      const isTargetButton = e.target.nodeName === "I" || e.target.className === "div-button";
  
      if (!!onRowClickFilter && !isTargetButton) 
        onRowClickFilter(mpId);
    }
    catch (error) {
      console.error("Could not filter by mixingplant: ", error);
    }
  }

  render() {
    const { width, groupHeights, height, isRightSidebar, mixingPlants } = this.props

    const { groupIdKey, groupTitleKey, groupRightTitleKey } = this.props.keys

    const sidebarStyle = {
      width: `${width}px`,
      height: `${height}px`
    }

    const groupsStyle = {
      width: `${width}px`
    }

    let groupLines = this.props.groups.map((group, index) => {
      const elementStyle = {
        height: `${groupHeights[index] - 1}px`,
        lineHeight: `${groupHeights[index] - 1}px`
      }

      let isEven = index % 2 === 0;

      if(group.title === ' ') { 
        elementStyle.borderBottom = '0px';
        isEven = !isEven
      }

      let isEvenClassName = isEven ? 'rct-sidebar-row-even' : 'rct-sidebar-row-odd';

      let isFilterable = false;
      const mixingplantIds = mixingPlants.map(mp => mp.id);

      if (group.id !== DEFAULT_MIXING_PLANT_ID && mixingplantIds.includes(group.id)) {
        isFilterable = true;
      }

      return (
        <div
          onClick={isFilterable ? (e) => this.onRowClickFilter(group.id, e) : () => {}}
          key={_get(group, groupIdKey)}
          className={
            'rct-sidebar-row ' + isEvenClassName
          }
          style={elementStyle}
        >
          {this.renderGroupContent(
            group,
            isRightSidebar,
            groupTitleKey,
            groupRightTitleKey
          )}
        </div>
      )
    })

    return (
      <div
        className={'rct-sidebar' + (isRightSidebar ? ' rct-sidebar-right' : '')}
        style={sidebarStyle}
      >
        <div style={groupsStyle}>{groupLines}</div>
      </div>
    )
  }
}
