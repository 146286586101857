import React, { Component } from "react";
import moment from "moment";

import { iterateTimes } from "../utility/calendar";
import { _get, deepObjectCompare } from "../utility/generic";

import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { 
        getOrders, 
        getSiloCapacityData, 
        getCapacityAtTime,
        capSiloCapacityData 
} from "../../../../helpers/silo-capacity-helper";
import { setSiloCapacityData } from "../../../../actions/app";
import { getParentPosition } from "../utility/dom-helpers";
import i18n from "../../../../i18n";

export default class TimelineSiloCapacityLineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touchTarget: null,
      touchActive: false
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // return nextProps.visibleTimeStart !== this.props.visibleTimeStart || !deepObjectCompare(nextProps.productionTakt, this.props.productionTakt);
    return true;
  }

  handleHeaderMouseDown(evt) {
    //dont bubble so that we prevent our scroll component
    //from knowing about it
    evt.stopPropagation();
  }

  getVisibleItems(canvasTimeStart, canvasTimeEnd) {
    const { itemTimeStartKey, itemTimeEndKey } = this.props.keys;

    return this.props.items.filter(item => {
      return (
        _get(item, itemTimeStartKey) <= canvasTimeEnd &&
        _get(item, itemTimeEndKey) >= canvasTimeStart
      );
    });
  }

  refHandler = el => {
    this.capacityComponent = el
    this.props.scrollHeaderRef(el)
  }

  handleWheel = e => {
    const { traditionalZoom } = this.props

    e.preventDefault()

    // zoom in the time dimension
    if (e.ctrlKey || e.metaKey || e.altKey) {
      const parentPosition = getParentPosition(e.currentTarget)
      const xPosition = e.clientX - parentPosition.x

      const speed = e.ctrlKey ? 10 : e.metaKey ? 3 : 1

      // convert vertical zoom to horiziontal
      this.props.onWheelZoom(speed, xPosition, e.deltaY)
    } else if (e.shiftKey) {
      // shift+scroll event from a touchpad has deltaY property populated; shift+scroll event from a mouse has deltaX
      let delta = e.deltaY || e.deltaX
      this.props.onScroll(delta, true)

      // no modifier pressed? we prevented the default event, so scroll or zoom as needed
    } else {
      if (e.deltaX !== 0) {
        if (!traditionalZoom) {
          let delta = e.deltaX
          this.props.onScroll(delta, true)
        }
      }
      if (e.deltaY !== 0) {
        window.scrollTo(window.pageXOffset, window.pageYOffset + e.deltaY)
        if (traditionalZoom) {
          const parentPosition = getParentPosition(e.currentTarget)
          const xPosition = e.clientX - parentPosition.x

          this.props.onWheelZoom(10, xPosition, e.deltaY)
        }
      }
    }
  }

  handleMouseDown = e => {
    if (e.button === 0) {
      this.dragStartPosition = e.pageX
      this.dragLastPosition = e.pageX
      this.setState({
        isDragging: true
      })
    }
  }

  handleMouseMove = e => {
    // this.props.onMouseMove(e)
    //why is interacting with item important?
    if (this.state.isDragging && !this.props.isInteractingWithItem) {

      let delta = this.dragLastPosition - e.pageX
      this.props.onScroll(delta, true)

      // this.capacityComponent.scrollLeft += this.dragLastPosition - e.pageX
      this.dragLastPosition = e.pageX
    }
  }

  handleMouseUp = () => {
    this.dragStartPosition = null
    this.dragLastPosition = null

    this.setState({
      isDragging: false
    })
  }

  handleMouseLeave = () => {
    // this.props.onMouseLeave(e)
    this.dragStartPosition = null
    this.dragLastPosition = null
    this.setState({
      isDragging: false
    })
  }

  render() { 
    const {
      canvasTimeStart,
      canvasTimeEnd,
      canvasWidth,
      timeSteps,
      headerLabelGroupHeight,
      headerLabelHeight,
      orders,
      productionTakt,
      mixingPlants,
      visibleTimeStart,
      visibleTimeEnd,
      silos,
      currentTime
    } = this.props;

    let minUnit = 'hour';
    const ratio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
    let modVisibleTimeEnd = moment(visibleTimeEnd).add(1, "d").valueOf();
    let modVisibleTimeStart = moment(visibleTimeStart).add(50/ratio, "milliseconds").valueOf();
    let chartData = {
      datasets: []
    };
    const errorOffset = 20;

    let data = getOrders(orders, productionTakt, silos, visibleTimeStart, modVisibleTimeEnd);
    let rawChartDataUncapped = getSiloCapacityData(data);
    let response = capSiloCapacityData(rawChartDataUncapped, silos);
    let rawChartData = response.chartdata;
    let errors = response.errors;
    
    // setSiloCapacityData(rawChartData);

    

    for (let key in rawChartData) {
      let siloData = rawChartData[key].filter(sd => 
        sd[0] > modVisibleTimeStart &&
        sd[0] < visibleTimeEnd
      );

      let silo = silos.filter(s => s.id == key)[0];
      if (silo === undefined)
        silo = {name: i18n.t("translation:text.silo_not_assigned"), color: "#8bc34a", max: 1500};

      let dataPoints = []; 

      for (let i = 0; i < siloData.length; i++) {
        if (siloData[i][0] > modVisibleTimeStart && i == 0) {
          dataPoints.push({
            x: modVisibleTimeStart,
            y: getCapacityAtTime(rawChartData, modVisibleTimeStart, key) >= 0 ? getCapacityAtTime(rawChartData, modVisibleTimeStart, key) : 0
          })
        }

        let x = moment(siloData[i][0]).valueOf();
        let y = Math.round(siloData[i][1] * 10) / 10; //Round to 1 decimal

        dataPoints.push({x, y});

        if (siloData[i][0] < visibleTimeEnd && i == siloData.length - 1) {
          dataPoints.push({
            x: visibleTimeEnd,
            y: getCapacityAtTime(rawChartData, visibleTimeEnd, key) >= 0 ? getCapacityAtTime(rawChartData, visibleTimeEnd, key) : 0
          })
        }
      }
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].start < modVisibleTimeStart && errors[i].end > modVisibleTimeStart && i == 0) {
          errors[i].start = modVisibleTimeStart;
        }
        else if (errors[i].end > visibleTimeEnd && errors[i].start < visibleTimeEnd && i == errors.length - 1) {
          errors[i].end = visibleTimeEnd;
        }
        if (errors[i].start < modVisibleTimeStart && errors[i].end < modVisibleTimeStart) continue;
        if (errors[i].start > visibleTimeEnd && errors[i].end > visibleTimeEnd) continue;

        chartData.datasets.push(
          {
            label: silo.name + " fehler" + errors[i].start,
            cubicInterpolationMode: "monotone",
            lineTension: 0,
            steppedLine: "before",
            borderColor: "rgba(0, 0, 0, 0)",
            fill: true,
            backgroundColor: "rgba(230, 12, 12, 0.4)",
            pointRadius: 0,
            pointHitRadius: 0,
            data: [
              {x: errors[i].start, y: 0},
              {x: errors[i].end, y: 0}
            ],
            key_type: 'error'
          }
        )
      } 

      chartData.datasets.push(
        {
          label: silo.name,
          cubicInterpolationMode: "monotone",
          lineTension: 0,
          showLine: true,
          borderColor: silo.color,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          pointRadius: 0,
          pointHitRadius: 0,
          data: dataPoints,
          key_type: 'info'
        }
      )
    }

    chartData.datasets.sort((a, b) => {
      let siloNames = silos.map(s => s.name);
      let indexA = siloNames.indexOf(a.label);
      let indexB = siloNames.indexOf(b.label);

      return indexB - indexA;
    });

    let dataPoints = JSON.parse(JSON.stringify(chartData.datasets)).filter(d => d.key_type === 'info');
    let max = 50;

    dataPoints.forEach(dp => {
      let data = JSON.parse(JSON.stringify(dp.data));
      if(data.length > 0) {
        let maxSize = data.sort((a, b) => {
          if(a.y > b.y) return -1;
          if(a.y < b.y) return 1;
          return 0;
        })[0].y;
  
        if(max < maxSize) {
          max = maxSize;
        }
      }
    });
    
    for (let index = 0; index < chartData.datasets.length; index++) {
      if(chartData.datasets[index].key_type === 'error') {
        chartData.datasets[index].data = chartData.datasets[index].data.map(e => {
          e.y = max;
          return e;
        });
      }
    }

    let headerStyle = {
      height: "200px",
      overflowX: 'scroll',
      overflowY: 'hidden',
      cursor: this.state.isDragging ? 'move' : 'default',
      // width: canvasWidth
    };

    const chartOptions = {
      legend: {
        display: true,
        labels: {
          filter: function(item, chart) {
              return !item.text.includes('fehler');
          }
        }
      },
      animation: {
        duration: 0,
      },
      tooltips: {
        mode: "nearest",
        intersect: false,
        displayColors: true,
        xPadding: 12,
        yPadding: 12,
        backgroundColor: "rgba(12, 16, 53, 0.7)",
        filter: function(tooltipItem, data) {
          let datasetIndex = tooltipItem.datasetIndex;
          let index = tooltipItem.index;

          if (data.datasets[datasetIndex].key_type == "error")
            return false;

          return true;
        },
        callbacks: {
          title: function(tooltipItems, data) {
            if (tooltipItems[0] !== undefined)
              return moment(tooltipItems[0].xLabel, 'MMM D, YYYY h:mm:ss.SSS a').add(30, 'seconds').startOf('minute').format("ddd Do MMMM, HH:mm:ss");
            return false;
          },
          label: function(tooltipItem, data) {


            return tooltipItem.yLabel + " t";
          },
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        yAxes: [
          {
            afterFit: function(scaleInstance) {
              scaleInstance.width = 50; // sets the width to 50px
            },
            scaleLabel: {
              padding: 24,
            },
            beginAtZero: true,
            stacked: false,
            display: true,
            ticks: {
              display: true,
              maxTicksLimit: 8,
              beginAtZero: true,
              precision: 0,
              callback: function(value, index, values) {
                return value + " t \t ";
              }
            },
            gridLines: {
              display: true,
              drawTicks: false,
              borderDash: [5, 10]
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              display: false,
            },
            display: true,
            type: "time",
            gridLines: {
              display: true,
              drawTicks: false,
              borderDash: [10, 5]
            },
          }
        ]
      },
      plugins: {
        datalabels: {
          display: false,
        }
      }
    };

    return (
      <div
        key="silo-capacity"
        data-test-id="silo-capacity"
        className="rct-header"
        style={headerStyle}
        ref={this.refHandler}
        onWheel={this.handleWheel}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="bottom-header" style={{ width: ((canvasTimeEnd - canvasTimeStart) * ratio) / 3, height: "100%"}}>
          <Line data={chartData} options={chartOptions} ref={r => this.chart = r}/>
        </div>
      </div>
    );
  }
}
