import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router";
import { Button, Popup } from "semantic-ui-react";



class CapacityToggleButton extends React.Component {

    componentDidUpdate() {
        const { 
            handleShowSiloCapacity,
            isChartCapacityActive, 
            isSiloCapacityActive, 
            isSiloCapacityDisabled, 
            isMixtureMaster
        } = this.props;

        if ((!isMixtureMaster || isSiloCapacityDisabled) && isSiloCapacityActive)
            handleShowSiloCapacity();
        if (!isChartCapacityActive && isSiloCapacityActive)
            handleShowSiloCapacity();
    }

    handleToggleCapacity = _ => {
        const { 
            handleShowChartCapacity,
            handleShowSiloCapacity,
            isChartCapacityActive, 
            isSiloCapacityActive, 
            isChartCapacityDisabled, 
            isSiloCapacityDisabled, 
            isMixtureMaster
        } = this.props;

        if (!isChartCapacityActive && !isSiloCapacityActive && (!isChartCapacityDisabled))
            handleShowChartCapacity();
        else if (isChartCapacityActive && !isSiloCapacityActive && (!isMixtureMaster || isSiloCapacityDisabled))
            handleShowChartCapacity();
        else if (isChartCapacityActive && !isSiloCapacityActive && (isMixtureMaster && !isSiloCapacityDisabled))
            handleShowSiloCapacity();
        else if (isChartCapacityActive && isSiloCapacityActive)
            handleShowChartCapacity();
    } 

    render() {
        const { 
            t, 
            isChartCapacityActive, 
            isSiloCapacityActive, 
            isChartCapacityDisabled
        } = this.props;

        let label = t("order.buttons.showcapacity");
        let icon = "bar chart";
        let disabled = false;
        let active = false;
        let color = undefined;

        if (isChartCapacityActive && !isSiloCapacityActive) {
            label = t("order.buttons.showcapacity");
            icon = "bar chart";
            active = true;
        }
        else if (isSiloCapacityActive) {
            label = t("order.buttons.show_silo_capacity_graph")
            icon = "clockwise rotated align right"
            active = true;
        }

        if (isChartCapacityDisabled)
            disabled = true;


        return (
            <Popup
                trigger={
                    <Button
                        className="buttonPadRight"
                        // label={label}
                        type="button"
                        color={color}
                        icon={icon}
                        disabled={disabled}
                        active={active}
                        onClick={this.handleToggleCapacity}
                    />
                }
                content={label}
                position="top center"
            />                
        )
    }

}

const CapacityToggleButton_i18nWrapper = withNamespaces('translation')(CapacityToggleButton);
export default withRouter(CapacityToggleButton_i18nWrapper);