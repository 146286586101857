import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import Timeline from '../Timeline';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import uuid from 'uuid/v4';
import UpdateGroup from '../Orders/NewOrder/UpdateGroup';
import AddMaterialToMixingPlant from '../Orders/NewOrder/AddMaterialToMixingPlant';
import { defaultItemRenderer } from '../Timeline/lib/items/defaultItemRenderer';
import containerResizeDetector from '../Timeline/resize-detector/container';
import MixingPlantFilter from '../Filters/MixingPlantFilter';
import {DEFAULT_MIXING_PLANT_ID} from '../../actions/orders';
import DeleteOrderModal from './DeleteOrderModal';
import { CUSTOMER_VIEW_CONTEXT } from '../Timeline/lib/timeline/timelineContexts';

const moment = extendMoment(Moment);

class MixingPlantsCapacityTimeline extends React.Component {
  state = {
    visibleTimeStart: this.props.visibleTimeStart,
    visibleTimeEnd: this.props.visibleTimeEnd,
    groups: this.props.groups,
    mixingPlantFilter: [],
    selected: []
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.visibleTimeStart !== this.props.visibleTimeStart) {
      this.setState({ 
        visibleTimeStart: this.props.visibleTimeStart, 
        visibleTimeEnd: this.props.visibleTimeEnd
      });
    }
  }

  handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.setState({ visibleTimeStart, visibleTimeEnd });
    this.props.updateTime(visibleTimeStart, visibleTimeEnd);
  };

  addMaterial = newGroup => {
    const {groups} = JSON.parse(JSON.stringify(this.state));
    let groupIds = groups.map(g => g.id);

    let indexFromParent = groupIds.indexOf(newGroup.parent);
    if(indexFromParent === -1) return;

    let indexToInsert = ++indexFromParent;  
    groups.splice(indexToInsert, 0, newGroup)

    this.setState({groups});
  }

  onCanvasClick = (groupId, time, e) => {
    const {groups} = this.state;

    let group = groups.filter(g => g.id == groupId)[0];

    if('root' in group && group.root) return;

    this.props.addOrderMaterial({
      id: uuid(),
      material_id: groupId.split('_')[0],
      group_id: groupId,
      amount: 0,
      start: moment(time).hour(8).minute(0).valueOf(),
      end: moment(time).hour(17).minute(0).valueOf(),
      tonPerHour: 0,
      obs: '',
      status: 'STANDARD',
      mixingPlant: group.parent,
      order: {},
      updated: false
    }, time);
  }

  onItemSelect = (itemId, e, time) => {
    this.setState({selected: [itemId]});
    this.props.onItemSelect(itemId, e, time);
  }

  onItemResize = (itemId, time, edge) => { 
    this.props.onItemResize(itemId, time, edge);
  }

  onItemMove = (itemId, dragTime, newGroupOrder) => { 
    this.props.onItemMove(itemId, dragTime, newGroupOrder);
  }

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    showMaxCapacityMessage
  }) => { 

    const {groups} = this.state;
    let group = groups.filter(g => g.id == item.group)[0];

    if(!group.root) return defaultItemRenderer({item, itemContext, getItemProps, getResizeProps});

    const itemProps = {...getItemProps(item.itemProps)};

    itemProps.style.background = 'white';

    let borderColor = 'black';

    return (
      <div {...itemProps}>
        <div
          style={{
            padding: '0px !important'
          }}
          className="rct-capacity-item rct-item-content"
        >
          <div style={{
            background: 'rgb(0, 70, 153)',
            width: itemContext.dimensions.width - 4,
            height: itemContext.dimensions.height - 5,
            border: `1.5px solid ${borderColor}`,
            position: 'relative',
            paddingLeft: '4px',
            fontWeight: 'bolder'
          }}>
            {/* {this.props.t("customer.neworder.tonday")} - {item.total} t */}
            {item.total} t
          </div>
        </div>  
      </div>
    );
  }

  groupRenderer = ({ group }) => { 
    let { groups } = this.state;

    return (
      <div 
        className="rct-sidebar-row" 
        style={{
          height: '38px', 
          lineHeight: '38px',
          display: 'flex',
          marginLeft: group.root ? '0px' : '15px',
          border: '0px'
        }}
        key={group.id}
      >
        {group.root 
          ? (<AddMaterialToMixingPlant key={group.id} mixingPlant={group} groups={groups} addMaterial={this.addMaterial} />)
          : (<UpdateGroup  
              updateMaterial={this.updateMaterial} 
              group={group} 
              removeMaterial={this.removeMaterial} 
              closeMaterialsByDay={this.closeMaterialsByDay}
              groups={groups}
            />
          )
        }
        
      </div>
    );
  }

  closeMaterialsByDay = _ => {
    this.setState({selected: []});
    this.props.closeMaterialsByDay();
  }

  updateMaterial = (newGroup, oldGroup) => {
    let {groups} = this.state;
    let {order_materials, materials} = this.props;

    let material = materials.filter(m => m.id == newGroup.split('_')[0])[0];

    groups = groups.map(g => {
      if(g.id == oldGroup) {
        g.id =`${newGroup}`;
        g.title = material.name

        return g;
      }

      return g;
    });

    
    order_materials = order_materials.map(om => {
      if(om.material_id == oldGroup.split('_')[0]) {
        om.material_id = newGroup.split('_')[0];
        om.group_id = `${newGroup}`
        return om;
      }

      return om;
    });

    this.props.updateOrderMaterials(order_materials);

    this.setState({
      groups
    });
  }

  removeMaterial = groupId =>{
    let {groups} = this.state;
    let {order_materials} = this.props;

    groups = groups.filter(g => g.id !== groupId);
    order_materials = order_materials.filter(om => om.group_id !== groupId);

    this.props.updateOrderMaterials(order_materials);

    this.setState({
      groups,
      shouldShowMaterialsByDay: false
    });
  }

  setMixingPlantFilter = filterValue => {
    this.setState({mixingPlantFilter: filterValue});
  }

  deleteOrder = _ => {
    const {selected} = this.state;

    if(selected.length === 0) return;
    
    this.props.removeOrder(selected[0]);
  }

  render() {
    let { order_materials, mixingPlants } = this.props;
    let { visibleTimeStart, visibleTimeEnd, groups, mixingPlantFilter, selected } = this.state;

    let range = moment.range(visibleTimeStart, visibleTimeEnd);

    let items = [];

    let showMaxCapacityMessage = false;

    if(mixingPlantFilter.length > 0) {
      groups = groups.filter(g => {
        return (mixingPlantFilter.indexOf(g.id) !== -1) || mixingPlantFilter.indexOf(g.parent) !== -1
      });
    }

    let mixingPlantWithAccessIds = mixingPlants.map(mp => mp.id);

    groups.forEach(group => {      
      let maxTonnageByDay = parseFloat(group.tonday);

      if('root' in group && group.root) {
        let childGroups = groups.filter(g => !g.root && g.parent === group.id).map(g => g.id);
        
        let orderMaterialsByMixingPlant = order_materials.filter(om => {
          let group = null;
          if(mixingPlantWithAccessIds.indexOf(om.mixingPlant) !== -1) {
            group = om.group_id;
          } else {
            group = `${om.material_id}_${DEFAULT_MIXING_PLANT_ID}`;
          }
          
          return childGroups.indexOf(group) !== -1
        });

        for (let day of range.by('day')) {
          let ordersMaterialsByDay = orderMaterialsByMixingPlant.filter(o => day.isSame(o.start, 'day'));

          let total = 0;

          let totalByOrderMaterials = ordersMaterialsByDay.reduce((acc, o) => 
            acc += parseFloat(o.amount === "" ? 0 : o.amount)
          , 0);

          total = total + totalByOrderMaterials;

          if(isNaN(total)) total = 0;
    
          let percentage = parseFloat((total/maxTonnageByDay) * 100).toFixed(2);

          if(percentage >= 100) showMaxCapacityMessage = true;

          if(total > 0) {
            items.push({
              id: uuid(),
              group: group.id,
              start_time: day.startOf('day').valueOf(),
              end_time: day.endOf('day').valueOf(),
              canMove: false,
              canResize: false,
              isNewOrder: true,
              total,
              percentage,
              tonday: maxTonnageByDay
            });
          }
  
        }
      }
    });
    
    const ordersItem = order_materials.map((om, index) => {
      
      let group = null;
      if(mixingPlantWithAccessIds.indexOf(om.mixingPlant) !== -1) {
        group = om.group_id;
      } else {
        group = `${om.material_id}_${DEFAULT_MIXING_PLANT_ID}`;
      }
      
      return {
        id: om.id,
        group,
        title: om.amount,
        start_time: moment(om.start).startOf('day'),
        end_time: moment(om.start).endOf('day'),
        canMove: true,
        canResize: false,
        isNewOrder: true
      }
    });

    items = items.concat(ordersItem);
    
    return (
      <React.Fragment>
        <div className='buttonLine'>
          <MixingPlantFilter 
            setMixingPlantFilter={this.setMixingPlantFilter}
            mixingPlantFilterValues={mixingPlantFilter}
          />
          <DeleteOrderModal deleteOrder={this.deleteOrder} selected={selected} />
        </div>
        <Timeline
          mixingPlants={this.props.mixingPlants}
          timelineContext={CUSTOMER_VIEW_CONTEXT}
          resizeDetector={containerResizeDetector}
          groups={groups}
          items={items}
          visibleTimeStart={this.state.visibleTimeStart.valueOf()}
          visibleTimeEnd={this.state.visibleTimeEnd.valueOf()}
          dragSnap={1000 * 60 * 60 * 1}
          itemHeightRatio={1}
          minZoom={1 * 24 * 60 * 60 * 1000}
          maxZoom={1 * 24 * 60 * 60 * 1000}
          canChangeGroup={false}
          sidebarWidth={250}
          stackItems={true}
          stickyOffset={51}
          onTimeChange={this.handleTimeChange}
          lineHeight={50}
          itemRenderer={this.itemRenderer}
          groupRenderer={this.groupRenderer}
          onCanvasClick={this.onCanvasClick}
          onItemSelect={this.onItemSelect}
          onItemResize={this.onItemResize}
          onItemMove={this.onItemMove}
          showMaxCapacityMessage={showMaxCapacityMessage}
          selected={selected}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state, props) { 
  const mixingPlants = state.mixingPlants;
  const orders = state.orders;
  const materials = state.materials;

  let mixingPlantWithAccessIds = mixingPlants.map(mp => mp.id);

  let groups = mixingPlants.map(mp => ({
    id: mp.id,
    title: mp.name,
    tonday: mp.tonday,
    root: true,
    parent: null
  }));

  groups.unshift({
    id: DEFAULT_MIXING_PLANT_ID,
    title: props.t("customer.neworder.default_mixing_plant"),
    tonday: 0,
    root: true,
    parent: null
  });

  let groupIds = groups.map(g => g.id);

  props.materialsGroups.forEach(mg => {

    let parent = null;
    if(mixingPlantWithAccessIds.indexOf(mg.parent) !== -1) {
      parent = mg.parent;
    } else {
      parent = DEFAULT_MIXING_PLANT_ID;

      mg.id = `${mg.id.split("_")[0]}_${parent}`;
      mg.parent = parent;
    }

    let indexFromParent = groupIds.indexOf(parent);
    if(indexFromParent === -1) return;

    let indexToInsert = ++indexFromParent;  
    groups.splice(indexToInsert, 0, mg)
  });

  groups = groups.filter(
    (group, index, self) =>
      index ===
      self.findIndex(
        i =>
          i.id == group.id
      )
  );

  return {
    groups,
    orders,
    materials,
    mixingPlants
   }
}

export default withNamespaces('translation')(connect(mapStateToProps, {})(MixingPlantsCapacityTimeline));