import { ADD_CUSTOMER, EDIT_CUSTOMER, REMOVE_CUSTOMER, LOAD_CUSTOMERS } from '../actions/customers';

const defaultState = [];

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case ADD_CUSTOMER:
      return [...state, action.data];
    case EDIT_CUSTOMER:
      return state.map(m => {
        if (m.id == action.data.id) {
          return {
            ...m,
            ...action.data
          };
        } else {
          return m;
        }
      });
    case REMOVE_CUSTOMER:
      return state.filter(({ id }) => id !== action.id);
    case LOAD_CUSTOMERS:
      return action.data;
  }
}