import React from 'react'
import PropTypes from 'prop-types'

export const TotalTaktItem = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  selected
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const itemProps = getItemProps(item.itemProps);

  itemProps.style = {
    ...itemProps.style,
    ...item.style
  }

  if(selected) {
    itemProps.style.border = '2.5px solid rgb(1, 69, 148)';
  }

  itemProps.style.boxShadow = undefined;

  return (
    <div {...itemProps}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

      <div
        className="rct-item-content"
        style={{ maxHeight: `${itemContext.dimensions.height}` }}
      >
        {/* {itemContext.title} */}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  )
}

// TODO: update this to actual prop types. Too much to change before release
// future me, forgive me.
TotalTaktItem.propTypes = {
  item: PropTypes.any,
  itemContext: PropTypes.any,
  getItemProps: PropTypes.any,
  getResizeProps: PropTypes.any
}