import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { updateSchedule } from '../../actions/scheduler';
import { Segment, Header, Button, Icon, Input } from 'semantic-ui-react';
import moment from 'moment';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import SubComponent from './SubComponent'
import Axios from 'axios';
import { editOrder } from '../../actions/orders';

class Scheduler extends React.Component {
    state = {
        trucks: {},
        averageTruckTonnage: {},
        cycleTimes: {},
        totalTimes: {},
        subComponentsOpen: {},
        waypoints: {},
        requestsMade: false,
        graphData: null,
        requesting: {},
        calculatedTrucks: {}
    }

    componentWillMount() {
        const { dayOrders } = this.props;
        const HARDCODED_TRUCK_TONNAGE = 27;
        let cycleTimes = {};
        let totalTimes = {}; 
        let subComponentsOpen = {};
        let waypoints = {};
        let stateTrucks = {};
        let averageTruckTonnage = {};
        let requesting = {};

        for (let i = 0; i < dayOrders.length; i++) {
            let order = dayOrders[i];
            let orderId = order.id;

            if (order.cycle_data !== null) {
                const { load, wayto, paver, installation, leave, wayback, trucks, averageTonnage } = order.cycle_data;
                cycleTimes[orderId] = {
                    load,
                    wayto,
                    paver,
                    installation,
                    leave,
                    wayback,
                    trucks: trucks || (false),  //HARDCODED VALUES FOR NOW / TODO / FIXME
                    averageTonnage: averageTonnage || (27)  //HARDCODED VALUES FOR NOW / TODO / FIXME
                };

                stateTrucks[orderId] = trucks || (false);
                averageTruckTonnage[orderId] = averageTonnage || (27); //HARDCODED VALUE FOR NOW / TODO / FIXME
                const totalTime = Math.round(parseInt(wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(wayback));
                if (!isNaN(totalTime))
                    totalTimes[orderId] = `${totalTime} min`;
                else
                    totalTimes[orderId] = "";
            } else {
                //HARDCODED VALUES FOR NOW / TODO / FIXME
                const [load, wayto, paver, installation, leave, wayback] = [10, 30, 10, 10, 10, 10];

                cycleTimes[orderId] = {
                    load,
                    wayto,
                    paver,
                    installation,
                    leave,
                    wayback,
                    trucks: false,
                    averageTonnage: 27
                };

                averageTruckTonnage[orderId] = 27; //HARDCODED VALUE FOR NOW / TODO / FIXME
                stateTrucks[orderId] = false;

                const totalTime = Math.round(parseInt(wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(wayback));
                if (!isNaN(totalTime))
                    totalTimes[orderId] = `${totalTime} min`;
                else
                    totalTimes[orderId] = "";
            } 

            
            subComponentsOpen[orderId] = false;

            if (order.waypoints) {
                waypoints[orderId] = order.waypoints;
            } 
            else {    
                waypoints[orderId] = {
                    to: [],
                    back: [],
                };
            }

            requesting[orderId] = false;
        }
        this.setState({
            cycleTimes,
            totalTimes,
            subComponentsOpen,
            waypoints,
            trucks: stateTrucks,
            averageTruckTonnage,
            requesting
        });
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        const { dayOrders } = this.props;
        let calculatedTrucks = Object.assign(this.state.calculatedTrucks);
        let hasChanged = false;

        for (let i = 0; i < dayOrders.length; i++) {
            const order = dayOrders[i];
            const orderId = order.id;
            const orderStart = moment(order.materials.start);
            const orderEnd = moment(order.materials.end);
            const orderAmount = order.materials.amount;
            const orderDuration = orderEnd.diff(orderStart, "hours", true);
            const orderTPH = Math.round(orderAmount / orderDuration);
            const totalTimes = {...this.state.totalTimes};

            let tmpCalculatedTrucks = "-";

            const totalTravelTime = parseInt(totalTimes[orderId]);
            const averageTonnage = this.state.averageTruckTonnage[orderId];

            const canCalculateTrucks = !!orderAmount && !!orderTPH && !!orderDuration && !isNaN(totalTravelTime) && !!averageTonnage;

            if (canCalculateTrucks) {
                const loadAmounts = orderAmount / averageTonnage;
                const availableTimePerLoad = (orderDuration * 60) / loadAmounts;

                tmpCalculatedTrucks = (totalTravelTime / availableTimePerLoad).toFixed(1);
            }

            calculatedTrucks[orderId] = tmpCalculatedTrucks;

            if (this.state.calculatedTrucks[orderId] !== calculatedTrucks[orderId])
                hasChanged = true;
        }

        if (hasChanged) {
            this.setState({
                calculatedTrucks
            });
        }
    }

    openDiagram = (data) => {
        const { day, mpid } = this.props.match.params;
        const { constructionSites, dayOrders } = this.props;

        const propData = {
            data,
            constructionSites,
            dayOrders
        }

        this.props.history.push({
            pathname: `/auftragsdispo/schedule/${day}/${mpid}/diagram`,
            state: propData
        });
    }
    
    handleReturn = _ => {
        this.props.history.push('/auftragsdispo/');
    }

    handleInput = (id, field, e) => {
        const value = e.target.value;
        let totalTimes = Object.assign(this.state.totalTimes);
        let cycleTimes = Object.assign(this.state.cycleTimes);
        cycleTimes[id][field] = value;

        const { wayto, load, paver, installation, leave, wayback } = cycleTimes[id];
        const totalTime = Math.round(parseInt(wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(wayback));

        if (!isNaN(totalTime))
            totalTimes[id] = `${totalTime} min`;

        this.setState({
            cycleTimes,
            totalTimes
        });
        this.handlePositive(this.state, id);
    }

    routeFound = (id, time, way, waypoints, shouldUpdateInput) => {
        let cycleTimes = Object.assign(this.state.cycleTimes);
        let tmpWaypoints = Object.assign(this.state.waypoints);
        let totalTimes = Object.assign(this.state.totalTimes);

        if (!cycleTimes[id][`way${way}`] || shouldUpdateInput)
            cycleTimes[id][`way${way}`] = time;
        tmpWaypoints[id] = waypoints;

        const { wayto, load, paver, installation, leave, wayback } = cycleTimes[id];
        const totalTime = Math.round(parseInt(wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(wayback));
        if (!isNaN(totalTime))
            totalTimes[id] = `${totalTime} min`;

        this.setState({
            cycleTimes,
            waypoints: tmpWaypoints,
            totalTimes
        });
        this.handlePositive(this.state, id);
    }

    subComponentOpenToggle = (id) => {
        let subComponentsOpen = Object.assign(this.state.subComponentsOpen);
        
        subComponentsOpen[id] = !subComponentsOpen[id];

        this.setState({
            subComponentsOpen,
            requestsMade: false
        });
    }

    startCycleOptimization = () => {
        const { dayOrders } = this.props;
        const origin =  "https://bpo-asphalt.de";
        //PATH
        //const origin = "http://alpha.bpo-asphalt.de";
        const url = `${origin}/common/DBTakt/DBTaktAuftragsdispo.php`;
        
        const data = {
            "pausesettings": {
                "pause1": 15,
                "pause2": 45,
                "timetopause": 270,
                "maxtime": 540,
                "optimize": true,
                "beladenPause": false,
                "initialPause": 0
            },
            "pauses": [],
            "sites": {}
        };

        const allCycleTimes = this.state.cycleTimes;
        // const HARDCODED_TRUCK_AMOUNT = 27; //For now the amount(/tonnage) per truck will be hardcoded. TODO / FIXME

        for (let i = 0; i < dayOrders.length; i++) {
            const order = dayOrders[i];
            const orderId = order.id;
            const orderStart = order.materials.start;
            const orderEnd = moment(order.materials.end);
            const orderDuration = orderEnd.diff(orderStart, "hours", true);
            const orderAmount = order.materials.amount;
            const orderTPH = (orderAmount / orderDuration);

            const cycleTimes = allCycleTimes[orderId];
            const { load, wayto, paver, installation, leave, wayback } = cycleTimes;

            const truckAmount = this.state.trucks[orderId];
            const averageTruckTonnage = this.state.averageTruckTonnage[orderId];

            if (!(load && wayto && paver && installation && leave && wayback))
                continue;

            const loadTime = parseInt(load);
            const outwardJourneyTime = parseInt(wayto) + parseInt(paver);
            const unloadTime = parseInt(installation);
            const returnJourneyTime = parseInt(leave) + parseInt(wayback);


            let constructionSite = {
                start: moment(orderStart).format("YYYY-MM-DD HH:mm:ss"),
                output: orderTPH,
                amount: orderAmount,

                trucks: {
                    count: truckAmount,
                    amount: averageTruckTonnage,
                },

                route: {
                    load: loadTime,
                    outwardJourney: outwardJourneyTime,
                    unload: unloadTime,
                    returnJourney: returnJourneyTime,
                },
            };

            data.sites[orderId] = constructionSite;
        }

        let formData = new FormData();
        formData.append("config", JSON.stringify(data));

        Axios({
            method: "post",
            url: url,
            config: { headers: {'Content-Type': 'multipart/form-data' }},
            data: formData
        }).then(result => {
            const data = result.data;
            this.openDiagram(data);

        }).catch(error => {
            console.log("Error: ", error);
        });
    }

    renderEditable = (cellInfo) => {
        const { t } = this.props;
        const fieldName = cellInfo.column.id;
        const orderId = cellInfo.original.orderid;
        let currentValue = parseInt(this.state[fieldName][orderId]);
        let placeholder;
        const calculatedTrucks = parseFloat(this.state.calculatedTrucks[orderId]);


        if (isNaN(currentValue) && fieldName === "trucks" && !isNaN(calculatedTrucks)) {
            currentValue = Math.ceil(calculatedTrucks) + 1;
        }
        if (isNaN(currentValue)) {
            currentValue = "";
        }

        if (fieldName === "trucks")
            placeholder = t("scheduler.truck_infos.auto_truck_amount")
        else 
            placeholder = "27";

        return (
          <Input
            type="number"
            min="1"
            placeholder={placeholder}
            defaultValue={currentValue}
            onBlur={e => {
                e.persist();
                let newState = Object.assign(this.state);
                let value = parseInt(e.target.value);

                if (e.target.value === "") {
                    if (fieldName === "trucks")
                        value = false; //HARDCODED VALUES FOR NOW /TODO /FIXME
                    else
                        value = 27; //HARDCODED VALUES FOR NOW /TODO /FIXME
                }

                newState[fieldName][orderId] = value;

                this.setState(
                    newState
                );
                this.handlePositive(this.state, orderId);
            }}
            // dangerouslySetInnerHTML={{
            //   __html: e.target.innerHTML
            // }}
          />
        );
    }

    handlePositive = (state, id = false) => {
        const { dayOrders } = this.props;
        const { trucks, averageTruckTonnage } = this.state;
        const { cycleTimes, waypoints } = state;

        for (let i = 0; i < dayOrders.length; i++) {
            let order = dayOrders[i];
            let orderId = order.id;
            if (id !== false && orderId !== id){
                continue;
            }
              
            let cycledata = cycleTimes[orderId];

            cycledata.trucks = trucks[orderId];
            cycledata.averageTonnage = averageTruckTonnage[orderId];

            order.cycle_data = cycledata;
            order.waypoints = waypoints[orderId];

            this.props.editOrder(order);
        }


         // TODO
    } 

    render() {
        const { t, dayOrders, materials, constructionSites , customers, mixingPlants, mixingPlant} = this.props;
        const { day, mpid } = this.props.match.params;

        let dayString = moment(parseFloat(day)).format("L");
        if (isNaN(day) || !day)
            dayString = "";

        let mpString = "";
        if (!!mixingPlant && !!mixingPlant.name)
            mpString = mixingPlant.name;

        const baseurl = "https://bpo-asphalt.de/common/interfaces/route.php";
        const allCycleTimes = Object.assign(this.state.cycleTimes);
        let data = [];

        for (let i = 0; i < dayOrders.length; i++) {
            const order = dayOrders[i];
            const orderId = order.id;
            const orderStart = moment(order.materials.start);
            const orderEnd = moment(order.materials.end);
            const orderAmount = order.materials.amount;
            const orderDuration = orderEnd.diff(orderStart, "hours", true);
            const orderTPH = Math.round(orderAmount / orderDuration);
            let waypoints = order.waypoints;

            if (!waypoints) {
                waypoints = {"to":["48.913539177879575,8.6981731653213532", "48.93394645,8.74796254"],
                            "back":["48.93394645,8.74796254","48.913539177879575,8.6981731653213532"]}; //HARDCODED DEFAULT VALUE /TODO /FIXME
            }

            const cycleTimes = allCycleTimes[orderId];
            const { wayto, load, paver, installation, leave, wayback } = cycleTimes;
            const totalTimes = {...this.state.totalTimes};

            let materialInfo = `${orderAmount}t • ${orderTPH} t/h`;
            let travelTime = this.state.totalTimes[orderId];
            let wayToTravelTime = {};

            
            let constructionSiteName = "-";
            const constructionSite = constructionSites.filter((cs) => {
                return cs.id == order.constructionSite;
            })[0];
            if (!constructionSite) 
                continue;
            else 
                constructionSiteName = constructionSite.name;
                

            let routeTo_Data = {}; 
            for (let index = 0; index < waypoints.to.length; index++) {
                let waypoint = waypoints.to[index];
                if (waypoint.lat && waypoint.lng) {
                    waypoint = `${waypoint.lat},${waypoint.lng}`;
                }
                routeTo_Data[`point${index}`] = waypoint;
            }
            routeTo_Data["key"] = "Auftragsdispo"


            let routeBack_Data = {}; 
            for (let index = 0; index < waypoints.back.length; index++) {
                let waypoint = waypoints.back[index];
                if (waypoint.lat && waypoint.lng) {
                    waypoint = `${waypoint.lat},${waypoint.lng}`;
                }
                routeBack_Data[`point${index}`] = waypoint;
            }
            routeBack_Data["key"] = "Auftragsdispo"

            if (!this.state.totalTimes[orderId] && !this.state.requesting[orderId]) {
                let stateCycleTimes = Object.assign(this.state.cycleTimes);
                let requesting = this.state.requesting;
                requesting[orderId] = true;

                let shouldRequest = !(!!stateCycleTimes[orderId].wayto && !!stateCycleTimes[orderId].wayback);

                if (shouldRequest) {
                    this.setState({
                        requesting    
                    });
                    Axios.get(baseurl, {
                        params: routeTo_Data
                    })
                    .then(result => {
                        const travelToTime = result.data.paths[0].time / 1000 / 60;
                        if (!stateCycleTimes[orderId].wayto)
                            stateCycleTimes[orderId].wayto = Math.round(travelToTime);
    
                        Axios.get(baseurl, {
                            params: routeBack_Data
                        })
                        .then(result => {
    
                            let totalTimes = {...this.state.totalTimes};
                            const travelBackTime = result.data.paths[0].time / 1000 / 60;
    
                            if (!stateCycleTimes[orderId].wayback)
                                stateCycleTimes[orderId].wayback = Math.round(travelBackTime);
        
                            const totalTime = Math.round(parseInt(stateCycleTimes[orderId].wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(stateCycleTimes[orderId].wayback));
    
                            if (isNaN(totalTime)){
                                wayToTravelTime[orderId] = " - ";
                                totalTimes[orderId] = " - ";
                            }else {
                                totalTimes[orderId] = `${totalTime} min`;
                                wayToTravelTime[orderId] = `${totalTime} min`;
                            }
    
                            requesting[orderId] = false;
                            console.log(this.state.totalTimes[orderId] , totalTimes[orderId],this.state.totalTimes[orderId] !== totalTimes[orderId]);
                            if (this.state.totalTimes[orderId] !== totalTimes[orderId]) {
                                this.setState({
                                    totalTimes,
                                    requesting
                                });
                                this.handlePositive(this.state, orderId);
                            }
                        })
                        .catch(error => {
                            console.log("Error: ", error);
                        });
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    });
                }
                else {
                    let totalTimes = {...this.state.totalTimes};
                    const totalTime = Math.round(parseInt(stateCycleTimes[orderId].wayto) + parseInt(load) + parseInt(paver) + parseInt(installation) + parseInt(leave) + parseInt(stateCycleTimes[orderId].wayback));

                    
                    if (isNaN(totalTime))
                        wayToTravelTime[orderId] = " - ";
                    else
                        wayToTravelTime[orderId] = `${totalTime} min`;

                    if (this.state.totalTimes[orderId] !== totalTimes[orderId]) {
                        this.setState(prevState => ({
                            totalTimes
                        }));
                        this.handlePositive(this.state, orderId);
                    }
                }
                
            }  


            let customerName = "-";
            const customer = customers.filter((c) => {
                return c.id == constructionSite.customer_id;
            })[0];
            if (!customer) 
                continue;
            else
                customerName = customer.name;


            let materialName = "-";
            const material = materials.filter((m) => {
                return m.id == order.materials.material_id;
            })[0];
            if (!material) 
                continue;
            else
                materialName = material.name;

            let datapoint = {};

            const calculatedTrucks = this.state.calculatedTrucks[orderId];

            datapoint.start = orderStart.format("HH:mm");
            datapoint.constructionsite = constructionSiteName;
            datapoint.customer = customerName;
            datapoint.material = materialName;
            datapoint.materialinfo = materialInfo;
            datapoint.traveltime = totalTimes[orderId];
            datapoint.calculated_trucks = calculatedTrucks;
            datapoint.trucks = "12";
            datapoint.tonnage = "27";
            datapoint.orderid = order.id;

            data.push(datapoint);
        }

        if (!this.state.requestsMade) {
            this.setState({
                requestsMade: true
            });
        }
         
        const columns = [
            {
                Header: t("scheduler.table_labels.start"),
                accessor: 'start',
                minWidth: 25,
                width: 74
            }, 
            {
                Header: t("scheduler.table_labels.construction_site"),
                id: "customer_and_site",
                accessor: d =>
                    (<div
                    dangerouslySetInnerHTML={{
                        __html: "<span>" + d.customer + "<br/>" + d.constructionsite + "</span>"
                    }}
                    />),
                minWidth: 60,
                width: 300
            }, 
            {
                Header: t("scheduler.table_labels.material"),
                id: "material_and_materialinfo",
                accessor: d =>
                    (<div
                    dangerouslySetInnerHTML={{
                        __html: "<span>" + d.material + "<br/>" + d.materialinfo + "</span>"
                    }}
                    />),
                minWidth: 25
            }, 
            {
                Header: t("scheduler.table_labels.wayto"),
                accessor: 'traveltime',
                minWidth: 25,
                width: 120                
            }, 
            {
                Header: t("scheduler.table_labels.calculated_trucks"),
                accessor: "calculated_trucks",
                minWidth: 20
            },
            {
                Header: t("scheduler.table_labels.trucks"),
                accessor: 'trucks',
                id: "trucks",
                Cell: this.renderEditable,
                sortable: false,
                minWidth: 25
            },
            {
                Header: t("scheduler.truck_infos.average_tonnage"),
                accessor: 'tonnage',
                id: "averageTruckTonnage",
                Cell: this.renderEditable,
                sortable: false,
                minWidth: 25
            }
        ];

      return (
        <div className='page'>
            <Segment raised>
                <Header as="h2" className="left floated">
                    <Header.Content>{`${t("scheduler.header")}: ${mpString} - ${dayString}`}</Header.Content>
                </Header>

                <div style={{textAlign: 'right'}}>
                    <Button.Group>
                        <Button
                            type="button"
                            onClick={() => this.handleReturn()}
                        >
                            <Icon name="arrow left" />
                            {t("buttons.return")}
                        </Button>

                        <Button className="startCycleOptimization" onClick={this.startCycleOptimization} color="green">
                        {t("scheduler.start_cycle_optimization")}
                        </Button>
                    </Button.Group>
                </div>

               <div className="schedulerLine">    
                    
               </div>

                <ReactTable
                    className="-striped -highlight customReactTable"
                    showPagination={false}
                    data={data}
                    columns={columns}
                    minRows={0}
                    collapseOnDataChange={false}
                    SubComponent={row => {
                        const { dayOrders } = this.props;
                        let orderId = row.original.orderid;
                        const order = dayOrders.filter(dayorder => dayorder.id == orderId)[0];
                        const orderConstructionSiteId = order.constructionSite;

                        let subComponentData = this.state.cycleTimes[orderId];
                        let waypoints = order.waypoints;
                        
                        const orderConstructionSite = constructionSites.filter(cs => 
                            cs.id == orderConstructionSiteId
                        )[0];

                        return(
                            <SubComponent 
                                constructionSite={orderConstructionSite}
                                mixingplant={mixingPlant}
                                waypoints={waypoints}
                                data={subComponentData}
                                row={row}
                                handleInput={this.handleInput}
                                routeFound={this.routeFound}
                                subComponentOpenToggle={this.subComponentOpenToggle}/>
                        );
                    }}
                />
            </Segment>
        </div>
      );
    }
}

function mapStateToProps(state, props) {
    let { day } = props.match.params;
    day = parseInt(day);
    const { mpid } = props.match.params;
    const { orders, materials, constructionSites, customers, mixingPlants } = state;
    const dayEnd = moment(parseInt(day)).add(1, "day").valueOf();

    const dayOrders = orders.filter((o) => {
        return (
            o.mixingPlant == mpid &&
            o.materials.start >= day &&
            o.materials.end <= dayEnd
        );
    });

    const mixingPlant = mixingPlants.filter(mp => mp.id == mpid)[0];

    return {
        dayOrders,
        materials,
        constructionSites,
        customers,
        mixingPlants,
        mixingPlant
    }
}

export default withNamespaces('translation')(
    connect(mapStateToProps, { updateSchedule, editOrder })(Scheduler)
)