import _ from "lodash";
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Table,
  Segment,
  Header,
  Icon,
  Button
} from "semantic-ui-react";
import Buttons from './Buttons';
import LabelledButton from '../LabelledButton/LabelledButton';
import RemoveSiloMessage from './RemoveSiloMessage';
import { formatNumber } from "../../helpers/format-number";

class Silos extends React.Component {

  constructor(props) {
    super(props);

    const tableData = props.tableData;

    this.state = {
      column: null,
      direction: null,
      tableData
    }
  }

  handleReturn = _ => {
    this.props.history.push('/auftragsdispo/base-data?tab=mixingplants');
  }

  handleSort = (clickedColumn) => {
    const { column, direction, tableData } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        tableData: _.sortBy(tableData, [clickedColumn]),
        direction: 'ascending',
      });

      return;
    }

    this.setState({
      tableData: tableData.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    });
  }

  render() {
    const { t, readOnly, silos, history, lng, mixingPlantId } = this.props;
    const { column, tableData, direction } = this.state;

    return(
      <div className='page'>
        <Segment raised>
          <Header as="h2" floated="left">
            <Header.Content>{t("silo.header")} - {this.props.mixingPlant.name}</Header.Content>
          </Header>

          <div style={{textAlign: 'right'}}>
            <Button.Group>
              <Button
                type="button"
                onClick={() => this.handleReturn()}
              >
                <Icon name="arrow left" />
                {t("buttons.return")}
              </Button>
            </Button.Group>
          </div>

          <div style={{clear: 'both'}}>
            <Buttons />
          </div>
          
          <p className="tableSortingTooltip">{t("general.table_sort_tooltip")}</p>
          <Table celled sortable selectable className="silosList">
            <Table.Header>
              <Table.Row style={{fontSize: '12px'}}>
                <Table.HeaderCell
                  sorted={column == 'name' ? direction : null}
                  onClick={() => this.handleSort('name')}
                  className="textSort">
                    {t("silo.name")}
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column == 'max' ? direction : null}
                  onClick={() => this.handleSort('max')}
                  className="numberSort">
                    {t("silo.max")}
                </Table.HeaderCell>

                {/* <Table.HeaderCell>
                  {t("silo.actual")}
                </Table.HeaderCell> */}

                {!readOnly && <Table.HeaderCell></Table.HeaderCell>}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tableData.map(silo => (
                <Table.Row key={silo.id} style={{fontSize: '12px'}}>
                  <Table.Cell>
                    {silo.name}
                  </Table.Cell>

                  <Table.Cell>
                    {formatNumber(parseFloat(silo.max), lng)}
                  </Table.Cell>

                  {/* <Table.Cell>
                    {formatNumber(parseFloat(silo.actual), lng)}
                  </Table.Cell> */}

                  {!readOnly && (<Table.Cell>
                    <div className="buttonLine" style={{justifyContent: 'center'}}>
                      <LabelledButton
                        label={t("silo.actions.edit")}
                        icon="edit outline"
                        onClick={() => history.push(`/auftragsdispo/mixing-plants/${mixingPlantId}/silos/${silo.id}`)}
                      />
                      <RemoveSiloMessage id={silo.id} />
                    </div>
                  </Table.Cell>)}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

const Silos_i18nWrapper = withNamespaces('translation')(Silos);

function mapStateToProps(state, props) {
  const permissions = state.permissions;
  const mixingPlants = state.mixingPlants;
  const history = props.history;
  let silos = state.silos;
  silos = silos.filter(s => s.mixing_plants_id == props.match.params.id);
  const tableData = silos.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  if(!('id' in props.match.params)) props.history.push('/auftragsdispo/base-data?tab=mixingplants');

  const mixingPlant = mixingPlants.filter(c => c.id == props.match.params.id);

  if(mixingPlant.length == 0) props.history.push('/auftragsdispo/base-data?tab=mixingplants');

  return {  
    readOnly: permissions.filter(p => p.perm_type == 'MENU_SILO' && p.perm_desc === 'W').length < 1,
    silos,
    mixingPlant: mixingPlant[0],
    mixingPlantId: props.match.params.id,
    history,
    tableData
  };
}

export default connect(mapStateToProps, {})(Silos_i18nWrapper);