import React from 'react';
import { withNamespaces } from 'react-i18next';
import {
  Header,
  Segment,
  Popup
} from "semantic-ui-react";
import { connect } from 'react-redux';
import Timeline, {
  CursorMarker,
  CustomMarker
} from '../Timeline';
import moment from 'moment';
import Buttons from './Buttons';
import DayViewItem from '../DayViewItem/DayViewItem';
import { HourViewItem } from '../HourViewItem/HourViewItem';
import {
  editOrder,
  updateOrders,
  removeOrder,
  addMultipleOrders,
  TAKT_GROUP_ID,
  removeMultipleOrders,
  addOrder,
  DEFAULT_MIXING_PLANT_ID,
  DAYVIEW_CAPACITY_GROUP
} from '../../actions/orders';
import { updateAppState, viewProductionTakt } from '../../actions/app';
import { updateDeleted } from '../../actions/deleted';
import {
  addProductionTakt,
  addMultipleProductionTakt,
  editProductionTakt,
  removeProductionTakt,
  removeMultipleProductionTakt,
  updateProductionTakt
} from '../../actions/productionTakt';
import OrderModal from './OrderModal';
import CutModal from './CutModal';
import CutOrderModal from './CutOrderModal';
import UpdateSiloModal from './UpdateSiloModal';
import ShowTaktModal from './ShowTaktModal';
import uuid from 'uuid/v4';
import { ProductionTaktItem } from '../ProductionTaktItem/ProductionTaktItem';
import { asyncForEach } from '../../helpers/async-foreach';
import { assignSilo, isSiloAvailable, isSiloAvailableSynchronous } from '../../helpers/is-silo-empty';
import { TotalTaktItem } from '../TotalTaktItem/TotalTaktItem';
import { loadSiloCapacityData, siloCapacityData } from '../../helpers/silo-capacity-helper';
import containerResizeDetector from '../Timeline/resize-detector/container';
import { MAIN_CONTEXT } from '../Timeline/lib/timeline/timelineContexts';
import { WIEGEMEISTER_PERMISSION, MISCHMEISTER_PERMISSION, ADMIN_PERMISSION, PRODUCTIONTAKT_PERMISSION } from '../../constants/constants';

const steps = {
  0: {
    unit: "hour",
    value: 12
  },
  1: {
    unit: "day",
    value: 1
  },
  2: {
    unit: "day",
    value: 2
  },
  3: {
    unit: "day",
    value: 7
  },
  4: {
    unit: "day",
    value: 14
  },
  5: {
    unit: "week",
    value: 4
  }
};

let moving = false;

class Orders extends React.Component {

  state = {
    intendedFocus: 'day',
    visibleTimeStart: moment(),
    visibleTimeEnd: moment().add(7, 'days'),
    step: this.props.step,
    mixingPlantFilter: [],
    showHorizontalCapacity: false,
    undo: [],
    redo: [],
    selected: [],
    showEditModal: false,
    editItemId: 0,
    showCutModal: false,
    showCutOrderModal: false,
    showUpdateSilo: false,
    taktToUpdateSilo: undefined,
    showTaktModal: false,
    cursorMarkerDate: 0,
    showSiloCapacityGraph: false,
    taktToCut: undefined,
    orderToCut: undefined,
    customMarkerStart: 0,
    customMarkerEnd: 0,
    isMoving: false,
    shouldUpdate: true,
    orderFromTaktOnDrag: 0,
    lineColor: 'black'
  }

  async componentDidMount() {
    let {
      step,
      visibleTimeStart,
      visibleTimeEnd,
      items,
      mixingPlantFilter,
      showHorizontalCapacity,
      showSiloCapacityGraph
    } = this.props;

    if (visibleTimeStart === 0) {
      visibleTimeStart = moment().startOf(steps[step].unit);
    }

    if (visibleTimeEnd === 0) {
      visibleTimeEnd = moment()
        .startOf(steps[step].unit)
        .add(steps[step].value, steps[step].unit);
    }

    let newProductionTakt = items.filter(i => i.group === TAKT_GROUP_ID && i.new);

    newProductionTakt = newProductionTakt.map(npt => ({
      id: npt.id,
      offset: -60,
      orders_id: npt.orders_id,
      duration: parseFloat(npt.duration).toFixed(2),
      amount: parseFloat(npt.percentage).toFixed(2)
    }))

    if (newProductionTakt.length > 0) await this.props.addMultipleProductionTakt(newProductionTakt);

    await this.setState({
      visibleTimeStart,
      visibleTimeEnd,
      step,
      mixingPlantFilter,
      showHorizontalCapacity,
      showSiloCapacityGraph,
      groups: this.props.groups
    });

    await assignSilo();

    await loadSiloCapacityData(visibleTimeStart, visibleTimeEnd);
  }

  componentWillUnmount() {
    const {
      visibleTimeStart,
      visibleTimeEnd,
      step,
      mixingPlantFilter,
      showHorizontalCapacity,
      showSiloCapacityGraph
    } = this.state;

    const stateToUpdate = {
      visibleTimeStart,
      visibleTimeEnd,
      step,
      mixingPlantFilter,
      showHorizontalCapacity,
      showSiloCapacityGraph
    }

    this.props.updateAppState(stateToUpdate);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.shouldUpdate;
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.isMoving) return null;

    let prevNewProductionTakt = prevProps.items.filter(i => i.group === TAKT_GROUP_ID && i.new);
    let currentNewProductionTakt = this.props.items.filter(i => i.group === TAKT_GROUP_ID && i.new);

    if (prevProps.multiSelect !== this.props.multiSelect && !this.props.isMixtureMaster) {
      const selected = this.state.selected;

      this.setState(prevState => {
        return {
          selected: selected.length > 0 ? [selected[selected.length - 1]] : []
        }
      });
    }

    if (prevNewProductionTakt.length !== currentNewProductionTakt.length) {
      currentNewProductionTakt = currentNewProductionTakt.map(npt => ({
        id: npt.id,
        offset: -60,
        orders_id: npt.orders_id,
        duration: parseFloat(npt.duration).toFixed(2),
        amount: parseFloat(npt.percentage).toFixed(2)
      }))

      if (currentNewProductionTakt.length > 0) await this.props.addMultipleProductionTakt(currentNewProductionTakt);

      await assignSilo();
    }

    if (
      (prevState.visibleTimeStart !== this.state.visibleTimeStart ||
        prevState.visibleTimeEnd !== this.state.visibleTimeEnd) &&
      this.props.isMixtureMaster
    ) {
      await loadSiloCapacityData(this.state.visibleTimeStart, this.state.visibleTimeEnd);
    }

    if (prevProps.admin_view_prod_takt !== this.props.admin_view_prod_takt) {
      let visibleTimeStart = this.state.visibleTimeStart
      if (visibleTimeStart === 0) {
        visibleTimeStart = moment().startOf(steps[this.props.step].unit);
      } else {
        visibleTimeStart = moment(visibleTimeStart).startOf(steps[this.props.step].unit);
      }

      let visibleTimeEnd = this.state.visibleTimeEnd;
      if (visibleTimeEnd === 0) {
        visibleTimeEnd = moment()
          .startOf(steps[this.props.step].unit)
          .add(steps[this.props.step].value, steps[this.props.step].unit);
      } else {
        visibleTimeEnd = moment(visibleTimeStart).add(steps[this.props.step].value, steps[this.props.step].unit);
      }

      await this.setState({
        visibleTimeStart,
        visibleTimeEnd,
        step: this.props.step
      });

    }

    if (prevProps.groups.length !== this.props.groups.length) {
      this.setState({
        groups: this.props.groups
      });
    }
  }

  handleOnUnitChange = (newUnit, oldUnit) => {
    this.setState({
      intendedFocus: newUnit
    })
  }

  onItemMove = async (itemId, dragTime, newGroupOrder, group) => {
    const { orders, groups, items, productionTakt, isMixtureMaster, deleted, multiSelect, isAdmin } = this.props;
    let { intendedFocus, selected, mixingPlantFilter } = this.state;
    const movedItem = items.filter(i => i.id == itemId)[0];
    const itemMovedIsOrder = !('type' in movedItem);


    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    let avaiableMixingPlants = groups;

    if (mixingPlantFilter.length > 0) {
      avaiableMixingPlants = groups.filter(g => mixingPlantFilter.indexOf(g.id) !== -1);
    }

    if (intendedFocus === 'hour') intendedFocus = 'minutes';

    let order = orders.filter(o => o.id == itemId);
    let diff = 0;

    if (order.length > 0) {
      let start = intendedFocus === 'day'
        ? moment(order[0].materials.start).startOf('day')
        : moment(order[0].materials.start);

      diff = moment(dragTime).diff(start, intendedFocus);
    }

    const movedDiff = moment(dragTime).diff(moment(movedItem.start_time), intendedFocus);

    await asyncForEach(selected, async (selectedItemId) => {
      const selectedItem = items.filter(i => i.id == selectedItemId)[0];

      if (!selectedItem) return;
      if (!isAdmin && isMixtureMaster && !('type' in selectedItem && selectedItem.type === 'TAKT')) return;

      if ('type' in selectedItem && selectedItem.type === 'TAKT' && !itemMovedIsOrder) {
        let selectedProductionTakt = productionTakt.filter(pt => pt.id == selectedItemId);
        selectedProductionTakt = selectedProductionTakt[0];

        let takts = JSON.parse(JSON.stringify(productionTakt.filter(pt => pt.orders_id == selectedProductionTakt.orders_id)));

        let order = orders.filter(o => o.id == selectedProductionTakt.orders_id);

        let offset = 0;

        takts.sort((a, b) => {
          let orderA = orders.filter(o => o.id == a.orders_id)[0];
          let orderB = orders.filter(o => o.id == b.orders_id)[0];

          let produktTaktStartA = moment(orderA.materials.start).add(a.offset, 'minutes').valueOf();
          let produktTaktStartB = moment(orderB.materials.start).add(b.offset, 'minutes').valueOf();

          if (produktTaktStartA > produktTaktStartB) return 1;
          if (produktTaktStartA < produktTaktStartB) return -1;
          return 0;
        });

        let selectedProductionTaktIndex = 0;
        takts.some((t, index) => {
          if (t.id == selectedProductionTakt.id) {
            selectedProductionTaktIndex = index;
            return true;
          }
          return false;
        });

        let firstTakt = takts[0];
        let firstTaktStart = moment(order[0].materials.start).add((parseFloat(firstTakt.offset) + movedDiff), 'minutes').valueOf();

        let lastTakt = takts[takts.length - 1];
        let lastTaktStart = moment(order[0].materials.start).add((parseFloat(lastTakt.offset) + movedDiff), 'minutes').valueOf();
        let lastTaktEnd = moment(lastTaktStart).add(lastTakt.duration, 'minutes').valueOf();

        if (lastTaktEnd > order[0].materials.end || firstTaktStart > order[0].materials.start) {

          if (lastTaktEnd > order[0].materials.end && takts.length > 1 && selectedProductionTaktIndex !== 0) {

            let previousTakt = undefined;
            if (selectedProductionTaktIndex === 0) {
              previousTakt = takts[0];
            } else {
              previousTakt = takts[selectedProductionTaktIndex - 1];
            }

            let previousTaktStart = moment(order[0].materials.start).add((parseFloat(previousTakt.offset)), 'minutes').valueOf();
            let offset1 = parseFloat(firstTakt.duration) - moment(order[0].materials.start).diff(moment(previousTaktStart), 'minutes');

            offset = offset1 + 5;
          } else {
            offset = 0;
          }

        } else {
          if (selectedItemId == itemId) {
            offset = moment(dragTime).diff(order[0].materials.start, intendedFocus);
          } else {
            offset = moment(selectedItem.start_time).diff(order[0].materials.start, intendedFocus);
          }
        }

        selectedProductionTakt.offset = offset;

        await this.props.editProductionTakt(selectedProductionTakt);
        await loadSiloCapacityData(this.state.visibleTimeStart, this.state.visibleTimeEnd);

        if (multiSelect || dragTime > order[0].materials.end) {
          this.setState({ selected: [], isMoving: false, shouldUpdate: true, orderFromTaktOnDrag: 0 });
        } else {
          this.setState({ isMoving: false, shouldUpdate: true, orderFromTaktOnDrag: 0 });
        }

        return;
      }

      let order = orders.filter(o => o.id == selectedItemId);

      if (order.length > 0 && itemMovedIsOrder) {
        order = order[0];

        if (selectedItemId == itemId) {
          const diff = moment(dragTime).diff(selectedItem.start_time, intendedFocus);
          order.materials.start = moment(order.materials.start).add(diff, intendedFocus).valueOf();
          order.materials.end = moment(order.materials.end).add(diff, intendedFocus).valueOf();

        } else {
          order.materials.start = moment(order.materials.start).add(diff, intendedFocus).valueOf();
          order.materials.end = moment(order.materials.end).add(diff, intendedFocus).valueOf();
        }

        let mixingPlant = avaiableMixingPlants.filter(mp => mp.id == group.id)[0];

        if (avaiableMixingPlants.length === 1) {
          mixingPlant = avaiableMixingPlants[0];
        }

        if (!mixingPlant) return;

        if (order.mixingPlant !== mixingPlant.id && order.mixingPlant === DEFAULT_MIXING_PLANT_ID && order.materials.status === 'CUSTOMER') {
          order.materials.status = 'STANDARD';
        }

        if (order.mixingPlant !== mixingPlant.id) {
          const orderProductionTakt = productionTakt.filter(pt => pt.orders_id == order.id)[0];

          if (orderProductionTakt) {
            orderProductionTakt.silo = null;
            await this.props.editProductionTakt(orderProductionTakt);
          }
        }

        if (selected.length === 1) {
          order.mixingPlant = mixingPlant.id;
        }

        await this.props.editOrder(order);
      }
    });

    if (multiSelect) {
      this.setState({ selected: [], isMoving: false, shouldUpdate: true, orderFromTaktOnDrag: 0 });
    } else {
      this.setState({ isMoving: false, shouldUpdate: true, orderFromTaktOnDrag: 0 });
    }

    await loadSiloCapacityData(this.state.visibleTimeStart, this.state.visibleTimeEnd);
  }



  onItemResize = async (itemId, time, edge) => {
    const { orders, items, productionTakt, deleted } = this.props;

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    const selectedItem = items.filter(i => i.id == itemId)[0];

    if ('type' in selectedItem && selectedItem.type === 'TAKT') {
      let selectedProductionTakt = productionTakt.filter(pt => pt.id == itemId);
      selectedProductionTakt = selectedProductionTakt[0];

      let order = orders.filter(o => o.id == selectedProductionTakt.orders_id);

      let selectedProductionTaktStart = moment(order[0].materials.start).add(selectedProductionTakt.offset, 'minutes');
      let selectedProductionTaktEnd = moment(selectedProductionTaktStart).add(selectedProductionTakt.duration, 'minutes').valueOf();

      if (edge === 'right') {
        const duration = moment(time).diff(moment(selectedProductionTaktStart), 'minutes');

        selectedProductionTakt.duration = duration;

        let selectedProductionTaktEnd = moment(selectedProductionTaktStart).add(duration, 'minutes').valueOf();

        if (selectedProductionTaktEnd > order[0].materials.end) {
          let duration = moment(order[0].materials.end).diff(moment(selectedProductionTaktStart), 'minutes');
          selectedProductionTakt.duration = duration;
        }
      } else {
        const duration = moment(selectedProductionTaktEnd).diff(moment(time), 'minutes');
        const offset = moment(time).diff(moment(order[0].materials.start), 'minutes');

        selectedProductionTakt.duration = duration;
        selectedProductionTakt.offset = offset;
      }

      await this.props.editProductionTakt(selectedProductionTakt);
      await loadSiloCapacityData(this.state.visibleTimeStart, this.state.visibleTimeEnd);
      return;
    }

    let order = orders.filter(o => o.id == itemId);

    if (order.length > 0) {
      order = order[0];

      if (edge === 'right') {
        order.materials.end = time;
      } else {
        order.materials.start = time;
      }

      await this.props.editOrder(order);
      await loadSiloCapacityData(this.state.visibleTimeStart, this.state.visibleTimeEnd);
    }

  }

  handleZoomIn = _ => {
    let { step, visibleTimeStart, visibleTimeEnd } = this.state;

    if (step > 0) {
      step = step - 1;
      visibleTimeStart = moment(visibleTimeStart).startOf(steps[step].unit);
      visibleTimeEnd = moment(visibleTimeStart).add(steps[step].value, steps[step].unit);

      this.setState({
        step,
        visibleTimeStart: visibleTimeStart,
        visibleTimeEnd,
        selected: []
      });
    }
  };

  handleZoomOut = _ => {
    let { step, visibleTimeStart, visibleTimeEnd } = this.state;

    if (step < Object.keys(steps).length - 1) {
      step = step + 1;

      visibleTimeStart = moment(visibleTimeStart).startOf(steps[step].unit);
      visibleTimeEnd = moment(visibleTimeStart).add(steps[step].value, steps[step].unit);

      this.setState({
        step,
        visibleTimeStart: visibleTimeStart,
        visibleTimeEnd,
        selected: []
      });
    }
  };

  stopMoving = _ => {
    clearInterval(moving);
  }

  left = e => {
    if (e.nativeEvent.button && e.nativeEvent.button !== 0) return;

    let { intendedFocus } = this.state;

    let step = -60;
    if (intendedFocus === 'hour' || intendedFocus === "minute") {
      step = -15;
    }

    if (intendedFocus === "day") {
      step = -(6 * 60);
    }

    if (intendedFocus === "week" || intendedFocus === "month") {
      step = -(12 * 60);
    }

    moving = setInterval(_ => {
      this.setState(prevState => ({
        visibleTimeStart: moment(prevState.visibleTimeStart).add(step, 'minutes'),
        visibleTimeEnd: moment(prevState.visibleTimeEnd).add(step, 'minutes')
      }));
    }, 10);
  }

  right = e => {
    if (e.nativeEvent.button && e.nativeEvent.button !== 0) return;

    let { intendedFocus } = this.state;

    let step = 60;
    if (intendedFocus === 'hour' || intendedFocus === "minute") {
      step = 15;
    }

    if (intendedFocus === "day") {
      step = 6 * 60;
    }

    if (intendedFocus === "week" || intendedFocus === "month") {
      step = 12 * 60;
    }

    moving = setInterval(_ => {
      this.setState(prevState => ({
        visibleTimeStart: moment(prevState.visibleTimeStart).add(step, 'minutes'),
        visibleTimeEnd: moment(prevState.visibleTimeEnd).add(step, 'minutes')
      }));
    }, 10);
  }

  onRowClickFilter = mpId => {
    const { mixingPlantFilter: mpFilter } = this.state;

    if (mpFilter.length === 1 && mpFilter[0] === mpId)
      this.setMixingPlantFilter([]);
    else
      this.setMixingPlantFilter([mpId]);
  }

  setMixingPlantFilter = filterValue => {
    const { groups } = this.props;

    let filteredGroups = groups;
    if (filterValue.length > 0) {
      filteredGroups = groups.filter(g => filterValue.indexOf(g.id) !== -1)
    }

    this.setState({
      mixingPlantFilter: filterValue,
      groups: filteredGroups
    });
  }

  handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.setState({ visibleTimeStart, visibleTimeEnd });
  };

  handleShowHorizontalCapacity = _ => {
    console.log('enteredddd')
    this.setState((prevState) => {
      return {
        showHorizontalCapacity: !prevState.showHorizontalCapacity,
        showSiloCapacityGraph: false
      };
    });
  }

  undo = _ => {
    const { undo } = JSON.parse(JSON.stringify(this.state));
    const { orders, productionTakt, deleted } = this.props;

    if (undo.length > 0) {
      this.saveToRedo({
        orders: JSON.parse(JSON.stringify(orders)),
        productionTakt: JSON.parse(JSON.stringify(productionTakt)),
        deleted: JSON.parse(JSON.stringify(deleted))
      });

      let last_entrance = undo.length - 1;
      let undoState = undo[last_entrance].items;

      this.props.updateOrders(undoState.orders);
      this.props.updateProductionTakt(undoState.productionTakt);
      this.props.updateDeleted(undoState.deleted);

      undo.pop();

      this.setState({
        undo: undo,
        selected: []
      });
    }
  };

  redo = _ => {
    const { redo } = JSON.parse(JSON.stringify(this.state));
    const { orders, productionTakt, deleted } = this.props;

    if (redo.length > 0) {
      let cleanRedo = false;
      this.saveToUndo({
        orders: JSON.parse(JSON.stringify(orders)),
        productionTakt: JSON.parse(JSON.stringify(productionTakt)),
        deleted: JSON.parse(JSON.stringify(deleted))
      }, cleanRedo);

      let last_entrance = redo.length - 1;
      let redoState = redo[last_entrance].items;

      this.props.updateOrders(redoState.orders);
      this.props.updateProductionTakt(redoState.productionTakt);
      this.props.updateDeleted(redoState.deleted);

      redo.pop();

      this.setState({
        redo,
        selected: []
      });
    }
  };

  saveToRedo = items => {
    let redo = [].concat(this.state.redo, {
      items
    });

    this.setState({ redo });
  };

  saveToUndo = async (itemsToSave, clean = true) => {
    const { undo } = this.state;

    let items = itemsToSave;

    let newUndo = [].concat(undo, {
      items
    });

    if (clean) this.cleanRedo();
    await this.setState({ undo: newUndo });
  };

  cleanRedo = _ => {
    this.setState({ redo: [] });
  };

  openModalAction = (showEditModal, editItemId) => {
    this.setState({ showEditModal, editItemId });
  }

  removeOrder = async (orderId) => {
    const { orders, productionTakt, deleted } = this.props;

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });
    await this.props.removeOrder(orderId);
  }

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    selected,
    percentage,
    shouldShowPercentage,
    showHorizontalCapacity,
    totalForItemsByDayAndMixingPlant,
    maxTonnageByDay,
    t,
    resizing,
    time_end,
    time_start
  }) => {

    console.log(item,'itemssss');
    if ('isCapacity' in item && item.isCapacity) {

      const itemProps = { ...getItemProps(item.itemProps) };

      itemProps.style.background = '#ffffff00';
      itemProps.style.textAlign = "center";

      let borderColor = 'black';
      let percentage = item.percentage;
      let backgroundColor = "#badbff";
      let classes = "";

      if (item.percentage > 100) {
        borderColor = 'black';
        backgroundColor = "red";
        percentage = 100;
        classes = "overcapacity"
      }

      let marginTop = '1px';

      return (
        <Popup
          wide={"very"}
          trigger={
            <div {...itemProps}>
              <div className="rct-capacity-item rct-item-content rct-item-center-scaled">
                <div style={{
                  background: '#badbff',
                  width: itemContext.dimensions.width,
                  height: itemContext.dimensions.height - 5,
                  border: `1.5px solid ${borderColor}`,
                  position: 'relative',
                  WebkitTransform: "scaleX(0.91)",
                  transform: "scaleX(0.91)",
                }}>
                  <div style={{
                    width: `${percentage}%`,
                    height: '10.7px',
                    marginTop,
                    backgroundColor: backgroundColor
                  }}
                    className={classes}
                  ></div>
                </div>
              </div>
            </div>
          }
          content={(
            <div>
              <p>
                {`${item.total} ${this.props.t("text.from")} ${item.tonday} t (${item.percentage}%)`}
              </p>
              {/* <p>{this.props.t("mixingplant.tonday")}: {item.tonday}</p>
              <p>{this.props.t("mixingplant.usedtonday")}: {item.total}</p>
              <p>{this.props.t("mixingplant.percentage")}: {item.percentage}%</p> */}
            </div>
          )}
          position="bottom center"
          hideOnScroll
        />
      );
    }

    if ('type' in item && item.type === 'TAKT') {
      let selectedItems = this.props.items.filter(i => this.state.selected.indexOf(i.id) !== -1 && i.group === TAKT_GROUP_ID);
      let selectedQuantity = selectedItems.length;

      return ProductionTaktItem({
        item,
        itemContext,
        getItemProps,
        getResizeProps,
        selected,
        percentage,
        shouldShowPercentage,
        showHorizontalCapacity,
        totalForItemsByDayAndMixingPlant,
        maxTonnageByDay,
        t,
        resizing,
        openModal: this.openModalAction,
        history: this.props.history,
        time_end,
        time_start,
        selectedQuantity
      });
    }

    if (this.props.isMixtureMaster && item.group === TAKT_GROUP_ID) {
      let selectedItems = this.props.items.filter(i => this.state.selected.indexOf(i.id) !== -1 && i.group === TAKT_GROUP_ID);
      let selectedQuantity = selectedItems.length;

      return TotalTaktItem({
        item,
        itemContext,
        getItemProps,
        getResizeProps,
        selected,
        percentage,
        shouldShowPercentage,
        showHorizontalCapacity,
        totalForItemsByDayAndMixingPlant,
        maxTonnageByDay,
        t,
        resizing,
        openModal: this.openModalAction,
        history: this.props.history,
        time_end,
        time_start,
        selectedQuantity
      });
    }

    if (this.state.intendedFocus === 'hour') {
      return HourViewItem({
        item,
        itemContext,
        getItemProps,
        getResizeProps,
        selected,
        resizing,
        openModal: this.openModalAction,
        history: this.props.history,
        isAdmin: this.props.isAdmin,
        isMixtureMaster: this.props.isMixtureMaster,
        isScaleMaster: this.props.isScaleMaster,
        allMixingplants: this.props.allMixingplants,
      });
    }

    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const itemProps = { ...getItemProps(item.itemProps) };

    if (itemProps.selected) {
      itemProps.className += "selected";
    }

    let horirontalCapacitySelected = {
      transform: 'scale(1.1)'
    };

    if (!selected) {
      this.openModal = (e) => { };
      itemProps.style.fontWeight = 300;
    } else {
      this.openModal = this.openModalAction;
      itemProps.style.fontWeight = 'bolder';
    }

    itemProps.className = `rct-custom ${itemProps.className} rct-day-view`;
    itemProps.style.lineHeight = `${(itemContext.dimensions.height / 3) - 5}px`;

    return (
      <div {...itemProps}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
        {shouldShowPercentage && showHorizontalCapacity &&
          <Popup
            trigger={(
              <div className='horizontal-capacity' style={horirontalCapacitySelected}>
                <div className='horizontal-capacity-progress' style={{ width: `${percentage}%` }}></div>
              </div>
            )}
            content={(
              <div>
                <p>
                  {`${totalForItemsByDayAndMixingPlant} ${t("text.from")} ${maxTonnageByDay} t (${percentage}%)`}
                </p>
              </div>
            )}
            position='top center'
            hideOnScroll
          />
        }
        <div
          className="rct-custom rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          <DayViewItem
            item={item}
            selected={selected}
            openModal={this.openModal}
            shouldShowPercentage={shouldShowPercentage}
            showHorizontalCapacity={showHorizontalCapacity}
            percentage={percentage}
            maxTonnageByDay={maxTonnageByDay}
            totalForItemsByDayAndMixingPlant={totalForItemsByDayAndMixingPlant}
          />
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    );
  }

  selectMultipleItems = (itemsArray) => {
    const { items } = this.props;
    let itemsIds = items.map((i) => { return i.id });

    let selectedItems = itemsArray.filter(i => itemsIds.includes(i.id));
    let selectedItemsIds = selectedItems.map((si) => { return si.id });

    if (!selectedItems) return;

    this.setState(prevState => ({
      selected: prevState.selected.concat(selectedItemsIds)
    }));
  }

  onItemSelect = (itemId, e, time) => {
    const { items, isMixtureMaster, isAdmin, multiSelect } = this.props;
    let { step } = this.state;

    let selectedItem = items.filter(i => i.id == itemId)[0];

    if (!selectedItem) return;

    if ((isMixtureMaster && !isAdmin) &&
      (!('type' in selectedItem && selectedItem.type === 'TAKT') && selectedItem.group !== TAKT_GROUP_ID)
    ) return;

    let newSelected = [itemId];

    if (isMixtureMaster) {
      let alsoSelect = '';
      if (typeof itemId === "string" && itemId.indexOf('_1') !== -1) {
        alsoSelect = itemId.slice(0, -2);
      } else {
        alsoSelect = `${itemId}_1`;
      }

      newSelected = [itemId, selectedItem.orders_id, alsoSelect];
    }

    let customMarkerStart = 0;
    let customMarkerEnd = 0;
    if ('type' in selectedItem && selectedItem.type === 'TAKT') {
      customMarkerStart = moment(selectedItem.start_time).valueOf();
      customMarkerEnd = moment(selectedItem.end_time).valueOf();

      if (step === 0) {
        customMarkerEnd = moment(selectedItem.end_time).add(-1, 'minutes').valueOf();
      }

      if (step === 1) {
        customMarkerEnd = moment(selectedItem.end_time).add(-5, 'minutes').valueOf();
      }
    }

    let lineColor = "black";

    if (multiSelect || e.shiftKey) {
      let selected = Object.assign(this.state.selected);
      let itemIndex = selected.indexOf(newSelected[0]);

      if (itemIndex !== -1) {
        selected.splice(itemIndex, 1);

        this.setState({
          selected,
          customMarkerStart,
          customMarkerEnd,
          lineColor
        });
      }
      else {
        this.setState(prevState => ({
          selected: prevState.selected.concat(newSelected),
          customMarkerStart,
          customMarkerEnd,
          lineColor
        }));
      }
    } else {
      this.setState(prevState => ({
        selected: newSelected,
        customMarkerStart,
        customMarkerEnd,
        lineColor
      }));
    }
  }

  onCanvasClick = (groupId, time, e) => {
    this.setState(prevState => ({
      selected: []
    }));
  }

  showCutModal = _ => {
    const { selected } = this.state;
    const { items } = this.props;

    if (selected.length === 0) return;

    let selectedItem = items.filter(i => selected.indexOf(i.id) !== -1 && 'type' in i);

    if (selectedItem.length !== 1) return;

    this.setState(prevState => ({
      showCutModal: !prevState.showCutModal,
      taktToCut: selectedItem[0].id
    }));
  }

  showCutOrderModal = _ => {
    const { selected } = this.state;
    const { items } = this.props;

    if (selected.length === 0) return;

    let selectedItem = items.filter(i => selected.indexOf(i.id) !== -1 && !('type' in i));

    if (selectedItem.length > 1) return;

    this.setState(prevState => ({
      showCutOrderModal: !prevState.showCutOrderModal,
      orderToCut: selectedItem[0].id
    }));
  }

  showTaktModal = _ => {
    const { mixingPlantFilter } = this.state;

    if (mixingPlantFilter.length === 1) {
      this.props.viewProductionTakt(mixingPlantFilter[0]);
      return;
    }

    this.setState(prevState => ({ showTaktModal: !prevState.showTaktModal }));
  }

  showSiloCapacityGraph = _ => {
    this.setState(prevState => ({ showSiloCapacityGraph: !prevState.showSiloCapacityGraph }))
  }

  cutOrder = async (amount) => {
    amount = parseFloat(amount);
    const { selected } = this.state;
    const { items, productionTakt, orders, deleted } = this.props;

    if (selected.length === 0) return;

    let selectedItem = items.filter(i => selected.indexOf(i.id) !== -1 && !('duration' in i));

    if (selectedItem.length > 1 || selectedItem.length === 0) return;

    selectedItem = selectedItem[0];

    if (selectedItem.group === TAKT_GROUP_ID) return;

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    const order = orders.filter(o => o.id == selectedItem.id)[0];
    let orderStart = order.materials.start;
    let orderEnd = order.materials.end;
    let orderAmount = order.materials.amount;
    let orderSplitTime = orderStart + (orderEnd - orderStart) / orderAmount * amount;
    let orderTpH = orderAmount / (orderEnd - orderStart);

    const orderProductions = productionTakt.filter(pt => {
      return pt.orders_id == order.id
    });

    orderProductions.sort((a, b) => {
      if (a.offset > b.offset) return 1;
      else if (a.offset < b.offset) return -1;

      return 0;
    });

    if (!order) return;

    let newOrder_1 = JSON.parse(JSON.stringify(order));

    newOrder_1.materials.amount = amount;
    newOrder_1.id = uuid();

    let newOrder1Duration = parseFloat(newOrder_1.materials.amount) / orderTpH;
    newOrder_1.materials.end = moment(newOrder_1.materials.start).add(newOrder1Duration, "milliseconds").valueOf();


    let newOrder_2 = JSON.parse(JSON.stringify(order));

    newOrder_2.materials.amount = Math.abs(amount - parseFloat(order.materials.amount));
    newOrder_2.id = uuid();

    let newOrder2Duration = parseFloat(newOrder_2.materials.amount) / orderTpH;
    newOrder_2.materials.end = moment(newOrder_1.materials.end).add(newOrder2Duration, "milliseconds").valueOf();
    newOrder_2.materials.start = moment(newOrder_1.materials.end).valueOf()

    let currentAmount = 0;
    let alreadySplit = false;
    let shouldSplit = true;

    for (let i = 0; i < orderProductions.length; i++) {
      let production = orderProductions[i];

      let productionStart = moment(orderStart).add(production.offset, "minutes").valueOf();
      let productionAmount = parseFloat(production.amount) / 100 * orderAmount;
      let productionDuration = parseFloat(production.duration);
      let productionOffset = parseFloat(production.offset);
      let productionTpH = (productionAmount / productionDuration) * 60;

      let newProductionAmount2 = productionAmount - (amount - currentAmount);
      let newProductionAmount1 = productionAmount - newProductionAmount2;
      let newProductionPercentageScaled1 = productionAmount / newOrder_1.materials.amount * 100;
      let newProductionPercentageScaled2 = productionAmount / newOrder_2.materials.amount * 100;

      let newProductionPercentage1 = newProductionAmount1 / newOrder_1.materials.amount * 100;
      let newProductionPercentage2 = newProductionAmount2 / newOrder_2.materials.amount * 100;

      if (newProductionPercentage1 === 0 || newProductionPercentage2 === 0)
        shouldSplit = false;
      else
        shouldSplit = true;

      let newProductionDuration1 = newProductionAmount1 / productionTpH;
      let newProductionDuration2 = newProductionAmount2 / productionTpH;

      let scaledProductionOffset = productionOffset + moment(newOrder_1.materials.start + productionOffset).diff(newOrder_2.materials.start, "minutes");
      let scaledProductionSplitOffset = (newProductionDuration1 * 60) + moment(newOrder_1.materials.start).add(productionOffset + newProductionDuration1, "minutes").diff(newOrder_2.materials.start, "minutes");

      currentAmount += productionAmount;

      if (currentAmount > amount && productionStart < orderSplitTime && !alreadySplit && shouldSplit) {

        let newProduction1 = {
          id: uuid(),
          offset: productionOffset,
          amount: newProductionPercentage1,
          duration: newProductionDuration1 * 60,
          orders_id: newOrder_1.id,
          silo: production.silo
        };

        productionOffset = productionOffset + newProductionDuration1 * 60;

        let newProduction2 = {
          id: uuid(),
          offset: scaledProductionSplitOffset,
          amount: newProductionPercentage2,
          duration: newProductionDuration2 * 60,
          orders_id: newOrder_2.id,
          silo: production.silo
        };

        await this.props.addMultipleProductionTakt([newProduction1, newProduction2]);
        alreadySplit = true;
      }
      else if (currentAmount <= amount) {
        let newProduction = {
          id: uuid(),
          offset: production.offset,
          amount: newProductionPercentageScaled1,
          duration: production.duration,
          orders_id: newOrder_1.id,
          silo: production.silo
        };

        await this.props.addProductionTakt(newProduction);
      }
      else if (currentAmount >= amount) {
        let newProduction = {
          id: uuid(),
          offset: scaledProductionOffset,
          amount: newProductionPercentageScaled2,
          duration: production.duration,
          orders_id: newOrder_2.id,
          silo: production.silo
        };

        await this.props.addProductionTakt(newProduction);
      }
    }

    await this.setState(prevState => ({
      selected: [],
      showCutOrderModal: !prevState.showCutOrderModal
    }));
    await this.props.addMultipleOrders([newOrder_1, newOrder_2]);
    await this.props.removeOrder(order.id);
    await this.props.removeMultipleProductionTakt(orderProductions);
  }

  cut = async (amount) => {
    const { selected } = this.state;
    const { items, productionTakt, orders, deleted } = this.props;

    if (selected.length === 0) return;

    let selectedItem = items.filter(i => selected.indexOf(i.id) !== -1 && 'type' in i);

    if (selectedItem.length !== 1) return;

    selectedItem = selectedItem[0];

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    let selectedProductionTakt = productionTakt.filter(pt => pt.id == selectedItem.id)[0];
    let order = orders.filter(o => o.id == selectedProductionTakt.orders_id)[0];

    let materialAmount = parseFloat((parseFloat(selectedProductionTakt.amount) / 100) * parseFloat(order.materials.amount)).toFixed(2);

    let tonsPerHour = Math.round(parseFloat(parseFloat(materialAmount) / selectedProductionTakt.duration) * 60);

    let selectedProductionTaktAmount = parseFloat((parseFloat(selectedProductionTakt.amount) / 100) * parseFloat(order.materials.amount));

    let timeToProduceTotalAmountAtStandardCapacity = parseFloat(selectedProductionTaktAmount) / parseFloat(tonsPerHour);
    let timeToProduceAtStandardCapacity = parseFloat(amount) / parseFloat(tonsPerHour);


    let amountPercentage1 = parseFloat((parseFloat(amount) / order.materials.amount) * 100);
    let amountPercentage2 = parseFloat(selectedProductionTakt.amount - amountPercentage1);

    let newProductionTakt = {
      id: uuid(),
      offset: selectedProductionTakt.offset,
      amount: amountPercentage1,
      duration: timeToProduceAtStandardCapacity * 60,
      orders_id: selectedProductionTakt.orders_id,
      silo: selectedProductionTakt.silo
    };

    let newOffset = parseFloat(selectedProductionTakt.offset) + parseFloat(newProductionTakt.duration);

    let newProductionTakt_2 = {
      id: uuid(),
      offset: newOffset,
      amount: amountPercentage2,
      duration: (timeToProduceTotalAmountAtStandardCapacity - timeToProduceAtStandardCapacity) * 60,
      orders_id: selectedProductionTakt.orders_id,
      silo: selectedProductionTakt.silo
    };

    await this.setState(prevState => ({
      selected: [],
      showCutModal: !prevState.showCutModal
    }));
    await this.props.addMultipleProductionTakt([newProductionTakt, newProductionTakt_2]);
    await this.props.removeProductionTakt(selectedProductionTakt.id);
  }

  mergeOrder = async () => {
    const { selected } = this.state;
    const { orders, productionTakt, deleted } = this.props;

    const selectedOrders = orders.filter(o => selected.indexOf(o.id) !== -1);

    if (selectedOrders.length !== 2) return;

    let canMerge = false;
    let totalOrderTime = 0;

    for (let index = 0; index < selectedOrders.length; index++) {
      let o = selectedOrders[index];
      let orderStart = o.materials.start;
      let orderEnd = o.materials.end;
      let orderDuration = orderEnd - orderStart;

      totalOrderTime += orderDuration;

      if (index === 0) continue;

      const element = selectedOrders[index];
      const prevElement = selectedOrders[index - 1];

      const sameConstructionSite = element.constructionSite === prevElement.constructionSite;
      const sameMixingPlant = element.mixingPlant === prevElement.mixingPlant;
      const sameMaterial = element.materials.material_id == prevElement.materials.material_id;

      canMerge = sameConstructionSite && sameMixingPlant && sameMaterial;

      if (!canMerge) break;
    }

    if (!canMerge) return;

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    selectedOrders.sort((a, b) => {
      if (parseFloat(a.materials.start) < parseFloat(b.materials.start)) return -1;
      if (parseFloat(a.materials.start) > parseFloat(b.materials.start)) return 1;
      return 0;
    });

    let newOrder = JSON.parse(JSON.stringify(selectedOrders[0]));
    delete newOrder['id'];
    delete newOrder['created_at'];
    delete newOrder['updated_at'];

    newOrder.materials.end = newOrder.materials.start + totalOrderTime;
    newOrder.materials.amount = selectedOrders.reduce((acc, order) =>
      acc + parseFloat(order.materials.amount), 0);

    await this.props.removeMultipleOrders(selectedOrders);
    await this.props.addOrder(newOrder);

    this.setState({ selected: [] });
  }

  merge = async () => {
    const { selected } = this.state;
    const { orders, productionTakt, deleted } = this.props;
    const selectedProductionTakt = productionTakt.filter(i => selected.indexOf(i.id) !== -1);
    if (selectedProductionTakt.length < 2) return;

    const ordersId = selectedProductionTakt.map(si => si.orders_id);

    let canMerge = Array.from(new Set(ordersId)).length === 1;

    if (!canMerge) return;

    await this.saveToUndo({
      orders: JSON.parse(JSON.stringify(orders)),
      productionTakt: JSON.parse(JSON.stringify(productionTakt)),
      deleted: JSON.parse(JSON.stringify(deleted))
    });

    selectedProductionTakt.sort((a, b) => {
      if (parseFloat(a.offset) < parseFloat(b.offset)) return -1;
      if (parseFloat(a.offset) > parseFloat(b.offset)) return 1;
      return 0;
    });

    let offset = selectedProductionTakt[0].offset;
    let amount = selectedProductionTakt.reduce((acc, item) => acc += parseFloat(item.amount), 0);
    let duration = selectedProductionTakt.reduce((acc, item) => acc += parseFloat(item.duration), 0);

    let newProductionTakt = {
      offset,
      amount,
      duration,
      orders_id: selectedProductionTakt[0].orders_id,
      silo: selectedProductionTakt[0].silo
    };

    await this.props.addProductionTakt(newProductionTakt);
    await this.props.removeMultipleProductionTakt(selectedProductionTakt);

    this.setState({ selected: [] });
  }

  handleOnDragItem = (itemId, dragTime, group, lastDragTime) => {
    let { items, orders, productionTakt } = this.props;
    let { selected, intendedFocus, orderFromTaktOnDrag } = this.state;

    if (intendedFocus === 'hour') intendedFocus = 'minutes';

    let movingItems = items.filter(i => selected.indexOf(i.id) !== -1);

    let selectedItem = items.filter(i => i.id == itemId)[0];

    if (!selectedItem) return;

    const isSelectedATakt = 'type' in selectedItem && selectedItem.type === 'TAKT';

    if (isSelectedATakt) {
      movingItems = movingItems.filter(mi => 'type' in mi && mi.type === 'TAKT');
    }

    this.setState(prevState => ({
      isMoving: true,
      shouldUpdate: movingItems.length === 1
    }));

    const selectedTakt = items.filter(i => selected.indexOf(i.id) !== -1 && 'type' in i && i.type === 'TAKT')[0];
    if (selectedTakt) {
      let diff = moment(lastDragTime).diff(moment(dragTime), "minutes");

      if (diff !== 0) {

        const newTaktStart = moment(dragTime);
        const newTaktEnd = moment(dragTime).add(selectedItem.duration, 'hour');
        isSiloAvailable(selectedTakt.id, selectedTakt.silo_id, newTaktStart).then((v) => {
          if (!v) {
            this.setState({
              lineColor: "red"
            });
          }
        });

        let takts = JSON.parse(JSON.stringify(productionTakt.filter(pt => pt.orders_id == selectedTakt.orders_id)));
        takts.sort((a, b) => {
          let orderA = orders.filter(o => o.id == a.orders_id)[0];
          let orderB = orders.filter(o => o.id == b.orders_id)[0];

          let produktTaktStartA = moment(orderA.materials.start).add(a.offset, 'minutes').valueOf();
          let produktTaktStartB = moment(orderB.materials.start).add(b.offset, 'minutes').valueOf();

          if (produktTaktStartA > produktTaktStartB) return 1;
          if (produktTaktStartA < produktTaktStartB) return -1;
          return 0;
        });

        const order = typeof orderFromTaktOnDrag === "object" && Object.keys(orderFromTaktOnDrag).length > 0
          ? orderFromTaktOnDrag
          : orders.filter(o => o.id == selectedTakt.orders_id)[0];

        let selectedProductionTaktIndex = 0;
        takts.some((t, index) => {
          if (t.id == selectedTakt.id) {
            selectedProductionTaktIndex = index;
            return true;
          }
          return false;
        });

        let isOverlapping = false;
        for (let index = 0; index < productionTakt.length; index++) {
          const otherTakt = productionTakt[index];

          if (otherTakt.id == selectedTakt.id) continue;

          const otherTaktOrder = orders.filter(o => o.id == otherTakt.orders_id)[0];

          if (!otherTaktOrder) continue;

          const otherTaktStart = moment(otherTaktOrder.materials.start).add(otherTakt.offset, 'minutes');
          const otherTaktEnd = moment(otherTaktStart).add(otherTakt.duration, 'minutes');

          if (
            (newTaktStart.isBetween(otherTaktStart, otherTaktEnd, null, '[]') ||
              newTaktEnd.isBetween(otherTaktStart, otherTaktEnd, null, '[]')) ||
            (otherTaktStart.isBetween(newTaktStart, newTaktEnd, null, '[]') ||
              otherTaktEnd.isBetween(newTaktStart, newTaktEnd, null, '[]'))
          ) {
            isOverlapping = true;
            break;
          }
        }

        let offset = moment(newTaktStart).diff(order.materials.start, 'minutes');
        takts[selectedProductionTaktIndex].offset = offset;

        let siloCapacity = siloCapacityData(newTaktStart, newTaktEnd, takts);
        let errorsFromCapacity = siloCapacity.errors.filter(efp => efp.silo === selectedTakt.silo_id);
        let isErrorFromCapacity = false;
        for (let j = 0; j < errorsFromCapacity.length; j++) {
          const error = errorsFromCapacity[j];

          const errorStart = moment(error.start);
          const errorEnd = moment(error.end);

          if (
            errorStart.isBetween(newTaktStart, newTaktEnd, null, '[]') ||
            errorEnd.isBetween(newTaktStart, newTaktEnd, null, '[]')
          ) {
            isErrorFromCapacity = true;
            break;
          }
        }

        let lineColor = 'black';
        if (
          (newTaktStart.valueOf() > order.materials.start && selectedProductionTaktIndex === 0) ||
          (newTaktEnd.valueOf() > order.materials.end && selectedProductionTaktIndex === takts.length - 1) ||
          isOverlapping ||
          isErrorFromCapacity
        ) {
          lineColor = 'red';
        }

        this.setState({
          customMarkerStart: newTaktStart,
          customMarkerEnd: newTaktEnd,
          orderFromTaktOnDrag: order,
          lineColor
        });
      }
    }

    movingItems = movingItems.map(mi => {
      if (selected.indexOf(itemId) !== -1 && itemId !== mi.id) {
        let diff = moment(dragTime).diff(moment(lastDragTime), intendedFocus);

        if (diff !== 0 && !isNaN(diff)) {
          mi.start_time = moment(mi.start_time).add(diff, intendedFocus).valueOf();
          mi.end_time = moment(mi.end_time).add(diff, intendedFocus).valueOf();
        }
      }

      return mi;
    });

  }

  onItemDoubleClick = (itemId, e, time) => {
    const { items, isMixtureMaster } = this.props;

    let selectedItem = items.filter(i => i.id == itemId)[0];

    if (!isMixtureMaster) return;
    if (!('type' in selectedItem && selectedItem.type === 'TAKT')) return;

    this.showUpdateSiloModal(itemId);
  }

  showUpdateSiloModal = (taktId = undefined) => {
    this.setState(prevState => ({
      showUpdateSilo: !prevState.showUpdateSilo,
      taktToUpdateSilo: taktId
    }));
  }

  onItemClick = (itemId, e, time) => {
    let { selected } = this.state;
    const { isMixtureMaster, items } = this.props;

    if (selected.indexOf(itemId) !== -1) {

      if (isMixtureMaster) {
        let selectedItem = items.filter(i => i.id == itemId)[0];

        let alsoRemove = '';
        if (typeof itemId === "string" && itemId.indexOf('_1') !== -1) {
          alsoRemove = itemId.slice(0, -2);
        } else {
          alsoRemove = `${itemId}_1`;
        }

        selected = selected.filter(s =>
          s !== itemId &&
          s !== alsoRemove &&
          s !== selectedItem.orders_id
        );
      } else {
        selected = selected.filter(s =>
          s !== itemId
        );
      }

      this.setState({ selected });
    }
  }

  updateSilo = async (siloId) => {
    let { taktToUpdateSilo } = this.state;
    let { productionTakt } = this.props;

    let takt = productionTakt.filter(pt => pt.id == taktToUpdateSilo)[0];

    takt.silo = siloId;

    await this.props.editProductionTakt(takt);
    await this.showUpdateSiloModal();
  }

  removeTaktView = _ => {
    this.props.viewProductionTakt(false);
  }

  updateTimestamp = (date) => {
    const { cursorMarkerDate, showSiloCapacityGraph } = JSON.parse(JSON.stringify(this.state));

    if (!showSiloCapacityGraph) return;

    if (cursorMarkerDate === 0) {
      this.setState({
        cursorMarkerDate: date
      });
    } else {
      let mc = moment(cursorMarkerDate);
      let md = moment(date);

      let diff = Math.abs(mc.diff(md, 'minutes'));

      if (diff > 5) {
        this.setState({
          cursorMarkerDate: date
        });
      }
    }

  }

  markers = selectedTakts => {
    const { customMarkerStart, customMarkerEnd, isMoving, lineColor } = this.state;

    if (selectedTakts.length !== 1) return null;

    return selectedTakts.map(t => {

      let start = t.start_time;
      let end = t.end_time;

      if (isMoving) {
        start = customMarkerStart;
        end = customMarkerEnd;
      }

      return (
        <React.Fragment key={uuid()}>
          <CustomMarker key={uuid()} date={moment(start).valueOf()}>
            {/* custom renderer for this marker */}
            {({ styles, date }) => {
              const customStyles = {
                ...styles,
                backgroundColor: lineColor,
                zIndex: '9'
              }
              return <div style={customStyles} />
            }}
          </CustomMarker>
          <CustomMarker key={uuid()} date={moment(end).valueOf()}>
            {/* custom renderer for this marker */}
            {({ styles, date }) => {
              const customStyles = {
                ...styles,
                backgroundColor: lineColor,
                zIndex: '9'
              }
              return <div style={customStyles} />
            }}
          </CustomMarker>
        </React.Fragment>
      );
    });
  }

  cutAction = _ => {
    const { selected } = this.state;
    const { items, multiSelect } = this.props;

    if (multiSelect) return;

    const selectedItem = items.filter(i => selected.indexOf(i.id) !== -1);

    if (selectedItem.length === 0) return;

    const selectedItemIsOrder = !('type' in selectedItem[0]) && selectedItem.length === 1;
    const selectedItemIsTakt = items.filter(i => selected.indexOf(i.id) !== -1 && 'type' in i).length === 1;

    if (selectedItemIsOrder) {
      this.showCutOrderModal();
    }

    if (selectedItemIsTakt) {
      this.showCutModal();
    }
  }

  mergeAction = _ => {
    const { selected } = this.state;
    const { items, multiSelect } = this.props;

    if (!multiSelect) return;
    const selectedItems = items.filter(i => selected.indexOf(i.id) !== -1);

    if (selectedItems.length <= 1) return;

    let selectedTakts = selectedItems.filter(si => "type" in si);

    if (selectedTakts <= 1)
      this.mergeOrder();
    else
      this.merge();
  }

  handleBookmark = () => {
    const visibleTimeStart = moment().hour(5).minute(0);
    const visibleTimeEnd = moment().hour(19).minute(0);

    this.setState({
      visibleTimeStart,
      visibleTimeEnd,
      step: 0
    })
  }

  jumpToDate = (date, event) => {
    const { step } = this.state;
    const stepObject = steps[step];
    const visibleTimeEnd = moment(date).add(stepObject.value, stepObject.unit).valueOf()

    this.setState({
      visibleTimeStart: date,
      visibleTimeEnd: visibleTimeEnd
    });
  }

  render() {

    let {
      t,
      items,
      groups,
      orders,
      mixingPlants,
      productionTakt,
      adminViewTitle,
      admin_view_prod_takt,
      isMixtureMaster,
      history
    } = this.props;

    const {
      intendedFocus,
      mixingPlantFilter,
      showHorizontalCapacity,
      selected,
      showEditModal,
      editItemId,
      showCutModal,
      showCutOrderModal,
      showUpdateSilo,
      taktToUpdateSilo,
      visibleTimeStart,
      visibleTimeEnd,
      showTaktModal,
      cursorMarkerDate,
      showSiloCapacityGraph,
      taktToCut,
      orderToCut,
      isMoving,
    } = this.state;


    let dragSnap = 1000 * 60 * 5; // 5 minutes
    let canResize = 'both';
    let lineHeight = 63;
    let useResizeHandle = false;
    let minResizeWidth = 1;

    if (mixingPlantFilter.length > 0 && !admin_view_prod_takt) {

      groups = groups.filter(g => mixingPlantFilter.indexOf(g.id) !== -1);
      orders = orders.filter(o => mixingPlantFilter.indexOf(o.mixingPlant) !== -1);
      items = items.filter(i => mixingPlantFilter.indexOf(i.group) !== -1);
      mixingPlants = mixingPlants.filter(mp => mixingPlantFilter.indexOf(mp.id) !== -1);

    }

    if (intendedFocus === 'hour' || intendedFocus === 'minute') {
      minResizeWidth = 1;

      items = items.map(i => {
        const order = orders.filter(o => o.id == i.id);

        if (order.length > 0) {
          i.start_time = moment(order[0].materials.start).valueOf();
          i.end_time = moment(order[0].materials.end).add(-1, 'minutes').valueOf();
        }

        return i;
      });
    } else {
      dragSnap = 1000 * 60 * 60 * 24 * 1;
      canResize = false;
      useResizeHandle = false;

      items = items.map(i => {
        const order = orders.filter(o => o.id == i.id);

        if (order.length > 0) {
          i.start_time = moment(order[0].materials.start).startOf('day');
          i.end_time = moment(order[0].materials.start).endOf('day');
        }

        i.canResize = false;

        return i;
      });
    }

    let mixingPlantsIds = mixingPlants.map(m => m.id);
    let mixingPlantsGroups = groups.filter(g => mixingPlantsIds.indexOf(g.id) !== -1);

    let showVertical = false;
    if (mixingPlantsGroups.length === 1) {
      showVertical = true;
    } else {
      showVertical = false;
    }

    const isCapacityButtonActive = ((intendedFocus === 'hour' && mixingPlantFilter.length === 0 && !isMixtureMaster)) ? false : true;

    let visibleTimeStartMoment = moment(visibleTimeStart);
    let visibleTimeEndMoment = moment(visibleTimeEnd);
    items = items.filter(i => {
      let startTime = moment(i.start_time);
      let endTime = moment(i.end_time);

      return startTime.isBetween(visibleTimeStartMoment, visibleTimeEndMoment, null, '[]') || endTime.isBetween(visibleTimeStartMoment, visibleTimeEndMoment, null, '[]');
    });

    let itemsGroups = items.map(i => i.group);
    groups = groups.filter(g => itemsGroups.indexOf(g.id) !== -1 || g.id == TAKT_GROUP_ID || mixingPlantsIds.indexOf(g.id) !== -1 || g.id == DEFAULT_MIXING_PLANT_ID);

    const selectedTakts = items.filter(i => selected.indexOf(i.id) !== -1 && 'type' in i && i.type === 'TAKT');
    const markers = this.markers(selectedTakts);

    let daysWithItems = Array.from(
      new Set(items.map(i => moment(i.start_time).startOf('day')))
    );

    let dailyViewCapacityItems = [];

    let groupsMixingPlants = groups.filter(g => 'tonday' in g);

    for (let i = 0; i < groupsMixingPlants.length; i++) {

      if (!(intendedFocus === 'day' && !isMixtureMaster && showHorizontalCapacity && mixingPlantFilter.length === 0)) break;

      const group = groupsMixingPlants[i];
      const groupCapacity = `${DAYVIEW_CAPACITY_GROUP}_${group.id}`;
      let ordersByMixingPlant = orders.filter(o => {
        let startTime = moment(o.materials.start);
        let endTime = moment(o.materials.end);

        return ('tonday' in group && o.mixingPlant === group.id) && (
          startTime.isBetween(visibleTimeStartMoment, visibleTimeEndMoment, null, '[]') ||
          endTime.isBetween(visibleTimeStartMoment, visibleTimeEndMoment, null, '[]'))
      });

      if (ordersByMixingPlant.length === 0) continue;

      let maxTonnageByDay = parseFloat(group.tonday);

      let indexFromParent = null;

      groups.some((g, index) => {
        if (g.id == group.id) {
          indexFromParent = index;
          return true;
        }
        return false;
      });

      groups.splice((indexFromParent), 0, {
        id: groupCapacity,
        title: ` `,
        type: 'CAPACITY_GROUP'
      });

      for (let j = 0; j < daysWithItems.length; j++) {
        const day = daysWithItems[j];

        let ordersByDay = ordersByMixingPlant.filter(o => day.isSame(o.materials.start, 'day') && o.mixingPlant === group.id);
        if (ordersByDay.length === 0) continue;

        let total = ordersByDay.reduce((acc, o) =>
          acc += parseFloat(o.materials.amount)
          , 0);

        let percentage = parseFloat((total / maxTonnageByDay) * 100).toFixed(2);

        const capacityItemId = `${day.format('YYYYMMDD')}_${groupCapacity}`;

        const alreadyAddedItem = dailyViewCapacityItems.filter(d => d.id == capacityItemId);

        if (alreadyAddedItem.length > 0) continue;

        dailyViewCapacityItems.push({
          id: capacityItemId,
          group: groupCapacity,
          start_time: moment(day).startOf('day').valueOf(),
          end_time: moment(day).endOf('day').valueOf(),
          canMove: false,
          canResize: false,
          total,
          percentage,
          tonday: maxTonnageByDay,
          isCapacity: true,
          lineHeight: 20
        });
      }
    }

    items = [
      ...items,
      ...dailyViewCapacityItems
    ];

    return (
      <div className='page'>
        {showEditModal && <OrderModal openModalAction={this.openModalAction} item={editItemId} removeOrder={this.removeOrder} />}
        {showCutModal && <CutModal cut={this.cut} showCutModal={this.showCutModal} taktToCut={taktToCut} />}
        {showCutOrderModal && <CutOrderModal cutOrder={this.cutOrder} showCutOrderModal={this.showCutOrderModal} orderToCut={orderToCut} />}
        {showUpdateSilo && <UpdateSiloModal updateSilo={this.updateSilo} taktId={taktToUpdateSilo} showUpdateSiloModal={this.showUpdateSiloModal} />}
        {showTaktModal && <ShowTaktModal showTaktModal={this.showTaktModal} />}
        <Segment raised>
          <Header as="h2">
            <Header.Content>{adminViewTitle}</Header.Content>
          </Header>
          <Buttons
            jumpToDate={this.jumpToDate}
            visibleTimeStart={visibleTimeStart}
            handleZoomOut={this.handleZoomOut}
            handleZoomIn={this.handleZoomIn}
            setMixingPlantFilter={this.setMixingPlantFilter}
            mixingPlantFilterValues={mixingPlantFilter}
            handleShowHorizontalCapacity={this.handleShowHorizontalCapacity}
            undo={this.undo}
            undoAvailable={this.state.undo.length >= 1}
            redo={this.redo}
            redoAvailable={this.state.redo.length >= 1}
            showCutModal={this.showCutModal}
            merge={this.merge}
            left={this.left}
            stopMoving={this.stopMoving}
            right={this.right}
            showCutOrderModal={this.showCutOrderModal}
            mergeOrder={this.mergeOrder}
            showTaktModal={this.showTaktModal}
            removeTaktView={this.removeTaktView}
            time={cursorMarkerDate}
            mixingPlantsIds={mixingPlantsIds}
            showSiloCapacityGraphAction={this.showSiloCapacityGraph}
            isSiloCapacityGraphButtonActive={showHorizontalCapacity}
            isCapacityButtonActive={isCapacityButtonActive}
            showHorizontalCapacity={showHorizontalCapacity}
            showSiloCapacityGraph={showSiloCapacityGraph}
            cutAction={this.cutAction}
            mergeAction={this.mergeAction}
            handleBookmark={this.handleBookmark}
          />
          <Timeline
            onRowClickFilter={this.onRowClickFilter}
            timelineContext={MAIN_CONTEXT}
            history={history}
            mixingPlantFilter={mixingPlantFilter}
            resizeDetector={containerResizeDetector}
            groups={groups}
            items={items}
            visibleTimeStart={this.state.visibleTimeStart.valueOf()}
            visibleTimeEnd={this.state.visibleTimeEnd.valueOf()}
            dragSnap={dragSnap}
            itemHeightRatio={1}
            step={this.state.step}
            minZoom={24 * 60 * 60 * 1000}
            maxZoom={24 * 60 * 60 * 1000}
            fullUpdate={true}
            canChangeGroup={true}
            canResize={canResize}
            sidebarWidth={200}
            stackItems={true}
            stickyOffset={51}
            showCapacity={showVertical && showHorizontalCapacity}
            mixingPlants={mixingPlants}
            orders={orders}
            productionTakt={productionTakt}
            onUnitChange={this.handleOnUnitChange}
            intendedFocus={this.state.intendedFocus}
            onItemMove={this.onItemMove}
            onTimeChange={this.handleTimeChange}
            onItemResize={this.onItemResize}
            showHorizontalCapacity={false}
            showHorizontalDayCapacity={intendedFocus === 'day' && !isMixtureMaster && showHorizontalCapacity && mixingPlantFilter.length === 0}
            t={t}
            itemRenderer={this.itemRenderer}
            lineHeight={lineHeight}
            selected={selected}
            onItemSelect={this.onItemSelect}
            selectMultipleItems={this.selectMultipleItems}
            useResizeHandle={useResizeHandle}
            minResizeWidth={minResizeWidth}
            onCanvasClick={this.onCanvasClick}
            onDragItem={this.handleOnDragItem}
            onItemDoubleClick={this.onItemDoubleClick}
            cursorMarkerDate={this.state.cursorMarkerDate}
            silos={this.props.silos}
            onItemClick={this.onItemClick}
            currentTime={cursorMarkerDate}
            isMixtureMaster={isMixtureMaster}
            showSiloCapacityGraph={showSiloCapacityGraph}
            updateTime={this.updateTimestamp}
            dragSelectAllowed={true}
          >
            {!isMoving && showSiloCapacityGraph &&
              <CursorMarker>
                {({ styles, date }) => {
                  styles.backgroundColor = 'red';
                  styles.zIndex = 200;
                  return <div style={styles} />
                }}
              </CursorMarker>
            }
            {markers}
          </Timeline>
        </Segment>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  let mixingPlants = state.mixingPlants;
  const allMixingplants = state.mixingPlants;
  const constructionSites = state.constructionSites;
  const orders = state.orders;
  const materials = state.materials;

  let visibleTimeStart = state.app.calendar_visibletimestart;

  let visibleTimeEnd = state.app.calendar_visibletimeend;
  let step = state.app.calendar_zoomstep;
  let mixingPlantFilter = state.app.calendar_mp_filter;
  let showHorizontalCapacity = state.app.calendar_show_capacity;
  let showSiloCapacityGraph = state.app.calendar_show_silo_graph;

  let productionTakt = state.productionTakt;
  const deleted = state.deleted;
  const silos = state.silos;
  const admin_view_prod_takt = state.app.admin_view_prod_takt;
  const view = state.app.view;
  const multiSelect = state.app.multi_select;

  const adminViewTitle = admin_view_prod_takt
    ? mixingPlants.filter(mp => mp.id == admin_view_prod_takt)[0].name
    : props.t("order.header")

  const user = state.user;
  let isMixtureMaster = false;
  let isAdmin = false;
  let isScaleMaster = false;
  let canMove = true;
  let canResize = 'both';

  !!user.permissions && user.permissions.forEach(permission => {
    if (permission.id === WIEGEMEISTER_PERMISSION.id) {
      isScaleMaster = true;
    }

    if (permission.id === MISCHMEISTER_PERMISSION.id) {
      step = 2;
      isMixtureMaster = true;
      let mixingPlantsId = mixingPlants.map(mp => mp.id);
      let filteredOrdersByMixingPlantIds = orders.filter(o => mixingPlantsId.indexOf(o.mixingPlant) !== -1).map(o => o.id);
      productionTakt = productionTakt.filter(pt => filteredOrdersByMixingPlantIds.indexOf(pt.orders_id) !== -1);
    }

    if (permission.id === ADMIN_PERMISSION.id) isAdmin = true;

    if (permission.id === PRODUCTIONTAKT_PERMISSION.id || permission.id === ADMIN_PERMISSION.id) {

      if (admin_view_prod_takt) {
        isMixtureMaster = true;
        step = 2;

        mixingPlants = mixingPlants.filter(mp => mp.id == admin_view_prod_takt);
        let mixingPlantsId = mixingPlants.map(mp => mp.id);
        let filteredOrdersByMixingPlantIds = orders.filter(o => mixingPlantsId.indexOf(o.mixingPlant) !== -1).map(o => o.id);
        productionTakt = productionTakt.filter(pt => filteredOrdersByMixingPlantIds.indexOf(pt.orders_id) !== -1);
      }
    }

  });

  let groups = [];
  mixingPlants.forEach(m => {
    groups.push({
      id: m.id,
      title: !m.name ? props.t("order.delivery") : m.name,
      // title: (isMixtureMaster) ? props.t("order.delivery") : m.name,
      tonday: m.tonday,
    });
  });

  // if (isMixtureMaster) {
  //   canMove = false;
  //   canResize = false;

  //   groups.push({
  //     id: TAKT_GROUP_ID,
  //     title: props.t("order.total_takt")
  //   });
  // }

  if (isAdmin) {
    canMove = true;
    canResize = 'both';
  }

  if (!isMixtureMaster) {
    groups.unshift({
      id: DEFAULT_MIXING_PLANT_ID,
      title: props.t("order.default_mixing_plant")
    });
  }

  let items = [];
  groups.forEach(mixingPlant => {
    let ordersForMixingPlant = orders.filter(o => o.mixingPlant == mixingPlant.id);

    if (ordersForMixingPlant.length > 0) {
      ordersForMixingPlant.forEach(om => {
        let material = materials.filter(m => m.id == om.materials.material_id);
        let constructionSiteProductionTakt = constructionSites.filter(cs => cs.id == om.constructionSite);
        let color = '#e4e4e4';
        let textColor = 'black';
        let canMoveItem = canMove;
        let canResizeItem = canResize;

        if (material.length > 0) {

          let orderDuration = moment(om.materials.end).diff(moment(om.materials.start), 'hours', true)
          let tonsPerHour = Math.round(parseFloat(parseFloat(parseFloat(om.materials.amount)) / orderDuration));

          if (om.materials.status === 'PREPLANNING') {
            color = "#fbfbfb";
          }

          if (om.materials.status === 'FIXED') {
            color = "#a9a9a9";
            canMoveItem = false;
            canResizeItem = false;
          }

          if (om.materials.status === "READONLY") {
            color = "#e6faff";
            canMoveItem = false;
            canResizeItem = false;
          }

          if (om.materials.status === "BPO") {
            color = "#a8d1ff";
            canMoveItem = false;
            canResizeItem = false;
          }

          if (om.materials.status === 'CUSTOMER') {
            color = "white";
          }

          items.push({
            id: om.id,
            group: mixingPlant.id,
            title: `${constructionSiteProductionTakt[0].name} *-* ${material[0].name} *-* ${om.materials.amount} t *-* ${tonsPerHour} t/h`,
            start_time: moment(om.materials.start).startOf('day').valueOf(),
            end_time: moment(om.materials.start).endOf('day').valueOf(),
            amount: parseFloat(om.materials.amount).toFixed(2),
            obs: om.materials.obs,
            orderGroup: om.orderGroup,
            color,
            textColor,
            canMove: canMoveItem,
            canResize: canResizeItem,
            style: {
              background: color,
            },
            className: om.materials.status
          });

          if (isMixtureMaster) {
            let mixingPlant = mixingPlants[0];

            let standardCapacity = mixingPlant.standard;
            let timeToProduceAtStandardCapacity = parseFloat(om.materials.amount) / parseFloat(standardCapacity);
            let orderProductionTakt = productionTakt.filter(pt => pt.orders_id === om.id);

            let productionTaktEntry = {};

            if (orderProductionTakt.length === 0 && om.materials.status !== 'CUSTOMER') {

              const errorsFromCapacity = state.app.siloCapacityData.errors;

              let tonsPerHour = Math.round(parseFloat(standardCapacity));
              let produktTaktStart = moment(om.materials.start).add(-1, 'hour');
              let produktTaktEnd = moment(om.materials.start).add(timeToProduceAtStandardCapacity, 'hour');

              let siloName = 'No Silo';

              let title = `${siloName} *-* ${parseFloat(om.materials.amount)} t *-* ${material[0].name}`;
              title = `${title} *-* ${tonsPerHour} t/h`;

              let isOverlapping = false;
              for (let index = 0; index < productionTakt.length; index++) {
                const otherTakt = productionTakt[index];

                const otherTaktOrder = orders.filter(o => o.id == otherTakt.orders_id)[0];
                const otherTaktStart = moment(otherTaktOrder.materials.start).add(otherTakt.offset, 'minutes');
                const otherTaktEnd = moment(otherTaktStart).add(otherTakt.duration, 'minutes');

                let isErrorFromCapacity = false;

                console.log(errorsFromCapacity, 'errorsFromCapacity');
                if (errorsFromCapacity) {

                  for (let j = 0; j < errorsFromCapacity.length; j++) {
                    const error = errorsFromCapacity[j];

                    const errorStart = moment(error.start);
                    const errorEnd = moment(error.end);

                    if (
                      errorStart.isBetween(produktTaktStart, produktTaktEnd, null, '[]') ||
                      errorEnd.isBetween(produktTaktStart, produktTaktEnd, null, '[]')
                    ) {
                      isErrorFromCapacity = true;
                      break;
                    }
                  }
                }

                if (
                  produktTaktStart.isBetween(otherTaktStart, otherTaktEnd, null, '[]') ||
                  produktTaktEnd.isBetween(otherTaktStart, otherTaktEnd, null, '[]') ||
                  isErrorFromCapacity
                ) {
                  isOverlapping = true;
                  break;
                }
              }

              let overlappingStyle = {};
              if (isOverlapping) {
                overlappingStyle.background = 'red';
              }

              productionTaktEntry = {
                canMove: false,
                id: uuid(),
                group: TAKT_GROUP_ID,
                start_time: produktTaktStart.valueOf(),
                end_time: produktTaktEnd.valueOf(),
                orders_id: om.id,
                orderGroup: om.orderGroup,
                obs: om.materials.obs,
                new: true,
                duration: timeToProduceAtStandardCapacity * 60,
                title,
                useResizeHandle: true,
                constructionSiteName: siloName,
                materialAmount: om.materials.amount,
                materialName: material[0].name,
                percentage: 100,
                style: {
                  background: material[0].color,
                  ...overlappingStyle
                }
              };

              items.push(productionTaktEntry)

            } else {

              let errorsFromCapacity = state.app.siloCapacityData.errors;

              orderProductionTakt.forEach(opt => {
                let produktTaktStart = moment(om.materials.start).add(opt.offset, 'minutes');
                let produktTaktEnd = moment(produktTaktStart).add(opt.duration, 'minutes');
                let duration = parseFloat(opt.duration) / 60;
                let silo = silos.filter(s => s.id == opt.silo);

                let siloName = '';
                let siloMax = '';
                if (silo.length > 0) {
                  siloName = silo[0].name;
                  siloMax = silo[0].max;
                } else {
                  siloName = 'No Silo';
                  siloMax = "";
                }

                let isSiloAvailable = isSiloAvailableSynchronous(opt.id, opt.silo, produktTaktStart);


                if (silo.length > 0 && typeof errorsFromCapacity === "object") {
                  errorsFromCapacity = errorsFromCapacity.filter(efp => efp.silo === silo[0].id);
                } else {
                  errorsFromCapacity = []
                }

                let materialAmount = parseFloat((parseFloat(opt.amount) / 100) * parseFloat(om.materials.amount)).toFixed(2);

                let tonsPerHour = Math.round(parseFloat(parseFloat(materialAmount) / duration));

                let title = `${siloName} *-* ${materialAmount} t *-* ${material[0].name}`;
                title = `${title} *-* ${tonsPerHour} t/h *-* - [${siloMax}t] `;

                let isOverlapping = false;
                for (let index = 0; index < productionTakt.length; index++) {
                  const otherTakt = productionTakt[index];

                  if (otherTakt.id == opt.id) continue;

                  const otherTaktOrder = orders.filter(o => o.id == otherTakt.orders_id)[0];

                  if (!otherTaktOrder) continue;

                  const otherTaktStart = moment(otherTaktOrder.materials.start).add(otherTakt.offset, 'minutes');
                  const otherTaktEnd = moment(otherTaktStart).add(otherTakt.duration, 'minutes');

                  let isErrorFromCapacity = false;

                  for (let j = 0; j < errorsFromCapacity.length; j++) {
                    const error = errorsFromCapacity[j];

                    const errorStart = moment(error.start);
                    const errorEnd = moment(error.end);

                    if (
                      errorStart.isBetween(produktTaktStart, produktTaktEnd, null, '[]') ||
                      errorEnd.isBetween(produktTaktStart, produktTaktEnd, null, '[]')
                    ) {
                      isErrorFromCapacity = true;
                      break;
                    }
                  }

                  if (
                    (produktTaktStart.isBetween(otherTaktStart, otherTaktEnd, null, '[]') ||
                      produktTaktEnd.isBetween(otherTaktStart, otherTaktEnd, null, '[]')) ||
                    (otherTaktStart.isBetween(produktTaktStart, produktTaktEnd, null, '[]') ||
                      otherTaktEnd.isBetween(produktTaktStart, produktTaktEnd, null, '[]')) ||
                    isErrorFromCapacity
                  ) {
                    isOverlapping = true;
                    break;
                  }
                }

                if (produktTaktEnd.valueOf() > om.materials.end) {
                  isOverlapping = true;
                }

                let overlappingStyle = {};
                if (isOverlapping || !isSiloAvailable) {
                  overlappingStyle.background = 'red';
                }

                productionTaktEntry = {
                  canMove: false,
                  id: `${opt.id}_1`,
                  group: TAKT_GROUP_ID,
                  start_time: produktTaktStart.valueOf(),
                  end_time: produktTaktEnd.valueOf(),
                  orders_id: om.id,
                  orderGroup: om.orderGroup,
                  obs: om.materials.obs,
                  new: false,
                  duration: opt.duration,
                  title,
                  useResizeHandle: true,
                  constructionSiteName: siloName,
                  materialAmount,
                  materialName: material[0].name,
                  percentage: opt.amount,
                  className: "productionBoxColorful",
                  style: {
                    background: material[0].color,
                    cursor: "default",
                    ...overlappingStyle
                  }
                };

                items.push(productionTaktEntry)
              });
            }
          }
        }
      });
    }
  });

  if (isMixtureMaster) {
    let ordersItems = items.filter(i => i.group !== TAKT_GROUP_ID);

    ordersItems.forEach(oi => {
      let order = orders.filter(o => o.id == oi.id)[0];
      let takt = productionTakt.filter(pt => pt.orders_id == order.id);
      let material = materials.filter(m => m.id == order.materials.material_id);
      let constructionSite = constructionSites.filter(cs => cs.id == order.constructionSite);

      if (view === 'order') {
        groups.push({
          id: order.id,
          title: `${constructionSite[0].name} - ${material[0].name}`
        });
      }

      if (view === 'material') {
        groups.push({
          id: material[0].id,
          title: `${material[0].name}`
        });

        groups = groups.filter(
          (group, index, self) =>
            index ===
            self.findIndex(
              g =>
                g.id == group.id
            )
        );
      }

      takt.forEach(t => {

        let produktTaktStart = moment(order.materials.start).add(t.offset, 'minutes');
        let produktTaktEnd = moment(produktTaktStart).add(t.duration, 'minutes');
        let duration = parseFloat(t.duration) / 60;

        let materialAmount = parseFloat((parseFloat(t.amount) / 100) * parseFloat(order.materials.amount)).toFixed(2);
        let tonsPerHour = Math.round(parseFloat(parseFloat(materialAmount) / duration));

        let silo = silos.filter(s => s.id == t.silo);

        if (typeof silo[0] === "undefined")
          silo.push({ id: "" });

        let siloName = '';
        let siloMax = "";
        if (silo.length > 0) {
          siloName = silo[0].name;
          siloMax = silo[0].max;
        } else {
          siloName = 'No Silo';
        }

        let title = `${siloName} *-* ${materialAmount} t *-* ${material[0].name}`;
        title = `${title} *-* ${tonsPerHour} t/h *-* - [${siloMax}t] `;

        let groupId = '';

        if (view === 'order') groupId = order.id;
        if (view === 'material') groupId = material[0].id;

        console.log(items,'itmssss');

        items.push({
          id: t.id,
          group: groupId,
          start_time: produktTaktStart.valueOf(),
          end_time: produktTaktEnd.valueOf(),
          title,
          type: 'TAKT',
          canMove: true,
          orders_id: order.id,
          duration,
          materialAmount,
          constructionSiteName: siloName,
          materialName: material[0].name,
          silo_id: silo[0].id,
          style: {
            background: '#e4e4e4'
          }
        });

      });
    });
  }

  if (isMixtureMaster) {
    items = items.map(i => {
      i["canChangeGroup"] = false;
      return i;
    })
  }

  return {
    t: props.t,
    groups,
    items,
    mixingPlants,
    orders,
    visibleTimeStart,
    visibleTimeEnd,
    step,
    isMixtureMaster,
    productionTakt,
    deleted,
    isAdmin,
    isScaleMaster,
    silos,
    adminViewTitle,
    admin_view_prod_takt,
    multiSelect,
    mixingPlantFilter,
    showHorizontalCapacity,
    showSiloCapacityGraph,
    history: props.history,
    allMixingplants,
  };
}

const reduxWrapper = connect(mapStateToProps, {
  editOrder,
  updateAppState,
  updateOrders,
  removeOrder,
  addProductionTakt,
  addMultipleProductionTakt,
  editProductionTakt,
  removeProductionTakt,
  removeMultipleProductionTakt,
  updateProductionTakt,
  updateDeleted,
  addMultipleOrders,
  viewProductionTakt,
  removeMultipleOrders,
  addOrder
})(Orders);

export default withNamespaces('translation')(reduxWrapper);