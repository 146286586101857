import { LOAD_USER } from '../actions/user';

const defaultState = {
  roles: []
};

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case LOAD_USER:
      return action.data;
  }
}