import React from 'react';
import Customers from '../Customers/Customers';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import {
  Header,
  Segment
} from "semantic-ui-react";

class CustomerInformation extends React.Component {
  state = {}

  render() {

    const {t} = this.props;
    
    return(
      <div className='page'>
        <Segment raised>
          <Header as="h2">
            <Header.Content>{t("menu.item1")}</Header.Content>
          </Header>

          <Customers />
          
        </Segment>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const customers = state.customers;
  const user = state.user;

  let customer = customers.filter(c => c.user_id == user.id)[0];

  return {
    customer
  };
}

export default withNamespaces('translation')(
  connect(mapStateToProps, {})(CustomerInformation)
);