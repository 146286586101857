import { SAVE_TO_STORE, LOAD_BASEDATA } from '../actions/basedata';

const defaultState = {
  companyName: ''
}

export default function(state=defaultState, action) {
  switch(action.type) {
    default:
      return state;
    case SAVE_TO_STORE:
      return {...state, ...action.data}
    case LOAD_BASEDATA:
      return {...action.data}
  }
}