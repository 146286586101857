import uuid from 'uuid/v4';
import moment from 'moment';
import { addDeletedEntry } from './deleted';

export const ADD_CONSTRUCTION_SITES = 'constructionSites/ADD_CONSTRUCTION_SITES';
export const EDIT_CONSTRUCTION_SITES = 'constructionSites/EDIT_CONSTRUCTION_SITES';
export const REMOVE_CONSTRUCTION_SITES = 'constructionSites/REMOVE_CONSTRUCTION_SITES';
export const LOAD_CONSTRUCTION_SITES = 'constructionSites/LOAD_CONSTRUCTION_SITES';

export function addConstructionSite(data) {
  return dispatch => {
    data.edited = true;
    data.id = uuid();
    data.created_at = moment().valueOf();
    data.updated_at = 0;
    dispatch({
      type: ADD_CONSTRUCTION_SITES,
      data
    });
    
    return data.id;
  }
}

export function editConstructionSite(data) {
  return dispatch => {
    data.edited = true;
    data.updated_at = moment().valueOf();
    dispatch({
      type: EDIT_CONSTRUCTION_SITES,
      data
    });
  }
}

export function removeConstructionSite(id) {
  return dispatch => {
    addDeletedEntry({
      id,
      table: 'construction_sites'
    });

    dispatch({
      type: REMOVE_CONSTRUCTION_SITES,
      id
    });
  }
}