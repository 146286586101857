import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import "moment/locale/de";

const defaultLanguage = 'de';

moment.locale(defaultLanguage);
// PATH
const origin = window.location.origin || "https://bpo-asphalt.de";
let backend = {loadPath: `${origin}/auftragsdispo/locales/{{lng}}/{{ns}}.json`}//'http://alpha.bpo-asphalt.de/auftragsdispo/locales/{{lng}}/{{ns}}.json' --- http://localhost:3000/auftragsdispo/locales/{{lng}}/{{ns}}.json

if(process.env.NODE_ENV === 'development') backend = {loadPath: '/locales/{{lng}}/{{ns}}.json'}

i18n
  .use(Backend)
  .use(reactI18nextModule)
  .init({
    lng: defaultLanguage,
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      wait: true
    },

    backend
  });


export default i18n;  