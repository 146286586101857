import React from 'react';
import {withNamespaces} from 'react-i18next';
import {connect} from 'react-redux';
import {Icon, Button, Form, Segment, Header, Message} from "semantic-ui-react";
import {save} from '../../actions/authentication';
import {editCustomer} from '../../actions/customers';
import Validate from "react-validate-form";
import {validationRules} from "../../validationRules";
import { CUSTOMER_PERMISSION } from '../../constants/constants';

const inlineStyle = {
    field: {
        marginTop: '10px'
    }
};

class Customer extends React.Component {

    state = {
        id: '',
        name: '',
        tel: '',
        fax: '',
        email: '',
        address1: '',
        address2: ''
    };

    componentDidMount() {
        this.setState({
            id: this.props.customer.id,
            name: this.props.customer.name,
            tel: this.props.customer.tel,
            fax: this.props.customer.fax,
            email: this.props.customer.email,
            address1: this.props.customer.address1,
            address2: this.props.customer.address2
        });
    }

    handleInputChange = (field, e) => {
        let state = this.state;
        state[field] = e.target.value;
        state["edited"] = true;

        this.setState(state);
    };

    handlePositive = async (state) => {
        const {authenticated, save} = this.props;

        let data = JSON.parse(JSON.stringify(state));

        await this.props.editCustomer(data);

        if (authenticated)
            save();

        if (this.props.isCustomer) {
            this.props.history.push('/auftragsdispo/base-data');
            return
        }

        this.props.history.push('/auftragsdispo/base-data?tab=customers');
    }

    handleNegative = _ => {
        if (this.props.isCustomer) {
            this.props.history.push('/auftragsdispo/base-data');
            return
        }

        this.props.history.push('/auftragsdispo/base-data?tab=customers');
    }

    render() {

        const {t} = this.props;

        const validations = {
            name: ["nameRequired"]
        };

        return (
            <div className='page'>
                <Validate validations={validations} rules={validationRules}>
                    {({validate, errorMessages, allValid, errorCount}) => (
                        <Segment raised>
                            <Header as="h2" floated="left">
                                <Header.Content>{t("customer.edit.header")}</Header.Content>
                            </Header>

                            <div style={{textAlign: 'right'}}>
                                <Button.Group>
                                    <Button
                                        type="button"
                                        onClick={() => this.handleNegative()}
                                        color="grey"
                                    >
                                        <Icon name="close"/>
                                        {t("buttons.cancel")}
                                    </Button>
                                    <Button disabled={errorCount >= 1} type="button"
                                            onClick={_ => this.handlePositive(this.state)} color="green">
                                        <Icon name="checkmark"/>
                                        {t("buttons.ok")}
                                    </Button>
                                </Button.Group>
                            </div>

                            <Form.Group style={{clear: 'both'}}>
                                <Form.Input
                                    label={t("customer.name")}
                                    name="name"
                                    placeholder={t("customer.name")}
                                    value={this.state.name}
                                    onChange={(e) => {
                                        this.handleInputChange('name', e);
                                        validate(e);
                                    }}
                                    fluid
                                />
                                {!!errorMessages.name && errorMessages.name.length > 0 &&
                                <Message negative>
                                    <Message.Header>
                                        {t(errorMessages.name[0])}
                                    </Message.Header>
                                </Message>
                                }
                            </Form.Group>

                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("customer.tel")}
                                    placeholder={t("customer.tel")}
                                    value={this.state.tel}
                                    onChange={(e) => this.handleInputChange('tel', e)}
                                    fluid
                                />
                            </Form.Group>

                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("customer.fax")}
                                    placeholder={t("customer.fax")}
                                    value={this.state.fax}
                                    onChange={(e) => this.handleInputChange('fax', e)}
                                    fluid
                                />
                            </Form.Group>

                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("customer.email")}
                                    placeholder={t("customer.email")}
                                    value={this.state.email}
                                    onChange={(e) => this.handleInputChange('email', e)}
                                    fluid
                                />
                            </Form.Group>

                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("customer.address1")}
                                    placeholder={t("customer.address1")}
                                    value={this.state.address1}
                                    onChange={(e) => this.handleInputChange('address1', e)}
                                    fluid
                                />
                            </Form.Group>

                            <Form.Group style={inlineStyle.field}>
                                <Form.Input
                                    label={t("customer.address2")}
                                    placeholder={t("customer.address2")}
                                    value={this.state.address2}
                                    onChange={(e) => this.handleInputChange('address2', e)}
                                    fluid
                                />
                            </Form.Group>
                        </Segment>
                    )}
                </Validate>
            </div>
        );
    }
}

const Customer_i18nWrapper = withNamespaces('translation')(Customer);

function mapStateToProps(state, props) {
    const authenticated = state.auth.authenticated;
    const customers = state.customers;
    const id = 'id' in props.match.params ? props.match.params.id : undefined;
    const permissions = state.permissions;
    const user = state.user;

    let isCustomer = false;

    !!user.permissions && user.permissions.forEach(permission => {
    if (permission.id == CUSTOMER_PERMISSION.id) {
        isCustomer = true;
    }
    });
    

    const readOnly = permissions.filter(p => p.perm_type == 'MENU_CUSTOMERS' && p.perm_desc === 'W').length < 1;

    if (readOnly) props.history.push('/auftragsdispo/login');

    if (!id) props.history.push('/auftragsdispo/base-data');

    const customer = customers.filter(m => m.id == id)[0];

    if (!customer) props.history.push('/auftragsdispo/base-data');

    return {
        history: props.history,
        customer,
        isCustomer,
        authenticated
    };
}

export default connect(mapStateToProps, {editCustomer, save})(Customer_i18nWrapper);