import React from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';
import Material from './Material';
import {
  Header,
  Table,
  Popup,
  Button,
  Icon
} from "semantic-ui-react";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import { enGB, de } from 'date-fns';


class MaterialsByDay extends React.Component {

  constructor(props) {
    super(props);
    const selected_date = new Date(props.selected_date);

    this.state = {
      duplicateRange: {
        startDate: selected_date,
        endDate: selected_date,
        key: "range"
      },
      isDuplicatePopupOpen: false
    }
  }

  handleInputChange = (field, e) => {
    let state = this.state;
    state[field] = e.target.value;

    this.setState(state);
  };

  static getDerivedStateFromProps(props, state) {
    const selected_date = new Date(props.selected_date);

    if (state.duplicateRange.startDate !== selected_date &&
        state.duplicateRange.endDate !== selected_date &&
        state.duplicateRange.startDate === state.duplicateRange.endDate &&
        state.selected_date !== props.selected_date) {
          return {
            duplicateRange: {
              startDate: selected_date,
              endDate: selected_date,
              key: "range"
            },
            selected_date: props.selected_date
          };
    }
    return null
  }

  handleDuplicateRangeChange = (ranges) => {
    this.setState({
      duplicateRange: ranges.range
    });
  }

  handleAcceptDuplicate = () => {
    const { t, order_materials, selected_date, selected_mixingPlant, duplicate } = this.props;
    const { duplicateRange } = this.state;

    const rangeStart =  duplicateRange.startDate;
    const rangeEnd =  duplicateRange.endDate;
    const range = moment.range(rangeStart, rangeEnd);

    let orderMaterialsByDay = order_materials.filter(om => {
      return moment(om.start).isSame(selected_date, 'day') && om.mixingPlant === selected_mixingPlant;
    });

    for (let a = 0; a < orderMaterialsByDay.length; a++) {
      let mat = orderMaterialsByDay[a];

      duplicate(mat, range);
    }

    this.handleDuplicatePopupClose();
  }

  handleDuplicatePopupOpen = () => {
    this.setState({
      isDuplicatePopupOpen: true
    });
  }

  handleDuplicatePopupClose = () => {
    const selected_date = new Date(this.props.selected_date);

    this.setState({
      duplicateRange: {
        startDate: selected_date,
        endDate: selected_date,
        key: "range"
      },
      isDuplicatePopupOpen: false
    });
  }

  render() {
    let language = undefined;

    switch(i18n.language) {
      case "de":
        language = de;
        break;
      case "en":
        language = enGB;
        break;
      default:
        console.warn("Language not available");
        language = de;
    }

    const { t, order_materials, selected_date, selected_mixingPlant } = this.props;
    const { duplicateRange, isDuplicatePopupOpen } = this.state;

    const weekStart = moment(selected_date).startOf("isoWeek");
    const weekEnd = moment(selected_date).isoWeekday(5);
    
    const staticInputRanges = createStaticRanges([
      {
        label: t("general.whole_week"),
        range: () => ({
          startDate: weekStart,
          endDate: weekEnd
        }) 
      }
    ]);

    let orderMaterialsByDay = order_materials.filter(om => {
      return moment(om.start).isSame(selected_date, 'day') && om.mixingPlant === selected_mixingPlant;
    });

    const isInvalidForDuplicate = orderMaterialsByDay.some(om => {
      return isNaN(parseInt(om.amount)) || om.amount <= 0;
    });

    let orderMaterialsToRender = orderMaterialsByDay.map((m, index) => 
      <Material 
        data={m} 
        key={m.id} 
        id={m.id}
        handleUpdateMaterialByDay={this.props.handleUpdateMaterialByDay}
      />
    ); 

    return (
      <React.Fragment>
        <Header as="h3" style={{marginTop: '0 !important'}}>
          <Header.Content>{t("order.neworder.materials.header")} - {moment(selected_date).format('DD.MM.YYYY')}</Header.Content>
        </Header>
        {/* <Buttons addMaterial={this.props.addMaterialForDay} mixingPlant={order_materials[0].mixingPlant}/> */}
        <Table celled compact>
          <Table.Header>
            <Table.Row style={{fontSize: '12px'}}>
              <Table.HeaderCell>{t("material.header")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.amount")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.start_1")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.end_1")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.tonsperhour")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.mixing_plant")}</Table.HeaderCell>
              <Table.HeaderCell>{t("order.neworder.materials.obs")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderMaterialsToRender}
          </Table.Body>
        </Table>

        <Popup
          on="click"
          open={isDuplicatePopupOpen}
          className="white_popup"
          trigger={
            <Button disabled={isInvalidForDuplicate} icon labelPosition='left'>
              <Icon name="clone outline" />
              {t("general.duplicate")}
            </Button>
          }
          content={
            <div style={{textAlign: "right"}}>
              <DateRangePicker
                locale={language}
                ranges={[duplicateRange]}
                onChange={this.handleDuplicateRangeChange}
                staticRanges={staticInputRanges}
                inputRanges={[]}/>

              <Button
                color="green"
                onClick={this.handleAcceptDuplicate}>
                  <Icon name="checkmark" />
                  {t("buttons.ok")}
              </Button>
            </div>
          }
          onOpen={this.handleDuplicatePopupOpen}
          onClose={this.handleDuplicatePopupClose}
        />
      </React.Fragment>
    );
  }
}

const MaterialsByDay_i18nWrapper = withNamespaces('translation')(MaterialsByDay);

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(MaterialsByDay_i18nWrapper);
