import { AUTHENTICATED, UNAUTHENTICATED, AUTHENTICATION_ERROR, INTENDED_ACTION_AFTER_ERROR } from '../actions/authentication';

export default function(state={}, action) {
  switch(action.type) {
    default:
      return state;
    case AUTHENTICATED:
      return { ...state, authenticated: true };
    case UNAUTHENTICATED:
      return { ...state, authenticated: false };
    case AUTHENTICATION_ERROR:
      return { ...state, error: action.payload };
    case INTENDED_ACTION_AFTER_ERROR:
      return { ...state, [INTENDED_ACTION_AFTER_ERROR]: action.payload };
  }
}